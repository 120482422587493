/* eslint-disable */
import { MdChevronLeft } from "react-icons/md";
import Skeleton from "react-loading-skeleton";

const CardLoader = ({}) => {
  return (
    <>
      {[1, 2, 3, 4, 5, 6].map((item) => {
        return (
          <div
            key={item}
            className="w-full h-24 flex justify-between items-center p-3 bg-white rounded-2xl ts-shadow mb-2 cursor-pointer"
          >
            <div className="flex h-full flex-col justify-between items-start -mt-1">
              <div className="flex justify-start items-start">
                <div className="w-12 h-12 rounded-xl">
                  <Skeleton containerClassName="w-full" className="w-full h-full rounded-xl" />
                </div>
                <div className="flex flex-col justify-start items-start mr-3">
                  <Skeleton className="w-28 h-3" />
                  <Skeleton className="w-24 h-3 my-2" />
                  <Skeleton containerClassName="h-5" className="w-32 h-3" />
                </div>
              </div>
            </div>
            <MdChevronLeft size={25} className="ts-accent" />
          </div>
        );
      })}
    </>
  );
};

export { CardLoader as default };
