/* eslint-disable */
import { ConfigLink, tools, useQueryString } from "core";
import React from "react";
import { MdChevronLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import defaultCar from "../../../../assets/images/part-request-default.svg";
import { Text } from "../../../components";
import { configs } from "./../../../../configs/configs";

interface IMyPartsCardProps {
  data: any;
}

const MyPartsCard: React.FC<IMyPartsCardProps> = React.memo(({ data }) => {
  const navigate = useNavigate();
  const pathname = window.location.pathname;

  const requestClickHandler = () => {
    navigate({
      pathname: ConfigLink.myShopPartDetail,
      search: useQueryString({ id: data.id, ...(pathname === "/all-products" ? { type: "view" } : undefined) }),
    });
  };

  return (
    <div
      onClick={requestClickHandler}
      key={data.id}
      className="w-full flex justify-between items-center p-3 bg-white rounded-2xl ts-shadow mb-2 cursor-pointer"
    >
      <div className="w-11/12 flex h-full flex-col justify-between items-start">
        <div className="w-full flex justify-start items-start">
          <div className="w-12 h-12 rounded-xl">
            <img
              src={data.images && data.images.length !== 0 ? `${configs().imageUrl}/${data.images[0]}` : defaultCar}
              alt="default"
              loading="lazy"
              className={`w-full h-full ${
                data.images && data.images.length !== 0 ? "object-cover aspect-square rounded-xl" : ""
              }`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = defaultCar;
              }}
            />
          </div>
          <div style={{ width: "79%" }} className="flex flex-col justify-start items-start mr-3 truncate">
            <Text type="H6" className="ts-accent w-full truncate">
              {data.name || "---"}
            </Text>
            <Text type="P4" className="ts-gray-07 my-2 truncate w-full">
              {data.cars && data.cars.length !== 0
                ? data.cars.map((item) => item.name).join("، ")
                : data.allCars
                ? "همه خودروها"
                : "---"}
            </Text>
            <Text type="HM" className="ts-primary">
              <strong className="ts-text-h6">{data.price ? tools.thousandSeparator(data.price) : "---"}</strong>{" "}
              {data.price ? "تومان" : ""}
            </Text>
          </div>
        </div>
      </div>
      <MdChevronLeft size={25} className="ts-accent" />
    </div>
  );
});

export { MyPartsCard };

const styles = {};
