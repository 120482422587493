/* eslint-disable */
import { useEffect, useState } from "react";
import { BsCreditCardFill, BsFillInfoCircleFill } from "react-icons/bs";
import { HiLibrary, HiLogin, HiQuestionMarkCircle, HiUserCircle, HiUsers } from "react-icons/hi";
import { IoMdCall } from "react-icons/io";
import { IoCarSport } from "react-icons/io5";
import { RiSettings5Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import user from "../../../assets/images/user.svg";
import { ConfigLink, ServiceStorage, vState } from "../../../core";
import { useChangeTitle } from "../../../core/hooks";
import metadata from "../../../metadata.json";
import { ContainerScroll, DesktopTitle, Text } from "../../components";
import { LogoutModal, MenuButton, ProfileLoader, Subscription } from "./components";

const UserProfile = ({}) => {
  const metaTags = useChangeTitle("ویراژ - پروفایل");
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [logoutShow, setLogoutShow] = useState(false);
  const [categoryIndex, setCategoryIndex] = useState(-1);

  const accountClickHandler = () => {
    navigate(ConfigLink.userSetting);
  };

  const settingClickHandler = () => {
    navigate(ConfigLink.accountSetting);
  };

  const contactClickHandler = () => {
    navigate(ConfigLink.contactUs);
  };

  const paymentsClickHandler = () => {
    navigate(ConfigLink.payments);
  };

  const workshopManageHandler = () => {
    navigate(ConfigLink.manageWorkshops);
  };

  const inviteClickHandler = () => {
    navigate(ConfigLink.inviteUsers);
  };

  const walletClickHandler = () => {
    navigate(ConfigLink.wallet);
  };

  const partRequestClickHandler = () => {
    navigate(ConfigLink.myPartRequest);
  };

  useEffect(() => {
    const profile = ServiceStorage.getAuth();
    console.log(profile);

    if (profile) {
      vState.userInfo = profile;
    }

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const operator = snap.userInfo.roles && snap.userInfo.roles.indexOf("operator") !== -1;
  const earlyAdopter = snap.userInfo.roles && snap.userInfo.roles.indexOf("shop-early-adopter") !== -1;

  const backClickHandler = () => {
    const returnUrl = ServiceStorage.getTempReturnURL();
    console.log(returnUrl);

    ServiceStorage.removeTempReturnURL();
    if (returnUrl && !returnUrl.includes("/profile")) {
      navigate(returnUrl);
    } else {
      navigate(ConfigLink.homePage);
    }
  };

  return (
    <>
      {/* <Header showBackButton backClick={backClickHandler} /> */}
      {metaTags}
      <ContainerScroll heightOffset={50} className="">
        <DesktopTitle title="پروفایل" />
        {loading ? (
          <ProfileLoader />
        ) : (
          <>
            <div className="w-full flex justify-start items-center mb-2">
              <img src={user} alt="tst-user" className="w-14 h-14 rounded-full ml-4"></img>
              <div className="flex flex-col justify-center items-center">
                <Text type="H4" className="ts-accent">
                  {`${snap.userInfo.name || "---"}`}
                </Text>
              </div>
            </div>

            <Subscription />

            {/* <MenuButton
              onClick={walletClickHandler}
              title="کیف پول"
              icon={<IoWallet size={23} className="ts-accent" />}
              extraInfo={() => {
                return (
                  <span className="rounded-full flex justify-center items-center px-4 py-1 ts-bg-primary-light ml-1">
                    <Text type="H6" className="ts-primary">
                      300,000 تومان
                    </Text>
                  </span>
                );
              }}
            /> */}

            <MenuButton
              onClick={accountClickHandler}
              title="حساب کاربری"
              icon={<HiUserCircle size={23} className="ts-accent" />}
            />

            <MenuButton
              onClick={settingClickHandler}
              title="تنظیمات"
              icon={<RiSettings5Fill size={23} className="ts-accent -mt-1" />}
            />

            <MenuButton
              onClick={workshopManageHandler}
              title="مدیریت کسب و کار"
              icon={<HiLibrary size={23} className="ts-accent -mt-1" />}
            />

            <MenuButton
              onClick={paymentsClickHandler}
              title="پرداخت‌ها"
              icon={<BsCreditCardFill size={23} className="ts-accent" />}
            />

            <MenuButton
              onClick={inviteClickHandler}
              title="دعوت از دیگران"
              icon={<HiUsers size={23} className="ts-accent -mt-1" />}
            />

            <MenuButton
              onClick={contactClickHandler}
              title="تماس با ما"
              icon={<IoMdCall size={23} className="ts-accent -rotate-90" />}
            />

            <MenuButton
              onClick={() => window.open("https://owner.virazh.ir/", "_blank")}
              title="گاراژ من"
              icon={<IoCarSport size={20} className="ts-accent" />}
              isExternal
            />

            <MenuButton
              onClick={() => window.open("https://virazh.ir/about", "_blank")}
              title="درباره ما"
              icon={<BsFillInfoCircleFill size={20} className="ts-accent" />}
              isExternal
            />

            <MenuButton
              onClick={() => window.open("https://virazh.ir/provider-guide", "_blank")}
              title="راهنمای کار با نرم افزار"
              icon={<HiQuestionMarkCircle size={20} className="ts-accent" />}
              isExternal
            />

            <MenuButton
              onClick={() => setLogoutShow(true)}
              title="خروج از حساب"
              icon={<HiLogin size={21} className="ts-secondary-red" />}
              logout
            />

            {/* --------- VERSION NUMBER ---------- */}
            <div className="w-full flex flex-col justify-center items-center mt-20">
              <div className="flex justify-center items-center mb-2">
                <Text type="P3" className="ts-gray-06 ml-2">{`ویراژ ©`}</Text>
                <Text type="P3" className="ts-gray-06">
                  {/* @ts-ignore */}
                  {`نسخه ${+metadata.buildMajor}.${+metadata.buildMinor}.${+metadata.buildRevision}`}
                </Text>
              </div>
              <Text type="P5" className="ts-gray-04">
                تمامی حقوق برای ویرا محفوظ است.
              </Text>
            </div>
          </>
        )}
      </ContainerScroll>
      <LogoutModal isOpen={logoutShow} onClose={() => setLogoutShow(false)} />
    </>
  );
};

export { UserProfile as default };
