/* eslint-disable */
import { ApiInstance, useObjectState } from "core";
import React, { useEffect, useState } from "react";
import { BottomModal, Button, InputField, Text, useNotification } from "ui/components";

type ModalType = "add" | "edit";

interface IApproveProvidePartModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDone: () => void;
  id: string;
  type: ModalType;
  data?: any;
}

const ApproveProvidePartModal: React.FC<IApproveProvidePartModalProps> = React.memo(
  ({ isOpen, onClose, onDone, id, type, data }) => {
    const [info, setInfo] = useObjectState({
      price: "",
      description: "",
    });
    const { price, description } = info;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if (type === "edit") {
        if (data && data.status !== "cancelled") {
          setInfo({
            price: data.price
              ? parseFloat(data.price)
                  .toFixed(0)
                  .toString()
                  .split(/(?=(?:\d{3})+$)/)
                  .join(",")
              : "",
            description: data.description || "",
          });
        }
      }
    }, [isOpen]);

    const acceptRequest = async (data) => {
      await ApiInstance.partSupply
        .sendRequest(id, data)
        .then((res) => {
          console.log(res);
          useNotification("درخواست تامین قطعه با موفقیت ارسال شد.", "success");
          onDone();
          setTimeout(() => {
            onClose();
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const editRequest = async (data) => {
      await ApiInstance.partSupply
        .changeRequestStatus(id, "provided", data)
        .then((res) => {
          console.log(res);
          useNotification("درخواست تامین قطعه با موفقیت ارسال شد.", "success");
          onDone();
          setTimeout(() => {
            onClose();
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const approveBtnHandler = () => {
      setLoading(true);

      const data = {
        description: description,
        price: price.replace(/,/gi, ""),
      };

      type === "add" ? acceptRequest(data) : editRequest(data);
    };

    return (
      <BottomModal isOpen={isOpen} onClose={onClose}>
        <div className="w-full flex flex-col justify-start items-start">
          <Text type="H4" className="ts-accent mb-6">
            تایید تامین قطعه
          </Text>

          <Text type="P3" className="ts-accent mb-6">
            پس از تایید شما، قیمت پیشنهادی و همچنین اطلاعات تماس شما در اختیار درخواست دهنده قرار خواهد گرفت. آیا از
            تامین این قطعه اطمینان دارید؟
          </Text>

          <div className="w-full rounded-xl flex flex-col justify-start items-start">
            <InputField
              label={"قیمت پیشنهادی (اختیاری)"}
              value={price}
              inputType="number"
              onChange={(e) => {
                let tempNumber = e.replace(/\D/g, "");
                let commaSeparatedNumber = tempNumber.split(/(?=(?:\d{3})+$)/).join(",");
                setInfo({ price: commaSeparatedNumber });
              }}
              placeholder="قیمت به تومان"
              hint="تومان"
            />

            <InputField
              label={"توضیحات (اختیاری)"}
              value={description}
              fieldType="textarea"
              rows={4}
              onChange={(e) => setInfo({ description: e })}
              className={`w-full rounded-xl bg-transparent outline-none mb-6 placeholder:text-gray-300`}
              placeholder="توضیحاتی درمورد قطعه، نحوه تحویل و..."
            />
            <div className="w-full flex justify-between">
              <Button
                onClick={approveBtnHandler}
                loading={loading}
                bcolor="primary"
                classNameContainer="ts-half-width"
                className="w-full rounded-xl h-12"
              >
                بله، تامین میکنم
              </Button>
              <Button
                onClick={onClose}
                bcolor="primaryTransparent"
                classNameContainer="ts-half-width"
                className="w-full rounded-xl h-12"
              >
                لغو
              </Button>
            </div>
          </div>
        </div>
      </BottomModal>
    );
  },
);

const privileges = [
  {
    label: "مدیر سیستم (ادمین)",
    value: "admin",
  },
  {
    label: "کاربر عادی",
    value: "agent",
  },
];

export { ApproveProvidePartModal };

const styles = {};
