/* eslint-disable */
import NoResult from "assets/images/clients-no-results.svg";
import historyIcon from "assets/images/sms-history-icon.svg";
import { ApiInstance, ConfigLink, useChangeTitle, useFetchQueryString, vState } from "core";
import _debounce from "lodash/debounce";
import { useEffect, useState } from "react";
import { HiUsers } from "react-icons/hi";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import { Button, ContainerScroll, Text, useNotification } from "ui/components";
import { useSnapshot } from "valtio";
import { MessageQueuedNotificationModal, SendMessageToCustomersModal, UserCard } from "./components";

const env = import.meta.env;

interface IQueryStringTransfer {
  id: string;
  subPayed?: string;
}

type SORT_TYPE = "DATE" | "NAME" | null;

const WorkshopClients = ({}) => {
  const metaTags = useChangeTitle("ویراژ - مشتریان من");
  let { id, subPayed } = useFetchQueryString<IQueryStringTransfer>();
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  const [users, setUsers] = useState<any[]>([]);
  const [pendingRequests, setPendingRequests] = useState(0);
  const [count, setCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);

  const [searchText, setSearchText] = useState("");
  const [sortUsers, setSortUsers] = useState<SORT_TYPE>("NAME");

  const [selectMode, setIsSelectMode] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any[]>([]);

  const [queueMessageModal, setQueueMessageModal] = useState(false);

  const getUsers = async (pageIncreased: boolean, query?: string | null) => {
    await ApiInstance.sessions
      .getClientsList(pageCount + 1, sortUsers, query || null)
      .then((res) => {
        console.log(res);
        setCount(res.data.meta.total || res.data.data.length);
        setPageCount((prev) => prev + 1);
        if (pageIncreased) {
          let data = [...users, ...res.data.data];
          setUsers(data);
        } else {
          setUsers(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 400) {
          useNotification(
            "جستجو نتیجه ای نداشت. لطفا متن جستجوی خود را تغییر داده و دوباره امتحان کنید.",
            "error",
            "لیست کاربرها",
          );
        } else {
          useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "لیست کاربرها");
        }
        setLoading(false);
        vState.isEndOfPage = false;
      })
      .finally(() => {
        setLoading(false);
        vState.isEndOfPage = false;
      });
  };

  const getHistory = async () => {
    await ApiInstance.bulkSms
      .getHistory(1, "DATE")
      .then((res) => {
        console.log("history", res);
        const pendingMessages = res.data.data.filter(
          (item) => item.status === "created" || item.status === "payment_succeeded",
        );
        console.log(pendingMessages);
        setPendingRequests(pendingMessages.length);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUsers(false);
    getHistory();

    if (subPayed && subPayed === "1") {
      setQueueMessageModal(true);
    }
  }, []);

  useEffect(() => {
    if (snap.isEndOfPage && count > users.length && !loading) {
      getUsers(true, searchText);
    } else {
      vState.isEndOfPage = false;
    }
  }, [snap.isEndOfPage]);

  const sortChangeHandler = (value) => {
    setSortUsers(value);
    setLoading(true);
    getUsers(false, searchText);
  };

  const callApi = (e) => {
    setLoading(true);
    getUsers(false, e);
  };
  const [debouncedCallApi] = useState(() => _debounce((e) => callApi(e), 1100));

  const handleChange = (e) => {
    setSearchText(e);
    debouncedCallApi(e);
  };

  const resetSearch = () => {
    setPageCount(0);
    setSearchText("");
    setLoading(true);
    setTimeout(() => {
      callApi(null);
    }, 1100);
  };

  const userClickHandler = (user) => {
    if (selectMode) {
      let users: any[] = [];
      const userIndex = selectedUser.findIndex((item) => item.id === user.id);

      if (userIndex === -1) {
        if (selectedUser.length === 100) {
          navigator.vibrate(300);
          useNotification("نمی‌توانید بیشتر از صد گیرنده برای ارسال پیامک انبوه انتخاب نمایید.", "warning");
          return;
        } else {
          users = [...selectedUser, user];
        }
      } else {
        users = selectedUser.filter((item) => item.id !== user.id);
      }
      setSelectedUser(users);
    } else {
      navigate({ pathname: ConfigLink.clientDetail.replace(":client_id", user.userProfileId) });
    }
  };

  const sortData = [
    {
      label: "به ترتیب تاریخ",
      value: "DATE",
    },
    {
      label: "به ترتیب الفبا",
      value: "NAME",
    },
  ];

  const inviteCustomerHandler = () => {
    navigate({ pathname: ConfigLink.inviteUsers });
  };

  const selectUserHandler = () => {
    if (selectMode) {
      if (selectedUser.length < 1) {
        useNotification("تعداد گیرندگان پیامک انبوه نمی‌تواند کمتر از بیست و پنج نفر باشد.", "info");
      } else {
        setMessageModal(true);
      }
    } else {
      setIsSelectMode(true);
    }
  };

  const removeSelectedUser = (id) => {
    const usersList = selectedUser.filter((item) => item.id !== id);
    setSelectedUser(usersList);
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={65} className="relative">
        <div className="w-full flex justify-between items-center mb-6">
          <Text type="H4" className="ts-accent">
            مشتریان من
          </Text>

          <span onClick={inviteCustomerHandler} className="flex justify-center items-center cursor-pointer ts-primary">
            <HiUsers size={21} />
            <Text type="H6" className="mr-1">
              دعوت از دیگران
            </Text>
          </span>
        </div>

        {!loading && searchText.length === 0 && count === 0 ? (
          <Text type="P3" className="ts-gray-06 -mt-3">
            در این قسمت مشتریانی که ثبت کرده اید نمایش داده می شوند که میتوانید با آنها طریق پیامک یا تماس در ارتباط
            باشید.
          </Text>
        ) : (
          <>
            {/* <SearchInput value={searchText} onChange={handleChange} onReset={resetSearch} /> */}

            {selectMode && (
              <div className="w-full flex justify-between items-center mb-5">
                <Text type="H6" onClick={() => setSelectedUser([...users])} className="ts-primary cursor-pointer">
                  انتخاب همه مشتریان
                </Text>

                <Text
                  type="H6"
                  onClick={() => {
                    setIsSelectMode(false);
                    setSelectedUser([]);
                  }}
                  className="ts-primary cursor-pointer"
                >
                  لغو
                </Text>
              </div>
            )}

            <div className="w-full flex justify-between items-center mb-6">
              {loading ? (
                <Skeleton className="w-28" />
              ) : (
                <Text type="H6" className="ts-accent">
                  {`${count} مشتری`}
                </Text>
              )}
              {/* <SelectPicker
                size="lg"
                data={sortData}
                value={sortUsers}
                disabled={loading}
                cleanable={false}
                onChange={(value: any) => sortChangeHandler(value)}
                searchable={false}
                placeholder="مرتب سازی"
                className={`w-36 h-12 outline-none rounded-xl`}
              /> */}
            </div>
          </>
        )}

        {loading ? (
          [1, 2, 3, 4, 5].map((item) => {
            return (
              <Skeleton style={{ height: "76px" }} containerClassName="w-full" className="w-full rounded-2xl mb-2" />
            );
          })
        ) : users.length === 0 ? (
          <div className="w-full flex flex-col justify-center items-center mt-12">
            <img src={NoResult} alt="no-result" />
            <Text type="P4" className="ts-accent mt-6">
              {searchText.length === 0 ? "شما تا کنون هیچ مشتری ثبت نکرده اید." : "نتیجه‌ای یافت نشد."}
            </Text>
          </div>
        ) : (
          users.map((item) => {
            return (
              <UserCard
                key={item.id}
                data={item}
                onClick={() => userClickHandler(item)}
                selectMode={selectMode}
                selectedUser={selectedUser}
              />
            );
          })
        )}
        {snap.isEndOfPage && !loading && (
          <div className="w-full flex justify-center">
            <Loader size="sm" />
          </div>
        )}
      </ContainerScroll>

      {users.length !== 0 && snap.activeFeatures.includes("bulk_sms") && (
        <div className="w-full flex justify-between items-center gap-2.5 fixed bottom-6 left-0 px-6 max-2xs:px-3 z-30 ts-bulk-float-button">
          <Button
            onClick={selectUserHandler}
            disabled={selectMode && selectedUser.length === 0}
            textType="H6"
            bcolor="primary"
            classNameContainer="w-full"
            className="w-full h-12 rounded-xl mr-0"
          >
            {selectMode
              ? `انتخاب مشتریان ${selectedUser.length > 0 ? `(${selectedUser.length} نفر)` : ""}`
              : `ارسال پیامک انبوه`}
          </Button>
          {pendingRequests !== 0 && (
            <Button
              onClick={() => navigate(ConfigLink.clientsBulkMessageHistory)}
              classNameContainer="w-12"
              className="w-12 h-12 ts-bg-gray-08"
              icon={<img src={historyIcon} alt="hstry" />}
            ></Button>
          )}
        </div>
      )}

      <SendMessageToCustomersModal
        isOpen={messageModal}
        onClose={() => setMessageModal(false)}
        users={selectedUser}
        removeUser={(id) => removeSelectedUser(id)}
        id={id}
      />

      <MessageQueuedNotificationModal isOpen={queueMessageModal} onClose={() => setQueueMessageModal(false)} id={id} />
    </>
  );
};

export { WorkshopClients as default };
