/* eslint-disable */
import React from "react";
import { BiChevronLeft } from "react-icons/bi";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { MdOutlineAccessTimeFilled } from "react-icons/md";
import { useNavigate } from "react-router";
import defaultImage from "../../../../assets/images/default-car.svg";
import { ConfigLink } from "../../../../core";
import { Image, Text } from "../../../components";

interface ICarsCardProps {
  data: any;
  id: number;
  backRoute: string;
}

const CarsCard: React.FC<ICarsCardProps> = React.memo(({ data, id, backRoute = ConfigLink.dashboard }) => {
  const navigate = useNavigate();

  const itemClickHandler = () => {
    if (!data.plate.deletedAt) {
      navigate({
        pathname: ConfigLink.carStatus.replace(":id", data.id),
      });
    }
  };

  return (
    <div
      key={id}
      onClick={itemClickHandler}
      className={`w-full relative ${
        data.plate.deletedAt ? "ts-bg-gray-01" : "bg-white"
      } ts-cars-card-height flex justify-between items-center p-3 mb-3 ts-shadow rounded-2xl`}
    >
      <div className="w-full flex h-full justify-start items-center">
        <div
          style={{ width: "69px" }}
          className=" h-full flex justify-center rounded-xl ts-bg-gray-01 items-center relative"
        >
          <Image thumbnail src={defaultImage} width="53px" height="25px" className="" />
        </div>
        <div style={{ width: "75%" }} className="mr-2 h-full flex flex-col justify-between items-start">
          <div className="flex justify-end items-center">
            <Text type="H6" className={`${data.plate.deletedAt ? "ts-gray-03" : "ts-accent"} `}>
              {`ایران ${data.plate.plateNumber.substring(7, 9)}-${data.plate.plateNumber.substring(3, 6)} ${
                data.plate.plateNumber.slice(2, 3) === "ا" ? "الف" : data.plate.plateNumber.slice(2, 3)
              } ${data.plate.plateNumber.slice(0, 2)}`}
            </Text>
          </div>
          <div style={{ width: "99%" }} className="truncate flex justify-start items-center">
            <Text type="P5" className={`${data.plate.deletedAt ? "ts-gray-04" : "text-black"}`}>
              {data.plate.contact ? data.plate.contact.contactName || "" : ""}
            </Text>
            <Text type="P5" className={`${data.plate.deletedAt ? "ts-gray-04" : "text-black"} whitespace-pre pt-1`}>
              {data.plate.carName && data.plate.contact && data.plate.contact.contactName ? ` - ` : ``}
            </Text>
            <Text type="P5" className={`${data.plate.deletedAt ? "ts-gray-04" : "ts-gray-06"}  truncate`}>
              {data.plate.carName || ""}
            </Text>
          </div>
          {data.plate.deletedAt ? (
            <Text type="P5" className={`ts-gray-08`}>
              این خودرو توسط مشتری حذف گردیده است.
            </Text>
          ) : (
            <div className="flex justify-end items-center">
              {data.status === "completed" ? (
                <IoIosCheckmarkCircle size={18} className="ts-success" />
              ) : (
                <MdOutlineAccessTimeFilled size={18} className="ts-warning" />
              )}
              <Text type="HM" className={`mr-1 ${data.status === "completed" ? "ts-success" : "ts-warning"}`}>
                {`${data.status === "completed" ? "ترخیص شده" : "در انتظار ترخیص"}`}
              </Text>
            </div>
          )}
        </div>
      </div>

      <BiChevronLeft size={30} className={`${data.plate.deletedAt ? "ts-gray-03" : "ts-accent"}  -mt-1`} />
    </div>
  );
});

export { CarsCard };

const styles = {};
