/* eslint-disable */
import React from "react";
import { Text } from "../../../components";

interface IFeedbackStatisticsProps {
  label: string;
  data: any;
}

const FeedbackStatistics: React.FC<IFeedbackStatisticsProps> = React.memo(({ label, data }) => {
  if (data) {
    return (
      <div className="w-full mt-4 rounded-2xl p-4 pb-3 ts-bg-gray-01 flex flex-col justify-start items-start">
        <Text type="H6" className="ts-gray-08 mb-4">
          {label}
        </Text>
        <div className="w-full ts-feedback-bar-height rounded-full flex justify-start items-start ts-feedback-chart-bg">
          <div style={{ width: `${data.ones}%` }} className="ts-success-bar h-full ts-bg-success rounded-full"></div>
          <div
            style={{ width: `${data.zeros}%` }}
            className={`h-full ts-bg-secondary-red rounded-full ${data.ones !== 0 ? "-mr-1" : ""}`}
          ></div>
        </div>
      </div>
    );
  } else {
    return null;
  }
});

export { FeedbackStatistics };

const styles = {};
