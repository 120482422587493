/* eslint-disable */
import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import carOne from "../../../../assets/images/welcome-image-1.svg";
import carTwo from "../../../../assets/images/welcome-image-2.svg";
import carThree from "../../../../assets/images/welcome-image-3.svg";
import { ConfigLink, useChangeTitle, vState } from "../../../../core";
import { Button, Image, Text } from "../../../components";

interface IWelcomeProps {}

const env = import.meta.env;

const Welcome: React.FC<IWelcomeProps> = React.memo(() => {
  const metaTags = useChangeTitle("معرفی");
  const navigate = useNavigate();
  const snap = useSnapshot(vState);

  const [step, setStep] = useState(0);

  const buttonClickHandler = () => {
    if (step === 2) {
      navigate(ConfigLink.createWorkspaceStepOne);
    } else {
      setStep((prev) => prev + 1);
    }
  };

  const changeSlideHandler = (index) => {
    setStep(index);
  };

  const info = [
    {
      id: 0,
      title: "یادآوری سرویس",
      tip: "با ثبت سرویس انجام شده بر روی خودرو، مالک خودرو با دریافت پیامک از موعد تعویض و یا چک آپ خودرو خود مطلع می شود.",
      image: carOne,
    },
    {
      id: 1,
      title: "ثبت  سوابق مشتریان",
      tip: "با اسکن پلاک مالکین خودرو، به تمامی سوابق تعمیر/تعویض قطعات انجام شده دسترسی خواهید داشت. همچنین شما می توانید تمامی سرویس های انجام شده توسط خود را در سوابق خودرو ثبت کنید",
      image: carTwo,
    },
    {
      id: 2,
      title: "تعمیرگاه",
      tip: "برای ثبت پلاک و ایجاد یادآور لازم است اطلاعات تجاری تعمیرگاه خود را وارد کنید. پس از اضافه شدن تعمیرگاه، میتوانید از تمامی خدمات ویراژ استفاده کنید.",
      image: carThree,
    },
  ];

  return (
    <>
      <div className="w-full mt-12 bg-white rounded-xl p-6 ts-shadow flex flex-col justify-center items-center">
        <Text type="H3" className="ts-accent mb-4 ts-letter-space">
          خوش آمدید
        </Text>

        <div style={{ direction: "ltr" }} className="w-full flex flex-col justify-center items-center">
          <Carousel
            className="relative"
            selectedItem={step}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
            swipeable={true}
            onChange={changeSlideHandler}
          >
            {info.map((item, index) => {
              return (
                <div style={{ direction: "rtl" }} className="w-full flex flex-col justify-center items-center">
                  <Image src={item.image} alt={`car-image-${step}`} className={`mb-6`} />
                  <Text type="H5" className="mb-4 ts-letter-space ts-gray-08">
                    {item.title}
                  </Text>
                  <Text type="P3" className="h-28 ts-letter-space ts-gray-08">
                    {item.tip}
                  </Text>
                </div>
              );
            })}
          </Carousel>
        </div>

        <Button
          bcolor="primary"
          textType="H6"
          classNameContainer="w-full"
          className="w-full h-12 rounded-xl ts-letter-space mt-7"
          onClick={buttonClickHandler}
        >
          {step === 2 ? "اضافه کردن تعمیرگاه" : "ادامه"}
        </Button>
      </div>

      {step === 2 && (
        <Text
          type="H6"
          className="ts-accent cursor-pointer text-center mt-8"
          onClick={() => navigate(ConfigLink.homePage, { replace: true })}
        >
          بعدا اضافه میکنم
        </Text>
      )}
    </>
  );
});

export { Welcome };

const styles = {};
