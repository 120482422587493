/* eslint-disable */
import { tools } from "core";
import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { BiSquareRounded } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import { IoCheckbox, IoClose } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import { Form, Input, InputGroup } from "rsuite";
import { BackButton, Button, CarsLevelWrapper, useNotification } from "ui/components";
import { useSnapshot } from "valtio";
import { BottomModal, Text } from "../";
import { ApiInstance, vState } from "../../../core";
import { CarsLoader } from "./CarsLoader";

interface ICarsModalProps {
  isSingleChoice?: boolean;
  selectedCars: any[] | any;
  selectCarHandler: (value: any, type?: "isAll" | "cars") => void;
  isOpen: boolean;
  onClose: () => void;
  allCarsSelected?: boolean;
}

interface ICarType {
  id: string;
  name: string;
  children: any[];
}

interface ICarBrandType {
  id: string;
  name: string;
  children: number;
}

type ILevelType = "brand" | "model" | "type";

const CarsModal: React.FC<ICarsModalProps> = React.memo(
  ({ isOpen, onClose, selectedCars, selectCarHandler, isSingleChoice = true, allCarsSelected = false }) => {
    const snap = useSnapshot(vState);

    const [carBrandAll, setCarBrandAll] = useState<ICarBrandType[]>([]);
    const [carModelAll, setCarModelAll] = useState<ICarType[]>([]);
    const [carTypeAll, setCarTypeAll] = useState<ICarType[]>([]);
    const [carBrand, setCarBrand] = useState<ICarBrandType[]>([]);
    const [carModel, setCarModel] = useState<ICarType[]>([]);
    const [carType, setCarType] = useState<ICarType[]>([]);
    // --------------
    const [selectedBrand, setSelectedBrand] = useState<ICarType | null>(null);
    const [selectedModel, setSelectedModel] = useState<ICarType | null>(null);
    const [selectedType, setSelectedType] = useState<ICarType | null>(null);

    const [selectedCar, setSelectedCar] = useState<ICarType[] | null>(null);
    const [allSupported, setAllSupported] = useState<boolean>(false);
    // --------------
    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(true);
    const [disabled, setDisabled] = useState(true);
    const [level, setLevel] = useState(1);

    const removeAllTypeItem = (data) => {
      return data.filter((item) => !item.name.includes("همه"));
    };

    const getCarsList = async () => {
      await ApiInstance.car
        .getCarBrands()
        .then((res) => {
          console.log(res);
          const data = isSingleChoice ? removeAllTypeItem(res.data.data) : res.data.data;
          setCarBrand(data);
          setCarBrandAll(data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const getCarModels = async (id) => {
      await ApiInstance.car
        .getCarModels(id)
        .then((res) => {
          console.log(res);
          const data = isSingleChoice ? removeAllTypeItem(res.data.data) : res.data.data;
          setCarModel(data);
          setCarModelAll(data);
          resetSearch();
          setLevel(2);
        })
        .catch((err) => {
          console.log(err);
          useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    useEffect(() => {
      if (carBrand.length === 0) {
        getCarsList();
      }

      if (isOpen) {
        if (allCarsSelected) {
          setAllSupported(allCarsSelected);
        }
        if (isSingleChoice) {
          console.log("selectedCars", selectedCars);
          if (selectedCars && selectedCars.id && selectedCars.id.length !== 0) {
            setSelectedCar([selectedCars]);
          }
        } else {
          if (selectedCars) {
            setSelectedCar(selectedCars);
          }
        }
      }
    }, [isOpen]);

    // useEffect(() => {
    //   document.getElementById("car-holder")?.scrollTop(0);
    // }, [level]);

    useEffect(() => {
      setDisabled((selectedCar === null || selectedCar.length === 0) && !allSupported);
      console.log("selectedCar", selectedCar);
    }, [selectedCar, allSupported]);

    const resetSearch = () => {
      setSearchText("");
      if (level === 1) {
        setCarBrand(carBrandAll);
      } else if (level === 2) {
        setCarModel(carModelAll);
      } else {
        setCarType(carTypeAll);
      }
    };

    const revertAllSupportSelectHandler = () => {
      if (allSupported) setAllSupported(false);
    };

    const isSelectedBefore = (item) => {
      if (selectedCar) {
        const index = selectedCar.findIndex((car) => car.id === item.id);
        const finalArray =
          index === -1
            ? isSingleChoice
              ? [item]
              : [...selectedCar, item]
            : isSingleChoice
            ? null
            : selectedCar.filter((car) => car.id !== item.id);
        setSelectedCar(finalArray);
      } else {
        setSelectedCar([item]);
      }
      revertAllSupportSelectHandler();
    };

    const selectBrandHandler = (item) => {
      setSelectedBrand(selectedBrand && selectedBrand.id === item.id ? null : item);
      if (item.children === 0) {
        isSelectedBefore(item);
      } else {
        setLoading(true);
        getCarModels(item.id);
      }
    };

    const selectModelHandler = (item) => {
      setSelectedModel(selectedModel && selectedModel.id === item.id ? null : item);
      if (item.children.length !== 0) {
        const data = isSingleChoice ? removeAllTypeItem(item.children) : item.children;
        setCarType(data);
        setCarTypeAll(data);
        setLevel(3);
        resetSearch();
      } else {
        isSelectedBefore(item);
      }
    };

    const selectTypeHandler = (item) => {
      setSelectedType(selectedType && selectedType.id === item.id ? null : item.id);
      isSelectedBefore(item);
    };

    const confirmClickHandler = () => {
      if (!isSingleChoice && allSupported) {
        selectCarHandler(allSupported, "isAll");
      }
      if (selectedCar) {
        selectCarHandler(isSingleChoice ? selectedCar[0] : selectedCar, "cars");
      }
      onClose();
    };

    const removeCarInChips = (item) => {
      const finalArray = selectedCar ? selectedCar?.filter((cars) => cars.id !== item.id) : null;
      setSelectedCar(finalArray);
      if (level === 1) {
        setSelectedBrand(null);
      } else if (level === 2) {
        setSelectedModel(null);
      } else {
        setSelectedType(null);
      }
    };

    const searchHandler = (e) => {
      console.log(e);
      setSearchText(e);
      let result: ICarType[] | ICarBrandType[] = [];
      if (level === 1) {
        e.length === 0 ? (result = carBrandAll) : (result = carBrandAll.filter((item) => item.name.includes(e)));
        setCarBrand(result);
      } else if (level === 2) {
        e.length === 0 ? (result = carModelAll) : (result = carModelAll.filter((item) => item.name.includes(e)));
        setCarModel(result);
      } else {
        e.length === 0 ? (result = carTypeAll) : (result = carTypeAll.filter((item) => item.name.includes(e)));
        setCarType(result);
      }
    };

    return (
      <BottomModal forPWA isOpen={isOpen} onClose={onClose} carModal className="relative ts-car-modal">
        <div className="w-full sticky top-0 pt-10 z-40 bg-white">
          <div className="w-full flex justify-between items-center -mt-5">
            <Text type="H4" className="ts-accent" onClick={() => setLoading((prev) => !prev)}>
              انتخاب برند و تیپ
            </Text>
            <MdClose onClick={onClose} size={27} className="ts-gray-05 cursor-pointer -mt-px" />
          </div>
          {!isSingleChoice && selectedCar && selectedCar?.length !== 0 && (
            <Text onClick={() => setSelectedCar(null)} type="H6" className="ts-primary cursor-pointer mt-6">
              حذف همه فیلترها
            </Text>
          )}
          <Text type="P4" className="ts-gray-07 mt-6 mb-4">
            یک برند و تیپ را انتخاب کنید.
          </Text>

          <Form
            fluid
            // onSubmit={submitSearchHandler}
            style={{ padding: "0 0.5px" }}
            className={`w-full transition-all rounded-2xl`}
          >
            <InputGroup disabled={loading} inside className="w-full h-11 outline-none border-white rounded-2xl">
              <InputGroup.Button
                onClick={resetSearch}
                className={`${
                  searchText.length === 0 ? "hidden" : ""
                } left-9 h-full bg-transparent hover:bg-transparent mr-7`}
              >
                <IoMdClose size={19} className={`transition-colors duration-200 -mt-1`} />
              </InputGroup.Button>
              <InputGroup.Button type="submit" className="h-full left-0 bg-transparent hover:bg-transparent">
                <AiOutlineSearch size={23} className={`ts-gray-03 transition-colors duration-200 -mt-1`} />
              </InputGroup.Button>
              <Input
                value={searchText}
                onChange={(e) => searchHandler(e)}
                className="h-full outline-none border-none rounded-2xl ts-input-shadow placeholder:text-gray-200"
                placeholder="جستجو..."
              />
            </InputGroup>
          </Form>
          {selectedCar && !isSingleChoice && (
            <div className="flex flex-nowrap overflow-auto ts-hide-scroll-but-keep-working mt-4">
              {selectedCar.map((item) => {
                return (
                  <div
                    onClick={() => removeCarInChips(item)}
                    className="flex h-6 justify-center items-center ml-1 rounded-full ts-bg-gray-01 py-1 px-4"
                  >
                    <Text type="HM" className="ts-accent w-max">{`${item.name || "---"}`}</Text>
                    <IoClose size={20} className="ts-accent -mt-1" />
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div id="car-holder" className="w-full ts-select-car-border mt-4 rounded-xl px-4 py-3 mb-20">
          {loading ? (
            <CarsLoader />
          ) : (
            <>
              {level !== 1 && <BackButton onClick={() => setLevel((prev) => prev - 1)} level={level} />}
              {level === 1 ? (
                <>
                  {!isSingleChoice && (
                    <>
                      <div
                        onClick={() => {
                          setAllSupported((prev) => !prev);
                          setSelectedCar(null);
                        }}
                        className="w-full flex justify-between items-center cursor-pointer"
                      >
                        <div className="flex justify-center items-center">
                          {allSupported ? (
                            <IoCheckbox size={23} className="ts-primary" />
                          ) : (
                            <BiSquareRounded size={23} className="ts-gray-04" />
                          )}
                          <Text type="P4" className="ts-accent mr-2">
                            مناسب برای همه مدل‌های خودرو
                          </Text>
                        </div>
                      </div>
                      <hr className="w-full h-px border-none ts-bg-gray-02 mt-4 mb-4" />
                    </>
                  )}
                  {carBrand.map((item: any, index) => {
                    return (
                      <CarsLevelWrapper
                        data={item}
                        index={index}
                        level={level}
                        mainList={carBrand}
                        onClick={() => selectBrandHandler(item)}
                        selectedCar={selectedCar}
                        selectedItem={selectedBrand}
                        hasLogo
                      />
                    );
                  })}
                </>
              ) : level === 2 ? (
                carModel.map((item: any, index) => {
                  return (
                    <CarsLevelWrapper
                      data={item}
                      index={index}
                      level={level}
                      mainList={carModel}
                      onClick={() => selectModelHandler(item)}
                      selectedCar={selectedCar}
                      selectedItem={selectedModel}
                    />
                  );
                })
              ) : (
                carType.map((item: any, index) => {
                  return (
                    <CarsLevelWrapper
                      data={item}
                      index={index}
                      level={level}
                      mainList={carType}
                      onClick={() => selectTypeHandler(item)}
                      selectedCar={selectedCar}
                      selectedItem={selectedType}
                    />
                  );
                })
              )}
            </>
          )}
        </div>

        <Button
          bcolor="primary"
          onClick={confirmClickHandler}
          disabled={disabled}
          classNameContainer={`ts-car-modal-float-button ${tools.isIOS() ? "ts-approve-btn-ios" : ""}`}
          className="w-full h-12 rounded-xl"
        >
          تایید
        </Button>
      </BottomModal>
    );
  },
);

export { CarsModal };

const styles = {};
