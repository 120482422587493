/* eslint-disable */
import { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Form } from "rsuite";
import { useSnapshot } from "valtio";
import { ApiInstance, ConfigLink, ServiceStorage, vState } from "../../../core";
import { useChangeTitle } from "../../../core/hooks";
import { Button, ContainerScroll, InputField, SelectCity, Text, useNotification } from "../../components";
import { Header } from "../../_layouts/components";

const env = import.meta.env;

const CompleteAccount = ({}) => {
  const metaTags = useChangeTitle("ویراژ - تکمیل پروفایل");
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [name, setName] = useState("");
  const [city, setCity] = useState<any>(null);
  const [cityModal, setCityModal] = useState(false);

  const getProfile = async () => {
    await ApiInstance.account.getUserProfile().then((res: any) => {
      console.log(res);
      vState.userInfo = res;
      setName(res.name || "");
    });
  };

  const getCities = async (cityId) => {
    const citiesList = await ApiInstance.common.getAllCities();
    const selectedCity = citiesList.filter((item) => item.value === cityId);
    if (selectedCity.length !== 0) {
      setCity(selectedCity[0]);
    }
  };

  const getUserCity = async () => {
    await ApiInstance.account
      .getUserSetting()
      .then((res) => {
        console.log(res);
        const cityId = res.data.cityId;
        setCity(cityId || null);
        if (cityId) {
          getCities(cityId);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setDisabled(name.length === 0 || city === null);
  }, [name, city]);

  useEffect(() => {
    getUserCity();
    const profile: any = ServiceStorage.getAuth();

    if (profile) {
      console.log(profile);
      vState.userInfo = profile;
      setName(profile.name || "");
    } else {
      getProfile();
    }
  }, []);

  const saveInfo = () => {
    const copyUser = { ...snap.userInfo, name: name };
    console.log("copyUser", copyUser);
    ServiceStorage.setAuth(copyUser);
  };

  const changeNameHandler = async () => {
    setLoading(true);

    await ApiInstance.account
      .updateUserProfile(name)
      .then((res) => {
        vState.userInfo.name = name;
        saveInfo();
        cityRegisterHandler();
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا مجددا تلاش کنید.", "error", "تکمیل پروفایل");
      });
  };

  const cityRegisterHandler = async () => {
    await ApiInstance.account
      .setUserSetting({ cityId: city ? city.value : "" })
      .then((res) => {
        console.log("res", res);
        useNotification("اطلاعات حساب کاربری شما تکمیل شد.", "success");
        navigate(ConfigLink.homePage);
      })
      .catch((err) => {
        console.log("err", err);
        useNotification("مشکلی رخ داده است. لطفا مجددا تلاش کنید.", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Header />
      {metaTags}
      <ContainerScroll heightOffset={50} className="">
        <Text type="H4" className="ts-accent mb-8">
          تکمیل پروفایل
        </Text>

        <Form fluid className="w-full">
          <Text type="P3" className="ts-gray-08 mb-6">
            لطفا پروفایل خود را کامل کنید.
          </Text>
          <InputField
            label={"نام و نام خانوادگی"}
            value={name}
            onChange={(e) => setName(e)}
            placeholder="نام و نام خانوادگی"
          />

          <Text type="H6" className="ts-accent mb-2">
            شهر
          </Text>

          <div
            onClick={() => setCityModal(true)}
            className={`transition-all mb-6 w-full cursor-pointer rounded-xl p-4 flex justify-between items-center h-12 ts-input-border`}
          >
            <Text type="P4" className="ts-accent">
              {city ? city.label : "شهر محل سکونت خود را انتخاب کنید"}
            </Text>
            <BiChevronDown size={21} className="ts-accent -mt-px" />
          </div>

          <Button
            onClick={changeNameHandler}
            loading={loading}
            disabled={disabled}
            bcolor="primary"
            textType="H6"
            classNameContainer="w-full"
            className="w-full h-12 rounded-xl mb-4"
          >
            تکمیل پروفایل
          </Button>
          <Button
            onClick={() => navigate(ConfigLink.homePage)}
            bcolor="primaryTransparent"
            textType="H6"
            classNameContainer="w-full"
            className="w-full h-12 rounded-xl"
          >
            بعدا تکمیل می‌کنم
          </Button>
        </Form>
      </ContainerScroll>

      <SelectCity
        isOpen={cityModal}
        selectedId={city ? city.value : ""}
        onClose={() => setCityModal(false)}
        onSelect={(city) => setCity(city)}
      />
    </>
  );
};

export { CompleteAccount as default };
