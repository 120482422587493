/* eslint-disable */
import { ConfigLink } from "core";
import React, { useEffect, useState } from "react";
import { BiChevronLeft, BiCurrentLocation } from "react-icons/bi";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { StarRating } from "ui/_layouts/components";
import thumbnail from "../../../../assets/images/workshop-card-img.svg";
import { Button, Text } from "../../../components";
import { useQueryString } from "./../../../../core/hooks/useQueryString";

interface IChangeViewFooterProps {
  modeChangeHandler: () => void;
  viewMode: 1 | 2;
  selectedWorkshop: null | any;
  onGetLocation: () => void;
  locationLoading: boolean;
}

const ChangeViewFooter: React.FC<IChangeViewFooterProps> = React.memo(
  ({ modeChangeHandler, viewMode, selectedWorkshop, onGetLocation, locationLoading }) => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery({
      query: "(max-width: 389px)",
    });

    const [boxOpacity, setBoxOpacity] = useState(false);

    useEffect(() => {
      if (selectedWorkshop) {
        setTimeout(() => {
          setBoxOpacity(true);
        }, 200);
      } else {
        setBoxOpacity(false);
      }
    }, [selectedWorkshop]);

    const showMoreHandler = () => {
      navigate({ pathname: ConfigLink.workshopInfo, search: useQueryString({ id: selectedWorkshop.id }) });
    };

    return (
      <div
        className={`w-full transition-all fixed ts-map-footer-bottom z-50 flex flex-col justify-center items-start px-6 max-2xs:px-3`}
      >
        {viewMode === 1 && (
          <Button
            bcolor="white"
            onClick={onGetLocation}
            icon={<BiCurrentLocation size={28} className="ts-primary ts-my-location-icon" />}
            loading={locationLoading}
            classNameContainer="w-12 h-12 ts-shadow mb-3"
            className="w-full h-full rounded-xl flex justify-center items-center bg-white ts-shadow z-40"
          ></Button>
        )}
        {selectedWorkshop && (
          <div
            className={`w-full relative transition-all break-normal delay-100 p-3 bg-white rounded-xl ts-shadow flex justify-between items-end mb-3 ${
              boxOpacity ? "opacity-100" : "opacity-0"
            }`}
          >
            <div className="w-full h-full flex justify-center items-center">
              <img src={thumbnail} alt="thumbnail" className="ml-2" />
              <div className="w-full flex flex-col justify-between items-start">
                <Text type="H6" className={`ts-accent mb-3 truncate w-1/2`}>
                  {selectedWorkshop.name || "---"}
                </Text>
                <Text type="P4" className={`ts-gray-06 truncate ${isMobile ? "w-3/5" : "w-1/2"}`}>
                  {selectedWorkshop?.categories
                    ? selectedWorkshop.categories.map((item) => item.name).join(", ")
                    : "---"}
                </Text>
              </div>
            </div>
            <div className="w-full h-full flex flex-col justify-end items-end absolute p-3 left-0 bottom-0">
              {selectedWorkshop.averageScore !== 0 && (
                <div className="flex justify-center items-center -mt-1.5">
                  <Text type="P4" className="ts-gray-06 ml-1 mt-0.5">
                    {selectedWorkshop.averageScore ? parseFloat(selectedWorkshop.averageScore).toFixed(1) : 0}
                  </Text>
                  <StarRating
                    // @ts-ignore
                    initialValue={
                      selectedWorkshop.averageScore ? parseFloat(selectedWorkshop.averageScore).toFixed(1) : 0
                    }
                    size={isMobile ? 16 : 18}
                  />
                </div>
              )}
              <div onClick={showMoreHandler} className={`flex mt-2 justify-center items-center cursor-pointer`}>
                <Text type="H6" className="ts-primary ml-1 w-max">
                  {isMobile ? "بیشتر" : "اطلاعات بیشتر"}
                </Text>
                <BiChevronLeft size={23} className="ts-primary -mt-0.5" />
              </div>
            </div>
          </div>
        )}
        <Button
          onClick={modeChangeHandler}
          bcolor="black"
          textType="H6"
          classNameContainer="w-full"
          className="w-full h-12"
        >
          {viewMode === 1 ? "نمایش لیست" : "نمایش نقشه"}
        </Button>
      </div>
    );
  },
);

export { ChangeViewFooter };

const styles = {};
