import { account } from "./account";
import { agents } from "./agents";
import { bulkSms } from "./bulkSms";
import { car } from "./car";
import { common } from "./common";
import { map } from "./map";
import { partSupply } from "./partSupply";
import { plates } from "./plates";
import { searchWorkshops } from "./searchWorkshops";
import { sessions } from "./sessions";
import { shop } from "./shop";
import { subscription } from "./subscription";
import { workshop } from "./workshop";

const ApiInstance = {
  workshop,
  account,
  car,
  plates,
  common,
  sessions,
  agents,
  partSupply,
  shop,
  subscription,
  searchWorkshops,
  map,
  bulkSms,
};

export { ApiInstance };
