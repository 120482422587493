/* eslint-disable */
import { vState } from "core";
import React from "react";
import { IoMdCheckmark } from "react-icons/io";
import { useSnapshot } from "valtio";
import { Button } from "../../../components";

interface ICustomHeaderProps {
  onAbortClicked: () => void;
  onDischargeClicked: () => void;
}

const env = import.meta.env;

const CustomHeader: React.FC<ICustomHeaderProps> = React.memo(({ onAbortClicked, onDischargeClicked }) => {
  const snap = useSnapshot(vState);

  return (
    <div
      className={`ts-discharge-button-wrapper transition-all fixed top-3 max-2xs:left-3 left-6 flex justify-center items-center z-50`}
    >
      {snap.carStatus.status !== "completed" && (
        <Button onClick={onAbortClicked} bcolor="gray" textType="H6" className={`h-11 transition-all`}>
          انصراف
        </Button>
      )}
      <Button
        onClick={onDischargeClicked}
        icon={<IoMdCheckmark size={19} className="text-white" />}
        bcolor="primary"
        disabled={snap.carStatus.status === "completed"}
        textType="H6"
        className={`h-11 transition-all mr-2`}
      >
        ترخیص
      </Button>
    </div>
  );
});

export { CustomHeader };
