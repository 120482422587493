import { proxy } from "valtio";
import { UserProfile } from "./../interfaces/dto/User";

interface ILocation {
  type: string;
  coordinates: [number, number];
}

interface ICategories {
  id: string;
  name: string;
  alias: string;
}

interface ISelectPicker {
  label: string;
  value: string;
}

interface IInAddWorkspaceInfo {
  name: string;
  categoryTitle?: string | undefined;
  categories: string[];
  // services: string[];
  features: string[];
  description: string;
  cars: string[];
  phone: string;
  city: string;
  address: string;
  location: null | ILocation;
  agent?: any;
  id: string;
}

interface IWorkspaceInfo {
  name: string;
  categoryTitle?: string | undefined;
  categories: ICategories[];
  services: string[];
  features: string[];
  description: string;
  cars: string[];
  phone: string;
  city: string;
  address: string;
  agent: any;
  location: null | ILocation;
  id: string;
  feedbacks?: any;
  premium?: any;
}

interface IPLateInfo {
  activeSession?: null | any;
  plateNumber: string;
  car: any;
  carName: string;
  carId?: string;
  mobile: string;
  ownerName?: string;
  kilometer: number;
  id: string;
}

interface ICarList {
  id: string;
  createdAt: string;
  kilometer: number;
  plate: {
    id: string;
    plateNumber: string;
    mobile: string;
    car: {
      id: string;
      name: string;
    };
  };
  status: string;
  updatedAt: string;
}

interface IRecentlySearches {
  id: number | string;
  plateNumber: string;
}

interface ICarStatus {
  createdAt: string;
  id: string;
  kilometer: number;
  createdBy?: {
    name: string;
    phoneNumber: string;
  };
  plate: {
    id: string;
    mobile: string;
    plateNumber: string;
    monthlyUsage: string;
    ownerName: string;
    carName: string;
    car: {
      id: string;
      name: string;
    };
    userProfileId: string;
  };
  status: string;
  updatedAt: string;
  workshop: any;
  contact?: {
    id: string;
    contactName: string;
    userProfileId: string;
    workshopId: string;
  };
}

export interface IVersion {
  number: number;
  code: number;
  note: string;
}

export interface IVState {
  pageLoading: boolean;
  hasError: boolean;
  isMobileDevice: boolean;
  isScrolling: boolean;
  isEndOfPage: boolean;
  isAuthenticated: boolean;
  activeFeatures: string[];
  carsModalOpen: boolean;
  menuLoading: boolean;
  version: IVersion;
  unauthorizedAccess: boolean;

  cities: any[];
  categories: any[];

  cars: any[];
  carChildren: any[];
  carsParents: any[];
  services: any[];
  SelectPickerServices: ISelectPicker[];
  workshopTypes: ICategories[];
  SelectPickerCategories: ISelectPicker[];

  userWorkspaces: IWorkspaceInfo[];
  userWorkspaceInfo: IInAddWorkspaceInfo;
  activeWorkshop: IWorkspaceInfo | null;
  tempLocation: null | ILocation;
  userInfo: UserProfile;
  recentlySearches: IRecentlySearches[];
  carList: ICarList[];
  carLoading: boolean;
  selectedPlate: IPLateInfo;
  carStatus: ICarStatus;
  reminders: any[];
  users: any[];
  usersInvitations: any[];
  newRemindersCount: number;
  sessionId: string;
  selectedWorkshop: string;
}

const vState = proxy({
  pageLoading: true,
  hasError: false,
  isMobileDevice: false,
  isScrolling: false,
  isEndOfPage: false,
  activeFeatures: [],
  menuLoading: false,
  carsModalOpen: false,
  version: {
    number: 0,
    code: 0,
    note: "",
  },
  unauthorizedAccess: false,

  cities: [],
  categories: [],

  //cars list---------
  cars: [],
  carsParents: [],
  carChildren: [],
  //------------------
  services: [],
  SelectPickerServices: [],
  workshopTypes: [],
  SelectPickerCategories: [],

  userInfo: {
    id: "",
    name: "",
    username: "",
    phoneNumber: "",
    roles: [],
    groups: [],
    premium: null,
    mobile: "",
    userProfileId: "",
    avatar: "",
  },
  userWorkspaces: [],
  userWorkspaceInfo: {
    name: "",
    categoryTitle: "",
    categories: [],
    // services: [],
    features: [],
    description: "",
    cars: [],
    phone: "",
    city: "",
    address: "",
    location: null,
    agent: null,
    id: "",
    festivalActivatedAt: null,
    festivalCount: null,
  },
  tempLocation: null,
  recentlySearches: [],
  carList: [],
  carLoading: true,
  carStatus: {
    createdAt: "",
    id: "",
    kilometer: 0,
    plate: {
      id: "",
      mobile: "",
      plateNumber: "",
      ownerName: "",
      monthlyUsage: "",
      carName: "",
      car: {
        id: "",
        name: "",
      },
      userProfileId: "",
    },
    status: "in_progress",
    updatedAt: "",
    workshop: {},
  },
  reminders: [],
  newRemindersCount: 0,
  selectedPlate: {
    activeSession: null,
    plateNumber: "",
    car: {},
    carName: "",
    carId: "",
    mobile: "",
    ownerName: "",
    kilometer: 0,
    id: "",
  },
  activeWorkshop: null,
  users: [],
  usersInvitations: [],
  sessionId: "",
  selectedWorkshop: "",
  isAuthenticated: false,
} as IVState);

export { vState };
