/* eslint-disable */
import React from "react";
import { IoClose } from "react-icons/io5";
import { Text } from "ui/components";

interface IRecentlySearchedCardProps {
  plateNumber: string;
  onClick: () => void;
  onDelete: () => void;
}

const RecentlySearchedCard: React.FC<IRecentlySearchedCardProps> = React.memo(({ plateNumber, onClick, onDelete }) => {
  return (
    <div className="w-full pt-4 pb-5 px-3 flex justify-between items-center z-10">
      <Text onClick={onClick} type="H6" className="ts-gray-06 cursor-pointer">
        {`ایران ${plateNumber.substring(7, 9)}-${plateNumber.substring(3, 6)} ${
          plateNumber.slice(2, 3) === "ا" ? "الف" : plateNumber.slice(2, 3)
        } ${plateNumber.slice(0, 2)}`}
      </Text>
      <IoClose onClick={onDelete} size={25} className="ts-gray-06 -mt-1.5 z-20 cursor-pointer" />
    </div>
  );
});

export { RecentlySearchedCard };
