/* eslint-disable */
import { digitsFaToEn } from "@persian-tools/persian-tools";
import { ApiInstance, useObjectState, vState } from "core";
import React, { useEffect, useState } from "react";
import { BottomModal, Button, InputField, Text, useNotification } from "ui/components";
import { useSnapshot } from "valtio";
import { ChangeOwnerConfirmModal } from "./ChangeOwnerConfirmModal";

interface IAddEditToClientListModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDone?: (name) => void;
  type: "add" | "edit";
  data?: any;
  editName?: boolean;
}

const env = import.meta.env;

const AddEditToClientListModal: React.FC<IAddEditToClientListModalProps> = React.memo(
  ({ isOpen, onClose, onDone, type, data, editName = false }) => {
    const defaultError = "پر کردن این فیلد الزامی می‌باشد.";

    const snap = useSnapshot(vState);

    const [info, setInfo] = useObjectState({
      contactName: "",
      mobile: "",
    });
    const { contactName, mobile } = info;

    const [errors, setErrors] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [changeOwnerModal, setChangeOwnerModal] = useState(false);

    useEffect(() => {
      if (isOpen && type === "edit") {
        const mobile = data
          ? data.mobile.includes("*")
            ? ""
            : data.mobile || ""
          : snap.carStatus.plate.mobile.includes("*")
          ? ""
          : snap.carStatus.plate.mobile || "";
        setInfo({
          contactName: data ? data.contactName : snap.carStatus.contact?.contactName || "",
          mobile: mobile,
        });
      }
    }, [isOpen]);

    const addContactHandler = async () => {
      ApiInstance.sessions
        .addClientToList({ ...info, userProfileId: snap.carStatus.plate.userProfileId })
        .then((res) => {
          console.log(res);
          useNotification("مشتری به لیست مشتریان شما اضافه شد.", "success");
          vState.carStatus.contact = {
            contactName: contactName,
            id: "",
            userProfileId: "",
            workshopId: "",
          };
          onClose();
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 400) {
            useNotification("شماره وارد شده با شماره مالک خودرو مغایرت دارد.", "error");
          } else {
            useNotification("مشکلی رخ داده است. لطفا لحظاتی بعد مجددا تلاش کنید.", "error");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const editCarStatusContactHandler = async () => {
      ApiInstance.sessions
        .editClient(snap.carStatus.plate.id || "", info)
        .then((res) => {
          console.log(res);
          useNotification("تغییرات اعمال شد.", "success");
          vState.carStatus.contact = {
            id: "",
            userProfileId: "",
            workshopId: "",
            contactName: contactName,
          };
          vState.carStatus.plate.mobile = mobile;
          onClose();
        })
        .catch((err) => {
          console.log(err);
          useNotification("مشکلی رخ داده است. لطفا لحظاتی بعد مجددا تلاش کنید.", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const editContactHandler = async () => {
      ApiInstance.sessions
        .editClientInList(data.id || "", info)
        .then((res) => {
          console.log(res);
          useNotification("تغییرات اعمال شد.", "success");
          onDone && onDone(info.contactName);
          onClose();
        })
        .catch((err) => {
          console.log(err);
          useNotification("مشکلی رخ داده است. لطفا لحظاتی بعد مجددا تلاش کنید.", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const registerClickHandler = async () => {
      let errorsObj = { ...errors };
      if (contactName.length === 0) {
        errorsObj.contactName = defaultError;
      }
      if (mobile.length === 0) {
        errorsObj.mobile = defaultError;
      } else if (mobile.length !== 11 || digitsFaToEn(mobile.slice(0, 2)) !== "09") {
        errorsObj.mobile = "شماره وارد شده صحیح نیست";
      }
      if (Object.keys(errorsObj).length !== 0) {
        useNotification("لطفا فیلدهای مشخص شده را پر کنید.", "warning");
        setErrors(errorsObj);
      } else {
        setLoading(true);
        type === "add"
          ? addContactHandler()
          : editName
          ? editContactHandler()
          : snap.carStatus.plate.mobile !== mobile
          ? setChangeOwnerModal(true)
          : editCarStatusContactHandler();
      }
    };

    const removeField = (field) => {
      let cn = { ...errors };
      delete cn[field];
      setErrors(cn);
    };

    return (
      <>
        <BottomModal isOpen={isOpen} onClose={onClose}>
          <div className="w-full text-right">
            <Text type="H4" className="ts-accent mb-6">
              {type === "add" ? "افزودن به لیست مشتریان" : "ویرایش اطلاعات مالک خودرو"}
            </Text>

            <InputField
              label="نام و نام خانوادگی"
              value={contactName}
              onChange={(e) => setInfo({ contactName: e })}
              onFocus={() => removeField("contactName")}
              placeholder="نام و نام خانوادگی مالک خودرو"
              error={"contactName" in errors}
              errorText={errors.contactName || ""}
            />
            {!editName && (
              <InputField
                label="شماره تماس"
                inputType="number"
                value={mobile}
                onChange={(e) => {
                  if (e.length <= 11) {
                    setInfo({ mobile: e });
                  }
                }}
                onFocus={() => removeField("mobile")}
                placeholder="09000000000"
                error={"mobile" in errors}
                errorText={errors.mobile || ""}
              />
            )}

            <div className=" w-full flex justify-between items-center gap-2">
              <Button
                onClick={registerClickHandler}
                loading={loading}
                bcolor="primary"
                classNameContainer="w-full"
                className="w-full h-12"
              >
                ذخیره
              </Button>

              <Button
                onClick={() => onClose()}
                bcolor="blackTransparent"
                textType="H6"
                classNameContainer="w-full"
                className="w-full h-12"
              >
                انصراف
              </Button>
            </div>
          </div>
        </BottomModal>

        <ChangeOwnerConfirmModal
          isOpen={changeOwnerModal}
          onClose={() => setChangeOwnerModal(false)}
          onDone={editCarStatusContactHandler}
          mobile={mobile}
        />
      </>
    );
  },
);

export { AddEditToClientListModal };
