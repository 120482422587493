/* eslint-disable */
import { digitsFaToEn } from "@persian-tools/persian-tools";
import React, { useEffect, useState } from "react";
import { BiChevronDown, BiCurrentLocation } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Form } from "rsuite";
import { useSnapshot } from "valtio";
import { ApiInstance, ConfigLink, ServiceStorage, useChangeTitle, useQueryString, vState } from "../../../core";
import { Button, ContainerScroll, InputField, SelectCity, Text, useNotification } from "../../components";

interface IAddWorkshopStepTwoProps {} //INTRODUCTION PAGES

const env = import.meta.env;

const AddWorkshopStepTwo: React.FC<IAddWorkshopStepTwoProps> = React.memo(() => {
  const defaultError = "پر کردن این فیلد الزامی می‌باشد.";
  const metaTags = useChangeTitle("ایجاد محیط کاری - اطلاعات تکمیلی");
  const navigate = useNavigate();
  const snap = useSnapshot(vState);
  const { city, address, location } = snap.userWorkspaceInfo;
  const returnUrl = ServiceStorage.getTempReturnURL();
  console.log(snap.workshopTypes);

  const getOtherCategory =
    snap.workshopTypes.length !== 0 ? snap.workshopTypes.filter((cat) => cat.alias === "other") : [];

  const otherID = getOtherCategory.length !== 0 ? getOtherCategory[0].id : "";

  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhone] = useState("");
  const [cityModal, setCityModal] = useState(false);
  const [selectedCity, setSelectedCity] = useState<any | null>(null);
  const [errors, setErrors] = useState<any>({});

  // useEffect(() => {
  //   setDisabled(
  //     city === "" || selectedCity === null || address.length === 0 /*|| location.lat === 0 || location.lng === 0*/,
  //   );
  // }, [city, selectedCity, address, location]);

  useEffect(() => {
    const tempWorkspace: any = ServiceStorage.getTempWorkshop();
    const tempCity = localStorage.getItem("temp_city");
    console.log("temp_city", tempCity);

    if (tempWorkspace) {
      vState.userWorkspaceInfo = JSON.parse(tempWorkspace);
      setPhone(JSON.parse(tempWorkspace)?.phone || "");
      console.log(JSON.parse(tempWorkspace));
    }
    if (tempCity !== null) {
      const city = JSON.parse(tempCity);
      console.log(city);
      vState.userWorkspaceInfo.city = city ? city.value : "";
      setSelectedCity(city);
    } else {
      getUserCity();
    }
  }, []);

  const getCities = async (cityId) => {
    const citiesList = await ApiInstance.common.getAllCities();
    const selectedCityObj = citiesList.filter((item) => item.value === cityId);
    if (selectedCityObj.length !== 0) {
      const city = selectedCityObj[0];
      const selected_city = {
        label: city.label,
        value: city.value,
      };
      setSelectedCity(selected_city);
      vState.userWorkspaceInfo.city = selected_city.value;
    }
  };

  const getUserCity = async () => {
    await ApiInstance.account
      .getUserSetting()
      .then((res) => {
        console.log(res);
        const data = res.data;
        if (data.cityId) {
          getCities(data.cityId);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const registerWsHandler = async () => {
    setLoading(true);

    let workshop = { ...snap.userWorkspaceInfo };
    if (phoneNumber.length !== 0) {
      vState.userWorkspaceInfo.phone = digitsFaToEn(phoneNumber);
      workshop = { ...snap.userWorkspaceInfo, phone: digitsFaToEn(phoneNumber) };
    } else {
      //@ts-ignore
      delete workshop.phone;
    }
    console.log(workshop);

    await ApiInstance.workshop
      .addWorkshop(workshop)
      .then((res) => {
        console.log(res);
        ServiceStorage.removeTempWorkshop();
        localStorage.removeItem("temp_city");
        useNotification("کسب و کار شما با موفقیت اضافه شد.", "success", "افزودن کسب و کار");
        console.log(snap.userWorkspaces.length);

        snap.userWorkspaces.length === 0 ? navigate(ConfigLink.welcome) : navigate(workshop.location ? -3 : -2);
      })
      .catch((err) => {
        console.log("err", err);
        useNotification("اشتباهی رخ داده. لطفا مجددا تلاش کنید.", "error", "افزودن کسب و کار");
      })
      .then(() => {
        setLoading(false);
      });
  };

  const saveWsHandler = () => {
    let errorsObj = { ...errors };
    if (
      (phoneNumber.length !== 0 && phoneNumber.length < 11) ||
      (phoneNumber.length !== 0 && digitsFaToEn(phoneNumber.slice(0, 1)) !== "0")
    ) {
      errorsObj.phone = "فرمت شماره وارد شده صحیح نیست";
    }
    if (city.length === 0) {
      errorsObj.city = defaultError;
    }
    if (address.length === 0) {
      errorsObj.address = defaultError;
    }
    if (Object.keys(errorsObj).length !== 0) {
      useNotification("لطفا فیلدهای مشخص شده را پر کنید.", "warning");
      setErrors(errorsObj);
    } else {
      registerWsHandler();
    }
  };

  const setLocationHandler = () => {
    ServiceStorage.setTempWorkshop(JSON.stringify({ ...snap.userWorkspaceInfo, phone: digitsFaToEn(phoneNumber) }));
    localStorage.setItem("temp_city", JSON.stringify(selectedCity));
    navigate({ pathname: ConfigLink.selectLocation, search: useQueryString({ type: "add" }) });
  };

  const backClickHandler = () => {
    navigate(ConfigLink.createWorkspaceStepOne);
  };

  const selectCityHandler = (city) => {
    setSelectedCity(city);
    vState.userWorkspaceInfo.city = city.value;
  };

  const openCityModalHandler = () => {
    if ("city" in errors) {
      removeField("city");
    }
    setCityModal(true);
  };

  const removeField = (field) => {
    let cn = { ...errors };
    delete cn[field];
    setErrors(cn);
  };

  return (
    <div
      className={`w-full ts-base-bg-white overflow-x-hidden h-full relative flex flex-col justify-start items-center`}
    >
      {/* <Header showBackButton backClick={backClickHandler} /> */}
      {metaTags}
      <ContainerScroll className="pb-4 overflow-x-hidden">
        <Text type="H4" className="ts-accent mb-6">
          اطلاعات تکمیلی
        </Text>
        <Text type="P3" className="ts-gray-06 mb-6">
          جهت اضافه کردن کسب و کار خود، اطلاعات خواسته شده را وارد نمایید:
        </Text>

        <Form fluid className="w-full">
          <InputField
            inputType="number"
            label="شماره تلفن ثابت (اختیاری)"
            value={phoneNumber}
            onChange={(e) => {
              if (e.length <= 11) {
                setPhone(e);
              }
            }}
            onFocus={() => removeField("phone")}
            placeholder="02100000000"
            error={"phone" in errors}
            errorText={errors.phone || ""}
          />

          <Text type="H6" className="ts-accent mb-2">
            شهر
          </Text>
          <div className="w-full mb-6">
            <div
              onClick={openCityModalHandler}
              className={`transition-all w-full cursor-pointer rounded-xl p-4 flex justify-between items-center h-12 ${
                errors.city ? "ts-error-border" : "ts-input-border"
              }`}
            >
              <Text type="P4" className="ts-accent">
                {selectedCity ? selectedCity.label : "انتخاب شهر"}
              </Text>
              <BiChevronDown size={21} className="ts-accent -mt-px" />
            </div>
            {errors.city && (
              <Text type="P5" className={`ts-error mt-1`}>
                {"پر کردن این فیلد الزامی است"}
              </Text>
            )}
          </div>

          <Text type="H6" className="ts-accent mb-2">
            آدرس دقیق
            {/* نشانی */}
          </Text>
          <Button
            icon={<BiCurrentLocation size={19} className="ts-primary" />}
            bcolor="primaryTransparent"
            textType="H6"
            classNameContainer="w-full"
            className="w-full rounded-xl h-10 mb-2"
            onClick={setLocationHandler}
          >
            {snap.userWorkspaceInfo.location ? "ویرایش موقعیت مکانی روی نقشه" : "مشخص کردن روی نقشه"}
          </Button>
          <InputField
            value={address}
            onChange={(e) => (vState.userWorkspaceInfo.address = e)}
            fieldType="textarea"
            rows={5}
            onFocus={() => removeField("address")}
            placeholder="آدرس پستی"
            error={"address" in errors}
            errorText={errors.address || ""}
          />

          <Button
            type="submit"
            bcolor="primary"
            textType="H6"
            classNameContainer="w-full"
            className="w-full rounded-xl h-12"
            loading={loading}
            disabled={disabled}
            onClick={saveWsHandler}
          >
            ذخیره
          </Button>
        </Form>
      </ContainerScroll>
      <SelectCity
        isOpen={cityModal}
        selectedId={
          selectedCity ? selectedCity.value || "" : snap.userWorkspaceInfo.city ? snap.userWorkspaceInfo.city : null
        }
        onClose={() => setCityModal(false)}
        onSelect={(city) => selectCityHandler(city)}
      />
    </div>
  );
});

export { AddWorkshopStepTwo as default };

const styles = {};
