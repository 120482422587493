/* eslint-disable */
import React from "react";
import Skeleton from "react-loading-skeleton";
import { Text } from "../../../components";

interface IActivityReportPageLoaderProps {
  isGrowth?: boolean;
  isChart?: boolean;
}

const ActivityReportPageLoader: React.FC<IActivityReportPageLoaderProps> = React.memo(({ isGrowth, isChart }) => {
  return (
    <>
      <div className="w-full flex justify-between items-center mt-10 mb-4">
        {/* ------------SECTION ONE------------ */}
        <Text type="H4" className="ts-accent">
          گزارش عملکرد کلی
        </Text>
      </div>
      <div className="w-full flex justify-between items-center mb-3">
        <Text type="H5" className="ts-accent">
          نمای کلی
        </Text>
      </div>
      <div className="w-full justify-between items-center mb-4 grid grid-cols-3 gap-2">
        <Skeleton style={{ height: "56.8px" }} containerClassName="w-full" className={`w-full rounded-xl`} />
        <Skeleton style={{ height: "56.8px" }} containerClassName="w-full" className={`w-full rounded-xl`} />
        <Skeleton style={{ height: "56.8px" }} containerClassName="w-full" className={`w-full rounded-xl`} />
        <Skeleton style={{ height: "56.8px" }} containerClassName="w-full" className={`w-full rounded-xl`} />
        <Skeleton style={{ height: "56.8px" }} containerClassName="w-full" className={`w-full rounded-xl`} />
        <Skeleton style={{ height: "56.8px" }} containerClassName="w-full" className={`w-full rounded-xl`} />
      </div>

      {/* ------------SECTION TWO------------ */}

      <Text type="H5" className="ts-accent mb-3">
        نمودار عملکرد
      </Text>
      <Skeleton containerClassName="w-full" className={`w-full h-80 rounded-xl mb-6`} />

      {/* ------------SECTION THREE------------ */}
      <Text type="H5" className="ts-accent mb-3">
        فعالیت شما نسبت به همکاران
      </Text>
      <Skeleton containerClassName="w-full" className={`w-full h-20 rounded-xl`} />
    </>
  );
});

export { ActivityReportPageLoader };

const styles = {};
