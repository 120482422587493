/* eslint-disable */
// @ts-ignore
import { digitsFaToEn } from "@persian-tools/persian-tools";
import React, { useEffect, useState } from "react";
import "react-multi-date-picker/styles/colors/purple.css";
import { Checkbox, Form } from "rsuite";
import { useSnapshot } from "valtio";
import { ApiInstance, tools, useObjectState, vState } from "../../../../core";
import { BottomModal, Button, InputField, SelectPickerField, Text, useNotification } from "../../../components";
import { DeleteReminderModal } from "./";

type ServiceType = "register" | "edit" | "update";
type IReminderType = "kilometers" | "days" | "both";

interface IRegisterNewServiceProps {
  isOpen: boolean;
  onClose: () => void;
  onDone?: () => void;
  type: ServiceType;
  editItem?: any;
  carId: string;
  plateId: string;
  currentKM?: number;
}

const RegisterNewService: React.FC<IRegisterNewServiceProps> = React.memo(
  ({ isOpen, onClose, onDone, type, carId, plateId, editItem, currentKM = 0 }) => {
    const defaultError = "پر کردن این فیلد الزامی می‌باشد.";

    const weekDays = ["ش", "ی", "د", "س", "چ", "پ", "ج"];
    const defaultValue = {
      serviceType: "",
      nextKM: "",
      selectedService: "",
      expiresInDays: "",
      // maxDate: "",
      // dateLabel: "",
      detail: "",
    };

    const snap = useSnapshot(vState) as typeof vState;

    // const [checked, setChecked] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [deleteReminder, setDeleteReminder] = useState(false);
    const [reminderType, setReminderType] = useState<IReminderType>("kilometers");
    const [reminder, setReminder] = useObjectState({
      serviceType: "",
      nextKM: "",
      selectedService: "",
      expiresInDays: "",
      // maxDate: "",
      // dateLabel: "",
      detail: "",
    });
    const [relatedServices, setRelatedServices] = useState([]);
    const [errors, setErrors] = useState<any>({});
    const [selectedRelatedServices, setSelectedRelatedServices] = useState<any[]>([]);
    const { detail, serviceType, nextKM, selectedService, expiresInDays /*, maxDate*/ } = reminder;

    const getWorkshop = async () => {
      const result = await ApiInstance.workshop.getWorkshop();
      console.log(result);
      if (result.status === 200) {
        vState.userWorkspaceInfo = result.data.data[0];
        const resServices = result.data.data[0].services;
        // TEMP
        // const selectedServices = allServices.filter((o1) => resServices.some((o2) => o1.value === o2.id));
        // vState.services = selectedServices;
      }
    };

    const getRecommendation = async () => {
      setLoading(true);
      await ApiInstance.sessions
        .getRecommendationService(reminder.serviceType)
        .then((res) => {
          console.log(res);
          setRelatedServices(res.data.data);
        })
        .catch((err) => console.log("err", err))
        .finally(() => setLoading(false));
    };

    useEffect(() => {
      getWorkshop();
    }, []);

    useEffect(() => {
      if (reminder.serviceType && type === "register") {
        const selectedItem: any = snap.services.filter((item: any) => item.id === reminder.serviceType)[0];
        console.log(selectedItem);

        setReminder({
          nextKM: selectedItem.expiresInKilometers ? selectedItem.expiresInKilometers.toString() : "",
          selectedService: selectedItem.name,
          expiresInDays: selectedItem.expiresInDays ? (+selectedItem.expiresInDays / 30).toString() : "",
        });
        setReminderType(selectedItem.expiresType || "kilometers");

        getRecommendation();
      }
    }, [reminder.serviceType]);

    useEffect(() => {
      setLoading(false);
      setSaveLoading(false);
      setErrors({});
      if (type === "register") {
        setReminder(defaultValue);
        setRelatedServices([]);
        setSelectedRelatedServices([]);
        setReminderType("kilometers");
      } else {
        if (editItem) {
          // const date = new DateObject(editItem.expiresInDate).convert(persian, persian_fa);
          setReminder({
            serviceType: editItem.service.id,
            nextKM: editItem.expiresInKilometer || "",
            selectedService: editItem.service.name,
            expiresInDays: editItem.expiresInDays || "",
            // maxDate: editItem.expiresInDate,
            // dateLabel: date.format(),
            detail: editItem.description,
          });
          setReminderType(editItem.service.expiresType || "kilometers");
        }
      }
    }, [isOpen]);

    const editReminderHandler = async (reminderObj) => {
      await ApiInstance.sessions
        .updateReminder(carId, editItem.id, reminderObj)
        .then((res) => {
          console.log("updateReminder:", res);
          if (res.status === 200 || res.status === 201) {
            useNotification("یادآور به روز رسانی شد.", "success", "به روز رسانی یادآور");
            onDone && onDone();
            getReminders();
          } else {
            useNotification("مشکلی پیش آمده. لطفا دوباره تلاش کنید.", "error", "به روز رسانی یادآور");
          }
        })
        .catch((err) => {
          console.log("err", err);
          useNotification("مشکلی پیش آمده. لطفا دوباره تلاش کنید.", "error", "به روز رسانی یادآور");
        })
        .finally(() => {
          setSaveLoading(false);
        });
    };

    const saveBtnHandler = () => {
      let errorsObj = { ...errors };
      if (serviceType.length === 0) {
        errorsObj.serviceType = "این فیلد الزامی است.";
      }
      if (nextKM.length === 0 && (reminderType === "both" || reminderType === "kilometers")) {
        errorsObj.nextKM = defaultError;
      }
      if (expiresInDays.length === 0 && (reminderType === "both" || reminderType === "days")) {
        errorsObj.expireDays = defaultError;
      }
      if (Object.keys(errorsObj).length !== 0) {
        useNotification("لطفا فیلدهای مشخص شده را پر کنید.", "warning");
        setErrors(errorsObj);
      } else {
        setSaveLoading(true);

        const reminderObj = {
          description: detail,
          service: serviceType,
          ...(nextKM.length !== 0 ? { expiresInKilometers: +digitsFaToEn(nextKM.toString()) } : undefined),
          ...(expiresInDays.length !== 0 ? { expiresInDays: +expiresInDays * 30 } : undefined),
          // ...(maxDate.length !== 0 ? { expiresInDate: maxDate } : undefined),
        };

        if (type === "register" || type === "update") {
          addOrUpdateReminderHandler(reminderObj);
        } else {
          editReminderHandler(reminderObj);
        }
      }
    };

    const getReminders = async () => {
      await ApiInstance.sessions
        .getReminders(carId, 1)
        .then((res) => {
          console.log(res);
          vState.reminders = res.data.data;

          let count = 0;
          res.data.data.map((item) => {
            if (!item.committed) {
              count++;
            }
          });
          console.log(count);

          vState.newRemindersCount = count;

          setTimeout(() => {
            setSaveLoading(false);
            onClose();
          }, 1000);
        })
        .catch((err) => console.log("err", err));
    };

    const addOrUpdateReminderHandler = async (reminderObj) => {
      await ApiInstance.sessions
        .addReminder(carId, reminderObj)
        .then((res) => {
          console.log("addReminder:", res);
          if (res.status === 200) {
            if (selectedRelatedServices.length === 0) {
              useNotification("یادآور با موفقیت افزوده شد.", "success", "اضافه کردن یادآور");
              onDone && onDone();
              getReminders();
            } else {
              addRelatedReminders();
            }
          } else {
            useNotification("مشکلی پیش آمده. لطفا دوباره تلاش کنید.", "error", "اضافه کردن یادآور");
            setSaveLoading(false);
          }
        })
        .catch((err) => {
          console.log("err", err);
          useNotification("مشکلی پیش آمده. لطفا دوباره تلاش کنید.", "error", "اضافه کردن یادآور");
          setSaveLoading(false);
        });
    };

    const addRelatedReminders = async () => {
      console.log(selectedRelatedServices);

      selectedRelatedServices.map(async (item: any, index) => {
        const reminderObj = {
          expiresInKilometers: +digitsFaToEn(`${item.ratio * +reminder.nextKM}`),
          ...(expiresInDays.length !== 0 ? { expiresInDays: +expiresInDays * 30 } : undefined),
          service: item.service,
        };

        await ApiInstance.sessions
          .addReminder(carId, reminderObj)
          .then((res) => {
            console.log(`addRelatedReminder-${index}:`, res);
          })
          .catch((err) => {
            console.log("err", err);
          });
      });
      setTimeout(() => {
        getReminders();
      }, 1500);
    };

    const saveDateHandler = (date) => {
      // setReminder({ maxDate: moment(date.toDate()).toISOString(), dateLabel: date?.toString() });
    };

    const relatedSelectHandler = (value, checked) => {
      let finalValue = JSON.parse(value);
      console.log(finalValue, checked);
      let selected = [...selectedRelatedServices];
      if (checked) {
        selected.push(finalValue);
        console.log(selected);
        setSelectedRelatedServices(selected);
      } else {
        selected = selected.filter((item) => item.id !== finalValue.id);
        console.log(selected);
        setSelectedRelatedServices(selected);
      }
    };

    const removeField = (field) => {
      let cn = { ...errors };
      delete cn[field];
      setErrors(cn);
    };

    const resetReminder = () => {
      setReminder({ serviceType: "", expiresInDays: "", nextKM: "" });
      setReminderType("kilometers");
    };

    return (
      <>
        <BottomModal isOpen={isOpen} onClose={onClose} className={`${deleteReminder ? "w-11/12 ml-4" : ""}`}>
          <div
            className={`w-full ${deleteReminder ? "h-44 overflow-hidden" : ""} flex flex-col justify-start items-start`}
          >
            <Text type="H4" className="ts-accent mb-4">
              {type === "register"
                ? "ثبت یادآور جدید"
                : type === "edit"
                ? `ویرایش یادآور  ${editItem.service.name}`
                : `ثبت یادآور جدید ${editItem.service.name}`}
            </Text>
            <div className="w-full rounded-xl ts-bg-gray-01 p-4 mb-6">
              <Form fluid className="w-full">
                <SelectPickerField
                  label="یادآور برای"
                  disabled={type === "edit" || type === "update"}
                  data={snap.SelectPickerServices}
                  value={reminder.serviceType}
                  error={"serviceType" in errors}
                  errorText={errors.serviceType || ""}
                  onClean={() => resetReminder()}
                  onChange={(value) => setReminder({ serviceType: value })}
                  onOpen={() => removeField("serviceType")}
                  searchable={false}
                  placeholder="خدمت مورد نظر را انتخاب کنید"
                  className="mb-4"
                />

                {reminderType !== "days" && (
                  <>
                    <Text type="H6" className="ts-accent mb-1">
                      کارکرد تا یادآوری بعدی (کیلومتر)
                    </Text>
                    <Text type="P5" className="ts-gray-07 mb-2 mt-3">
                      {`مقدار مسافتی که نیاز است طی شود تا برای انجام ${
                        selectedService ? `"${selectedService}"` : "سرویس انتخابی"
                      } به مالک این خودرو یادآوری شود.`}
                    </Text>
                    <InputField
                      value={nextKM}
                      inputType="number"
                      onChange={(e) => setReminder({ nextKM: e })}
                      onFocus={() => removeField("nextKM")}
                      placeholder="2000"
                      error={"nextKM" in errors && nextKM.length === 0}
                      errorText={errors.nextKM || ""}
                      margin="mb-1"
                    />
                    <Text type="P4" className="ts-gray-06 mb-4">
                      {`کیلومتر بعدی تعویض:‌ ${
                        reminder.nextKM.length === 0 ? "" : tools.thousandSeparator(+currentKM + +reminder.nextKM)
                      }`}
                    </Text>
                  </>
                )}

                {(reminderType === "days" || reminderType === "both") && (
                  <>
                    <Text type="H6" className="ts-accent mb-1">
                      مدت زمان تا تعویض بعدی
                    </Text>
                    <Text type="P5" className="ts-gray-07 mb-2 mt-3">
                      {`مدت زمانی که لازم است طی شود تا برای انجام ${
                        selectedService ? `"${selectedService}"` : "سرویس انتخابی"
                      } به مالک این خودرو یادآوری شود.`}
                    </Text>
                    <InputField
                      value={expiresInDays}
                      hint="ماه"
                      inputType="number"
                      onChange={(e) => setReminder({ expiresInDays: e })}
                      onFocus={() => removeField("expiresInDays")}
                      placeholder="6"
                      error={"expiresInDays" in errors && expiresInDays.length === 0}
                      errorText={errors.expiresInDays || ""}
                      margin="mb-4"
                    />
                  </>
                )}

                {/* <Text type="H6" className="ts-accent mb-2">
                  حداکثر زمان تعویض بعدی
                </Text>
                <DatePicker
                  value={reminder.dateLabel}
                  editable={false}
                  onChange={saveDateHandler}
                  weekDays={weekDays}
                  minDate={new DateObject({ calendar: persian })}
                  calendar={persian}
                  locale={persian_fa}
                  containerClassName="w-full"
                  className="purple"
                  inputClass="w-full"
                  mapDays={({ date }) => {
                    //disable friday
                    let isWeekend = [6, 6].includes(date.weekDay.index);

                    if (isWeekend)
                      return {
                        disabled: false,
                        style: { color: "#900" },
                      };
                  }}
                  render={(value, openCalendar) => {
                    return (
                      <div
                        onClick={openCalendar}
                        className="w-full h-12 mb-3 px-4 py-3 rounded-xl ts-input-border flex justify-between items-center"
                      >
                        <Text type="P5" className={`${reminder.dateLabel ? "ts-gray-07" : "ts-gray-04"}`}>
                          {reminder.dateLabel || "تاریخ حدودی"}
                        </Text>
                        <HiCalendar size={18} className="ts-primary" />
                      </div>
                    );
                  }}
                /> */}

                <InputField
                  label="توضیحات یادآور"
                  value={reminder.detail}
                  fieldType="textarea"
                  rows={5}
                  onChange={(e) => setReminder({ detail: e })}
                  placeholder="قطعات و مواد مصرفی (نوع روغن و ...) یا توضیحات (روغن سوزی دارد، و ...)"
                  margin="mb-3"
                />
              </Form>
            </div>

            {reminder.serviceType && !loading && relatedServices.length !== 0 && type !== "edit" && (
              <div className={`w-full ts-similar-reminder-show rounded-xl ts-bg-gray-01 p-4 mb-6`}>
                <div className="flex items-center mb-3">
                  {/* <Toggle
                    size="md"
                    className="ts-primary ml-3"
                    checked={checked}
                    onChange={(checked) => setChecked(checked)}
                  /> */}
                  <Text type="H6" className="ts-accent">
                    یادآورهای مرتبط پیشنهادی
                  </Text>
                </div>
                <Text type="P4" className="ts-gray-08 mb-5">{`در صورت انجام خدمات دیگری به همراه ${snap.services
                  .filter((item) => item.value === reminder.serviceType)
                  .map((item) => item.label)}، میتوانید از لیست زیر آنها را انتخاب کنید.`}</Text>

                <div className="-mr-4">
                  {relatedServices.map((item: any) => {
                    const expKM = item.recommendService.expiresInKilometers;
                    const ratio = item.kilometerRatio;
                    return (
                      <Checkbox
                        onChange={(value, checked) => relatedSelectHandler(value, checked)}
                        value={JSON.stringify({
                          id: item.id,
                          service: item.recommendService.id,
                          ratio: ratio,
                        })}
                      >
                        <Text type="P4" className="ts-accent pr-6">
                          {`${item.recommendService.name} - ${
                            ratio ? tools.thousandSeparator(+reminder.nextKM * ratio) : expKM ? expKM : 0
                          } کیلومتر`}
                        </Text>
                      </Checkbox>
                    );
                  })}
                </div>
              </div>
            )}

            <div className=" w-full flex justify-between items-center sticky bottom-0 bg-white pt-6 left-0">
              <Button
                onClick={saveBtnHandler}
                loading={saveLoading}
                disabled={disabled}
                bcolor="primary"
                textType="H6"
                classNameContainer="ts-half-width"
                className="w-full rounded-lg h-12"
              >
                ذخیره
              </Button>

              <Button
                onClick={() => onClose()}
                bcolor="primaryTransparent"
                textType="H6"
                classNameContainer="ts-half-width"
                className="w-full rounded-lg h-12"
              >
                لغو
              </Button>
            </div>
          </div>
        </BottomModal>

        <DeleteReminderModal isOpen={deleteReminder} onClose={() => setDeleteReminder(false)} />
      </>
    );
  },
);

export { RegisterNewService };
