/* eslint-disable */
import React, { useEffect, useState } from "react";
import { HiExclamation } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { ApiInstance, vState } from "../../../../core";
import { useAuth } from "../../../../hooks";
import { BottomModal, Button, Text, useNotification } from "../../../components";

interface ITransferOwnershipModalProps {
  data: any;
  isOpen: boolean;
  userId: string;
  onClose: () => void;
  onEnd: () => void;
}

const TransferOwnershipModal: React.FC<ITransferOwnershipModalProps> = React.memo(
  ({ data, isOpen, onClose, onEnd, userId }) => {
    const history = useNavigate();
    const auth = useAuth();
    const snap = useSnapshot(vState);
    const [loading, setLoading] = useState(false);

    useEffect(() => {}, []);

    const failureRequest = () => {
      useNotification("مشکلی رخ داده است. لطفا مجددا تلاش کنید.", "error", "حذف کاربر");
    };

    const deleteBtnHandler = async () => {
      setLoading(true);
      await ApiInstance.agents
        .transferOwnership(userId)
        .then(async (res) => {
          console.log(res);
          useNotification(
            `مالکیت این کسب و کار شما به "${
              data.name || "شخص مورد نظر"
            }" واگذار شد. شما از این پس با نقش مدیر در این کسب و کار می‌توانید فعالیت کنید.`,
            "success",
          );
          await onEnd();
          onClose();
        })
        .catch((err) => {
          console.log(err);
          failureRequest();
        })
        .finally(() => {
          setLoading(false);
        });
    };

    return (
      <BottomModal isOpen={isOpen} onClose={onClose}>
        <div className="w-full flex flex-col justify-start items-start">
          <div className="flex justify-end items-center mb-4">
            <HiExclamation size={21} className="ts-secondary-red -mt-1" />
            <Text type="H4" className="ts-secondary-red mr-2">
              انتقال مالکیت
            </Text>
          </div>
          <Text type="P3" className="ts-accent mb-6 whitespace-pre-wrap">
            {`در صورت انتقال مالکیت، تمامی حق و حقوق مرتبط با این کسب و کار به “${
              data.name || "بدون نام"
            }” منتقل خواهد شد.
آیا از انجام این کار اطمینان دارید؟`}
          </Text>
          <div className=" w-full flex justify-between items-center">
            <Button
              onClick={deleteBtnHandler}
              loading={loading}
              bcolor="black"
              textType="H6"
              classNameContainer="ts-half-width"
              className="w-full rounded-xl h-12"
            >
              بله، منتقل میکنم
            </Button>

            <Button
              onClick={() => onClose()}
              bcolor="blackTransparent"
              textType="H6"
              classNameContainer="ts-half-width"
              className="w-full rounded-xl h-12"
            >
              خیر
            </Button>
          </div>
        </div>
      </BottomModal>
    );
  },
);

export { TransferOwnershipModal };

const styles = {};
