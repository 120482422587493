/* eslint-disable */
import React from "react";
import Skeleton from "react-loading-skeleton";

interface IReminderLoaderProps {
  forHistory?: boolean;
}

const ReminderLoader: React.FC<IReminderLoaderProps> = React.memo(({ forHistory = false }) => {
  return (
    <div className="w-full -mt-0.5">
      {[1, 2, 3, 4].map((item) => {
        return (
          <Skeleton
            style={{ height: forHistory ? "91px" : "133px" }}
            containerClassName="w-full"
            className={`${forHistory ? "mb-6" : "mb-3"} w-full rounded-2xl`}
          />
        );
      })}
    </div>
  );
});

export { ReminderLoader };

const styles = {};
