/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { vState } from "../../../../core";
import { BottomModal } from "../../../components";
import { PlateNotRegistered, PlateRegistered } from "./";
import { CarKilometerRegisterModal } from "./CarKilometerRegisterModal";

interface IPlateRegisterModalProps {
  isOpen: boolean;
  onClose: () => void;
  isRegistered: boolean;
  plate: string;
}

const PlateRegisterModal: React.FC<IPlateRegisterModalProps> = React.memo(
  ({ isOpen, onClose, isRegistered, plate }) => {
    const history = useNavigate();
    const snap = useSnapshot(vState);

    const [carKmModal, setCarKmModal] = useState(false);

    useEffect(() => {}, []);

    const openRegisterFunction = () => {
      setCarKmModal(true);
      onClose();
    };

    const modalBodyHandler = () => {
      if (isRegistered) {
        return <PlateRegistered plate={plate} onSubmit={openRegisterFunction} onClose={onClose} />;
      } else {
        return <PlateNotRegistered isOpen={isOpen} plate={plate} onSubmit={openRegisterFunction} />;
      }
    };

    return (
      <>
        <BottomModal isOpen={isOpen} onClose={onClose}>
          <div className="w-full flex flex-col justify-start items-start">{modalBodyHandler()}</div>
        </BottomModal>
        <CarKilometerRegisterModal
          isOpen={carKmModal}
          onClose={() => setCarKmModal(false)}
          km={snap.selectedPlate.kilometer || 0}
        />
      </>
    );
  },
);

export { PlateRegisterModal };

const styles = {};
