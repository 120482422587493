/* eslint-disable */
import qs from "qs";
import { axios } from "./api";

const baseUrl = "/agents";

interface IAgent {
  name: string;
  mobile: string;
  accessLevel: string;
  workshop: string;
}

const agents = {
  getAgentsInvitations: async () => {
    const url = `${baseUrl}/invitations?pageSize=70`;
    const result = await axios.get(url);

    return result;
  },
  getAgents: async (page: number) => {
    const url = `${baseUrl}`;
    const result = await axios.get(url, {
      params: {
        pageSize: 50,
        page,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });

    return result;
  },
  addAgent: async (data: IAgent) => {
    const url = `${baseUrl}/invitations`;
    const result = await axios.post(url, data);

    return result;
  },
  editApprovedAgent: async (id: string, data: any) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.put(url, data);

    return result;
  },
  editPendingAgent: async (id: string, data: any) => {
    const url = `${baseUrl}/invitations/${id}`;
    const result = await axios.put(url, data);

    return result;
  },
  acceptInvitations: async (id: string) => {
    const url = `${baseUrl}/invitations/${id}/accept`;
    const result = await axios.put(url);

    return result;
  },
  getInvitationInfo: async (id: string) => {
    const url = `${baseUrl}/invitations/${id}`;
    const result = await axios.get(url);

    return result;
  },
  deleteApprovedAgents: async (id: string) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.delete(url);

    return result;
  },
  deletePendingAgents: async (id: string) => {
    const url = `${baseUrl}/invitations/${id}`;
    const result = await axios.delete(url);

    return result;
  },
  resendInvitationSMS: async (id: string) => {
    const url = `${baseUrl}/invitations/resend-sms/${id}`;
    const result = await axios.get(url);

    return result;
  },
  transferOwnership: async (newOwnerAgentId: string) => {
    const url = `/workshops/change-owner`;
    const result = await axios.put(url, { newOwnerAgentId });

    return result;
  },
};

export { agents };
