/* eslint-disable */
import { ConfigLink, vState } from "core";
import React from "react";
import { FaStar } from "react-icons/fa6";
import { HiBell } from "react-icons/hi";
import { IoIosCheckmarkCircle, IoMdCloseCircle } from "react-icons/io";
import { IoCarSport } from "react-icons/io5";
import { MdChevronLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Divider } from "rsuite";
import { StaticCard, Text } from "ui/components";
import { useSnapshot } from "valtio";

interface IWorkshopMainCardProps {
  statistics: any;
}

const WorkshopMainCard: React.FC<IWorkshopMainCardProps> = ({ statistics }) => {
  const snap = useSnapshot(vState);
  const navigate = useNavigate();
  const acWorkshop = snap.activeWorkshop;
  const reminderEnabled = snap.activeFeatures.includes("add_reminders");
  const admissionEnabled = snap.activeFeatures.includes("admission_discharge");
  const shopEnabled = snap.activeFeatures.includes("shop");
  const partOrderEnabled = snap.activeFeatures.includes("part_order_list");

  const viewWorkshopHandler = () => {
    if (acWorkshop?.id) {
      navigate({
        pathname: ConfigLink.workshopPanel.replace(":id", acWorkshop.id),
      });
    }
  };

  return (
    <div className="w-full flex flex-col justify-start items-start p-4 mb-6 rounded-xl ts-shadow ts-workshop-info-box">
      <span className="w-full flex justify-between items-start">
        <Text type={"H4"} className="ts-gray-08">
          {acWorkshop?.name || "بدون نام"}
        </Text>
        <span onClick={viewWorkshopHandler} className="flex justify-center items-center cursor-pointer -ml-1.5">
          <Text type="H6" className="w-max ts-primary ml-1">
            مدیریت کسب‌و‌کار
          </Text>
          <MdChevronLeft size={21} className="ts-primary -mt-1" />
        </span>
      </span>

      <span className="w-full flex justify-between items-center mt-2">
        {acWorkshop?.feedbacks && (
          <span className="flex justify-center items-center">
            <Text type="P3" className="ts-gray-06 ml-1">
              {parseFloat(acWorkshop.feedbacks.averageScore || 0).toFixed(1)}
            </Text>
            <FaStar size={20} color="#FFA23A" className="-mt-1.5" />
          </span>
        )}

        <Text type={"P4"} className={`ts-gray-06 ${acWorkshop?.feedbacks ? "w-3/4" : "w-full"} truncate text-left`}>
          {acWorkshop?.categories ? acWorkshop.categories.map((item) => item.name).join(", ") : "---"}
        </Text>
      </span>

      <span className={admissionEnabled || shopEnabled || partOrderEnabled ? "w-full mt-8" : ""}>
        {(admissionEnabled || shopEnabled || partOrderEnabled) && (
          <span className="w-full flex justify-between items-start mb-1">
            <Text type={"H6"} className="ts-gray-07">
              گزارش عملکرد (هفته گذشته)
            </Text>

            <span
              onClick={() => navigate(ConfigLink.activityReport)}
              className="flex justify-center items-center cursor-pointer -ml-1.5"
            >
              <Text type="H6" className="ts-primary ml-1">
                جزییات بیشتر
              </Text>
              <MdChevronLeft size={21} className="ts-primary -mt-1" />
            </span>
          </span>
        )}

        {statistics && (
          <>
            {admissionEnabled && (
              <span className="w-full flex justify-between items-center mt-5">
                <StaticCard
                  simple
                  icon={<IoCarSport size={19} className="ts-primary" />}
                  number={statistics.sessions.count}
                  label="پذیرش"
                  average={{ percent: statistics.sessions.growthPercent }}
                />
                {reminderEnabled && (
                  <>
                    <Divider vertical className="h-12 mx-2" />
                    <StaticCard
                      simple
                      icon={<HiBell size={18} className="ts-primary" />}
                      number={statistics.reminders.count}
                      label="تعریف یادآور"
                      average={{ percent: statistics.reminders.growthPercent }}
                    />
                  </>
                )}
              </span>
            )}

            {(shopEnabled || partOrderEnabled) && (
              <span className="w-full flex justify-between items-center mt-5">
                {shopEnabled && (
                  <>
                    <StaticCard
                      simple
                      icon={<IoIosCheckmarkCircle size={19} className="ts-primary" />}
                      number={statistics.products.count}
                      label="قطعات تعریف شده"
                      average={{ percent: statistics.products.growthPercent }}
                    />
                    {partOrderEnabled && <Divider vertical className="h-12 mx-2" />}
                  </>
                )}
                {partOrderEnabled && (
                  <>
                    <StaticCard
                      simple
                      icon={<IoMdCloseCircle size={18} className="ts-primary" />}
                      number={statistics.providedParts.count}
                      label="اعلام امکان تامین"
                      average={{ percent: statistics.providedParts.growthPercent }}
                    />
                  </>
                )}
              </span>
            )}
          </>
        )}
      </span>
    </div>
  );
};

export { WorkshopMainCard };

const styles = {};
