/* eslint-disable */
import React, { useState } from "react";
import { HiCheckCircle, HiTrash } from "react-icons/hi";
import { MdOutlineAccessTimeFilled } from "react-icons/md";
import { useNavigate } from "react-router";
import { Loader } from "rsuite";
import { useSnapshot } from "valtio";
import { ApiInstance, vState } from "../../../../core";
import { Text, useNotification } from "../../../components";
import accessIcon from "./../../../../assets/images/user-access-icon.svg";

type UserType = "accepted" | "invited" | "owner";
type AccessType = "owner" | "admin" | "agent";

interface IUserCardProps {
  id: string;
  name: string;
  mobile: string;
  accessLevel: AccessType;
  type: UserType;
  onDelete?: () => void;
  onEdit?: () => void;
}

const UserCard: React.FC<IUserCardProps> = React.memo(({ id, name, mobile, type, accessLevel, onDelete, onEdit }) => {
  const history = useNavigate();
  const snap = useSnapshot(vState);

  const [loading, setLoading] = useState(false);

  const resendHandler = async () => {
    setLoading(true);
    await ApiInstance.agents
      .resendInvitationSMS(id)
      .then((res) => {
        console.log(res);
        useNotification("پیام دعوت مجددا ارسال شد.", "success", "دعوتنامه");
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است.", "error", "دعوتنامه");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      key={id}
      // style={{ height: "69px" }}
      className="w-full bg-white relative rounded-2xl ts-shadow flex justify-start items-start p-3 mb-3"
    >
      <div className="w-full h-full flex flex-col justify-between items-start">
        <div className="flex justify-start items-center mb-4">
          <Text type="H6" className="ts-accent">
            {name}
          </Text>
          <div
            className={`flex justify-center items-center rounded-full mr-2 px-2 py-1 ${
              type === "accepted" ? "ts-accept-chips" : type === "owner" ? "ts-bg-gray-02" : "ts-pending-chips"
            }`}
          >
            {type === "accepted" || type === "owner" ? (
              <HiCheckCircle size={18} className={`-mt-px ${type === "accepted" ? "ts-success" : "ts-gray-07"}`} />
            ) : (
              <MdOutlineAccessTimeFilled size={17} className={`-mt-px ts-warning`} />
            )}
            <Text
              type="HM"
              className={`${
                type === "accepted" ? "ts-success" : type === "owner" ? "ts-gray-07" : "ts-warning"
              } mr-1.5`}
            >
              {type === "accepted" ? "کاربر فعال" : type === "owner" ? "مالک تعمیرگاه" : "در انتظار تایید"}
            </Text>
          </div>
        </div>
        <div className="w-full flex justify-start items-center">
          <Text type="HM" className="ts-gray-06 ml-2.5">
            <b>شماره همراه:</b> {`${mobile}`}
          </Text>
          {type === "invited" && (
            <div className="flex justify-center items-center">
              {loading && <Loader size="xs" className="ml-2 -mt-0.5" />}
              <Text onClick={() => resendHandler()} type="HM" className="ts-primary cursor-pointer">
                ارسال مجدد پیامک
              </Text>
            </div>
          )}
        </div>
      </div>
      <div className="absolute top-3 left-3 bg-white flex justify-end items-center">
        {type !== "owner" && snap.userInfo.mobile !== mobile && accessLevel !== "agent" && (
          <>
            {type !== "invited" && <img src={accessIcon} alt="access" className="w-6 h-6" onClick={onEdit} />}
            <HiTrash size={23} className="ts-primary mr-2" onClick={onDelete} />
          </>
        )}
      </div>
    </div>
  );
});

export { UserCard };

const styles = {};
