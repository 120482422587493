/* eslint-disable */
import React from "react";
import { FaDownLong, FaUpLong } from "react-icons/fa6";
import { useMediaQuery } from "react-responsive";
import { Text } from "./Text";

interface IAverage {
  percent: string | number;
}

interface IStaticCardProps {
  icon: any;
  number: number;
  label: string;
  average?: IAverage;
  simple?: boolean;
}

const StaticCard: React.FC<IStaticCardProps> = React.memo(({ icon, number, label, average, simple = false }) => {
  const isXSmallScreen = useMediaQuery({
    query: "(max-width: 380px)",
  });
  return (
    <div
      className={`w-full flex ${
        simple ? "px-2" : "bg-white ts-shadow rounded-xl p-2"
      } flex-col justify-start items-start`}
    >
      <div className="w-full flex justify-between items-center mb-1">
        <div className="flex justify-start items-center">
          <div className="w-5 h-5 lg:w-6 lg:h-6 rounded-full p-1 ts-bg-primary-light ml-2 flex justify-center items-center">
            {icon}
          </div>
          <Text type="H5" className="ts-accent -mb-0.5">
            {number}
          </Text>
        </div>
        {average && +average.percent !== 0 && (
          <div className="flex justify-center items-center">
            <Text
              type="P3"
              className={`${average.percent < 0 ? "ts-error ts-reverse-direction" : "ts-success"} ml-px`}
            >{`${parseFloat(average.percent.toString()).toFixed(0)}٪`}</Text>
            {average.percent < 0 ? (
              <FaDownLong size={14} className="-mt-1 ts-error" />
            ) : (
              <FaUpLong size={14} className="-mt-1 ts-success" />
            )}
          </div>
        )}
      </div>
      <Text type={isXSmallScreen ? "Tiny" : "P5"} className="ts-gray-06 ts-static-label truncate font-light">
        {label}
      </Text>
    </div>
  );
});

export { StaticCard };
