/* eslint-disable */
import qs from "qs";
import { axios } from "./api";

interface IReport {
  reporteeType: "workshop" | "product";
  reporteeId: string;
  reason: string;
  description?: string;
}

const baseUrl = "/workshops";

const searchWorkshops = {
  getWorkshops: async (
    coordinates: number[],
    categories?: string[] | null,
    orderBy?: string | null,
    latLang?: any[],
  ) => {
    const url = `${baseUrl}/bbox`;

    const result = await axios.get(url, {
      params: {
        southwestLat: coordinates[0],
        southwestLong: coordinates[1],
        northeastLat: coordinates[2],
        northeastLong: coordinates[3],
        ...(categories ? { categories } : undefined),
        ...(orderBy ? { orderBy } : undefined),
        ...(orderBy && latLang && orderBy === "distance"
          ? { currentLat: latLang[0], currentLong: latLang[1] }
          : undefined),
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });

    return result;
  },
  getWorkshopInfo: async (id: string) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.get(url);
    return result;
  },
  reportProblem: async (data: IReport) => {
    const url = `/Reports`;
    const result = await axios.post(url, data);
    return result;
  },
  getWorkshopShortLink: async (workshopId: string) => {
    const url = `/share/workshop`;
    const result = await axios.post(url, { workshopId });
    return result;
  },
  getFeedbacks: async (page: number, sort: "NON" | "DATE" | "NAME" | null, workshopId?: string | null) => {
    const url = `/Feedbacks?pageSize=50&page=${page}&sort=${sort}${workshopId ? `&workshopId=${workshopId}` : ""}`;
    const result = await axios.get(url);

    return result;
  },
};
export { searchWorkshops };
