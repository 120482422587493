/* eslint-disable */
import React from "react";
import Skeleton from "react-loading-skeleton";

interface IStaticsCardLoaderProps {}

const StaticsCardLoader: React.FC<IStaticsCardLoaderProps> = React.memo(() => {
  return (
    <div className="w-full justify-between items-center mb-4 grid grid-cols-3 gap-2">
      <Skeleton style={{ height: "56.8px" }} containerClassName="w-full" className={`w-full rounded-xl`} />
      <Skeleton style={{ height: "56.8px" }} containerClassName="w-full" className={`w-full rounded-xl`} />
      <Skeleton style={{ height: "56.8px" }} containerClassName="w-full" className={`w-full rounded-xl`} />
    </div>
  );
});

export { StaticsCardLoader };

const styles = {};
