/* eslint-disable */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import { useSnapshot } from "valtio";
import { ApiInstance, ConfigLink, vState } from "../../../core";
import { useChangeTitle, useFetchQueryString } from "../../../core/hooks";
import { useAuth } from "../../../hooks";
import { Button, ContainerScroll, Text, useNotification } from "../../components";
import { Header } from "../../_layouts/components";

const env = import.meta.env;

interface IQueryStringTransfer {
  id: string;
}

const ManageUsers = ({}) => {
  const auth = useAuth();
  const metaTags = useChangeTitle("ویراژ - دعوتنامه");
  let { id } = useFetchQueryString<IQueryStringTransfer>();
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [info, setInfo] = useState({
    mobile: "",
    name: "",
    workshop: {
      name: "",
      id: "",
    },
  });

  const proceedRegisterHandler = async () => {
    if (auth.authenticated) {
      setLoading(true);

      await ApiInstance.agents
        .acceptInvitations(id)
        .then((res) => {
          console.log(res);
          navigate(ConfigLink.homePage, { replace: true });
          setTimeout(() => {
            useNotification(`کسب و کار "${info.workshop.name || ""}" به لیست کسب و کارهای شما اضافه شد.`, "success");
          }, 1500);
        })
        .catch((err) => {
          console.log("err", err);
          if (err.response.status === 400 && err.response.data.message === "Invitation expired") {
            useNotification("لینک دعوتنامه شما منقضی شده است. لطفا مجددا درخواست عضویت کنید.", "error", "دعوتنامه");
            setTimeout(() => {
              navigate(ConfigLink.homePage, { replace: true });
            }, 2000);
          } else {
            useNotification(err.response.data.message, "error", "دعوتنامه");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      auth.login({ redirectUri: `${window.location.href}` });
    }
  };

  const getInfo = async () => {
    await ApiInstance.agents
      .getInvitationInfo(id)
      .then((res) => {
        console.log(res);
        setInfo(res.data);
      })
      .catch((err) => {
        console.log("err getInfo", err);

        if (err.response.status === 400 && err.response.data.message === "Invitation not found") {
          useNotification("دعوتنامه ای با این مشخصات یافت نشد", "error", "دعوتنامه");
        }
      });
  };

  useEffect(() => {
    if (id) {
      getInfo();
    }
  }, []);

  useEffect(() => {
    if (auth.ready) {
      console.log("ready");
      setPageLoading(false);
    }
  }, [auth.ready]);

  return (
    <>
      <Header logoCenter noProfile />
      {metaTags}
      {pageLoading ? (
        <div className="w-full h-screen flex justify-center items-center">
          {" "}
          <Loader size="sm" />{" "}
        </div>
      ) : (
        <ContainerScroll heightOffset={50} NoSticky className="">
          <Text type="H4" className="ts-accent mb-8">
            ورود
          </Text>

          <Text type="P3" className="ts-accent mb-6">
            شما از طرف کسب و کار {info.workshop.name ? `"${info.workshop.name}"` : ""} برای استفاده از اپ ویراژ دعوت شده
            اید. با کلیک بر روی دکمه زیر می توانید برای ثبت نام/ ورود اقدام نمایید.
          </Text>

          <Button
            onClick={proceedRegisterHandler}
            loading={loading}
            disabled={disabled}
            bcolor="primary"
            textType="H6"
            classNameContainer="w-full"
            className={`w-full rounded-xl h-12`}
          >
            ادامه
          </Button>
        </ContainerScroll>
      )}
    </>
  );
};

export { ManageUsers as default };
