/* eslint-disable */
import { ApiInstance, ConfigLink, ServiceStorage, tools, useChangeTitle, vState } from "core";
import { isNull } from "lodash";
import { useEffect, useState } from "react";
import { FaCircleExclamation } from "react-icons/fa6";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { Divider } from "rsuite";
import { Button, ContainerScroll, Text, useNotification } from "ui/components";
import { useSnapshot } from "valtio";

const ClientBulkSmsCheckout = ({}) => {
  const metaTags = useChangeTitle("ویراژ - تایید و تکمیل خرید");
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [data, setData] = useState<any>(null);

  const getInfo = async () => {
    const info = ServiceStorage.getBulkSmsInfo();
    console.log(info);
    if (info) {
      await ApiInstance.bulkSms
        .getPaymentInfo(info)
        .then((res) => {
          console.log(res);
          setData(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          useNotification("مشکلی رخ داده است. لطفا لحظاتی بعد مجددا امتحان کنید.", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      useNotification("اطلاعات پیامک یافت نشد. لطفا دوباره اطلاعات را وارد کنید", "error");
      setTimeout(() => {
        navigate(ConfigLink.workshopClients);
      }, 1500);
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  const paySubHandler = async () => {
    if (data.paymentInfo && data.paymentInfo.id) {
      setButtonLoading(true);
      await ApiInstance.bulkSms
        .getPaymentUrl({
          paymentId: data.paymentInfo.id,
        })
        .then((res) => {
          console.log(res);
          ServiceStorage.setTempSubReturnURL(`${ConfigLink.workshopClients}`);
          const paymentUrl = res.data.paymentUrl;
          if (paymentUrl) {
            window.location.assign(paymentUrl);
          } else {
            useNotification(
              "اطلاعات پرداخت یافت نشد. لطفا مجددا صفحه را بارگذاری کنید و در صورت رفع نشدن مشکل با پشتیبانی تماس بگیرید.",
              "error",
            );
          }
        })
        .catch((err) => {
          console.log(err);
          useNotification("مشکلی رخ داده است. لطفا لحظاتی بعد مجددا امتحان کنید.", "error");
        })
        .finally(() => {
          setButtonLoading(false);
        });
    } else {
      useNotification(
        "اطلاعات پرداخت یافت نشد. لطفا مجددا صفحه را بارگذاری کنید و در صورت رفع نشدن مشکل با پشتیبانی تماس بگیرید.",
        "error",
      );
    }
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={150} className="pt-36">
        <Text type="H3" className="ts-accent text-center mb-8">
          تایید و تکمیل خرید
        </Text>

        <Text type="P3" className="ts-accent text-center mb-4">
          جزییات خرید را بررسی کنید:
        </Text>

        <div className="w-full ts-shadow rounded-xl p-6 flex flex-col justify-start items-start">
          <span className="w-full flex justify-start items-center mb-4">
            <Text type="P4" className="ts-accent w-40 ml-2">
              عنوان:
            </Text>
            {loading ? (
              <Skeleton className="w-20" />
            ) : (
              <Text type="P4" className="ts-accent">
                ارسال پیامک
              </Text>
            )}
          </span>

          <span className="w-full flex justify-start items-center mb-4">
            <Text type="P4" className="ts-accent w-40 ml-2 tracking-tighter">
              تعداد کل پیامک‌های ارسالی:
            </Text>
            {loading ? (
              <Skeleton className="w-20" />
            ) : (
              <Text type="P4" className="ts-accent">
                {data.paymentInfo.packageInfo?.packageItemCount || "---"}
              </Text>
            )}
          </span>

          <span className="w-full flex justify-start items-center mb-4">
            <Text type="P4" className="ts-accent w-40 ml-2 tracking-tighter">
              هزینه ارسال هر پیامک:
            </Text>
            {loading ? (
              <Skeleton className="w-20" />
            ) : (
              data.paymentInfo.packageInfo && (
                <Text type="P4" className="ts-accent">
                  {data.paymentInfo.packageInfo.realPricePerItem
                    ? `${tools.thousandSeparator(data.paymentInfo.packageInfo?.realPricePerItem / 10)} تومان`
                    : "---"}
                </Text>
              )
            )}
          </span>

          {data?.paymentInfo.packageInfo?.discountPercent && data?.paymentInfo.packageInfo?.discountPercent !== 0 ? (
            <span className="w-full flex justify-start items-center mb-4">
              <Text type="P4" className="ts-primary w-40 ml-2">
                تخفیف:
              </Text>
              {loading ? (
                <Skeleton className="w-20" />
              ) : (
                <Text type="P4" className="ts-primary w-max whitespace-nowrap">
                  {`${tools.thousandSeparator(data.paymentInfo.packageInfo?.discountPrice / 10)} تومان(${
                    data.paymentInfo.packageInfo?.discountPercent * 100
                  }%)`}
                </Text>
              )}
            </span>
          ) : null}
          <Divider className="w-full ts-bg-gray-02 m-0 mb-4" />

          <span className="w-full flex justify-start items-center mb-4">
            <Text type="P4" className="ts-accent w-40 ml-2">
              مبلغ کل:
            </Text>
            {loading ? (
              <Skeleton className="w-20" />
            ) : (
              data.paymentInfo.packageInfo && (
                <Text type="P4" className="ts-accent">
                  {tools.thousandSeparator(data.paymentInfo.packageInfo?.priceWithDiscount / 10)} تومان
                </Text>
              )
            )}
          </span>

          <span className="w-full flex justify-start items-center mb-4">
            <Text type="P4" className="ts-accent w-40 ml-2 tracking-tighter">
              مالیات بر ارزش افزوده (
              {data && data.paymentInfo.packageInfo ? data.paymentInfo.packageInfo?.taxPercent * 100 : 10}درصد):
            </Text>
            {loading ? (
              <Skeleton className="w-20" />
            ) : (
              data.paymentInfo.packageInfo && (
                <Text type="P4" className="ts-accent">
                  {`${tools.thousandSeparator(data.paymentInfo.packageInfo?.taxPrice / 10)} تومان`}
                </Text>
              )
            )}
          </span>

          <Divider className="w-full ts-bg-gray-02 m-0 mb-4" />

          <span className="w-full flex justify-start items-center">
            <Text type="H6" className="ts-accent w-40 ml-2">
              مبلغ قابل پرداخت:
            </Text>
            {loading ? (
              <Skeleton className="w-20" />
            ) : (
              data.paymentInfo.packageInfo && (
                <Text type="H6" className="ts-accent">
                  {tools.thousandSeparator(data.paymentInfo.packageInfo?.paidPrice / 10)} تومان
                </Text>
              )
            )}
          </span>
        </div>

        <div className="flex justify-start items-start relative mt-3 py-2.5 px-4 rounded-2xl ts-bulk-notice">
          <FaCircleExclamation size={18} className="ts-info -mt-0.5 absolute" />
          <Text type="P4" className="ts-gray-07 mr-1 indent-5">
            خواهشمند است در فرایند پرداخت به خرید و مبلغ توجه داشته باشید. درصورت پرداخت وجه امکان انصراف و بازگشت وجه
            وجود ندارد.
          </Text>
        </div>

        <div className="w-full ts-sub-sticky-button flex flex-col justify-center items-center bg-white fixed bottom-0 left-0 px-6 pb-10">
          <Button
            onClick={paySubHandler}
            loading={buttonLoading}
            disabled={isNull(data)}
            bcolor="primary"
            textType="H6"
            classNameContainer="w-full max-w-7xl"
            className="w-full rounded-xl h-12 mb-2 mt-2"
          >
            پرداخت
          </Button>
        </div>
      </ContainerScroll>
    </>
  );
};

export { ClientBulkSmsCheckout as default };
