/* eslint-disable */
import React from "react";
import { BiChevronDown } from "react-icons/bi";
import { Text } from "ui/components";

interface ICarBrandSelectorProps {
  title?: string;
  value: any;
  className?: string;
  error?: boolean;
  onClick?: () => void;
}

const CarBrandSelector: React.FC<ICarBrandSelectorProps> = React.memo(
  ({ title = "", value, className = "", error = false, onClick }) => {
    return (
      <div className="w-full mb-6">
        <Text type="H6" className="ts-accent mb-2">
          {title || "برند و تیپ خودرو"}
        </Text>
        <div
          onClick={() => {
            // vState.carsModalOpen = true;
            onClick && onClick();
          }}
          className={`w-full rounded-xl outline-none ${
            error ? "ts-error-border" : "ts-input-border"
          } h-12 flex justify-between items-center px-4 py-3 cursor-pointer whitespace-nowrap ${className}`}
        >
          <Text type="P4" className={`ts-accent w-11/12 truncate`}>
            {value || "خودروی مورد نظر خود را انتخاب کنید"}
          </Text>
          <BiChevronDown size={20} className="ts-accent -mt-0.5" />
        </div>
        {error && (
          <Text type="P5" className={`ts-error mt-1`}>
            {"انتخاب یک گزینه الزامی است"}
          </Text>
        )}
      </div>
    );
  },
);

export { CarBrandSelector };

const styles = {};
