/* eslint-disable */
import React, { useEffect, useState } from "react";
import { MdOutlineAddCircle } from "react-icons/md";
import { useParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import { ApiInstance, ConfigLink, useChangeTitle, useFetchQueryString, vState } from "../../../core";
import {
  Button,
  ContainerScroll,
  PremiumNoticeModal,
  SuccessfulSubscription,
  Text,
  useNotification,
} from "../../components";
import { AddEditToClientListModal } from "../Home/WorkshopClients/components";
import {
  CarInfoTab,
  CarKilometerRegisterModal,
  CustomHeader,
  EditCarProps,
  FinalRegisterModal,
  HistoryDetailModal,
  RegisterNewService,
  RemindersHistoryTab,
  RemindersListTab,
  SingleReminderDetailModal,
  TabBar,
  TerminateSession,
} from "./components";

interface ICarStatusProps {}

interface IQueryStringTransfer {
  subPayed?: string;
}

const CarStatus: React.FC<ICarStatusProps> = React.memo(({}) => {
  const metaTags = useChangeTitle("ویراژ - مشاهده خودرو");
  const snap = useSnapshot(vState);
  let { subPayed } = useFetchQueryString<IQueryStringTransfer>();
  const { id } = useParams();
  const reminderFeatureActivated = snap.activeFeatures.includes("add_reminders");

  const [loading, setLoading] = useState(true);
  const [kilometerEdit, setKilometerEdit] = useState(false);
  const [showRegisterServiceModal, setShowRegisterServiceModal] = useState(false);
  const [showFinalRegisterModal, setShowFinalRegisterModal] = useState(false);
  const [editPropsModal, setEditPropsModal] = useState(false);
  const [terminateSessionModal, setTerminateSessionModal] = useState(false);
  const [selectedReminder, setSelectedReminder] = useState(null);
  const [serviceType, setServiceType] = useState<"register" | "edit" | "update">("register"); //register or edit
  const [sortReminders, setSortReminders] = useState("kilometer");
  const [singleReminderOpen, setSingleReminderOpen] = useState(false);
  const [addEditClientModal, setAddEditClientModal] = useState(false);

  const [pageCount, setPageCount] = useState<number>(0);
  const [remindersCount, setRemindersCount] = useState<number>(0);
  const [moreDataLoading, setMoreDataLoading] = useState<boolean>(false);
  const [historyModal, setHistoryModal] = useState<boolean>(false);
  const [selectedHistory, setSelectedHistory] = useState<string>("");
  const [premiumModal, setPremiumModal] = useState(false);
  const [successfulSubModal, setSuccessfulSubModal] = useState(false);

  const [activeTab, setActiveTab] = useState<number>(0);

  const getCarStatus = async () => {
    await ApiInstance.sessions
      .getSession(id || "")
      .then((res) => {
        console.log(res);
        const data = res.data.data;
        vState.carStatus = data;
        vState.userWorkspaceInfo = data.workshop;
        getReminders(data.status, data.plate.id);
      })
      .catch((err) => console.log("err", err));
  };

  const getReminders = async (status, plateId) => {
    console.log(status);
    const completed = status === "completed";
    await ApiInstance.sessions
      .getReminders(completed ? plateId : id, pageCount + 1, completed ? "history" : "session", completed ? id : null)
      .then((res) => {
        console.log(res);
        setRemindersCount(res.data.meta.total);
        setPageCount((prev) => prev + 1);
        let data: any[] = [];
        let count = 0;

        pageCount !== 0 ? (data = [...snap.reminders, ...res.data.data]) : (data = res.data.data);

        vState.reminders = data;

        data.map((item) => {
          if (!item.committed) {
            count++;
          }
        });
        vState.newRemindersCount = count;
        setPageCount((prev) => prev + 1);
      })
      .catch((err) => {
        console.log("err", err);
        useNotification("مشکلی رخ داده است. لطفا لحظاتی بعد مجددا تلاش کنید.", "error");
      })
      .then(() => {
        setLoading(false);
        if (moreDataLoading) setMoreDataLoading(false);
      });
  };

  useEffect(() => {
    vState.sessionId = id || "";
    vState.selectedPlate.activeSession = null;
    getCarStatus();

    if (subPayed && subPayed === "1") {
      setSuccessfulSubModal(true);
    }
  }, []);

  useEffect(() => {
    setActiveTab(snap.activeFeatures.includes("add_reminders") ? 0 : 1);
  }, [snap.activeFeatures]);

  useEffect(() => {
    if (snap.isEndOfPage && remindersCount > snap.reminders.length && !loading) {
      setMoreDataLoading(true);
      getReminders(snap.carStatus.status, snap.carStatus.plate.id);
    } else {
      vState.isEndOfPage = false;
    }
  }, [snap.isEndOfPage]);

  const registerNewServiceHandler = () => {
    setServiceType("register");
    setShowRegisterServiceModal(true);
  };

  const editItemHandler = (item) => {
    setSelectedReminder(item);
    setServiceType(item.committed ? (snap.carStatus.status !== "completed" ? "update" : "edit") : "edit");
    setSingleReminderOpen(true);
  };

  const singleReminderBtnClicked = () => {
    setShowRegisterServiceModal(true);
  };

  const sortData = [
    {
      label: "به ترتیب کارکرد",
      value: "kilometer",
    },
    {
      label: "به ترتیب تاریخ",
      value: "expiry_date",
    },
  ];

  const changeSortHandler = (value) => {
    setLoading(true);
    setSortReminders(value);
    // getReminders(value);
  };

  return (
    <>
      {metaTags}
      <CustomHeader
        onAbortClicked={() => setTerminateSessionModal(true)}
        onDischargeClicked={() => setShowFinalRegisterModal(true)}
      />

      {reminderFeatureActivated && (
        <div className="w-full max-2xs:px-3 px-6 fixed top-20 pt-8 bg-white pb-3 ts-car-status-header">
          <div className="w-full flex justify-between items-end mb-6">
            <div className="flex flex-col justify-start items-start">
              <Text type="H3" className="w-max ts-accent font-black hidden lg:block mb-6">
                پذیرش و ترخیص
              </Text>
              <Text type="H4" className="w-max ts-accent">
                مشاهده خودرو
              </Text>
            </div>
            {snap.carStatus.status === "in_progress" ? (
              <Button
                onClick={registerNewServiceHandler}
                textType="H6"
                icon={<MdOutlineAddCircle size={24} className="text-white ml-3 -mt-1" />}
                bcolor="black"
                classNameContainer="w-64 hidden lg:block"
                className="w-full h-12 rounded-xl mr-0"
              >
                ثبت یادآور جدید
              </Button>
            ) : (
              <Button
                onClick={() => setKilometerEdit(true)}
                textType="H6"
                bcolor="black"
                classNameContainer="w-64 hidden lg:block"
                className="w-full h-12 rounded-xl mr-0"
              >
                ثبت کارکرد و یادآور جدید
              </Button>
            )}
          </div>
          <TabBar activeTab={activeTab} onActiveChanged={(number) => setActiveTab(number)} />
        </div>
      )}
      <ContainerScroll heightOffset={30} className={`relative h-screen`}>
        {!reminderFeatureActivated && (
          <Text type="H4" className="ts-accent mb-6 w-full">
            مشاهده خودرو
          </Text>
        )}
        {activeTab === 1 ? (
          <CarInfoTab
            reminderFeatureActivated={reminderFeatureActivated}
            loading={loading}
            onCarEditClicked={() => setEditPropsModal(true)}
            onOwnerEditClicked={() => setAddEditClientModal(true)}
            onAddToCustomersClicked={() => setAddEditClientModal(true)}
            openPremiumModal={() => setPremiumModal(true)}
            onUpdateKilometerClicked={() => setKilometerEdit(true)}
          />
        ) : activeTab === 2 ? (
          <RemindersHistoryTab />
        ) : (
          <RemindersListTab
            loading={loading}
            onEditReminderClicked={(data) => editItemHandler(data)}
            onAddNewReminderClicked={registerNewServiceHandler}
            onAddNewReminderWithKilometerClicked={() => setKilometerEdit(true)}
          />
        )}
      </ContainerScroll>

      {/* --------------- Modals ------------------- */}
      <SingleReminderDetailModal
        type={serviceType}
        isOpen={singleReminderOpen}
        onClose={() => setSingleReminderOpen((prev) => !prev)}
        onBtnClicked={singleReminderBtnClicked}
        data={selectedReminder}
        currentKilometer={+snap.carStatus.kilometer}
      />
      <HistoryDetailModal
        isOpen={historyModal}
        onClose={() => setHistoryModal(false)}
        data={selectedHistory}
        currentKilometer={+snap.carStatus.kilometer}
      />
      <RegisterNewService
        carId={id || ""}
        plateId={snap.carStatus.plate.id}
        editItem={selectedReminder}
        type={serviceType}
        isOpen={showRegisterServiceModal}
        currentKM={snap.carStatus.kilometer}
        onClose={() => setShowRegisterServiceModal((prev) => !prev)}
        onDone={() => {
          setPageCount(0);
          setSingleReminderOpen(false);
        }}
      />
      <EditCarProps
        isOpen={editPropsModal}
        onClose={() => setEditPropsModal(false)}
        data={snap.carStatus}
        noPhoneNumber={snap.carStatus.plate.mobile.includes("*")}
      />
      <FinalRegisterModal
        carId={id || ""}
        isOpen={showFinalRegisterModal}
        onClose={() => setShowFinalRegisterModal(false)}
      />
      <CarKilometerRegisterModal
        isComplete={snap.carStatus.status !== "in_progress"}
        justForEdit={snap.carStatus.status === "in_progress"}
        km={snap.carStatus.kilometer || 0}
        isOpen={kilometerEdit}
        onClose={() => setKilometerEdit(false)}
      />
      <TerminateSession isOpen={terminateSessionModal} onClose={() => setTerminateSessionModal(false)} id={id || ""} />
      <AddEditToClientListModal
        isOpen={addEditClientModal}
        onClose={() => setAddEditClientModal(false)}
        type={snap.carStatus.contact ? "edit" : "add"}
      />
      <PremiumNoticeModal isOpen={premiumModal} onClose={() => setPremiumModal(false)} />
      <SuccessfulSubscription
        isOpen={successfulSubModal}
        onClose={() => setSuccessfulSubModal(false)}
        newUrl={`${ConfigLink.carStatus.replace(":id", id || "")}`}
      />
    </>
  );
});

export { CarStatus as default };
