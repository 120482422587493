/* eslint-disable */
import { axios } from "./api";

interface IPart {
  name: string;
  cars: string[];
  price: number | string;
  manufacture: string;
  grouping: string;
  manufacturingCountry: string;
  code?: string;
  description?: string;
}

const baseUrl = "/shops";

const shop = {
  getParts: async (
    workshopId: string | null,
    page: number,
    sort: "DATE" | "NAME" | "",
    query: string | null,
    filter: any,
    grouping: string | null,
  ) => {
    const url = `${baseUrl}/search`;
    const result = await axios.post(url, {
      pageSize: 50,
      page,
      ...(sort ? { sort } : undefined),
      ...(query ? { query } : undefined),
      ...(filter.car ? { carIds: [filter.car.id] } : undefined),
      ...(filter.manufacture ? { manufacture: filter.manufacture } : undefined),
      ...(filter.manufacturingCountry ? { manufacturingCountry: filter.manufacturingCountry } : undefined),
      // ...(filter.cityId ? { cityId: filter.cityId } : undefined),
      ...(grouping ? { grouping } : undefined),
      ...(workshopId ? { workshopId } : undefined),
    });

    return result;
  },
  addPart: async (data: IPart) => {
    const url = `${baseUrl}`;
    const result = await axios.post(url, data);

    return result;
  },
  getPartInfo: async (id: string) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.get(url);

    return result;
  },
  editPartInfo: async (id: string, data: IPart) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.put(url, data);

    return result;
  },
  deletePart: async (id: string) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.delete(url);

    return result;
  },
};

export { shop };
