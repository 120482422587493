/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { ApiInstance, ConfigLink, useObjectState, useQueryString, vState } from "../../../../core";
import { BottomModal, Button, InputField, Text, useNotification } from "../../../components";
import { AccessLevelCard } from "./AccessLevelCard";
import { TransferOwnershipModal } from "./TransferOwnershipModal";

type ModalType = "add" | "edit";
type UserType = "active" | "pending";

interface IAddOrEditUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  type: ModalType;
  user?: any;
  userType: UserType;
  workshop: string;
  onAddOrEditDone?: () => void;
  userLevel: string;
  url: { id: string; name: string; access: string };
}

const AddOrEditUserModal: React.FC<IAddOrEditUserModalProps> = React.memo(
  ({ isOpen, onClose, type, user, workshop, onAddOrEditDone, userType, userLevel, url }) => {
    const navigate = useNavigate();
    const snap = useSnapshot(vState);

    const [info, setInfo] = useObjectState({
      name: "",
      mobile: "",
      accessLevel: "",
    });
    const [userId, setUserId] = useState("");
    const { name, mobile, accessLevel } = info;

    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);

    const [transferModal, setTransferModal] = useState(false);

    useEffect(() => {
      if (type === "add") {
        setDisabled(name.length === 0 || mobile.length === 0 || accessLevel.length === 0);
      } else {
        setDisabled(info.accessLevel === "transfer");
      }
    }, [info]);

    useEffect(() => {
      if (type === "edit" && user && isOpen) {
        console.log(user);

        setInfo({
          name: user.name || user.user?.name || "",
          mobile: user.mobile || user.user?.mobile || "",
          accessLevel: user.accessLevel,
        });
        setUserId(user.id);
      } else if (type === "add" && isOpen) {
        setInfo({
          name: "",
          mobile: "",
          accessLevel: "",
        });
      }
    }, [isOpen]);

    const addUser = async () => {
      const user = { ...info, workshop: workshop };

      await ApiInstance.agents
        .addAgent(user)
        .then((res) => {
          console.log(res);
          onAddOrEditDone && onAddOrEditDone();
          useNotification("کاربر جدید اضافه شد.", "success", "اضافه کردن کاربر");
          setTimeout(() => {
            onClose();
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 400 && err.response.data.message === "Invitation exist") {
            useNotification("کاربر با این شماره در حال حاضر وجود دارد.", "error", "اضافه کردن کاربر جدید");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const editUserSuccess = async () => {
      useNotification("سطح دسترسی کاربر مورد نظر تغییر کرد.", "success", "ویرایش سطح دسترسی");
      onAddOrEditDone && (await onAddOrEditDone());
      onClose();
    };

    const editAcceptedUser = async () => {
      const userObj = { accessLevel: accessLevel };

      await ApiInstance.agents
        .editApprovedAgent(userId, userObj)
        .then((res) => {
          console.log(res);
          editUserSuccess();
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
        });
    };

    const editPendingUser = async () => {
      console.log(info);

      await ApiInstance.agents
        .editPendingAgent(userId, info)
        .then((res) => {
          console.log(res);
          editUserSuccess();
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
        });
    };

    const editUser = async () => {
      userType === "active" ? editAcceptedUser() : editPendingUser();
    };

    const approveBtnHandler = () => {
      setLoading(true);
      type === "add" ? addUser() : editUser();
    };

    const doneHandler = async () => {
      onAddOrEditDone && (await onAddOrEditDone());
      navigate(
        { pathname: ConfigLink.manageWorkshopStaffs, search: useQueryString({ ...url, access: "admin" }) },
        { replace: true },
      );
      onClose();
    };

    return (
      <>
        <BottomModal isOpen={isOpen} onClose={onClose}>
          <div className="w-full flex flex-col justify-start items-start">
            <Text type="H4" className="ts-accent mb-6">
              {type === "add" ? "اضافه کردن کاربر جدید" : "ویرایش دسترسی"}
            </Text>

            <Text type="P3" className="ts-gray-08 mb-6">
              {type === "add"
                ? "نام و شماره همراه شخص مورد نظر را وارد کنید و یک سطح دسترسی برای آن انتخاب کنید."
                : `برای مشخص کردن سطح دسترسی “${
                    user ? user.name || "کاربر انتخاب شده" : "کاربر انتخاب شده"
                  }” یک گزینه را انتخاب کنید.`}
            </Text>

            {type !== "edit" && (
              <>
                <InputField
                  label="نام و نام خانوادگی"
                  value={name}
                  onChange={(e) => setInfo({ name: e })}
                  placeholder="نام و نام خانوادگی کاربر"
                />

                <InputField
                  inputType="number"
                  label="شماره همراه"
                  value={mobile}
                  onChange={(e) => {
                    if (e.length <= 11) setInfo({ mobile: e });
                  }}
                  placeholder="09000000000"
                />
              </>
            )}

            {type !== "edit" && (
              <Text type="H6" className="ts-accent mb-2">
                سطح دسترسی
              </Text>
            )}

            {privileges.map((item) => {
              return (
                <AccessLevelCard
                  key={item.value}
                  data={item}
                  onClick={() => setInfo({ accessLevel: item.value })}
                  accessLevel={info.accessLevel}
                />
              );
            })}
            {type === "edit" && userLevel === "owner" && (
              <AccessLevelCard
                data={transferOwnership}
                onClick={() => {
                  setInfo({ accessLevel: transferOwnership.value });
                  setTransferModal(true);
                }}
                accessLevel={info.accessLevel}
              />
            )}
            <div className="w-full flex justify-between mt-4">
              <Button
                onClick={approveBtnHandler}
                disabled={disabled}
                loading={loading}
                bcolor="primary"
                textType="H5"
                classNameContainer="ts-half-width"
                className="w-full rounded-xl h-12"
              >
                {type === "add" ? "اضافه کردن" : "ذخیره"}
              </Button>
              <Button
                onClick={onClose}
                bcolor="primaryTransparent"
                textType="H5"
                classNameContainer="ts-half-width"
                className="w-full rounded-xl h-12"
              >
                لغو
              </Button>
            </div>
          </div>
        </BottomModal>

        <TransferOwnershipModal
          isOpen={transferModal}
          data={info}
          userId={userId}
          onClose={() => {
            setTransferModal(false);
            setInfo({ accessLevel: user.accessLevel || "" });
          }}
          onEnd={doneHandler}
        />
      </>
    );
  },
);

const privileges = [
  {
    label: "کاربر عادی",
    value: "agent",
    description: "کاربر به همه بخش ها به جز اطلاعات مالی، مدیریت امکانات و مدیریت کارمندان دسترسی خواهد داشت.",
  },
  {
    label: "مدیریت",
    value: "admin",
    description: "این کاربر مانند مالک کسب و کار به همه بخش ها دسترسی خواهد داشت اما مالک کسب و کار نیست.",
  },
];

const transferOwnership = {
  label: "انتقال مالکیت",
  value: "transfer",
  description:
    "با انتخاب این گزینه مالکیت کسب و کار از شما به این فرد منتقل خواهد شد. سطح دسترسی شما نیز به مدیریت تغییر خواهد کرد.",
};

export { AddOrEditUserModal };

const styles = {};
