/* eslint-disable */
import React from "react";
import { BiChevronLeft } from "react-icons/bi";
import { HiLibrary } from "react-icons/hi";
import { Text } from "../../../components";

interface IWorkshopCardProps {
  data: any;
  isActive: boolean;
  onClick: () => void;
}

const WorkshopCard: React.FC<IWorkshopCardProps> = React.memo(({ data, isActive, onClick }) => {
  return (
    <>
      <div
        style={{ height: "73px" }}
        onClick={onClick}
        className={`w-full transition-all flex justify-between items-center outline-none relative cursor-pointer ts-shadow mb-4 bg-white rounded-xl p-6`}
      >
        <div className="w-full flex justify-start items-center">
          <HiLibrary size={24} className="ts-accent ml-2 -mt-0.5" />
          <div className="w-4/5 truncate flex justify-start items-center">
            <Text type="P3" className="ts-accent ml-1 truncate">
              {`${data.name || ""}`}
            </Text>
            {data && data.categories && (
              <Text type="P5" className="ts-accent ml-1 truncate">
                {`- ${data && data.categories && data.categories.map((item) => item.name).join(", ")}`}
              </Text>
            )}
            {isActive && (
              <div className="flex justify-center items-center rounded-full ts-bg-primary mr-3 py-0.5 px-2">
                <Text type="HM" className="text-white pt-px">
                  فعال
                </Text>
              </div>
            )}
          </div>
        </div>
        <BiChevronLeft size={30} className="ts-accent -mt-1 absolute left-6 top-6" />
      </div>
    </>
  );
});

export { WorkshopCard };

const styles = {};
