/* eslint-disable */
import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-multi-date-picker/styles/colors/purple.css";
import { BottomModal, Button, Text } from "../../../components";

interface IHistoryDetailModalProps {
  isOpen: boolean;
  onClose: () => void;
  data: any;
  currentKilometer: number;
}

const HistoryDetailModal: React.FC<IHistoryDetailModalProps> = React.memo(
  ({ isOpen, onClose, data, currentKilometer }) => {
    const weekDays = ["ش", "ی", "د", "س", "چ", "پ", "ج"];
    const defaultValue = {
      serviceType: "",
      nextKM: "",
      selectedService: "",
      // maxDate: "",
      // dateLabel: "",
      detail: "",
    };

    const [loading, setLoading] = useState(false);

    // const expiresType = data ? data.service.expiresType : "";
    // const today = moment();
    // const nextChangeTime =
    //   data && data.updatedAt && data.expiresInDays ? moment(data.updatedAt).add(data.expiresInDays, "days") : 0;
    // const differenceTiming = nextChangeTime ? nextChangeTime.diff(today, "days") : 0;

    // const daysRemained = nextChangeTime ? nextChangeTime.diff(today, "days") : -1000;
    // const isTimePassed = nextChangeTime ? nextChangeTime.diff(today, "days") <= 0 : false;

    // const currentKM = +currentKilometer || 0;
    // const nextKM = data ? +(data.kilometer + data.expiresInKilometers) : 0;
    // const remainKM = nextKM - currentKM;
    // const kilometerPercentage =
    //   currentKM > nextKM || remainKM === 0 ? 100 : 100 - +(remainKM / data.expiresInKilometers).toFixed(2) * 100;

    // const timePercentage = !nextChangeTime
    //   ? 100
    //   : 100 - +(+nextChangeTime.diff(today, "days") / (data ? data.expiresInDays : 1)).toFixed(2) * 100;

    return (
      <BottomModal isOpen={isOpen} onClose={onClose} className={``}>
        <div className="w-full flex flex-col justify-start items-start px-0.5">
          <Text type="H4" className="ts-gray-08 mb-6">
            جزییات یادآور
          </Text>

          <span className="w-full flex flex-col justify-start items-start ts-shadow mb-3 p-4 rounded-xl">
            <Text type="H5" className="ts-accent mb-6">
              تعویض روغن
            </Text>

            <span className="flex justify-start items-center mb-4">
              <Text type="H6" className="w-28 lg:w-32 ml-1 ts-gray-06">
                نام کسب و کار:
              </Text>
              {/* <Text type='H6' className="ts-primary">اتوسرویس مستوفی</Text> */}
              <Text type="P4" className="ts-accent">
                اتوسرویس مستوفی
              </Text>
            </span>

            <span className="flex justify-start items-center mb-4">
              <Text type="H6" className="w-28 lg:w-32 ml-1 ts-gray-06">
                ثبت کننده یادآور:
              </Text>
              {/* <Text type='H6' className="ts-primary">اتوسرویس مستوفی</Text> */}
              <Text type="P4" className="ts-accent">
                اکبر احدی
              </Text>
            </span>

            <span className="flex justify-start items-center mb-4">
              <Text type="H6" className="w-28 lg:w-32 ml-1 ts-gray-06">
                تاریخ ثبت:
              </Text>
              {/* <Text type='H6' className="ts-primary">اتوسرویس مستوفی</Text> */}
              <Text type="P4" className="ts-accent">
                {" "}
                ۱۴۰۰/۸/۲۹ ساعت ۱۵:۳۰
              </Text>
            </span>

            <span className="flex justify-start items-center mb-4">
              <Text type="H6" className="w-28 lg:w-32 ml-1 ts-gray-06">
                کارکرد:
              </Text>
              {/* <Text type='H6' className="ts-primary">اتوسرویس مستوفی</Text> */}
              <Text type="P4" className="ts-accent">
                ۱۳۵۰۰
              </Text>
            </span>
          </span>

          {/* {loading ? (
              <Skeleton
                style={{ height: "68px" }}
                containerClassName="w-full"
                className="w-full mt-4 mb-3 rounded-2xl"
              />
            ) : (
              expiresType !== "days" && (
                <div className="w-full mb-3 ts-bg-gray-01 rounded-2xl p-4 flex flex-col justify-start items-start">
                  <Text type="P4" className="ts-accent mb-2">
                    {`${
                      !data.committed
                        ? data.expiresInKilometers
                        : remainKM <= 0
                        ? "0"
                        : Math.abs(nextKM - (currentKM || 0))
                    } کیلومتر مانده تا تعویض بعدی`}
                  </Text>
                  <Progress.Line
                    strokeColor={kilometerPercentage >= 80 ? "#CA2525" : "#272D37"}
                    strokeWidth={7}
                    percent={kilometerPercentage < 0 || !data.committed ? 0 : kilometerPercentage}
                    showInfo={false}
                    className="w-full p-0"
                  />
                </div>
              )
            )}

            {loading ? (
              <Skeleton style={{ height: "68px" }} containerClassName="w-full" className="w-full mb-3 rounded-2xl" />
            ) : (
              (expiresType === "both" || expiresType === "days") &&
              data.service.expiresInDays && (
                <div className="w-full mb-3 ts-bg-gray-01 rounded-2xl p-4 flex flex-col justify-start items-start">
                  <Text type="P4" className="ts-accent mb-2">
                    {`${!data.committed ? +data.expiresInDays / 30 : Math.abs(Math.round(differenceTiming / 30))} ${
                      differenceTiming / 30 > 1 ? "ماه" : "روز"
                    } ${differenceTiming / 30 < 0 ? "از موعد تعویض گذشته" : "تا تعویض بعدی"}`}
                  </Text>
                  <Progress.Line
                    strokeColor={timePercentage >= 80 ? "#CA2525" : "#752FBB"}
                    strokeWidth={7}
                    percent={timePercentage < 0 || !data.committed ? 0 : timePercentage}
                    showInfo={false}
                    className="w-full p-0"
                  />
                  {daysRemained < 0 && daysRemained !== -1000 && (
                    <Text type="P5" className="ts-gray-07 mt-2">
                      {`تاریخ آخرین تعویض: ${new Date(data.updatedAt).toLocaleDateString("fa-IR")}`}
                    </Text>
                  )}
                </div>
              )
            )} */}

          {loading ? (
            <Skeleton containerClassName="w-full" className="w-full h-28 mb-4 rounded-2xl" />
          ) : (
            <div className="w-full ts-bg-gray-01 rounded-2xl p-4 flex flex-col justify-start items-start">
              <Text type="H6" className="ts-accent mb-3">
                توضیحات یادآور:
              </Text>
              <Text type="P4" className="ts-gray-06">
                {/* {data.description || "توضیحاتی برای این یادآور اضافه نشده است."} */}
                ایرانول ۱۲۰۰۰ ۱۰w۴۰ روغن تعویض شده به همراه فیلتر بنزین سرکان
              </Text>
            </div>
          )}
          <Button
            onClick={onClose}
            bcolor="primaryTransparent"
            classNameContainer="w-full"
            className="w-full h-12 rounded-xl mt-6"
          >
            متوجه شدم
          </Button>
        </div>
      </BottomModal>
    );
  },
);

export { HistoryDetailModal };
