/* eslint-disable */
import React from "react";
import Skeleton from "react-loading-skeleton";
import { Text } from "ui/components";

interface IHomepageLoaderProps {}

const HomepageLoader: React.FC<IHomepageLoaderProps> = React.memo(() => {
  return (
    <div className="w-full flex flex-col justify-start items-start">
      <Skeleton containerClassName="w-full" className="w-full rounded-xl mb-6" style={{ height: "211px" }} />
      <Text type="H4" className="ts-accent mb-4">
        خدمات پرکاربرد
      </Text>
      <span className="w-full grid grid-rows-none grid-cols-2 gap-2 lg:grid-cols-4 ts-service-button-wrapper mb-6">
        <Skeleton className="w-full rounded-xl h-28" />
        <Skeleton className="w-full rounded-xl h-28" />
        <Skeleton className="w-full rounded-xl h-28" />
        <Skeleton className="w-full rounded-xl h-28" />
      </span>
      <Text type="H4" className="ts-accent mb-4">
        جستجو
      </Text>
      <span className="w-full grid grid-rows-none grid-cols-2 gap-2 lg:grid-cols-4 ts-service-button-wrapper mb-6">
        <Skeleton className="w-full rounded-xl h-28" />
        <Skeleton className="w-full rounded-xl h-28" />
        <Skeleton className="w-full rounded-xl h-28" />
        <Skeleton className="w-full rounded-xl h-28" />
      </span>
    </div>
  );
});

export { HomepageLoader };

const styles = {};
