/* eslint-disable */
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useSnapshot } from "valtio";
import { useChangeTitle, vState } from "../../../core";
import { ContainerScroll } from "../../components";
import { Header } from "../../_layouts/components";
import { NoFeatureWelcome } from "./components";

interface IWelcomeProps {}

const env = import.meta.env;

const Welcome: React.FC<IWelcomeProps> = React.memo(({}) => {
  const metaTags = useChangeTitle("ویراژ - خوش آمدید");
  const snap = useSnapshot(vState);

  return (
    <div
      className={`w-full ts-base-bg-white overflow-x-hidden h-full relative flex flex-col justify-start items-center`}
    >
      {metaTags}
      <Header />
      <ContainerScroll className="pb-4 overflow-x-hidden">
        <NoFeatureWelcome />
      </ContainerScroll>
    </div>
  );
});

export { Welcome as default };

const styles = {};
