/* eslint-disable */
import { axios } from "./api";

const baseUrl = "/plates";
const plates = {
  getPlate: async (plateNumber: string) => {
    const url = `${baseUrl}/get_by_plate_number?value=${decodeURIComponent(plateNumber)}`;
    const result = await axios
      .get(url)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return result;
  },
  addPlate: async (data: {
    plateNumber: string;
    mobile: string;
    car?: string;
    carName: string;
    ownerName?: string;
  }) => {
    const url = `${baseUrl}`;
    const result = await axios.post(url, data);

    return result;
  },
  updateCarProperties: async (
    id: string,
    data: {
      mobile: string | null;
      plateNumber: string;
      ownerName: string | null;
      carName: string;
      monthlyUsage: string | null;
    },
  ) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.put(url, data);

    return result;
  },
};
export { plates };
