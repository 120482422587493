/* eslint-disable */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ApiInstance, ConfigLink, useFetchQueryString } from "../../../core";
import { useNotification } from "../../components";
import { useAuth } from "./../../../hooks/auth";

interface ICompleteInviteProps {}

interface IQueryStringTransfer {
  code: string;
}
const CompleteInvite: React.FC<ICompleteInviteProps> = React.memo(({}) => {
  const auth = useAuth();
  const navigate = useNavigate();
  let { code } = useFetchQueryString<IQueryStringTransfer>();

  const registerInvitation = async () => {
    await ApiInstance.account
      .completeInvitation(code)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است و در حال حاضر امکان ثبت درخواست وجود ندارد.", "error");
      })
      .finally(() => {
        navigate(ConfigLink.createWorkspaceStepOne);
        localStorage.removeItem("temp_city");
      });
  };

  useEffect(() => {
    if (auth.ready) {
      if (auth.authenticated) {
        if (code) {
          registerInvitation();
        } else {
          navigate(ConfigLink.homePage);
        }
      }
    }
  }, [auth.ready, auth.authenticated]);

  return (
    <>
      <div></div>
    </>
  );
});

export { CompleteInvite as default };

const styles = {};
