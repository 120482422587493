/* eslint-disable */
// @ts-ignore
import { digitsFaToEn } from "@persian-tools/persian-tools";
import { kilometerAverages } from "hooks";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Form, SelectPicker } from "rsuite";
import { useSnapshot } from "valtio";
import { ApiInstance, useObjectState, vState } from "../../../../core";
import { Button, InputField, Text, useNotification } from "../../../components";
import { LicensePlate } from "./LicensePlate";

interface IPlateNotRegisteredProps {
  onSubmit: () => void;
  plate: string;
  isOpen: boolean;
}

const env = import.meta.env;

const PlateNotRegistered: React.FC<IPlateNotRegisteredProps> = React.memo(({ onSubmit, plate, isOpen }) => {
  const defaultError = "پر کردن این فیلد الزامی می‌باشد.";

  const snap = useSnapshot(vState, { sync: true });

  const defaultValues = {
    plateNumber: "",
    car: {},
    carName: "",
    carId: "",
    mobile: "",
    ownerName: "",
    kilometer: 0,
    id: "",
    monthlyUsage: "",
  };

  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [selectedPlate, setSelectedPlate] = useObjectState(defaultValues);
  const { carName, mobile } = selectedPlate;

  const isSmallMobile = useMediaQuery({
    query: "(max-width: 380px)",
  });

  useEffect(() => {
    if (isOpen) {
      vState.selectedPlate = defaultValues;
      setSelectedPlate(defaultValues);
    }
  }, [isOpen]);

  const registerPlate = async () => {
    setLoading(true);
    let plateObj: any = {
      plateNumber: plate,
      mobile: selectedPlate.mobile ? digitsFaToEn(selectedPlate.mobile) : null,
      carName: selectedPlate.carName || null,
      ownerName: selectedPlate.ownerName || null,
      ...(selectedPlate.monthlyUsage.length !== 0 ? { monthlyUsage: selectedPlate.monthlyUsage } : undefined),
    };
    await ApiInstance.plates
      .addPlate(plateObj)
      .then((res) => {
        console.log(res);
        if (res.status === 200 || res.status === 201) {
          useNotification("پلاک با موفقیت اضافه شد.", "success", "ثبت پلاک");
          const data = {
            plateNumber: plate,
            id: res.data.data.id,
            mobile: selectedPlate.mobile,
            carName: selectedPlate.carName,
            ownerName: selectedPlate.ownerName,
          };

          vState.selectedPlate = { ...snap.selectedPlate, ...data } as any;

          // removeFromRecently();
          setTimeout(() => {
            onSubmit();
          }, 1500);
        } else {
          useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "خطا");
        }
      })
      .catch((err) => {
        console.log("err", err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "خطا");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const registerClickHandler = async () => {
    let errorsObj = { ...errors };
    if (carName.length === 0) {
      errorsObj.carName = defaultError;
    }
    if (mobile.length === 0) {
      errorsObj.mobile = defaultError;
    } else if (mobile.length !== 11 || digitsFaToEn(mobile.slice(0, 2)) !== "09") {
      errorsObj.mobile = "شماره وارد شده صحیح نیست";
    }
    if (Object.keys(errorsObj).length !== 0) {
      useNotification("لطفا فیلدهای مشخص شده را پر کنید.", "warning");
      setErrors(errorsObj);
    } else {
      registerPlate();
    }
  };

  const removeField = (field) => {
    let cn = { ...errors };
    delete cn[field];
    setErrors(cn);
  };

  return (
    <>
      <Text type="H4" className="ts-accent mb-6">
        ثبت خودرو
      </Text>
      <Text type="H6" className="ts-accent mb-2">
        شماره پلاک وارد شده
      </Text>
      <LicensePlate
        firstSection={+plate.slice(0, 2)}
        letter={plate.slice(2, 3) === "ا" ? "الف" : plate.slice(2, 3)}
        secondSection={+plate.substring(3, 6)}
        thirdSection={+plate.substring(7, 9)}
      />
      <Text type="P3" className="ts-gray-08 my-6">
        این خودرو تا کنون در سیستم شما ثبت نشده است، برای تکمیل و ثبت آن اطلاعات زیر را وارد کنید.
      </Text>

      <Form className="w-full">
        <InputField
          label="خودرو"
          value={carName}
          onChange={(e) => setSelectedPlate({ carName: e })}
          onFocus={() => removeField("carName")}
          placeholder="نام و مدل خودرو"
          error={"carName" in errors}
          errorText={errors.carName || ""}
        />

        <Text type="H6" className="ts-accent mb-2">
          متوسط کارکرد خودرو در ماه (اختیاری)
        </Text>
        <SelectPicker
          size="lg"
          placement="auto"
          data={kilometerAverages}
          value={selectedPlate.monthlyUsage}
          name="monthlyUsage"
          onChange={(e) => setSelectedPlate({ monthlyUsage: e })}
          block
          searchable={false}
          cleanable={false}
          placeholder="متوسط کارکرد خودروی خود را انتخاب کنید"
          className={`w-full h-12 mb-6 outline-none rounded-xl`}
        />

        <InputField
          label="شماره تماس"
          inputType="number"
          value={mobile}
          onChange={(e) => {
            if (e.length <= 11) {
              setSelectedPlate({ mobile: e });
            }
          }}
          onFocus={() => removeField("mobile")}
          placeholder="09000000000"
          error={"mobile" in errors}
          errorText={errors.mobile || ""}
        />

        <InputField
          label=" نام و نام خانوادگی (اختیاری)"
          value={selectedPlate.ownerName}
          name="ownerName"
          onChange={(e) => setSelectedPlate({ ownerName: e })}
          placeholder="نام و نام خانوادگی"
        />

        <Button
          onClick={registerClickHandler}
          type="submit"
          loading={loading}
          disabled={disabled}
          bcolor="primary"
          classNameContainer="w-full"
          className="w-full h-12"
        >
          ثبت خودرو
        </Button>
      </Form>
    </>
  );
});

export { PlateNotRegistered };
