/* eslint-disable */
import qs from "qs";
import { axios } from "./api";

interface IRequest {
  name: string;
  car: string;
  description?: string;
}

interface ISendRequest {
  price?: string;
  description?: string;
}

const baseUrl = "/parts";

const partSupply = {
  getRequests: async (page: number, sort: "DATE" | "NAME" | null, supplier: boolean = false) => {
    const url = `${baseUrl}`;
    const result = await axios.get(url, {
      params: {
        pageSize: 50,
        page,
        supplier,
        ...(sort ? { sort } : undefined),
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });

    return result;
  },
  getAllRequests: async (page: number, sort: "DATE" | "NAME" | null, query: string | null) => {
    const url = `${baseUrl}/all`;
    const result = await axios.get(url, {
      params: {
        pageSize: 50,
        page,
        ...(query ? { query } : undefined),
        ...(sort ? { sort } : undefined),
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });

    return result;
  },
  addRequests: async (data: IRequest) => {
    const url = `${baseUrl}`;
    const result = await axios.post(url, data);

    return result;
  },
  getRequestInfo: async (id: string) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.get(url);

    return result;
  },
  editRequestInfo: async (id: string, data: IRequest) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.put(url, data);

    return result;
  },
  deleteRequestInfo: async (id: string, reason: string) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.delete(url, {
      data: { reason },
    });

    return result;
  },
  sendRequest: async (id: string, data: ISendRequest) => {
    const url = `${baseUrl}/${id}/accept`;
    const result = await axios.post(url, data);

    return result;
  },
  deleteSendedRequest: async (id: string) => {
    const url = `${baseUrl}/${id}/supplier`;
    const result = await axios.delete(url);

    return result;
  },
  changeRequestStatus: async (id: string, status: "cancelled" | "provided", data?: ISendRequest) => {
    const url = `${baseUrl}/${id}/supply`;
    const result = await axios.put(url, {
      status,
      ...(data ? { price: data.price, description: data.description } : undefined),
    });

    return result;
  },
  reportProblem: async (reporteeId: string, reason: string, description?: string | null) => {
    const url = `/Reports`;
    const result = await axios.post(url, { reporteeId, reason, ...(description ? { description } : undefined) });
    return result;
  },
};

export { partSupply };
