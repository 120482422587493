/* eslint-disable */
import { ServiceStorage, tools, vState } from "core";
import React from "react";
import { FiCornerUpRight } from "react-icons/fi";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import banner from "../../../../assets/images/history-detail-header.svg";
import { Text } from "../../../components";

interface IHistoryDetailHeaderProps {
  loading: boolean;
  createdAt?: string;
}

const env = import.meta.env;

const HistoryDetailHeader: React.FC<IHistoryDetailHeaderProps> = React.memo(({ loading, createdAt }) => {
  const navigate = useNavigate();
  const snap = useSnapshot(vState);

  const isLargeScreen = useMediaQuery({
    query: "(min-width: 530px)",
  });

  const backButtonClickHandler = () => {
    const returnUrl = ServiceStorage.getTempReturnURL();
    ServiceStorage.removeTempReturnURL();
    if (returnUrl) {
      navigate(returnUrl);
    } else {
      // history.push({ pathname: ConfigLink.history, search: useQueryString({ id: plateId }) });
    }
  };

  return (
    <div
      style={{
        background: `url(${banner})`,
        backgroundPosition: isLargeScreen ? "40% 55%" : "45% 75%",
        backgroundRepeat: "no-repeat",
      }}
      className={`w-full transition-all ts-history-custom-header-height-full bg-cover z-20 fixed -top-1 flex flex-col justify-between items-start pt-3 pb-6 max-2xs:px-3 p-6`}
    >
      <div
        onClick={backButtonClickHandler}
        className={`transition-all h-10 px-3  rounded-xl ts-bg-gray-01 flex justify-start items-center cursor-pointer`}
      >
        <FiCornerUpRight size={18} className="ts-primary" />
        <Text type="H6" className="ts-primary mr-1" onClick={backButtonClickHandler}>
          بازگشت
        </Text>
      </div>
      <div className="w-full">
        <div className="flex justify-start items-center mb-3">
          <Text type="P4" className="ts-gray-04 ml-4">
            تاریخ انجام یادآور:
          </Text>
          {loading ? (
            <Skeleton className="w-32" />
          ) : (
            <Text type="P4" className="text-white">
              {createdAt ? tools.convertDateToPersianDate(createdAt, false, true) : "تاریخ در دسترس نیست"}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
});

export { HistoryDetailHeader };
