/* eslint-disable */
// @ts-ignore
import successImage from "assets/images/invite-user-success.svg";
import { ConfigLink } from "core";
import React from "react";
import { useNavigate } from "react-router-dom";
import { BottomModal, Button, Text } from "ui/components";

interface IMessageQueuedNotificationModalProps {
  isOpen: boolean;
  onClose: () => void;
  id: string;
}

const env = import.meta.env;

const MessageQueuedNotificationModal: React.FC<IMessageQueuedNotificationModalProps> = React.memo(
  ({ onClose, isOpen, id }) => {
    const navigate = useNavigate();

    const closeModalHandler = () => {
      navigate(ConfigLink.workshopClients, { replace: true });
      onClose();
    };

    return (
      <BottomModal isOpen={isOpen} onClose={onClose} centerScreen className="rounded-xl">
        <img src={successImage} alt="success-msg" className="mx-auto" />

        <Text type="H4" className={`ts-accent text-center mx-auto my-6`}>
          ارسال پیامک
        </Text>

        <Text type="P4" className={`ts-accent mb-4`}>
          پیامک شما در صف انتشار پیامک‌های ویراژ قرار گرفت. پس از ارسال به شما اطلاع‌رسانی خواهد شد.
        </Text>

        <Button onClick={closeModalHandler} bcolor="primary" classNameContainer="w-full" className="w-full h-12">
          متوجه شدم
        </Button>
      </BottomModal>
    );
  },
);

export { MessageQueuedNotificationModal };
