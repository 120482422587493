//@ts-nocheck
/* eslint-disable */
import { useAuth } from "hooks";
import React, { useEffect, useState } from "react";
import { vState } from "../../core";

interface IContainerScrollProps {
  heightOffset?: number | undefined;
  noPadding?: boolean;
  id?: string;
  children?: any;
  disableScroll?: boolean;
  className?: string;
  onScroll?: (e) => void;
  NoSticky?: boolean;
}

const ContainerScroll = React.forwardRef(
  (
    {
      className = "",
      children,
      heightOffset = undefined,
      noPadding = false,
      id = undefined,
      disableScroll = false,
      onScroll,
      NoSticky = false,
    }: IContainerScrollProps,
    ref,
  ) => {
    const auth = useAuth();
    const [lastScrollTop, setLastScrollTop] = useState(0);

    useEffect(() => {
      vState.isScrolling = false;
    }, []);

    function navbarControlHandler(e) {
      let st = e.target.scrollingElement.scrollTop;

      if (st <= 20) {
        vState.isScrolling = false;
      }

      if (st > lastScrollTop && st > 20) {
        // downScroll code
        if (!vState.isScrolling) {
          vState.isScrolling = true;
        }
      } else {
        // upScroll code
        if (st <= 10) {
          if (vState.isScrolling) {
            vState.isScrolling = false;
          }
        }
      }
      setLastScrollTop(st <= 0 ? 0 : st); // For Mobile or negative scrolling

      if (window.innerHeight + window.scrollY >= document.body.scrollHeight - 1.5) {
        console.log("end of page");
        vState.isEndOfPage = true;
      }
    }

    useEffect(() => {
      window.addEventListener("scroll", navbarControlHandler);
    }, []);

    // const controlNavbar = (e) => {
    //   if (onScroll) {
    //     onScroll(e);
    //   }
    // };
    const controlNavbar = (e) => {
      let st = e.target.scrollTop;
      if (st <= 20) {
        vState.isScrolling = false;
      }

      if (st > lastScrollTop && st > 20) {
        // downScroll code
        vState.isScrolling = true;
        console.log("down");
      } else {
        // upScroll code
        if (st <= 10) {
          vState.isScrolling = false;
        }
      }
      setLastScrollTop(st <= 0 ? 0 : st); // For Mobile or negative scrolling
    };
    return (
      <div
        // onScroll={controlNavbar}
        id="container-scroll"
        style={{ position: "unset", height: "inherit", paddingBottom: heightOffset, paddingTop: "88px" }}
        className={`w-full transition-all overflow-x-hidden overflow-y-auto ts-hide-scroll-but-keep-working z-30 px-6 max-2xs:px-3 ${
          NoSticky ? "" : auth && auth.authenticated ? "ts-wrapper-large-screen" : ""
        } ${className}`}
      >
        {children}
      </div>
    );
  },
);

export { ContainerScroll };
