/* eslint-disable */
import React from "react";
import Skeleton from "react-loading-skeleton";
import { Text } from "ui/components";

interface IPlateAddLoaderProps {}

const PlateAddLoader: React.FC<IPlateAddLoaderProps> = React.memo(() => {
  const fakeArray = [1, 2, 3, 4];

  return (
    <div className="w-full">
      <Text type="H4" className="ts-accent mb-6">
        پذیرش{" "}
      </Text>

      <Text type="H6" className="ts-accent mb-1">
        شماره پلاک
      </Text>

      <Skeleton containerClassName="w-full" className={`w-full h-14 rounded-xl mb-3`} />
      <Skeleton containerClassName="w-full" className={`w-full h-12 rounded-xl mb-12`} />

      <div className="w-full flex justify-between items-center mb-5">
        <Text type="P4" className="ts-accent">
          جستجوهای اخیر
        </Text>
      </div>

      {fakeArray.map((item, index) => {
        return (
          <div key={item} className="w-full flex flex-col justify-center items-center">
            <div className="w-full flex justify-between items-center mt-4 mb-4 px-3">
              <Skeleton className={`w-28 h-5`} />
              <Skeleton className={`w-6 h-6 rounded-lg`} />
            </div>
            {fakeArray.length > index + 1 && <hr className="w-full ts-recently-hr" />}
          </div>
        );
      })}
    </div>
  );
});

export { PlateAddLoader };

const styles = {};
