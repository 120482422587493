/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { ApiInstance, vState } from "../../../../core";
import { useAuth } from "../../../../hooks";
import { BottomModal, Button, Text, useNotification } from "../../../components";

interface ILogoutModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDone: () => void;
  data: any;
}

const AlreadyInvitedModal: React.FC<ILogoutModalProps> = React.memo(({ isOpen, onClose, data, onDone }) => {
  const history = useNavigate();
  const auth = useAuth();
  const snap = useSnapshot(vState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  const sendAgainHandler = async () => {
    setLoading(true);
    await ApiInstance.account
      .sendInvitation(data)
      .then((res) => {
        console.log(res);
        onDone();
        setTimeout(() => {
          onClose();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا مجددا تلاش کنید.", "error", "دعوت دیگران");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <BottomModal isOpen={isOpen} onClose={onClose}>
      <div className="w-full flex flex-col justify-start items-start">
        <Text type="H4" className="ts-accent">
          دعوت از دیگران
        </Text>
        <Text type="P3" className="ts-accent my-6">
          پیامک دعوت به این شماره قبلا ارسال شده است. آیا میخواهید مجددا ارسال شود؟
        </Text>
        <div className=" w-full flex justify-between items-center">
          <Button
            onClick={sendAgainHandler}
            loading={loading}
            bcolor="black"
            textType="H6"
            classNameContainer="ts-half-width"
            className="w-full rounded-xl h-12"
          >
            بله، دوباره ارسال شود
          </Button>

          <Button
            onClick={() => onClose()}
            bcolor="blackTransparent"
            textType="H6"
            classNameContainer="ts-half-width"
            className="w-full rounded-xl h-12"
          >
            خیر
          </Button>
        </div>
      </div>
    </BottomModal>
  );
});

export { AlreadyInvitedModal };

const styles = {};
