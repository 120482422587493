/* eslint-disable */
import React, { useEffect } from "react";

interface ICustomModalItemProps {
  name: string;
  active?: boolean;
  link: string;
}

interface ICustomModalProps {
  isShow: boolean;
  className?: string;
  children: any;
}

const CustomModal: React.FC<ICustomModalProps> = React.memo(({ children, isShow, className = "" }) => {
  useEffect(() => {}, []);
  return (
    <div
      className={`absolute bottom-0 h-full w-full z-50 bg-gray-400 bg-opacity-80 flex justify-center items-center ${
        isShow ? "ts-show-modal" : "ts-hide-modal"
      } ${className}`}
    >
      {children}
    </div>
  );
});

export { CustomModal };

const styles = {};
