/* eslint-disable */
import admission from "assets/images/home-admission-icon.svg";
import customers from "assets/images/home-customers-icon.svg";
import requests from "assets/images/home-requests-icon.svg";
import shop from "assets/images/home-shop-icon.svg";
import { ConfigLink, vState } from "core";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Text } from "ui/components";
import { useSnapshot } from "valtio";
import { GradientButton } from "./GradientButton";

interface IMostUsedServicesProps {}

const MostUsedServices: React.FC<IMostUsedServicesProps> = React.memo(() => {
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  return (
    <div className="w-full flex flex-col justify-start items-start mb-6">
      <Text type="H5" className="ts-accent mb-3">
        خدمات پرکاربرد
      </Text>
      <span className="w-full grid grid-rows-none grid-cols-2 gap-2 lg:grid-cols-4 ts-service-button-wrapper">
        {snap.activeFeatures.includes("admission_discharge") && (
          <GradientButton icon={admission} label="پذیرش و ترخیص" onClick={() => navigate(ConfigLink.dashboard)} />
        )}
        {snap.activeFeatures.includes("shop") && (
          <GradientButton icon={shop} label="فروشگاه قطعات من" isNew onClick={() => navigate(ConfigLink.shop)} />
        )}
        {snap.activeFeatures.includes("contact_list") && (
          <GradientButton
            icon={customers}
            label="مشتریان من"
            isNew
            onClick={() => navigate(ConfigLink.workshopClients)}
          />
        )}

        {snap.activeFeatures.includes("part_order_list") && (
          <GradientButton
            icon={requests}
            label="درخواستهای مشتریان"
            isNew
            onClick={() => navigate(ConfigLink.usersPartRequests)}
          />
        )}
      </span>
    </div>
  );
});

export { MostUsedServices };
