/* eslint-disable */
import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { HiLocationMarker, HiPhone, HiClock } from "react-icons/hi";
import { Text } from "../../../components";

interface IWorkshopViewLoaderProps {}

const WorkshopViewLoader: React.FC<IWorkshopViewLoaderProps> = React.memo(() => {
  return (
    <>
      <Skeleton className="w-56 h-5 mb-6" />

      {/* <Skeleton style={{ height: "22px" }} className="w-48 mb-4" /> */}

      <Skeleton style={{ height: "188px" }} containerClassName="w-full" className="w-full rounded-xl mb-4" />

      <div className="w-full flex justify-between items-center">
        <Skeleton containerClassName="ts-half-width" className="w-full h-12 rounded-xl" />
        <Skeleton containerClassName="ts-half-width" className="w-full h-12 rounded-xl" />
      </div>

      <Text type="H4" className="ts-accent text-right mb-4 mt-7">
        اطلاعات و نشانی
      </Text>
      <Skeleton count={3} containerClassName="w-full" className="w-full" />
      <div className="w-full flex justify-start items-center mt-2">
        <div className="w-12 h-12 rounded-2xl p-3 ts-bg-primary-light ml-3">
          <HiLocationMarker size={23} className="ts-accent" />
        </div>
        <Skeleton className="w-56 h-5" />
      </div>

      <div className="w-full flex justify-start items-center my-4">
        <div className="w-12 h-12 rounded-2xl p-3 ts-bg-primary-light ml-3">
          <HiPhone size={23} className="ts-accent -rotate-90" />
        </div>
        <Skeleton className="w-28 h-5" />
      </div>

      {/* <div className="w-full flex justify-start items-center">
        <div className="w-12 h-12 rounded-2xl p-3 ts-bg-primary-light ml-3">
          <HiClock size={23} className="ts-accent" />
        </div>
        <div className="flex flex-col justify-start items-start">
          <Skeleton className="w-56 h-5" />
          <Skeleton className="w-32 h-4 mt-2" />
        </div>
      </div> */}

      <div className="w-full flex flex-col justify-start items-start rounded-xl ts-bg-primary-light py-4 px-6 my-8">
        <Text type="H4" className="ts-accent mb-6">
          خدمات
        </Text>

        <Skeleton count={4} containerClassName="w-full" className="w-full" />
      </div>

      <div className="w-full flex flex-col justify-start items-start rounded-xl ts-bg-primary-light py-4 px-6">
        <Text type="H4" className="ts-accent mb-6">
          خودرو های قابل پذیرش
        </Text>

        <Skeleton count={4} containerClassName="w-full" className="w-full" />
      </div>
    </>
  );
});

export { WorkshopViewLoader };

const styles = {};
