import accessory from "../assets/images/shop-icon/accessory.svg";
import all from "../assets/images/shop-icon/all.svg";
import body from "../assets/images/shop-icon/body.svg";
import brakes from "../assets/images/shop-icon/brakes.svg";
import electrical from "../assets/images/shop-icon/electrical.svg";
import engine from "../assets/images/shop-icon/engine.svg";
import gearbox from "../assets/images/shop-icon/gearbox.svg";
import light from "../assets/images/shop-icon/light.svg";
import other from "../assets/images/shop-icon/other.svg";
import radiator from "../assets/images/shop-icon/radiator.svg";
import shaft from "../assets/images/shop-icon/shaft.svg";
import tire from "../assets/images/shop-icon/tire.svg";
import usable from "../assets/images/shop-icon/usable.svg";
import ventilator from "../assets/images/shop-icon/ventilator.svg";

export const shopCategories = [
  {
    value: "",
    label: "همه",
    image: all,
  },
  {
    value: "بدنه و اتاق",
    label: "بدنه و اتاق",
    image: body,
  },
  {
    value: "برقی",
    label: "برقی",
    image: electrical,
  },
  {
    value: "چراغ",
    label: "چراغ",
    image: light,
  },
  {
    value: "رادیاتور و اجزا",
    label: "رادیاتور و اجزا",
    image: radiator,
  },
  {
    value: "جلوبندی و تعلیق",
    label: "جلوبندی و تعلیق",
    image: shaft,
  },
  {
    value: "موتور و اجزا",
    label: "موتور و اجزا",
    image: engine,
  },
  {
    value: "گیربکس و اجزا",
    label: "گیربکس و اجزا",
    image: gearbox,
  },
  {
    value: "تهویه",
    label: "تهویه",
    image: ventilator,
  },
  {
    value: "ترمز",
    label: "ترمز",
    image: brakes,
  },
  {
    value: "لاستیکی و پلیمری",
    label: "لاستیکی و پلیمری",
    image: tire,
  },
  {
    value: "لوازم مصرفی",
    label: "لوازم مصرفی",
    image: usable,
  },
  {
    value: "اکسسوری",
    label: "اکسسوری",
    image: accessory,
  },
  {
    value: "سایر",
    label: "سایر",
    image: other,
  },
];
