/* eslint-disable */
import axios from "axios";
import { neshanAxios } from "./neshanApi";

const neshanAPI = `https://api.neshan.org`;
const ApiConfig = { baseURL: neshanAPI };

const map = {
  searchOnMap: async (search: string, lat: number, lng: string) => {
    const url = `/v1/search?term=${search}&lat=${lat}&lng=${lng}`;
    const result = await neshanAxios.get(url, ApiConfig);

    return result;
  },
  getUserIp: async () => {
    const result = await axios.get("https://api.ipify.org/?format=json", ApiConfig);

    return result;
  },
  getUserLocation: async (ip) => {
    const result = await axios.get(`https://freeipapi.com/api/json/${ip}`, ApiConfig);

    return result;
  },
};
export { map };
