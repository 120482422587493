/* eslint-disable */
import DatePicker, { utils } from "@hassanmojab/react-modern-calendar-datepicker";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BiSolidCalendarAlt } from "react-icons/bi";
import { FaCircleCheck } from "react-icons/fa6";
import { HiArrowCircleLeft, HiArrowCircleRight, HiBell } from "react-icons/hi";
import { IoCarSport } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import * as shamsi from "shamsi-date-converter";
import { useSnapshot } from "valtio";
import { ApiInstance, ConfigLink, useEffectNoInitial, useQueryString, vState } from "../../../../core";
import { StaticCard } from "../../../components";
import { CardsLoader } from "./CardsLoader";
import { CarsCard } from "./CarsCard";
import { NoRegisteredCars } from "./NoRegisteredCars";
import { StaticsCardLoader } from "./StaticsCardLoader";

interface IRegisteredCarsProps {
  day?: number;
  onDone?: (data) => void;
  statics: any;
}

const RegisteredCars: React.FC<IRegisteredCarsProps> = ({ day, onDone, statics }) => {
  const snap = useSnapshot(vState);
  const navigate = useNavigate();
  const hasReminderFeature = snap.activeFeatures.includes("add_reminders");

  const [dayCounter, setDayCounter] = useState(0);
  const [selectedDay, setSelectedDay] = useState<any>(null);

  useEffect(() => {
    if (day) {
      day > 0 ? setDayCounter(0) : setDayCounter(day);
    }
  }, [day]);

  useEffectNoInitial(() => {
    navigate(
      {
        pathname: ConfigLink.dashboard,
        search: useQueryString({ day: dayCounter }),
      },
      { replace: true },
    );

    const changedDate = moment().subtract(Math.abs(dayCounter), "days");
    console.log(changedDate);
    const persianDate = shamsi.gregorianToJalali(changedDate.format("YYYY/MM/DD"));
    console.log(persianDate);
    setSelectedDay({
      year: persianDate[0],
      month: persianDate[1],
      day: persianDate[2],
    });
  }, [dayCounter]);

  const changeDateHandler = (value) => {
    if (value) {
      setSelectedDay(value);
      const { year, month, day } = value;
      const shamsiToGeorgianDate = shamsi.jalaliToGregorian(year, month, day);
      const finalDate = `${shamsiToGeorgianDate[0]}/${shamsiToGeorgianDate[1]}/${shamsiToGeorgianDate[2]}`;
      const diff = moment().diff(moment(finalDate).toISOString(), "days");
      console.log(diff);
      previousDayHandler(diff > 0 ? -diff : 0);
    }
  };

  const nextDayHandler = async () => {
    if (dayCounter !== 0) {
      setDayCounter((prev) => prev + 1);
      const dayOrg = dayCounter + 1;
      vState.carLoading = true;
      const minDate = moment()
        .add(dayOrg - 1, "days")
        .format("YYYY-MM-DDT23:59:59.000[Z]");
      const maxDate = moment().add(dayOrg, "days").format("YYYY-MM-DDT23:59:59.000[Z]");

      await ApiInstance.sessions
        .getAllSessions(`?minDate=${minDate}&maxDate=${maxDate}`)
        .then((res) => {
          console.log("cars", res);
          vState.carList = res.data.data;
          onDone && onDone(res.data.meta);
        })
        .catch((err) => {
          console.log("err", err);
          setDayCounter((prev) => prev - 1);
        })
        .finally(() => (vState.carLoading = false));
    }
  };

  const previousDayHandler = async (value) => {
    if (value !== null) {
      setDayCounter(value);
    } else {
      setDayCounter((prev) => prev - 1);
    }
    const dayOrg = value ? Math.abs(value) : Math.abs(dayCounter - 1);
    console.log(dayOrg);

    vState.carLoading = true;
    const minDate = moment()
      .subtract(Math.abs(dayOrg + 1), "days")
      .format("YYYY-MM-DDT23:59:59.000[Z]");
    const maxDate = moment().subtract(Math.abs(dayOrg), "days").format("YYYY-MM-DDT23:59:59.000[Z]");

    await ApiInstance.sessions
      .getAllSessions(`?minDate=${minDate}&maxDate=${maxDate}`)
      .then((res) => {
        console.log("cars", res);
        vState.carList = res.data.data;
        onDone && onDone(res.data.meta);
      })
      .catch((err) => {
        console.log("err", err);
        setDayCounter((prev) => prev + 1);
      })
      .finally(() => (vState.carLoading = false));
  };

  const renderCustomInput = ({ ref }) => (
    <span className="flex justify-center items-center mr-6">
      <BiSolidCalendarAlt size={20} className="ts-accent -mt-0.5" />
      <input
        readOnly
        ref={ref}
        onClick={() => {
          if (ref) ref.current.focus();
        }}
        value={new Date(new Date(new Date()).setDate(new Date().getDate() + dayCounter)).toLocaleDateString("fa-IR", {
          month: "long",
          day: "numeric",
          year: "numeric",
        })}
        className="cursor-pointer mr-1 h-full w-28 bg-transparent border-none outline-none focus-within:outline-none ts-text-p4"
      />
    </span>
  );

  return (
    <>
      <div className="w-full flex flex-col justify-start items-center mt-4">
        <div className="w-full h-10 ts-bg-primary-light rounded-xl flex justify-between items-center p-2 mb-4">
          <HiArrowCircleRight
            onClick={() => previousDayHandler(null)}
            size={22}
            className="ts-primary cursor-pointer"
          />

          <DatePicker
            value={selectedDay}
            onChange={(v) => changeDateHandler(v)}
            renderInput={renderCustomInput}
            maximumDate={utils("fa").getToday()}
            shouldHighlightWeekends
            locale={"fa"}
            colorPrimary="#55288E"
          />

          <HiArrowCircleLeft
            onClick={nextDayHandler}
            size={22}
            className={`ts-primary cursor-pointer transition-all duration-300 `}
          />
        </div>

        {snap.carLoading ? (
          <StaticsCardLoader />
        ) : (
          <div
            className={`w-full justify-between items-center mb-4 grid ${
              hasReminderFeature ? "grid-cols-3" : "grid-cols-2"
            } gap-2`}
          >
            <StaticCard
              icon={<IoCarSport size={19} className="ts-primary" />}
              number={statics.total || 0}
              label="خودرو پذیرش شده"
            />
            {hasReminderFeature && (
              <StaticCard
                icon={<HiBell size={18} className="ts-primary" />}
                number={statics.reminders || 0}
                label="یادآور ثبت شده"
              />
            )}
            <StaticCard
              icon={<FaCircleCheck size={18} className="ts-primary" />}
              number={statics.commited || 0}
              label="خودرو ترخیص شده"
            />
          </div>
        )}
        {snap.carLoading ? (
          <CardsLoader />
        ) : snap.carList.length === 0 ? (
          <NoRegisteredCars />
        ) : (
          snap.carList.map((item, index) => {
            return <CarsCard data={item} id={index} backRoute={`${ConfigLink.dashboard}${window.location.search}`} />;
          })
        )}
      </div>
    </>
  );
};

export { RegisteredCars };

const styles = {};
