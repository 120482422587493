/* eslint-disable */
import { ApiInstance } from "core";
import React, { useState } from "react";
import { BottomModal, Button, Text, useNotification } from "ui/components";

interface ICancelPartProvideRequestModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDone: () => void;
  id: string;
}

const CancelPartProvideRequestModal: React.FC<ICancelPartProvideRequestModalProps> = React.memo(
  ({ isOpen, onClose, onDone, id }) => {
    const [loading, setLoading] = useState(false);

    const approveBtnHandler = async () => {
      setLoading(true);

      await ApiInstance.partSupply
        .changeRequestStatus(id, "cancelled")
        .then((res) => {
          console.log(res);
          useNotification("انصراف شما از قطعه مورد نظر ثبت شد.", "success");
          onDone();
          setTimeout(() => {
            onClose();
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    return (
      <BottomModal isOpen={isOpen} onClose={onClose}>
        <Text type="H4" className="ts-accent mb-6 text-right">
          انصراف از تامین قطعه
        </Text>

        <Text type="P3" className="ts-accent mb-6 text-right">
          آيا از تامین قطعه منصرف شدید؟
        </Text>

        <div className="w-full flex justify-between">
          <Button
            onClick={approveBtnHandler}
            loading={loading}
            bcolor="black"
            classNameContainer="ts-half-width"
            className="w-full rounded-xl h-12"
          >
            بله، منصرف شدم
          </Button>
          <Button
            onClick={onClose}
            bcolor="blackTransparent"
            classNameContainer="ts-half-width"
            className="w-full rounded-xl h-12"
          >
            لغو
          </Button>
        </div>
      </BottomModal>
    );
  },
);

const privileges = [
  {
    label: "مدیر سیستم (ادمین)",
    value: "admin",
  },
  {
    label: "کاربر عادی",
    value: "agent",
  },
];

export { CancelPartProvideRequestModal };

const styles = {};
