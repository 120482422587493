/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { ApiInstance, ServiceStorage, tools, vState } from "../../../core";
import metadata from "../../../metadata.json";
import { ServerNotRespond } from "../../views/ErrorPages/ServerNotRespond";
import { LoadingCustom } from "../LoadingPage";
import { NeedUpdateModal } from "../NeedUpdateModal";
import { useAuth } from "./../../../hooks/auth";

interface IConfigFetcherProps {
  children: any;
}

const ConfigFetcher: React.FC<IConfigFetcherProps> = React.memo(({ children }) => {
  const snap = useSnapshot(vState);
  const auth = useAuth();

  const [isFetched, setIsFetched] = useState(false);
  const [appVersion, setAppVersion] = useState("0");
  const [needUpdate, setNeedUpdate] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (auth.ready && auth.authenticated) {
      checkAppVersion();
      getActiveFeatures();
      getUI();
    }
  }, [auth.ready, auth.authenticated]);

  const getUI = async () => {
    await ApiInstance.common.getUIConfigs();
  };

  const getActiveFeatures = async () => {
    await ApiInstance.workshop
      .getWorkshop()
      .then((res) => {
        const data = res.data.data;
        if (data.length === 0) {
          console.log("No Workshop");
          ServiceStorage.removeActiveWorkshop();
          ServiceStorage.removeTempActiveWorkshop();
          vState.activeWorkshop = null;
          vState.selectedWorkshop = "";
          vState.userWorkspaces = [];
        } else {
          console.log("Workshop OK");
          vState.userWorkspaces = data;
          tools.setActiveWorkshop(data);
        }
      })
      .catch((err) => {
        console.log("ERR getActiveFeatures", err);
      });
  };

  const checkAppVersion = async () => {
    await ApiInstance.common
      .getVersion()
      .then((res) => {
        if (res.data.data) {
          console.log("version", res);
          const inAppVersion = +`${metadata.buildMajor}${metadata.buildMinor}${metadata.buildRevision}`;
          const newVersion = +`${res.data.data.versionName ? res.data.data.versionName.replaceAll(".", "") : 0}`;
          console.log("AppVersion", inAppVersion);
          console.log("ServerVersion", newVersion);
          vState.version = {
            number: res.data.data.versionName || 0,
            code: res.data.data.versionCode || 0,
            note: res.data.data.description || "",
          };
          setAppVersion(res.data.data.versionName || "0");
          setNeedUpdate(newVersion > inAppVersion);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchData = async () => {
    await ApiInstance.common
      .healthCheck()
      .then((res) => {
        console.log("HealthCheck", res);
        vState.hasError = false;
        setIsFetched(true);
      })
      .catch((e) => {
        console.log("error", e);
        vState.hasError = true;
      });
  };

  //return children;
  return !snap.hasError ? (
    isFetched ? (
      needUpdate ? (
        <NeedUpdateModal isOpen={needUpdate} onClose={() => setNeedUpdate(true)} version={appVersion} />
      ) : (
        children
      )
    ) : (
      <LoadingCustom />
    )
  ) : (
    <ServerNotRespond />
  );
});

export { ConfigFetcher };

const styles = {};
