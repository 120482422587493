/* eslint-disable */
import { ConfigLink } from "core";
import React, { useEffect, useState } from "react";
import { BsCircleFill } from "react-icons/bs";
import { HiOutlineAdjustments } from "react-icons/hi";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { SelectPicker } from "rsuite";
import { Header } from "ui/_layouts/components";
import noWorkshop from "../../../../assets/images/welcome-image-3.svg";
import { ContainerScroll, Text } from "../../../components";

import { WorkshopCard } from "./WorkshopCard";

interface ICardViewProps {
  workshops: any[];
  filter: string[];
  filterOpen: () => void;
  onSortChanged: (sort: string) => void;
  screenLoading: boolean;
}

const CardView: React.FC<ICardViewProps> = React.memo(
  ({ workshops, filter, filterOpen, onSortChanged, screenLoading }) => {
    const navigate = useNavigate();

    const [sort, setSort] = useState("");

    useEffect(() => {}, []);

    const sortWorkshops = [
      {
        label: "به ترتیب نزدیکترین",
        value: "distance",
      },
      {
        label: "به ترتیب بیشترین امتیاز",
        value: "feedbacks",
      },
    ];

    return (
      <>
        <Header noProfile showBackButton backClick={() => navigate(ConfigLink.homePage)} />
        <ContainerScroll heightOffset={80} className={`h-screen px-6 max-2xs:px-3 ts-map-card-view-wrapper`}>
          <div className="w-full flex justify-between items-center mb-4">
            <Text type="H4" className="ts-accent">
              لیست کسب و کارها
            </Text>
            <div
              onClick={filterOpen}
              className="w-12 h-12 mr-2 rounded-xl relative bg-white ts-shadow cursor-pointer p-2 flex justify-center items-center"
            >
              <HiOutlineAdjustments size={22} className="ts-accent" />
              {filter.length !== 0 && <BsCircleFill size={13} className="ts-primary absolute -top-1 -left-1" />}
            </div>
          </div>
          <div className="w-full flex justify-between items-center mb-2">
            <Text type="H6" className="ts-accent">
              {`${workshops.length} مورد پیدا شد`}
            </Text>
            <SelectPicker
              size="lg"
              data={sortWorkshops}
              value={sort}
              cleanable
              onClean={() => {
                setSort("");
                onSortChanged("");
              }}
              onChange={(value) => {
                setSort(value);
                onSortChanged(value);
              }}
              searchable={false}
              placeholder="مرتب سازی بر اساس"
              style={{ minWidth: "190px" }}
              className={`w-auto h-12 outline-none rounded-xl`}
            />
          </div>
          {screenLoading ? (
            [1, 2, 3, 4].map((item) => (
              <Skeleton
                key={item}
                style={{ height: "200px" }}
                containerClassName="w-full"
                className="w-full mb-2 rounded-xl"
              />
            ))
          ) : workshops.length === 0 ? (
            <div className="w-full h-full flex flex-col justify-center items-center -mt-9">
              <img src={noWorkshop} alt="no-result" />
              <Text type="P4" className="ts-gray-07 mt-6 text-center">
                کسب و کاری در این محدوده ثبت نشده است.
              </Text>
            </div>
          ) : (
            workshops.map((item) => {
              return <WorkshopCard key={item.id} data={item} />;
            })
          )}
        </ContainerScroll>
      </>
    );
  },
);

export { CardView };

const styles = {};
