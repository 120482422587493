/* eslint-disable */
import { axios } from "./api";

const baseUrl = "/ui/cars";
const car = {
  updateCarKilometer: async (id: string, km: number) => {
    const url = `/sessions/${id}/kilometer`;
    const result = await axios.put(url, { kilometer: km });

    return result;
  },
  getCarBrands: async () => {
    const url = `${baseUrl}/brands`;
    const result = await axios.get(url);

    return result;
  },
  getCarModels: async (id: string) => {
    const url = `${baseUrl}/${id}/models`;
    const result = await axios.get(url);

    return result;
  },
};
export { car };
