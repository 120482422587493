/* eslint-disable */
import { ApiInstance, useChangeTitle, vState } from "core";
import React, { useEffect, useState } from "react";
import { BsSquareFill } from "react-icons/bs";
import { FaCircleCheck, FaClipboardList } from "react-icons/fa6";
import { HiBell, HiLogin } from "react-icons/hi";
import { IoIosCloseCircle } from "react-icons/io";
import { IoCarSport } from "react-icons/io5";
import { useNavigate } from "react-router";
import { SelectPicker } from "rsuite";
import { ContainerScroll, StaticCard, Text, useNotification } from "ui/components";
import { useSnapshot } from "valtio";
import { ActivityComparison, ActivityReportPageLoader, ActivityTabBar, StaticLineChart } from "./components";

interface IActivityReportsProps {}

const ActivityReports: React.FC<IActivityReportsProps> = React.memo(({}) => {
  const metaTags = useChangeTitle("ویراژ - گزارش عملکرد کلی");
  const snap = useSnapshot(vState);
  const history = useNavigate();
  const admissionEnabled = snap.activeFeatures.includes("admission_discharge");
  const reminderEnabled = snap.activeFeatures.includes("add_reminders");
  const definedPartsEnabled = snap.activeFeatures.includes("shop");
  const partRequestsEnabled = snap.activeFeatures.includes("part_order_list");

  const [loading, setLoading] = useState(true);
  const [growthLoading, setGrowthLoading] = useState(true);
  const [selectedRange, setSelectedRange] = useState("per_day");
  const [chartSelectedRange, setChartSelectedRange] = useState("per_day");
  const [data, setData] = useState({
    cancelledParts: { count: 0, growthCompareOthersPercent: 0, growthPercent: 0 },
    completedSessions: { count: 0, growthCompareOthersPercent: 0, growthPercent: 0 },
    products: { count: 0, growthCompareOthersPercent: 0, growthPercent: 0 },
    providedParts: { count: 0, growthCompareOthersPercent: 0, growthPercent: 0 },
    reminders: { count: 0, growthCompareOthersPercent: 0, growthPercent: 0 },
    sessions: { count: 0, growthCompareOthersPercent: 0, growthPercent: 0 },
  });
  const [chartData, setChartData] = useState({
    cancelledParts: [],
    providedParts: [],
    products: [],
    reminders: [],
    sessions: [],
  });
  const [activeTab, setActiveTab] = useState<0 | 1 | 2>(0);

  const changeSortHandler = (value) => {
    setSelectedRange(value);
    setGrowthLoading(true);
  };

  const changeChartSortHandler = (value) => {
    setChartSelectedRange(value);
    setLoading(true);
  };

  const getStatics = async () => {
    await ApiInstance.workshop
      .getStatisticsGrowth(selectedRange)
      .then((res) => {
        console.log("statics", res);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا مجددا امتحان کنید", "error", "گزارش کلی");
      })
      .finally(() => {
        setGrowthLoading(false);
      });
  };

  const getChartStatics = async () => {
    await ApiInstance.workshop
      .getChartStatics(chartSelectedRange === "per_week" ? "per_month" : chartSelectedRange)
      .then((res) => {
        console.log("chart", res);
        setChartData(res.data);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا مجددا امتحان کنید", "error", "نمودار عملکرد");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setActiveTab(admissionEnabled ? 0 : 1);
  }, []);

  useEffect(() => {
    getStatics();
  }, [selectedRange]);

  useEffect(() => {
    getChartStatics();
  }, [chartSelectedRange]);

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={100} className={`relative px-6 max-2xs:px-3 pt-10 overflow-x-hidden`}>
        {loading || growthLoading ? (
          <ActivityReportPageLoader isGrowth={growthLoading} isChart={loading} />
        ) : (
          <>
            <div className="w-full flex justify-between items-center mt-10 mb-4">
              {/* ------------SECTION ONE------------ */}
              <Text type="H4" className="ts-accent">
                گزارش عملکرد کلی
              </Text>
            </div>
            <div className="w-full flex justify-between items-center mb-3">
              <Text type="H5" className="ts-accent">
                نمای کلی
              </Text>
              <SelectPicker
                size="lg"
                appearance="subtle"
                data={timePeriod}
                value={selectedRange}
                cleanable={false}
                searchable={false}
                onChange={(value) => changeSortHandler(value)}
                placeholder="بازه زمانی"
                className={`w-28 h-10 outline-none ts-activity-picker-wrapper`}
                renderValue={(value, item: any) => {
                  return <p className="ts-primary ts-activity-picker">{item.showingLabel}</p>;
                }}
              />
            </div>
            <div
              className={`w-full justify-between items-center mb-6 grid grid-cols-3 ${
                admissionEnabled && definedPartsEnabled && partRequestsEnabled ? "lg:grid-cols-6" : ""
              } gap-2`}
            >
              {admissionEnabled ? (
                <>
                  <StaticCard
                    average={{
                      percent: data.sessions.growthPercent || 0,
                    }}
                    icon={<IoCarSport size={19} className="ts-primary" />}
                    number={+(data.sessions.count || 0)}
                    label="خودرو پذیرش شده"
                  />
                  <StaticCard
                    average={{
                      percent: data.reminders.growthPercent || 0,
                    }}
                    icon={<HiBell size={18} className="ts-primary" />}
                    number={+(data.reminders.count || 0)}
                    label="یادآور ثبت شده"
                  />
                  <StaticCard
                    average={{
                      percent: data.completedSessions.growthPercent || 0,
                    }}
                    icon={<HiLogin size={18} className="ts-primary" />}
                    number={+(data.completedSessions.count || 0)}
                    label="خودرو ترخیص شده"
                  />
                </>
              ) : null}

              {definedPartsEnabled || partRequestsEnabled ? (
                <>
                  {definedPartsEnabled && (
                    <StaticCard
                      average={{
                        percent: data.products.growthPercent || 0,
                      }}
                      icon={<FaClipboardList size={18} className="ts-primary" />}
                      number={+(data.products.count || 0)}
                      label="قطعات تعریف شده"
                    />
                  )}

                  {partRequestsEnabled && (
                    <>
                      <StaticCard
                        average={{
                          percent: data.providedParts.growthPercent || 0,
                        }}
                        icon={<FaCircleCheck size={18} className="ts-primary" />}
                        number={+(data.providedParts.count || 0)}
                        label="اعلام امکان تامین"
                      />
                      <StaticCard
                        average={{
                          percent: data.cancelledParts.growthPercent || 0,
                        }}
                        icon={<IoIosCloseCircle size={18} className="ts-primary" />}
                        number={+(data.cancelledParts.count || 0)}
                        label="انصراف از تامین"
                      />
                    </>
                  )}
                </>
              ) : null}
            </div>

            {/* ------------SECTION TWO------------ */}
            <div className="w-full flex justify-between items-center mb-3">
              <Text type="H5" className="ts-accent">
                نمودار عملکرد
              </Text>
              <SelectPicker
                size="lg"
                appearance="subtle"
                data={chartTimePeriod}
                value={chartSelectedRange}
                cleanable={false}
                searchable={false}
                onChange={(value) => changeChartSortHandler(value)}
                placeholder="بازه زمانی"
                className={`w-28 h-10 outline-none ts-activity-picker-wrapper`}
                renderValue={(value, item: any) => {
                  return <p className="ts-primary ts-activity-picker">{item.showingLabel}</p>;
                }}
              />
            </div>
            <ActivityTabBar activeTab={activeTab} onActiveChanged={(number: any) => setActiveTab(number)} />

            <div className="w-full bg-white ts-shadow p-4 rounded-xl mb-6 mt-3 ts-chart-wrapper">
              <div
                style={{ height: "300px" }}
                className={`w-full ${
                  (activeTab === 1 && chartData.sessions.length !== 0 && chartData.reminders.length !== 0) ||
                  (activeTab === 1 && chartData.cancelledParts.length !== 0 && chartData.providedParts.length !== 0)
                    ? ""
                    : "flex justify-center items-center relative"
                }`}
              >
                <StaticLineChart
                  orgData={{
                    data0:
                      activeTab === 0
                        ? chartData.sessions
                        : activeTab === 2
                        ? chartData.products
                        : chartData.providedParts,
                    data1: activeTab === 0 ? chartData.reminders : activeTab === 2 ? [] : chartData.cancelledParts,
                  }}
                  selectedRange={chartSelectedRange}
                  type={activeTab}
                />
              </div>

              <div className="w-full flex justify-start items-start mt-6">
                {activeTab === 2 && (
                  <div className="flex justify-start items-center ml-6">
                    <BsSquareFill size={15} className="ts-success ml-2" />
                    <Text type="P4" className="ts-gray-06">
                      قطعات تعریف شده
                    </Text>
                  </div>
                )}
                {activeTab !== 2 && (
                  <>
                    <div className="flex justify-start items-center ml-6">
                      <BsSquareFill size={15} className="ts-success ml-2" />
                      <Text type="P4" className="ts-gray-06">
                        {activeTab === 0 ? "خودرو پذیرش شده" : "قطعات تامین شده"}
                      </Text>
                    </div>
                    <div className="flex justify-start items-center">
                      <BsSquareFill size={15} className="ts-info ml-2" />
                      <Text type="P4" className="ts-gray-06">
                        {activeTab === 0 ? "یادآور ثبت شده" : "انصراف از تامین قطعه"}
                      </Text>
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* ------------SECTION THREE------------ */}
            <Text type="H5" className="ts-accent mb-3">
              فعالیت شما نسبت به همکاران
            </Text>
            <div className="w-full ts-shadow px-4 py-3 bg-white ts-shadow rounded-xl mb-6 flex flex-col justify-center items-center">
              {reminderEnabled && (
                <>
                  <ActivityComparison label="تعریف یادآور" data={data.reminders.growthCompareOthersPercent || 0} />
                  {(admissionEnabled || partRequestsEnabled) && <hr className="w-full my-2 ts-recently-hr" />}
                </>
              )}

              {admissionEnabled && (
                <>
                  <ActivityComparison label="پذیرش و ترخیص" data={data.sessions.growthCompareOthersPercent || 0} />
                  {partRequestsEnabled && <hr className="w-full my-2 ts-recently-hr" />}
                </>
              )}

              {definedPartsEnabled && (
                <>
                  <ActivityComparison label="قطعات تعریف شده" data={data.products.growthCompareOthersPercent || 0} />
                  {partRequestsEnabled && <hr className="w-full my-2 ts-recently-hr" />}
                </>
              )}

              {partRequestsEnabled && (
                <>
                  <ActivityComparison label="امکان تامین" data={data.providedParts.growthCompareOthersPercent || 0} />
                  <hr className="w-full my-2 ts-recently-hr" />

                  <ActivityComparison label="انصراف تامین" data={data.cancelledParts.growthCompareOthersPercent || 0} />
                </>
              )}
            </div>
          </>
        )}
      </ContainerScroll>
    </>
  );
});

export { ActivityReports as default };

const timePeriod = [
  {
    label: "روزانه",
    showingLabel: "روزانه",
    value: "per_day",
  },
  {
    label: "یک هفته گذشته",
    showingLabel: "یک هفته",
    value: "per_week",
  },
  {
    label: "یک ماه گذشته",
    showingLabel: "یک ماه",
    value: "per_month",
  },
];

const chartTimePeriod = [
  {
    label: "یک هفته گذشته",
    showingLabel: "یک هفته",
    value: "per_day",
  },
  {
    label: "سه ماه گذشته",
    showingLabel: "سه ماهه",
    value: "per_week",
  },
  {
    label: "یکسال گذشته",
    showingLabel: "یک سال",
    value: "per_month",
  },
];
