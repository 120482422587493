import L from "leaflet";
import React, { useRef } from "react";
import { Marker, Tooltip } from "react-leaflet";
import { Text } from "ui/components";
import markerImg from "../../../../assets/images/marker-2.svg";

interface ISetLocationProps {
  data: any;
  currentLocation: L.LatLngExpression | null;
  selectedWorkshop: null | any;
  selectWorkshopHandler: (any) => void;
  workshops: any[];
  index: number;
}

const MarkerComponent: React.FC<ISetLocationProps> = React.memo(
  ({ data, currentLocation, selectedWorkshop, selectWorkshopHandler, workshops, index }) => {
    let markerRef: any = useRef();

    // const itemRemovedBefore = workshops.filter((item) => item.id === data.id);
    // if (itemRemovedBefore.length === 0) {
    //   console.log("not");
    // }

    let mapIcon = L.icon({
      iconUrl: markerImg,
      iconSize: [20, 24],
      iconAnchor: [11, 29],
      tooltipAnchor: [75, -20],
    });

    // map.once("movestart", () => {
    //   console.log("hi");
    // removeAllMarkers();
    // const markers = document.getElementsByClassName("leaflet-marker-pane");
    // const popups = document.getElementsByClassName("leaflet-popup");

    // for (let i = 0; i < markers.length; i++) {
    //   markers[i].classList.add("ts-map-arguments-hide");
    // }
    // for (let j = 0; j < popups.length; j++) {
    //   popups[j].classList.add("ts-map-arguments-hide");
    // }
    // });

    // map.once("moveend", () => {
    //   removeAllMarkers();
    // });

    // const removeAllMarkers = () => {
    //   map.eachLayer(function (layer: any) {
    //     if (!!layer.toGeoJSON) {
    //       map.removeLayer(layer);
    //     }
    //   });
    // };

    // if (currentLocation) {
    //   let marker = L.marker(currentLocation, {
    //     icon: mapIcon,
    //     autoPan: false,
    //   });
    //   // if (itemRemovedBefore.length === 0) {
    //   //   removeAllMarkers();
    //   // } else {
    //   marker.addTo(layers);
    //   marker
    //     .addTo(map)
    //     .bindPopup(data.name, {
    //       maxHeight: 28,
    //       keepInView: true,
    //       autoPan: false,
    //       closeButton: false,
    //       autoClose: false,
    //       closeOnClick: false,
    //       closeOnEscapeKey: false,
    //       className: `${selectedWorkshop && selectedWorkshop.id === data.id ? "ts-selected-item-map" : ""}`,
    //     })
    //     .openPopup(currentLocation)
    //     .off("click")
    //     .on("click", (e) => {
    //       console.log("e", e);
    //       selectWorkshopHandler(data);
    //     });
    //   map.addLayer(layers);
    //   // }
    // }

    // return null;

    return (
      <Marker
        ref={markerRef}
        icon={mapIcon}
        autoPan={false}
        position={data.location.coordinates}
        eventHandlers={{
          click: (L) => {
            console.log("marker clicked");
            selectWorkshopHandler(data);
            console.log(L);
            L.target._tooltip._contentNode.classList.add("ts-tooltip-selected");
          },
        }}
      >
        {/* <Popup
          keepInView
          autoPan={false}
          closeButton={false}
          autoClose={false}
          closeOnClick={false}
          closeOnEscapeKey={false}
          className={`${selectedWorkshop && selectedWorkshop.id === data.id ? "ts-selected-item-map" : ""}`}
          maxHeight={28}
        >
          {data.name}
        </Popup> */}
        <Tooltip
          permanent
          position={data.location.coordinates}
          interactive
          direction={"bottom"}
          offset={[data.name.length > 15 ? data.name.length + 50 : 25, 0]}
        >
          <div
            onClick={() => {
              console.log("tooltip clicked");
              selectWorkshopHandler(data);
            }}
            className={`ts-tooltip-default w-full h-7 flex justify-center items-center rounded-xl ${
              selectedWorkshop && selectedWorkshop.id === data.id ? "ts-tooltip-selected" : "ts-tooltip-not-selected"
            }`}
          >
            <Text type="P4">{data.name}</Text>
          </div>
        </Tooltip>
      </Marker>
    );
  },
);

export { MarkerComponent };
