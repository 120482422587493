/* eslint-disable */
import { countries } from "hooks";
import React, { useEffect, useState } from "react";
import { HiPencilAlt, HiTrash } from "react-icons/hi";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router";
import { useSnapshot } from "valtio";
import { ApiInstance, tools, useChangeTitle, useFetchQueryString, vState } from "../../../core";
import { Button, ContainerScroll, ImageSlider, ImageViewerModal, Text, useNotification } from "../../components";
import { AddEditPartModal, DeletePartModal } from "./components";
import ProviderCard from "./components/ProviderCard";

interface IMyPartDetailProps {}

interface IQueryStringTransfer {
  id: string;
  type?: string;
}

interface ICarProps {
  id: string;
  name: string;
}

interface IPartProps {
  name: string;
  cars: ICarProps[] | null;
  allCars: boolean;
  grouping: string;
  // manufacture: [],
  manufacture: string;
  manufacturingCountry: string;
  price: string;
  code?: string;
  description?: string;
  images: string[] | null;
  workshop?: any;
  createdBy?: any;
}

const MyShopPartDetail: React.FC<IMyPartDetailProps> = React.memo(({}) => {
  const metaTags = useChangeTitle("ویراژ - جزئیات قطعه");
  const navigate = useNavigate();
  const snap = useSnapshot(vState);
  const { id, type } = useFetchQueryString<IQueryStringTransfer>();

  const [partInfo, setPartInfo] = useState<IPartProps>({
    name: "",
    cars: null,
    allCars: false,
    grouping: "",
    // manufacture: [],
    manufacture: "",
    manufacturingCountry: "",
    price: "",
    code: "",
    description: "",
    images: null,
    workshop: null,
    createdBy: null,
  });
  const [pageLoading, setPageLoading] = useState(true);

  const [editPartModal, setEditPartModal] = useState<boolean>(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const getPartInfo = async () => {
    await ApiInstance.shop
      .getPartInfo(id)
      .then((res) => {
        console.log(res);
        const data = res.data.data;
        setPartInfo(data);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error");
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  useEffect(() => {
    getPartInfo();
  }, []);

  // const backClickHandler = () => {
  //   // const returnUrl = ServiceStorage.getTempReturnURL();
  //   // console.log(returnUrl);

  //   // ServiceStorage.removeTempReturnURL();
  //   // if (returnUrl) {
  //   //   history.push(returnUrl);
  //   // } else {
  //   navigate(".");
  //   // }
  // };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={115} className={`relative overflow-x-hidden`}>
        <div className="w-full flex justify-between items-center mb-4">
          <Text type="H4" className="ts-accent">
            اطلاعات قطعه
          </Text>
          {(!type || (type && type !== "view")) && (
            <div className="flex justify-center items-center gap-2">
              <Button
                onClick={() => setEditPartModal(true)}
                bcolor="white"
                classNameContainer="w-10 ts-shadow rounded-xl"
                className="w-full h-10 rounded-xl"
              >
                <HiPencilAlt size={21} className="ts-primary" />
              </Button>

              <Button
                onClick={() => setCancelModalOpen(true)}
                bcolor="white"
                classNameContainer="w-10 ts-shadow rounded-xl"
                className="w-full h-10 rounded-xl "
              >
                <HiTrash size={21} className="ts-primary" />
              </Button>
            </div>
          )}
        </div>
        <>
          <div className="w-full rounded-2xl ts-shadow p-4 mb-8 flex flex-col justify-start items-start">
            <div className="w-full flex justify-start items-start mb-4">
              <Text type="H6" className="ts-accent w-20 ml-2">
                نام قطعه
              </Text>
              {pageLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <Text type="P4" className="ts-accent">
                  {partInfo.name || "---"}
                </Text>
              )}
            </div>

            <div className="w-full flex justify-start items-start mb-4">
              <Text type="H6" className="ts-accent w-20 ml-2">
                خودرو
              </Text>
              {pageLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <Text type="P4" className="ts-accent">
                  {partInfo.cars && partInfo.cars.length !== 0
                    ? partInfo.cars.map((item) => item.name).join("، ")
                    : partInfo.allCars
                    ? "مناسب برای همه مدل‌های خودرو"
                    : "---"}
                </Text>
              )}
            </div>

            <div className="w-full flex justify-start items-start mb-4">
              <Text type="H6" className="ts-accent w-20 ml-2">
                دسته بندی
              </Text>
              {pageLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <Text type="P4" className="ts-accent">
                  {partInfo.grouping || "---"}
                </Text>
              )}
            </div>

            <div className="w-full flex justify-start items-start mb-4">
              <Text type="H6" className="ts-accent w-20 ml-2">
                شرکت سازنده
              </Text>
              {pageLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <Text type="P4" className="ts-accent">
                  {partInfo.manufacture ? partInfo.manufacture : "---"}
                </Text>
              )}
            </div>

            <div className="w-full flex justify-start items-start mb-4">
              <Text type="H6" className="ts-accent w-20 ml-2">
                کشور سازنده
              </Text>
              {pageLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <Text type="P4" className="ts-accent">
                  {partInfo.manufacturingCountry
                    ? countries.filter((item) => item.value === partInfo.manufacturingCountry)[0].label
                    : "---"}
                </Text>
              )}
            </div>

            <div className="w-full flex justify-start items-start mb-4">
              <Text type="H6" className="ts-accent w-20 ml-2">
                قیمت
              </Text>
              {pageLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <Text type="P4" className="ts-accent">
                  {partInfo.price ? `${tools.thousandSeparator(partInfo.price)} تومان` : "---"}
                </Text>
              )}
            </div>

            <div className="w-full flex justify-start items-start mb-4">
              <Text type="H6" className="ts-accent w-20 ml-2">
                کد یکتا
              </Text>
              {pageLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <Text type="P4" className="ts-accent">
                  {partInfo.code ? partInfo.code : "---"}
                </Text>
              )}
            </div>

            <div className="w-full flex justify-start items-start">
              <Text type="H6" className="ts-accent w-20 ml-2">
                توضیحات
              </Text>
              {pageLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <Text type="P4" className="ts-accent ts-request-description">
                  {partInfo.description || "---"}
                </Text>
              )}
            </div>

            {((partInfo.images && partInfo.images.length !== 0) || pageLoading) && (
              <div className="w-full flex flex-col justify-start items-start mt-4">
                <Text type="H6" className="ts-accent mb-2">
                  عکس قطعه
                </Text>
                {pageLoading ? (
                  <Skeleton className="w-full ts-carousel-image-height rounded-xl" />
                ) : (
                  <ImageSlider
                    images={partInfo.images || []}
                    // dir="/shop"
                    dir=""
                    onClick={(i) => {
                      setImageIndex(i);
                      setViewModalOpen(true);
                    }}
                  />
                )}
              </div>
            )}
          </div>

          {type && type === "view" && partInfo.workshop && (
            <>
              <Text type="H4" className="ts-accent mb-4">
                تامین کننده
              </Text>
              {/* <div className="flex justify-start items-start mb-4 py-2.5 px-4 rounded-2xl ts-pending-chips relative">
                    <AiFillExclamationCircle size={22} className="ts-warning -mt-0.5 absolute top-2.5 right-4" />
                    <Text type="P4" className="ts-gray-07 mr-1 indent-6">
                      ویراژ هیچگونه مسئولیتی در قبال تامین قطعه و صحت قیمت و سایر اطلاعات آن ندارد. در صورت مشاهده یا بروز
                      هرگونه مشکل می توانید
                      <b onClick={() => setReportModalShow(true)} className="ts-text-p4 z-20 ts-info mr-0.5 cursor-pointer">
                        گزارش کنید.
                      </b>
                    </Text>
                  </div> */}

              {pageLoading ? (
                <ProviderCard type="loader" />
              ) : partInfo.workshop ? (
                <ProviderCard type="info" data={{ workshop: partInfo.workshop, createdBy: partInfo.createdBy }} />
              ) : null}
            </>
          )}
        </>
      </ContainerScroll>
      {/* <Footer activeTab={5} /> */}

      <AddEditPartModal
        type="edit"
        isOpen={editPartModal}
        onClose={() => setEditPartModal(false)}
        onDone={getPartInfo}
        data={partInfo}
        id={id}
      />
      <DeletePartModal isOpen={cancelModalOpen} onClose={() => setCancelModalOpen(false)} id={id} />
      <ImageViewerModal
        isOpen={viewModalOpen}
        onClose={() => setViewModalOpen(false)}
        images={partInfo.images || []}
        dir="/shop"
        activeIndex={imageIndex}
      />
    </>
  );
});

export { MyShopPartDetail as default };

const styles = {};
