/* eslint-disable */
import { useEffect, useState } from "react";
import { AiFillMessage } from "react-icons/ai";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { Loader, SelectPicker } from "rsuite";
import { useSnapshot } from "valtio";
import { ApiInstance, ConfigLink, vState } from "../../../core";
import { useChangeTitle, useFetchQueryString } from "../../../core/hooks";
import { useAuth } from "../../../hooks";
import { ContainerScroll, Text, useNotification } from "../../components";
import { BulkSmsTabBar, BulkSmsTabData, CardLoader } from "./components";

const env = import.meta.env;

interface IQueryStringTransfer {
  id: string;
  subPayed?: string;
}

type SORT_TYPE = "DATE" | "NAME" | null;

const BulkSmsHistory = ({}) => {
  const auth = useAuth();
  const metaTags = useChangeTitle("ویراژ - تاریخچه پیامک انبوه");
  let { id, subPayed } = useFetchQueryString<IQueryStringTransfer>();
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  const [showData, setShowData] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);

  const [sortReminders, setSortReminders] = useState<SORT_TYPE>("DATE");

  const [activeTab, setActiveTab] = useState(1);
  const [isShrunk, setShrunk] = useState(false);

  const getFilteredData = (condition: string[], data: any[]) => {
    setLoading(true);
    const finalData = data.filter((item) => condition.includes(item.status));
    console.log(finalData);
    setShowData(finalData);
    setTimeout(() => {
      setLoading(false);
    }, 600);
  };

  const getHistory = async (pageIncreased: boolean) => {
    await ApiInstance.bulkSms
      .getHistory(pageCount + 1, sortReminders)
      .then((res) => {
        console.log(res);
        setCount(res.data.meta.total);
        setPageCount((prev) => prev + 1);
        if (pageIncreased) {
          let value = [...data, ...res.data.data];
          setData(value);
          getFilteredData(["sent"], value);
        } else {
          setData(res.data.data);
          getFilteredData(["sent"], res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "لیست کاربرها");
      })
      .finally(() => {
        setLoading(false);
        vState.isEndOfPage = false;
      });
  };

  useEffect(() => {
    getHistory(false);
  }, []);

  useEffect(() => {
    if (activeTab === 2) {
      getFilteredData(["payment_succeeded", "approved"], data);
    }
    if (activeTab === 3) {
      getFilteredData(["created", "payment_failed"], data);
    }
    if (activeTab === 4) {
      getFilteredData(["rejected"], data);
    }
  }, [activeTab]);

  useEffect(() => {
    console.log(count, data.length);

    if (snap.isEndOfPage && count > data.length && !loading) {
      getHistory(true);
    } else {
      vState.isEndOfPage = false;
    }
  }, [snap.isEndOfPage]);

  const sortChangeHandler = (value) => {
    setLoading(true);
    getHistory(false);
  };

  const sortData = [
    {
      label: "به ترتیب تاریخ",
      value: "DATE",
    },
    {
      label: "به ترتیب الفبا",
      value: "NAME",
    },
  ];

  const backClickHandler = () => {
    navigate(".");
  };

  const sensNewSmsHandler = () => {
    navigate({ pathname: ConfigLink.workshopClients }, { replace: true });
  };

  const tabChangedHandler = (number) => {
    setActiveTab(number);
  };

  return (
    <>
      {/* <Header showBackButton noLogo backClick={backClickHandler} profileActive /> */}
      {metaTags}
      <ContainerScroll heightOffset={65} className="relative">
        <div className="w-full flex justify-between items-center mb-6">
          <Text type="H4" className="ts-accent">
            تاریخچه پیامک انبوه
          </Text>

          <span onClick={sensNewSmsHandler} className="flex justify-center items-center cursor-pointer ts-primary">
            <AiFillMessage size={21} />
            <Text type="H6" className="mr-1">
              ارسال پیام جدید
            </Text>
          </span>
        </div>

        <div className={`w-full transition-all duration-500 ts-car-status-info bg-white`}>
          <div
            className={`w-full transition-all duration-75 ${
              isShrunk ? "fixed ts-top-20 left-0 px-6 max-2xs:px-3 pt-9 bg-white z-20" : ""
            }`}
          >
            <BulkSmsTabBar activeTab={activeTab} onActiveChanged={(number) => tabChangedHandler(number)} />
          </div>
        </div>

        <div className="w-full">
          {(loading || data.length !== 0) && (
            <div className="w-full flex justify-between items-center mb-3">
              {loading ? (
                <Skeleton className="w-28" />
              ) : (
                <Text type="H6" className="ts-accent">
                  {`${showData.length} پیامک`}
                </Text>
              )}
              <SelectPicker
                size="lg"
                data={sortData}
                value={sortReminders}
                disabled={loading}
                cleanable={false}
                onChange={(value: any) => sortChangeHandler(value)}
                searchable={false}
                placeholder="مرتب سازی"
                className={`w-36 h-12 outline-none rounded-xl`}
              />
            </div>
          )}

          {loading ? <CardLoader height="129px" className="mb-3" /> : <BulkSmsTabData data={showData} />}
          {snap.isEndOfPage && !loading && (
            <div className="w-full flex justify-center">
              <Loader size="sm" />
            </div>
          )}
        </div>
      </ContainerScroll>
    </>
  );
};

export { BulkSmsHistory as default };
