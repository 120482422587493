/* eslint-disable */
import { ConfigLink, tools, vState } from "core";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import activated from "../../../../assets/images/service-activated.svg";
import notActivated from "../../../../assets/images/service-not-activated.svg";
import { Button, Text } from "../../../components";

interface ISubscriptionProps {}

const Subscription: React.FC<ISubscriptionProps> = React.memo(() => {
  const navigate = useNavigate();
  const snap = useSnapshot(vState);

  const showServiceHandler = () => {
    navigate(ConfigLink.subInfo);
  };

  const status = snap.userInfo.premium ? "activated" : "not-activated";

  return (
    <div className="w-full px-6 py-3 rounded-2xl ts-shadow flex justify-between items-center ts-subscription-box mb-3">
      <span className="flex flex-col justify-start items-start">
        {status === "activated" ? (
          <Text type="P4" className="text-white mb-4">
            <b className="ts-text-h6">سرویس ویژه</b> برای شما فعال است.
          </Text>
        ) : (
          <Text type="P4" className="text-white mb-3">
            سرویس ویژه برای شما فعال نیست.
          </Text>
        )}
        {status === "activated" ? (
          <Text type="P4" className="text-white">
            اعتبار تا تاریخ{" "}
            {snap.userInfo.premium ? tools.convertDateToPersianDate(snap.userInfo.premium.expiresAt) : "---"}
          </Text>
        ) : (
          <Button onClick={showServiceHandler} bcolor="white" className="h-9 rounded-xl ts-primary">
            مشاهده سرویس ویژه
          </Button>
        )}
      </span>
      <img src={status === "activated" ? activated : notActivated} alt={status} />
    </div>
  );
});

export { Subscription };

const styles = {};
