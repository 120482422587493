/* eslint-disable */
import defaultCar from "assets/images/part-request-default.svg";
import { configs } from "configs";
import { ConfigLink, tools } from "core";
import React, { useEffect } from "react";
import { MdChevronLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Text } from "ui/components";

interface IUserPartRequestCardProps {
  data: any;
}

const UserPartRequestCard: React.FC<IUserPartRequestCardProps> = React.memo(({ data }) => {
  useEffect(() => {}, []);

  const navigate = useNavigate();

  const requestClickHandler = () => {
    navigate({ pathname: ConfigLink.usersPartRequestDetail.replace(":id", data.id) });
  };

  return (
    <div
      onClick={requestClickHandler}
      key={data.id}
      className="w-full flex justify-between items-center p-3 bg-white rounded-2xl ts-shadow mb-2 cursor-pointer"
    >
      <div className="flex h-full flex-col justify-between items-start">
        <div className="flex justify-start items-start">
          <div className="w-69 h-full rounded-xl">
            <img
              src={data.images && data.images.length !== 0 ? `${configs().imageUrl}/${data.images[0]}` : defaultCar}
              alt="default"
              className={`w-full h-full ${
                data.images && data.images.length !== 0 ? "object-cover aspect-square rounded-xl" : ""
              }`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = defaultCar;
              }}
            />
          </div>
          <div className="flex flex-col justify-start items-start mr-3">
            <Text type="H6" className="ts-accent">
              {data.name || "---"}
            </Text>
            <Text type="P4" className="ts-gray-07 my-2">
              {tools.isObject(data.car) ? data.car.name : data.car}
            </Text>
            <Text type="P5" className="ts-accent">
              {data.createdAt ? tools.convertDateToPersianDate(data.createdAt, true) : "---"}
            </Text>
          </div>
        </div>
      </div>
      <MdChevronLeft size={25} className="ts-accent" />
    </div>
  );
});

export { UserPartRequestCard };

const styles = {};
