/* eslint-disable */
import carImage from "assets/images/purple-car.svg";
import {
  ApiInstance,
  ConfigLink,
  ServiceStorage,
  tools,
  useChangeTitle,
  useFetchQueryString,
  useQueryString,
  vState,
} from "core";
import { kilometerAverages, useAuth } from "hooks";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { ContainerScroll, Text, useNotification } from "ui/components";
import { useSnapshot } from "valtio";

const env = import.meta.env;

interface IQueryStringTransfer {
  id: string;
}

type SORT_TYPE = "DATE" | "NAME" | null;

const ClientServiceHistoryDetail = ({}) => {
  const auth = useAuth();
  const metaTags = useChangeTitle("ویراژ - جزئیات تاریخچه");
  let { id } = useFetchQueryString<IQueryStringTransfer>();
  const snap = useSnapshot(vState);
  const history = useNavigate();

  const [data, setData] = useState({
    createdAt: "",
    updatedAt: "",
    kilometer: 0,
    plate: {
      id: "",
      plateNumber: "",
      car: null,
      carName: "",
      monthlyUsage: null,
      userProfileId: "",
    },
    workshop: {},
    reminders: [],
  });
  const [loading, setLoading] = useState(true);

  const getUsers = async () => {
    await ApiInstance.sessions
      .getSession(id)
      .then((res) => {
        console.log(res);
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 400) {
          useNotification(
            "جستجو نتیجه ای نداشت. لطفا متن جستجوی خود را تغییر داده و دوباره امتحان کنید.",
            "error",
            "لیست کاربرها",
          );
        } else {
          useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "لیست کاربرها");
        }
        setLoading(false);
        vState.isEndOfPage = false;
      })
      .finally(() => {
        setLoading(false);
        vState.isEndOfPage = false;
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const backClickHandler = () => {
    ServiceStorage.removeTempActiveWorkshop();
    history({ pathname: ConfigLink.clientDetail, search: useQueryString({ id: data.plate.userProfileId }) });
  };

  return (
    <>
      <ContainerScroll heightOffset={50} className={``}>
        <img src={carImage} alt="car" className="mx-auto mb-6" />
        <div className="flex justify-center items-center mb-8">
          <Text type="P4" className="ts-gray-06 ml-4">
            تاریخ انجام یادآور:
          </Text>
          {loading ? (
            <Skeleton className="w-32" />
          ) : (
            <Text type="P4" className="ts-accent">
              {data.createdAt ? tools.convertDateToPersianDate(data.createdAt, false, true) : "تاریخ در دسترس نیست"}
            </Text>
          )}
        </div>
        {loading ? (
          <Skeleton containerClassName="w-full" className="w-full h-36 rounded-2xl mb-4" />
        ) : (
          <div className={`w-full ts-shadow rounded-2xl mb-4 bg-white flex flex-col justify-start items-start p-4`}>
            <Text type="H5" className="ts-accent mb-4">
              مشخصات خودرو
            </Text>
            <div className="w-full flex justify-start items-center mb-4">
              <Text type="P4" className="ts-gray-06 w-28 ml-3">
                پلاک
              </Text>
              <Text type="P4" className="ts-accent">
                {data.plate.plateNumber ? tools.setPlateNumber(data.plate.plateNumber) : "---"}
              </Text>
            </div>
            <div className="w-full flex justify-start items-center mb-4">
              <Text type="P4" className="ts-gray-06 w-28 ml-3">
                نوع ماشین:
              </Text>
              <Text type="P4" className="ts-accent">
                {data.plate ? (data.plate.car ? (data.plate.car as any).name : data.plate.carName || "---") : "---"}
              </Text>
            </div>
            <div className="w-full flex justify-start items-center mb-4">
              <Text type="P4" className="ts-gray-06 w-28 ml-3">
                کارکرد ثبت شده:
              </Text>
              <Text type="P4" className="ts-accent">
                {`${data.kilometer ? tools.thousandSeparator(data.kilometer) : 0} کیلومتر`}
              </Text>
            </div>
            <div className="w-full flex justify-start items-center">
              <Text type="P4" className="ts-gray-06 w-28 ml-3">
                متوسط کارکرد (ماه) :
              </Text>
              <Text type="P4" className="ts-accent">
                {data.plate.monthlyUsage
                  ? kilometerAverages.filter((item) => item.value === data.plate.monthlyUsage)[0].label
                  : "---"}
              </Text>
            </div>
          </div>
        )}

        {loading ? (
          <Skeleton containerClassName="w-full" className="w-full h-48 rounded-2xl" />
        ) : (
          data.reminders && (
            <div className={`w-full ts-shadow rounded-2xl bg-white flex flex-col justify-start items-start p-4`}>
              <Text type="H5" className="ts-accent mb-4">
                خدمات ثبت شده
              </Text>
              <ul className="list-disc mr-4">
                {data.reminders.map((item: any) => {
                  return (
                    <li className="mb-1">
                      <Text type="P4" className="ts-accent">
                        {item.service.name || "---"}
                      </Text>
                    </li>
                  );
                })}
              </ul>
            </div>
          )
        )}
      </ContainerScroll>
    </>
  );
};

export { ClientServiceHistoryDetail as default };
