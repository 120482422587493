/* eslint-disable */
import React from "react";
import { BottomModal, Button, Text } from "./";
import { VpnEnableImage } from "./../views/ErrorPages/components/vpn-img";

interface IVpnAlertProps {
  isOpen: boolean;
  onClose: () => void;
}

const VpnAlert: React.FC<IVpnAlertProps> = React.memo(({ isOpen, onClose }) => {
  return (
    <BottomModal centerScreen staticBackdrop isOpen={isOpen} onClose={onClose} className="p-6 rounded-xl mx-0">
      <span className="w-full flex justify-center items-center">
        <VpnEnableImage />
      </span>
      <Text type="P4" className="ts-accent my-7">
        برای اختلال کمتر و عملکرد بهتر اپلیکیشن، لطفا VPN خود را خاموش کنید.
      </Text>

      <Button bcolor="primary" onClick={onClose} classNameContainer="w-full" className="w-full h-12 rounded-xl">
        متوجه شدم
      </Button>
    </BottomModal>
  );
});

export { VpnAlert };

const styles = {};
