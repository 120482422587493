/* eslint-disable */
// @ts-ignore
import { ConfigLink, ServiceStorage, tools, vState } from "core";
import React, { useEffect, useState } from "react";
import { FaCircleExclamation } from "react-icons/fa6";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
// import successImage from "../../../../assets/images/invite-user-success.svg";
import { BottomModal, Button, InputField, Text } from "ui/components";

interface ISendMessageToCustomersModalProps {
  isOpen: boolean;
  onClose: () => void;
  users: any[];
  removeUser: (id) => void;
  id: string;
}

const env = import.meta.env;

const SendMessageToCustomersModal: React.FC<ISendMessageToCustomersModalProps> = React.memo(
  ({ onClose, isOpen, users, removeUser, id }) => {
    const navigate = useNavigate();
    const snap = useSnapshot(vState);

    const eachMessageFee = 490;
    const oneMessageCharLength = 70;

    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState("");
    const [messageText, setMessageText] = useState("");
    const [messageCount, setMessageCount] = useState(1);
    const [showAllUsers, setShowAllUsers] = useState(false);

    const resetValues = () => {
      setMessageText("");
      setTitle("");
      setShowAllUsers(false);
    };

    useEffect(() => {
      setMessageCount(messageText.length === 0 ? 1 : Math.ceil(messageText.length / oneMessageCharLength));
    }, [messageText]);

    useEffect(() => {
      if (isOpen && users.length === 0) {
        onClose();
      }
    }, [users]);

    const paymentHandler = () => {
      const usersID = users.map((item) => item.id);
      console.log(usersID);
      const data = {
        title: title,
        payload: messageText,
        contactIds: usersID,
      };
      ServiceStorage.setBulkSmsInfo(data);
      navigate({ pathname: ConfigLink.clientMessageCheckout });
    };

    return (
      <BottomModal isOpen={isOpen} onClose={onClose}>
        <Text type="H4" className={`ts-accent mb-8`}>
          ارسال پیامک
        </Text>

        <InputField
          placeholder="مانند: اطلاع رسانی جابجایی کسب و کار، تاخیر در باز کردن..."
          value={title}
          onChange={(e) => setTitle(e)}
          label="عنوان"
          margin="mb-2"
        />
        <Text type="P4" className={`ts-accent mb-5`}>
          عنوان در متن پیامک ارسال نمی شود اما به شما کمک می کند تاریخچه پیام های خود را راحت تر مدیریت کنید.
        </Text>

        <Text type="H6" className={`ts-accent mb-4`}>
          مخاطبین پیام
        </Text>

        <div className="w-full flex justify-start items-start flex-wrap mb-5 gap-2">
          {users.map((item, index) => {
            if (index < 5) {
              return (
                <span
                  onClick={() => {
                    if (users.length > 1) removeUser(item.id);
                  }}
                  className="flex justify-center text-white items-center rounded-full ts-bg-gray-08 px-4 py-1 cursor-pointer"
                >
                  <Text type="HM" className="ml-1 w-max">
                    {item.contactName || "بدون نام"}
                  </Text>
                  {users.length > 1 && <MdClose size={20} className="" />}
                </span>
              );
            }
          })}
          {users.length > 5 && !showAllUsers && (
            <Text onClick={() => setShowAllUsers(true)} type="H6" className="ts-gray-06 pt-1.5 cursor-pointer">{`${
              users.length - 5
            }+ نفر دیگر`}</Text>
          )}
          {users.length > 5 &&
            showAllUsers &&
            users.map((item, index) => {
              if (index > 4) {
                return (
                  <span
                    onClick={() => removeUser(item.id)}
                    className="flex justify-center text-white items-center rounded-full ts-bg-gray-08 px-4 py-1 cursor-pointer"
                  >
                    <Text type="HM" className="ml-1 w-max">
                      {item.contactName || "بدون نام"}
                    </Text>
                    <MdClose size={20} className="" />
                  </span>
                );
              }
            })}
        </div>

        <InputField
          placeholder="متن پیامی که می‌خواهید برای مشتریان ارسال کنید(حداقل پنج کاراکتر)"
          fieldType="textarea"
          rows={4}
          value={messageText}
          onChange={(e) => setMessageText(e)}
          label="متن پیام"
          margin="mb-2"
        />
        <div className="w-full flex flex-col justify-start items-start mb-5">
          <Text type="P5" className="ts-gray-07 mb-3">{`${messageCount} پیامک (${
            messageText.length - (messageCount - 1) * oneMessageCharLength
          }/${oneMessageCharLength})`}</Text>
          <Text type="P4" className="ts-primary">
            هزینه تقریبی ارسال پیامک:{" "}
            <b className="ts-text-h6">
              {messageText.length > 1 ? tools.thousandSeparator(messageCount * eachMessageFee * users.length) : 0} تومان
            </b>
          </Text>
        </div>

        <div className="flex justify-start items-start relative mb-5 py-2.5 px-4 rounded-2xl ts-bulk-notice">
          <FaCircleExclamation size={18} className="ts-info -mt-0.5 absolute" />
          <Text type="P4" className="ts-gray-07 mr-1 indent-5">
            پیامک های انبوه به صورت تجمعی هر روز در ساعت ۰۹:۰۰ صبح و بعد از تایید توسط ویراژ ارسال می شوند.
          </Text>
        </div>

        <div className="w-full flex justify-between items-center gap-2">
          <Button
            onClick={paymentHandler}
            disabled={messageText.length < 5 || users.length === 0 || title.length === 0}
            bcolor="primary"
            classNameContainer="w-full"
            className="w-full h-12"
          >
            پرداخت
          </Button>
          <Button
            onClick={() => {
              onClose();
              resetValues();
            }}
            bcolor="primaryTransparent"
            classNameContainer="w-full"
            className="w-full h-12"
          >
            لغو
          </Button>
        </div>
      </BottomModal>
    );
  },
);

export { SendMessageToCustomersModal };
