/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { vState } from "../../../../core";
import { Button, Text } from "../../../components";
import { LicensePlate } from "./";

interface IPlateRegisteredProps {
  onSubmit: () => void;
  plate: string;
  onClose: () => void;
}

const PlateRegistered: React.FC<IPlateRegisteredProps> = React.memo(({ onSubmit, plate, onClose }) => {
  const snap = useSnapshot(vState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    vState.selectedPlate.plateNumber = plate;
  }, []);

  const registerClickHandler = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      onSubmit();
    }, 400);
  };

  return (
    <>
      {snap.selectedPlate.activeSession && (
        <>
          <Text type="P3" className="ts-gray-08 mb-4">
            این خودرو در تاریخ
            <strong>{new Date(snap.selectedPlate.activeSession.updatedAt).toLocaleDateString("fa-IR")}</strong>
            توسط شما پذیرش شده ولی ترخیص نشده است. در صورت نیاز به پذیرش جدید، لازم است وضعیت پذیرش قبلی را مشخص کنید.
          </Text>
        </>
      )}
      <Text type="H6" className="ts-accent mb-2">
        شماره پلاک
      </Text>
      <LicensePlate
        firstSection={+plate.slice(0, 2)}
        letter={plate.slice(2, 3) === "ا" ? "الف" : plate.slice(2, 3)}
        secondSection={+plate.substring(3, 6)}
        thirdSection={+plate.substring(7, 9)}
      />
      {snap.selectedPlate.activeSession === null && (
        <Text type="P3" className="ts-gray-08 my-6">
          این خودرو قبلا با اطلاعات زیر در سامانه ثبت شده است.
        </Text>
      )}
      <div
        className={`w-full rounded-2xl ts-bg-primary-light p-4 mb-4 ${
          snap.selectedPlate.activeSession ? "mt-2" : ""
        } flex justify-start items-start`}
      >
        <Text type="P4" className="ts-accent ml-4">
          خودرو:
        </Text>
        <Text type="P4" className="ts-accent">
          {snap.selectedPlate.car
            ? snap.selectedPlate.car.name || "---"
            : snap.selectedPlate.carName === "Not_Defined"
            ? "یافت نشد"
            : snap.selectedPlate.carName}
        </Text>
      </div>

      <Button
        onClick={registerClickHandler}
        loading={loading}
        bcolor="primary"
        classNameContainer="w-full"
        className="w-full h-12"
      >
        مشاهده وضعیت خودرو
      </Button>

      {snap.selectedPlate.activeSession && (
        <Button
          onClick={onClose}
          bcolor="transparent"
          classNameContainer="w-full"
          className="w-full h-12 mt-2 ts-primary -mb-4"
        >
          بازگشت و ادامه
        </Button>
      )}
    </>
  );
});

export { PlateRegistered };
