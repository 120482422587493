/* eslint-disable */
import noSegment from "assets/images/no-segment-requested.svg";
import { ApiInstance, ConfigLink, useChangeTitle, useFetchQueryString, useQueryString, vState } from "core";
import _debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router";
import { Loader, SelectPicker } from "rsuite";
import { Button, ContainerScroll, SearchInput, Text, useNotification } from "ui/components";
import { useSnapshot } from "valtio";
import { UserPartRequestCard } from "./components";

interface IUsersPartRequestsProps {}

type SORT_TYPE = "DATE" | "NAME" | "";

interface IQueryStringTransfer {
  q?: string;
}

const UsersPartRequests: React.FC<IUsersPartRequestsProps> = React.memo(({}) => {
  const metaTags = useChangeTitle("ویراژ - درخواستهای مشتریان");
  const snap = useSnapshot(vState);
  const navigate = useNavigate();
  let { q } = useFetchQueryString<IQueryStringTransfer>();

  const [requests, setRequests] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);

  const [searchText, setSearchText] = useState("");
  const [sortReminders, setSortReminders] = useState<SORT_TYPE>("");

  const [filterOpen, setFilterOpen] = useState(false);
  const [categoriesFilter, setCategoriesFilter] = useState<string[]>([]);
  const [CitiesFilter, setCitiesFilter] = useState("");
  const [viewStatusFilter, setViewStatusFilter] = useState<"all" | "actives" | "not-actives">("all");
  const [selectedDateFilter, setSelectedDateFilter] = useState<any[]>([]);

  const getServices = async (sort: SORT_TYPE, pageIncreased: boolean, query?: string | null) => {
    if (query) {
      updateURL(query);
    }

    await ApiInstance.partSupply
      .getAllRequests(pageCount + 1, sort || null, query || null)
      .then((res) => {
        console.log(res);
        setCount(res.data.meta.total);
        if (pageIncreased) {
          let data = [...requests, ...res.data.data];
          setRequests(data);
          setPageCount((prev) => prev + 1);
        } else {
          setRequests(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error");
      })
      .finally(() => {
        setLoading(false);
        vState.isEndOfPage = false;
      });
  };

  useEffect(() => {
    if (q) setSearchText(q);
    getServices(sortReminders, false, q || null);
  }, []);

  useEffect(() => {
    if (snap.isEndOfPage && count > requests.length && !loading) {
      getServices(sortReminders, true, searchText);
    } else {
      vState.isEndOfPage = false;
    }
  }, [snap.isEndOfPage]);

  const sortChangeHandler = (value) => {
    setSortReminders(value);
    setLoading(true);
    getServices(value, false, q || searchText);
  };

  const callApi = (e) => {
    setLoading(true);
    getServices(sortReminders, false, e);
  };
  const [debouncedCallApi] = useState(() => _debounce((e) => callApi(e), 1100));

  const handleChange = (e) => {
    setSearchText(e);
    debouncedCallApi(e);
  };

  const resetSearch = () => {
    setSearchText("");
    updateURL("");
    setLoading(true);
    setTimeout(() => {
      callApi(null);
    }, 1100);
  };

  const sortData = [
    {
      label: "به ترتیب تاریخ",
      value: "DATE",
    },
    {
      label: "به ترتیب الفبا",
      value: "NAME",
    },
  ];

  const updateURL = (search: string | null) => {
    navigate({
      pathname: ConfigLink.usersPartRequests,
      search: useQueryString({
        q: search || undefined,
      }),
    });
  };

  const getFilterData = (cat, city, viewStt, date) => {
    setCategoriesFilter(cat);
    setCitiesFilter(city);
    setViewStatusFilter(viewStt);
    setSelectedDateFilter(date);
    console.log("func", cat, city, viewStt, date);
    updateURL(q || searchText);
    setLoading(true);
    getServices(sortReminders, false, q || searchText);
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={160} className={`overflow-x-hidden`}>
        <>
          <div className="w-full flex justify-between items-center mb-6">
            <Text type="H4" className="ts-accent">
              درخواستهای مشتریان
            </Text>

            <Button
              onClick={() => navigate(ConfigLink.myPartsActivity)}
              bcolor="transparent"
              iconPosition="afterText"
              icon={<BiChevronLeft size={20} className="ts-primary -mt-0.5" />}
              className="ts-primary -mt-px -ml-4"
            >
              فعالیت شما
            </Button>
          </div>
          <div className="w-full flex justify-between items-center mb-6 gap--2">
            {loading ? (
              <Skeleton containerClassName="w-full" className="w-full h-12 rounded-xl -mt-0.5" />
            ) : (
              <SearchInput value={searchText} onChange={handleChange} onReset={resetSearch} />
            )}
            {/* <div
          onClick={() => setFilterOpen(true)}
          className="w-12 h-12 rounded-xl ts-shadow bg-white cursor-pointer p-2 flex justify-center items-center relative"
        >
          <HiOutlineAdjustments size={22} className="ts-accent" />
          {(categoriesFilter.length !== 0 ||
            CitiesFilter.length !== 0 ||
            viewStatusFilter !== "all" ||
            selectedDateFilter.length === 2) && (
            <BsCircleFill size={13} className="ts-primary absolute -top-1 -left-1" />
          )}
        </div> */}
          </div>

          {(loading || requests.length !== 0) && (
            <div className="w-full flex justify-between items-center mb-6">
              {loading ? (
                <Skeleton className="w-28" />
              ) : (
                <Text type="H6" className="ts-accent">
                  {`${count} درخواست قطعه`}
                </Text>
              )}
              {loading ? (
                <Skeleton className="w-36 h-12 outline-none rounded-xl" />
              ) : (
                <SelectPicker
                  size="lg"
                  data={sortData}
                  value={sortReminders}
                  disabled={loading}
                  cleanable={false}
                  onChange={(value: any) => sortChangeHandler(value)}
                  searchable={false}
                  placeholder="مرتب سازی"
                  className={`w-36 h-12 outline-none rounded-xl`}
                />
              )}
            </div>
          )}

          {loading ? (
            [1, 2, 3, 4, 5].map((item) => (
              <Skeleton key={item} containerClassName="w-full" className="w-full h-24 rounded-2xl mb-2" />
            ))
          ) : requests.length === 0 ? (
            <div className="w-full flex flex-col justify-center items-center mt-20">
              <img src={noSegment} alt="no-part" className="mb-6" />
              <Text type="P4" className="ts-gray-07">
                درخواست قطعه‌ای تاکنون ثبت نشده است.
              </Text>
            </div>
          ) : (
            requests.map((item) => {
              return <UserPartRequestCard key={item.id} data={item} />;
            })
          )}
          {snap.isEndOfPage && !loading && (
            <div className="w-full flex justify-center my-6">
              <Loader size="xs" />
            </div>
          )}
          {/* <ProviderFilterModal
        isOpen={filterOpen}
        onClose={() => setFilterOpen(false)}
        onFilterChanged={getFilterData}
        categories={categoriesFilter}
        cities={CitiesFilter}
        viewStatus={viewStatusFilter}
        selDates={selectedDateFilter}
      /> */}
        </>
      </ContainerScroll>
    </>
  );
});

export { UsersPartRequests as default };

const styles = {};
