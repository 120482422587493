/* eslint-disable */
import { ApiInstance, ConfigLink, ServiceStorage, useChangeTitle } from "core";
import { addIDAsTempId, useAuth } from "hooks";
import { useEffect, useState } from "react";
import { HiLocationMarker, HiPencilAlt, HiPhone } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import { Button, ContainerScroll, Text, useNotification } from "ui/components";
import { MiniMap } from "ui/views/Home/MyPartRequests/components";
import { WorkshopViewLoader } from "../components";

const env = import.meta.env;

const ViewWorkshop = ({}) => {
  const auth = useAuth();
  const metaTags = useChangeTitle("ویراژ - مشاهده کسب و کار");
  const { id } = useParams();

  const navigate = useNavigate();
  const returnUrl = ServiceStorage.getTempReturnURL();

  const [loading, setLoading] = useState(true);
  const [workshop, setWorkshops] = useState<any>({});
  const [markerAnchor, setMarkerAnchor] = useState<any>(null);

  const getWorkshop = async () => {
    await ApiInstance.workshop
      .viewSingleWorkshop(id || "")
      .then((res) => {
        console.log(res);
        setWorkshops(res.data);
      })
      .catch((err) => {
        // useNotification("مشکلی رخ داده است. لطفا مجددا امتحان کنید", "error", "مشخصات تعمیرگاه");
        useNotification("مشکلی رخ داده است. لطفا مجددا امتحان کنید", "error", "مشخصات کسب و کار");
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getWorkshop();
  }, []);

  const editWorkshopHandler = () => {
    addIDAsTempId(id || "");
    navigate({ pathname: ConfigLink.editWorkshop.replace(":id", id || "") });
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={50} className="">
        {loading ? (
          <WorkshopViewLoader />
        ) : (
          <>
            <Text type="H3" className="ts-accent mb-6">
              {workshop.data.name || "---"}
            </Text>
            {/* <div className="w-full flex justify-start items-center mb-4">
              <Text type="P4" className="ts-accent ml-2.5">
                {workshop.data.rate || 0}
              </Text>
              <Rating
                initialValue={workshop.data.rate || 0}
                size={20}
                rtl
                readonly
                allowFraction
                fillColor="#FFC700"
                emptyColor="#D5DDE5"
                SVGclassName="inline-block"
              />
              <Text type="P4" className="ts-gray-07 mr-2.5">
                ({workshop.data.rate || 0} نظر)
              </Text>
            </div> */}
            <div className="w-full relative">
              {!workshop.data.location && (
                <div className="w-full ts-mini-map bg-black opacity-70 z-20 absolute top-0 left-0 flex justify-center items-center">
                  <Text type="H5" className="text-white text-center">
                    موقعیت مکانی برای کسب و کار اضافه نشده
                    {/* موقعیت مکانی برای تعمیرگاه اضافه نشده TEMP*/}
                  </Text>
                </div>
              )}
              <MiniMap data={workshop.data} id={"workshop-mini-map"} />
            </div>
            {workshop.data.agent && workshop.data.agent.accessLevel !== "agent" && (
              <Button
                bcolor="primary"
                textType="H6"
                onClick={editWorkshopHandler}
                icon={<HiPencilAlt size={23} className="text-white -mt-1" />}
                classNameContainer="w-full"
                className="w-full h-12"
              >
                {/* ویرایش اطلاعات تعمیرگاه */}
                ویرایش اطلاعات کسب و کار
              </Button>
            )}
            <Text type="H4" className="ts-accent text-right mb-4 mt-8">
              اطلاعات و نشانی
            </Text>
            {workshop.data.description && (
              <Text type="P3" className="ts-gray-05 text-justify mb-4">
                {workshop.data.description || "---"}
              </Text>
            )}
            <div className="w-full flex justify-start items-center">
              <div className="w-12 h-12 rounded-2xl p-3 ts-bg-primary-light ml-3">
                <HiLocationMarker size={23} className="ts-accent" />
              </div>
              <Text type="H6" className="ts-accent">
                {workshop.data.address || "---"}
              </Text>
            </div>

            <div className="w-full flex justify-start items-center my-4">
              <div className="w-12 h-12 rounded-2xl p-3 ts-bg-primary-light ml-3">
                <HiPhone size={23} className="ts-accent -rotate-90" />
              </div>
              <Text
                onClick={() => {
                  if (workshop.data.phone) {
                    window.open(`tel:${workshop.data.phone}`, "_self");
                  }
                }}
                type="H6"
                className="ts-accent"
              >
                {workshop.data.phone || "---"}
              </Text>
            </div>

            {/* <div className="w-full flex justify-start items-center">
              <div className="w-12 h-12 rounded-2xl p-3 ts-bg-primary-light ml-3">
                <HiClock size={23} className="ts-accent" />
              </div>
              <div className="flex flex-col justify-start items-start">
                <Text type="H6" className="ts-accent">
                  ملک شهر، خیابان مطهری، نبش کوچه ۱۴
                </Text>
                <Text type="P5" className="ts-gray-05 mt-2">
                  در حال حاضر بسته می باشد.
                </Text>
              </div>
            </div> */}

            {workshop.data.services && workshop.data.services.length !== 0 && (
              <div className="w-full flex flex-col justify-start items-start rounded-xl ts-bg-primary-light py-4 px-6 my-8">
                <Text type="H4" className="ts-accent mb-6">
                  خدمات
                </Text>

                <ul className="list-disc mr-4">
                  {workshop.data.services.map((item) => {
                    return (
                      <li className="mb-1">
                        <Text type="P4" className="ts-gray-07">
                          {item.name || "---"}
                        </Text>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}

            {workshop.data.cars && workshop.data.cars.length !== 0 && (
              <div className="w-full flex flex-col justify-start items-start rounded-xl ts-bg-primary-light py-4 px-6">
                <Text type="H4" className="ts-accent mb-6">
                  خودرو های قابل پذیرش
                </Text>

                <ul className="list-disc mr-4">
                  {workshop.data.cars.map((item) => {
                    return (
                      <li className="mb-1">
                        <Text type="P4" className="ts-gray-07">
                          {item.name || "---"}
                        </Text>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </>
        )}
      </ContainerScroll>
      {/* <Footer activeTab={2} /> */}
    </>
  );
};

export { ViewWorkshop as default };
