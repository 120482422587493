/* eslint-disable */
import { ApiInstance, useObjectState } from "core";
import { countries, shopCategories } from "hooks";
import React, { useEffect, useState } from "react";
import {
  BottomModal,
  Button,
  CarBrandSelector,
  CarsModal,
  CharacterLimit,
  InputField,
  SelectPickerField,
  Text,
  Upload,
  useNotification,
} from "ui/components";

type ModalType = "add" | "edit";

interface IAddEditPartModalProps {
  isOpen: boolean;
  type: ModalType;
  onClose: () => void;
  onDone: () => void;
  id?: string;
  data?: any;
}

const AddEditPartModal: React.FC<IAddEditPartModalProps> = React.memo(({ isOpen, onClose, onDone, id, type, data }) => {
  const defaultError = "پر کردن این فیلد الزامی می‌باشد.";
  const uploader = React.useRef();

  const defaultValues = {
    name: "",
    cars: null,
    allCars: false,
    grouping: "",
    // manufacture: [],
    manufacture: "",
    manufacturingCountry: "Iran",
    price: "",
    code: "",
    description: "",
    images: [],
  };

  const [imagesFiles, setImageFiles] = useState<any[]>([]);
  const [info, setInfo] = useObjectState<any>(defaultValues);
  const { name, cars, grouping, manufacture, manufacturingCountry, price, code, description, allCars, images } = info;

  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (data && type === "edit") {
      setInfo({
        ...defaultValues,
        ...data,
        price: data.price
          ? parseFloat(data.price)
              .toFixed(0)
              .toString()
              .split(/(?=(?:\d{3})+$)/)
              .join(",")
          : "",
        images: data.images || [],
      });
      console.log(data.images);
    }
  }, [isOpen, data]);

  const resetData = () => {
    setInfo(defaultValues);
    setImageFiles([]);
  };

  const getFormattedData = (images) => {
    return {
      ...info,
      cars: cars ? cars.map((item) => item.id) : null,
      allCars: allCars,
      price: price.replace(/,/gi, ""),
      images,
    };
  };

  const acceptRequest = async (images) => {
    const finalData = getFormattedData(images);

    await ApiInstance.shop
      .addPart(finalData)
      .then((res) => {
        console.log(res);
        useNotification("قطعه مورد نظر اضافه شد", "success");
        onDone();
        setTimeout(() => {
          resetData();
          onClose();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editRequest = async (images) => {
    const finalData = getFormattedData(images);

    await ApiInstance.shop
      .editPartInfo(id || "", finalData)
      .then((res) => {
        console.log(res);
        useNotification("قطعه مورد نظر ویرایش شد", "success");
        onDone();
        setTimeout(() => {
          resetData();
          onClose();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const approveBtnHandler = () => {
    let errorsObj = { ...errors };
    if (name.length === 0) {
      errorsObj.name = defaultError;
    }
    if (cars === null && !allCars) {
      errorsObj.cars = defaultError;
    }
    if (grouping.length === 0) {
      errorsObj.grouping = "انتخاب یک گزینه الزامی است.";
    }
    if (manufacture.length === 0) {
      errorsObj.manufacture = defaultError;
    }
    if (manufacturingCountry.length === 0) {
      errorsObj.manufacturingCountry = "انتخاب یک گزینه الزامی است.";
    }
    if (price.length < 4 || +price.replace(/,/gi, "") < 1000) {
      errorsObj.price = "قیمت نامعتبر است.";
    }
    const inProgressImages = imagesFiles.filter((item) => item.progress && item.progress.status === "in_progress");
    const failedImages = imagesFiles.filter((item) => item.progress && item.progress.status === "failed");

    if (inProgressImages.length !== 0) {
      useNotification("تا بارگذاری کامل تصویر(ها) منتظر بمانید.", "error");
      return;
    }
    if (failedImages.length !== 0) {
      useNotification("ابتدا وضعیت تصاویری که دارای خطای بارگذاری هستند را مشخص کنید.", "warning");
      return;
    }

    if (Object.keys(errorsObj).length !== 0) {
      useNotification("لطفا فیلدهای مشخص شده را پر کنید.", "warning");
      setErrors(errorsObj);
    } else {
      setLoading(true);
      const imagesArray = imagesFiles.map((item) => (item.progress ? item.progress.id : item.key));
      type === "add" ? acceptRequest(imagesArray) : editRequest(imagesArray);
    }
  };

  const removeField = (field) => {
    let cn = { ...errors };
    delete cn[field];
    setErrors(cn);
  };

  const uploadHandler = (files) => {
    // console.log(files);
    setImageFiles(files);
  };

  return (
    <>
      <BottomModal isOpen={isOpen} onClose={onClose}>
        <div className="w-full flex flex-col justify-start items-start">
          <Text type="H4" className="ts-accent mb-6">
            {type === "edit" ? "ویرایش قطعه" : "تعریف قطعه جدید"}
          </Text>

          <Upload
            multiple
            optional
            files={info.images}
            onChange={(uploads) => uploadHandler(uploads)}
            accept="image/*"
          />

          <InputField
            label="نام قطعه"
            value={name}
            onChange={(e) => {
              if (e.length < 46) setInfo({ name: e });
            }}
            placeholder="مثلا مه شکن عقب"
            onFocus={() => removeField("name")}
            error={"name" in errors}
            errorText={errors.name || ""}
            margin="mb-1"
          />
          <CharacterLimit field={name} limit={45} />

          <CarBrandSelector
            value={cars ? cars.map((item) => item.name).join("، ") : allCars ? "مناسب برای همه مدل‌های خودرو" : null}
            error={"cars" in errors}
            onClick={() => {
              setModalOpen(true);
              removeField("cars");
            }}
          />

          <SelectPickerField
            label="دسته بندی"
            data={shopCategories.filter((item) => item.label !== "همه")}
            value={grouping}
            onChange={(value) => setInfo({ grouping: value })}
            onOpen={() => removeField("grouping")}
            error={"grouping" in errors}
            errorText={errors.grouping || ""}
            searchable={false}
            cleanable={false}
            placeholder="دسته بندی را انتخاب کنید"
          />

          <InputField
            label="شرکت سازنده"
            value={manufacture}
            onChange={(e) => setInfo({ manufacture: e })}
            placeholder="شرکت سازنده قطعه"
            onFocus={() => removeField("manufacture")}
            error={"manufacture" in errors}
            errorText={errors.manufacture || ""}
          />

          {/* <TagPicker
            block
            size="lg"
            creatable
            data={manufactureList}
            value={manufacture}
            onChange={(value) => setInfo({ manufacture: value })}
            onCreate={(value, item) => console.log("value", value, "item", item)}
            placement="auto"
            cleanable={false}
            placeholder="شرکت سازنده را انتخاب کنید"
            className={`w-full h-12 outline-none rounded-xl mb-6 ts-manufacture-wrapper`}
          /> */}

          <SelectPickerField
            label="کشور سازنده"
            data={countries}
            value={manufacturingCountry}
            onChange={(value) => setInfo({ manufacturingCountry: value })}
            onOpen={() => removeField("manufacturingCountry")}
            error={"manufacturingCountry" in errors}
            errorText={errors.manufacturingCountry || ""}
            searchable
            cleanable={false}
            placeholder="کشور سازنده قطعه را انتخاب کنید"
          />

          <div className="w-full rounded-xl flex flex-col justify-start items-start">
            <InputField
              label="قیمت"
              hint="تومان"
              inputType="price"
              value={price}
              onChange={(e) => {
                let tempNumber = e.replace(/\D/g, "");
                let commaSeparatedNumber = tempNumber.split(/(?=(?:\d{3})+$)/).join(",");
                setInfo({ price: commaSeparatedNumber });
              }}
              placeholder="قیمت به تومان"
              onFocus={() => removeField("price")}
              error={"price" in errors}
              errorText={errors.price || ""}
            />

            <InputField
              label="کد یکتا (اختیاری)"
              value={code}
              onChange={(e) => setInfo({ code: e })}
              placeholder="کد یکتای قطعه"
            />

            <InputField
              label="توضیحات"
              value={description}
              fieldType="textarea"
              rows={4}
              onChange={(e) => setInfo({ description: e })}
              placeholder="توضیحاتی درمورد قطعه، نحوه تحویل و..."
            />

            <div className="w-full flex justify-between">
              <Button
                onClick={approveBtnHandler}
                loading={loading}
                bcolor="primary"
                classNameContainer="ts-half-width"
                className="w-full rounded-xl h-12"
              >
                ثبت قطعه
              </Button>
              <Button
                onClick={() => {
                  onClose();
                  resetData();
                }}
                bcolor="primaryTransparent"
                classNameContainer="ts-half-width"
                className="w-full rounded-xl h-12"
              >
                لغو
              </Button>
            </div>
          </div>
        </div>
      </BottomModal>
      <CarsModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        isSingleChoice={false}
        selectedCars={cars}
        allCarsSelected={allCars}
        selectCarHandler={(value, type) => {
          if (type === "cars") {
            setInfo({ cars: value, allCars: false });
          } else {
            setInfo({ allCars: value, cars: null });
          }
        }}
      />
    </>
  );
});

export { AddEditPartModal };

const styles = {};
