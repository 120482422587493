/* eslint-disable */
import afterMarketIcon from "assets/images/home-icon-after-market-search.svg";
import equipmentIcon from "assets/images/home-icon-equipment-search.svg";
import partRequest from "assets/images/home-icon-part-request.svg";
import workshopSearchIcon from "assets/images/home-icon-workshop-search.svg";
import { ConfigLink, vState } from "core";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Text } from "ui/components";
import { useSnapshot } from "valtio";
import { GradientButton } from "./GradientButton";

interface ISearchPagesProps {}

const SearchPages: React.FC<ISearchPagesProps> = React.memo(() => {
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  const operator = snap.userInfo.roles && snap.userInfo.roles.indexOf("operator") !== -1;
  const workshopSearch = snap.activeFeatures.includes("workshop_search");
  const shopSearch = snap.activeFeatures.includes("shop_search");
  const partSearch = snap.activeFeatures.includes("part_order");
  const equipmentSearch = snap.activeFeatures.includes("equipment_search");

  return (
    <div
      className={`w-full flex flex-col justify-start items-start mb-6 ${
        !workshopSearch && !shopSearch && !partSearch && !equipmentSearch && "hidden"
      }`}
    >
      <Text type="H5" className="ts-accent mb-3">
        جستجو
      </Text>
      <span className="w-full grid grid-rows-none grid-cols-2 gap-2 ts-service-button-wrapper">
        {workshopSearch && (
          <GradientButton
            icon={workshopSearchIcon}
            label="کسب‌و‌کار"
            isNew
            onClick={() => navigate(ConfigLink.searchWorkshops)}
          />
        )}
        {shopSearch && (
          <GradientButton
            icon={afterMarketIcon}
            label="قطعات یدکی"
            isNew
            onClick={() => navigate({ pathname: ConfigLink.allShop })}
          />
        )}
        {partSearch && (
          <GradientButton
            icon={partRequest}
            label="درخواست قطعه"
            isNew
            onClick={() => navigate(ConfigLink.myPartRequest)}
          />
        )}
        {equipmentSearch && <GradientButton icon={equipmentIcon} label="تجهیزات" isNew onClick={() => {}} />}
      </span>
    </div>
  );
});

export { SearchPages };
