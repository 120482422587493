/* eslint-disable */
import React from "react";
import { useSnapshot } from "valtio";
import { vState } from "../../../../core";
import { Text } from "../../../components";

interface IActivityTabBarProps {
  activeTab: number;
  onActiveChanged: (id: number) => void;
}

const env = import.meta.env;

const ActivityTabBar: React.FC<IActivityTabBarProps> = React.memo(({ activeTab, onActiveChanged }) => {
  const snap = useSnapshot(vState);
  const admissionEnabled = snap.activeFeatures.includes("admission_discharge");
  const part_order = snap.activeFeatures.includes("part_order");
  const shopEnabled = snap.activeFeatures.includes("shop");

  return (
    <div className="flex-nowrap whitespace-nowrap overflow-x-auto overflow-y-hidden ts-hide-scroll-x-but-keep-working touch-pan-x border-b-2 border-b-gray-300 flex justify-start items-start">
      {admissionEnabled && (
        <Tab onClick={(id) => onActiveChanged(id)} tabNumber={0} title="پذیرش و ترخیص" activeTabNumber={activeTab} />
      )}
      {part_order && (
        <Tab
          // className="mx-4"
          onClick={(id) => onActiveChanged(id)}
          tabNumber={1}
          title="درخواست قطعه"
          activeTabNumber={activeTab}
        />
      )}
      {shopEnabled && (
        <Tab
          // className="mx-4"
          onClick={(id) => onActiveChanged(id)}
          tabNumber={2}
          title="فروشگاه قطعات"
          activeTabNumber={activeTab}
        />
      )}
    </div>
  );
});

const Tab = ({ tabNumber, title, activeTabNumber, onClick, className = "" }) => {
  return (
    <div
      onClick={() => onClick(tabNumber)}
      className={`w-auto p-3 transition-all duration-300 -mb-px flex justify-center items-center h-12 ${
        activeTabNumber === tabNumber && "ts-bg-primary-light rounded-tl-xl rounded-tr-xl border-b-2 ts-active-tab-line"
      } ${className}`}
    >
      <Text
        type={activeTabNumber === tabNumber ? "H5" : "P3"}
        className={`w-max transition-all duration-300 ${activeTabNumber === tabNumber ? "ts-primary" : "ts-gray-07"}`}
      >
        {title}
      </Text>
    </div>
  );
};

export { ActivityTabBar };
