/* eslint-disable */
import React from "react";
import Skeleton from "react-loading-skeleton";

interface ICardsLoaderProps {}

const CardsLoader: React.FC<ICardsLoaderProps> = React.memo(() => {
  return (
    <div className="w-full -mt-0.5">
      {[1, 2, 3].map((item) => {
        return <Skeleton containerClassName="w-full" className={`mb-2 w-full ts-cars-card-height rounded-2xl`} />;
      })}
    </div>
  );
});

export { CardsLoader };

const styles = {};
