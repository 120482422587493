import { useLocation } from "react-router-dom";
import { tools } from "../services/tools";
import qs from "qs";

/**
 * CAUTION : !!! Use this hook in top of your page, do not use this inside any nested function.
 * Use this hook to fetch search query string
 * @example
 *   const { status } = useFetchQueryString<ISetNewPasswordData>();
 */
function useFetchQueryString<Type>(): Type {
  let location = useLocation();

  let temp = qs.parse(location?.search, {
    ignoreQueryPrefix: true,
  });
  return tools.toObject(temp) as Type;
}

export { useFetchQueryString };
