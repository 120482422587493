/* eslint-disable */
import { useEffect, useState } from "react";
import { MdOutlineContentCopy } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Divider, Loader } from "rsuite";
import { useSnapshot } from "valtio";
import logo from "../../../../assets/images/logo-xl.png";
import { ApiInstance, ConfigLink, ServiceStorage, tools, vState } from "../../../../core";
import { useChangeTitle, useFetchQueryString, useQueryString } from "../../../../core/hooks";
import { Button, ContainerScroll, Text, useNotification } from "../../../components";

interface IQueryStringTransfer {
  token: string;
  payment_status: "OK" | "FAILED";
  type?: string;
}

const PaymentStatus = ({}) => {
  const metaTags = useChangeTitle("ویراژ - وضعیت پرداخت");
  const snap = useSnapshot(vState);
  const navigate = useNavigate();
  let { token, payment_status, type } = useFetchQueryString<IQueryStringTransfer>();
  const returnUrl = ServiceStorage.getTempSubReturnURL();

  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState({
    id: "",
    createdAt: "",
    updatedAt: "",
    amount: "",
    status: "",
    userId: "",
    factorNumber: "",
    description: "",
    package: "",
    paidAmount: "",
    transactionId: "",
    refnumber: "",
    trackingCode: "",
  });

  const getBulkVerification = async () => {
    await ApiInstance.bulkSms
      .verifyPayment(token)
      .then((res) => {
        console.log(res);
        setInfo(res.data.payment);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا لحظاتی بعد مجددا امتحان کنید.", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSubscriptionVerification = async () => {
    await ApiInstance.subscription
      .verifyPayment(token)
      .then((res) => {
        console.log(res);
        setInfo(res.data.payment);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا لحظاتی بعد مجددا امتحان کنید.", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPaymentStatus = async () => {
    if (type && type === "bulk") {
      getBulkVerification();
    } else {
      getSubscriptionVerification();
    }
  };

  useEffect(() => {
    getPaymentStatus();
  }, []);

  const backHandler = () => {
    if (returnUrl) {
      ServiceStorage.removeTempSubReturnURL();
      ServiceStorage.removeBulkSmsInfo();
      navigate(
        {
          pathname: returnUrl,
          search: info.status === "succeeded" ? useQueryString({ subPayed: "1" }) : "",
        },
        { replace: true },
      );
    } else {
      if (info.status === "succeeded") {
        navigate(
          { pathname: ConfigLink.manageWorkshops, search: useQueryString({ subPayed: "1" }) },
          { replace: true },
        );
      } else {
        navigate(ConfigLink.manageWorkshops, { replace: true });
      }
    }
  };

  const copyHandler = () => {
    navigator.clipboard.writeText(info.transactionId);
    useNotification("کد رهگیری کپی شد", "info");
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={50} NoSticky className="relative pt-0">
        <img src={logo} alt="logo" className="ts-primary my-28 mx-auto" />
        <div
          className={`w-full ${
            loading ? "ts-receipt-height" : ""
          } ts-shadow rounded-xl p-6 flex flex-col justify-center items-center`}
        >
          {loading ? (
            <Loader center className="mt-28 ts-primary" />
          ) : (
            <>
              <Text type="H4" className={`${info.status === "succeeded" ? "ts-success" : "ts-warning"} mb-6`}>
                {info.status === "succeeded" ? "پرداخت موفق" : "پرداخت ناموفق"}
              </Text>
              {info.status === "succeeded" ? (
                <>
                  <Text type="H6" className="ts-accent mb-2">
                    رسید پرداخت آنلاین:
                  </Text>
                  <Text type="H5" className="ts-success mb-4">
                    {tools.thousandSeparator(+info.paidAmount / 10)} تومان
                  </Text>
                  <Text type="P4" className="ts-accent">
                    از طریق کارت بانکی شما انجام شده است.
                  </Text>
                </>
              ) : (
                <>
                  <Text type="P4" className="ts-accent mb-4">
                    پرداخت ناموفق بود. در صورت کسر شدن وجه، مبلغ طی ۷۲ ساعت به حساب شما برگشت داده خواهد شد.
                  </Text>
                  <Text type="H6" className="ts-accent mb-2">
                    کد پیگیری:
                  </Text>
                  <span onClick={copyHandler} className="flex justify-center items-center cursor-pointer">
                    <MdOutlineContentCopy size={19} className="ts-gray-05 -mt-0.5" />
                    <Text type="H5" className="ts-gray-07 mr-2">
                      {info.transactionId}
                    </Text>
                  </span>
                </>
              )}
              <Divider className="w-full ts-bg-gray-02 m-0 my-4" />

              <Text type="P4" className="ts-gray-07 mb-6">
                تاریخ: {tools.convertDateToPersianDate(info.updatedAt, true)}
              </Text>
              <Button
                onClick={backHandler}
                bcolor={info.status === "succeeded" ? "primary" : "black"}
                textType="H6"
                classNameContainer="w-full"
                className="w-full rounded-xl h-12"
              >
                بازگشت به ویراژ
              </Button>
            </>
          )}
        </div>
      </ContainerScroll>
    </>
  );
};

export { PaymentStatus as default };
