/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { ServiceStorage, vState } from "../../../core";
import { BottomModal, Button, Text } from "../../components";

interface ISelectWorkshopModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDone: () => void;
  onWorkshopSelected?: () => void;
}

const SelectWorkshopModal: React.FC<ISelectWorkshopModalProps> = React.memo(
  ({ isOpen, onClose, onDone, onWorkshopSelected }) => {
    const history = useNavigate();
    const snap = useSnapshot(vState);

    const [activeId, setActiveId] = useState("");
    const [activeWorkshop, setActiveWorkshop] = useState<any>(null);

    useEffect(() => {
      if (isOpen) {
        const active = ServiceStorage.getActiveWorkshop();
        setActiveId(active || "");
      }
    }, [isOpen]);

    const setActiveIdHandler = (workshop) => {
      setActiveId(workshop.id);
      setActiveWorkshop(workshop);
    };

    const selectWorkshopHandler = () => {
      vState.selectedWorkshop = activeId;
      vState.activeWorkshop = activeWorkshop;
      if (activeWorkshop) {
        const features =
          activeWorkshop.categories.length !== 0
            ? activeWorkshop.categories
                .filter((item) => item.features !== null)
                .map((item) => item.features)
                .flat()
            : [];
        console.log(features);

        vState.activeFeatures = features;
        // vState.menuLoading = true;
        // if (features.length === 0) {
        //   history.push(ConfigLink.homePage);
        // } else {
        //   history.push(ConfigLink.dashboard);
        // }
        // setTimeout(() => {
        //   vState.menuLoading = false;
        // }, 1100);
      }
      ServiceStorage.setActiveWorkshop(activeId);
      onDone();
      onWorkshopSelected && onWorkshopSelected();
      onClose();
    };

    return (
      <BottomModal isOpen={isOpen} onClose={onClose}>
        <div className="w-full flex flex-col justify-center items-start px-0.5">
          <Text type="H4" className="ts-accent">
            انتخاب کسب‌و‌کار فعال
          </Text>
          <Text type="P4" className="ts-gray-08 my-4 font-normal">
            کسب‌و‌کار مورد نظر خود را انتخاب نمایید.
          </Text>
          {snap.userWorkspaces.map((item: any) => {
            return (
              <div
                onClick={() => setActiveIdHandler(item)}
                className={`w-full h-14 transition-all flex ${
                  activeId === item.id && "ts-primary-outline"
                } items-start relative cursor-pointer ts-shadow mb-3 bg-white rounded-2xl p-3 truncate`}
              >
                <div className="w-full flex h-full justify-start items-center">
                  <Text
                    type={activeId === item.id ? "H6" : "P4"}
                    className="ts-accent transition-all w-99/100 truncate"
                  >
                    {item.name || "---"}{" "}
                    {`- ${item.categories ? item.categories.map((item) => item.name).join("، ") : ""}`}
                  </Text>
                </div>
              </div>
            );
          })}
          <Button
            onClick={selectWorkshopHandler}
            disabled={activeId.length === 0}
            bcolor="primary"
            classNameContainer="w-full"
            className="w-full h-12 mt-3"
          >
            انتخاب
          </Button>
        </div>
      </BottomModal>
    );
  },
);

export { SelectWorkshopModal };

const styles = {};
