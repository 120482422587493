/* eslint-disable */
import noReminder from "assets/images/no-reminder.svg";
import { vState } from "core";
import React from "react";
import { Image, Text } from "ui/components";
import { useSnapshot } from "valtio";
import { ReminderLoader } from "./ReminderLoader";

interface IRemindersHistoryTabProps {}

const env = import.meta.env;

const RemindersHistoryTab: React.FC<IRemindersHistoryTabProps> = React.memo(() => {
  const snap = useSnapshot(vState);

  return (
    <>
      <div className={`w-full flex justify-between items-center mb-6 ts-reminders-wrapper`}>
        <Text type="H6" className="ts-accent">
          تاریخچه یادآورها
        </Text>
      </div>

      {true ? (
        <ReminderLoader forHistory />
      ) : snap.reminders.length === 0 ? (
        <div className="w-full flex flex-col justify-center items-center">
          <Image src={noReminder} width="" height="" alt="no-reminder" className="mt-2" />
          <Text type="P4" className="ts-gray-07 mt-2 mb-6">
            تاریخچه ای در حال حاضر وجود ندارد
          </Text>
        </div>
      ) : (
        snap.reminders.map((item: any) => {
          return (
            <span
              // onClick={() => historyClickHandler(item.id)}
              className="w-full flex justify-between items-center rounded-2xl mb-6 ts-shadow p-4"
            >
              <span className="flex flex-col justify-start items-start">
                <Text type="H5" className="ts-accent mb-6">
                  ۱۴۰۰/۱/۲۱ ساعت ۱۰:۳۲
                </Text>
                <Text type="H6" className="ts-primarye cursor-pointere ts-gray-06">
                  اتو سرویس صبوری
                </Text>
                {/* <Text type='P4' className="ts-gray-06">اتو سرویس صبوری</Text> */}
              </span>
              {/* <MdChevronLeft size={29} className="ts-accent" /> */}
            </span>
          );
        })
      )}
      {/* {snap.carStatus.status === "in_progress" ? (
              <Button
                onClick={registerNewServiceHandler}
                textType="H6"
                icon={<MdOutlineAddCircle size={24} className="text-white ml-3 -mt-1" />}
                bcolor="black"
                classNameContainer="w-full lg:hidden"
                className="ts-float-button ts-ios-btn h-12 rounded-xl mr-0"
              >
                ثبت یادآور جدید
              </Button>
            ) : (
              <Button
                onClick={() => setKilometerEdit(true)}
                textType="H6"
                bcolor="black"
                classNameContainer="w-full lg:hidden"
                className="ts-float-button ts-ios-btn h-12 rounded-xl mr-0"
              >
                ثبت کارکرد و یادآور جدید
              </Button>
            )} */}
    </>
  );
});

export { RemindersHistoryTab };
