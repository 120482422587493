/* eslint-disable */
import { ApiInstance, tools, useChangeTitle, vState } from "core";
import React, { useEffect, useState } from "react";
import { AiFillExclamationCircle } from "react-icons/ai";
import { HiPencilAlt } from "react-icons/hi";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { Button, ContainerScroll, ImageSlider, ImageViewerModal, Text, useNotification } from "ui/components";
import { useSnapshot } from "valtio";
import { ReportProblem } from "../components";
import { ApproveProvidePartModal, CancelPartProvideRequestModal } from "./components";

interface IUserPartRequestDetailProps {}

const UserPartRequestDetail: React.FC<IUserPartRequestDetailProps> = React.memo(({}) => {
  const metaTags = useChangeTitle("ویراژ - جزئیات درخواست");
  const snap = useSnapshot(vState);
  const { id } = useParams();

  const [requestInfo, setRequestInfo] = useState<any>({
    name: "",
    id: "",
    car: {
      id: "",
      name: "",
    },
    createdBy: {
      phoneNumber: "",
      name: "",
    },
    description: "",
    createdAt: "",
    suppliers: [],
    images: null,
  });
  const [status, setStatus] = useState<"provided" | "cancelled" | null>(null);
  const [pageLoading, setPageLoading] = useState(true);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState<"add" | "edit">("add");
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [supplierId, setSupplierId] = useState("");
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [reportModalShow, setReportModalShow] = useState<boolean>(false);
  const [supplier, setSupplier] = useState({
    createdAt: "",
    description: "",
    price: "",
  });

  const checkStatus = (data) => {
    if (data.suppliers.length !== 0) {
      const userRequest = data.suppliers.filter((item) => item.workshop.id === snap.activeWorkshop?.id);
      if (userRequest.length !== 0) {
        setStatus(userRequest[0].status);
        setSupplierId(userRequest[0].id);
        setSupplier(userRequest[0]);
      }
    } else {
      setStatus(null);
      setSupplierId("");
      setSupplier({
        createdAt: "",
        description: "",
        price: "",
      });
    }
  };

  const getPartInfo = async () => {
    await ApiInstance.partSupply
      .getRequestInfo(id || "")
      .then((res) => {
        console.log(res);
        const data = res.data.data;
        setRequestInfo(data);
        checkStatus(data);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error");
      })
      .finally(() => {
        setPageLoading(false);
      });
    console.log(status);
  };

  useEffect(() => {
    getPartInfo();
  }, []);

  const acceptPartHandler = (type) => {
    setModalType(type);
    setModalOpen(true);
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={50} className={`relative overflow-x-hidden`}>
        {pageLoading ? (
          <Skeleton className="w-36 mb-4" />
        ) : (
          <Text type="H4" className="ts-accent mb-4">
            {requestInfo.name || "نام تعریف نشده"}
          </Text>
        )}
        {pageLoading ? (
          <>
            <Skeleton containerClassName="w-full" className="w-full h-64 ts-part-card rounded-2xl mb-4" />
            <Skeleton containerClassName="w-full" className="w-full h-44 ts-part-card rounded-2xl" />
          </>
        ) : (
          <>
            <div className="w-full rounded-2xl ts-shadow p-4 mb-4 flex flex-col justify-start items-start">
              <Text type="H5" className="ts-accent mb-4">
                اطلاعات قطعه
              </Text>
              <div className="w-full flex justify-start items-start mb-4">
                <Text type="H6" className="ts-accent w-69 ml-2">
                  خودرو
                </Text>
                <Text type="P4" className="ts-accent">
                  {requestInfo.car.name || "---"}
                </Text>
              </div>

              <div className="w-full flex justify-start items-start">
                <Text type="H6" className="ts-accent w-69 ml-2">
                  توضیحات
                </Text>
                <Text type="P4" className="ts-accent ts-request-description">
                  {requestInfo.description || "---"}
                </Text>
              </div>

              {requestInfo.images && requestInfo.images.length !== 0 && (
                <div className="w-full flex flex-col justify-start items-start mt-3">
                  <Text type="H6" className="ts-accent w-69 mb-2">
                    عکس قطعه
                  </Text>
                  <ImageSlider
                    images={requestInfo.images}
                    // dir="/parts"
                    dir=""
                    onClick={(i) => {
                      setImageIndex(i);
                      setViewModalOpen(true);
                    }}
                  />
                </div>
              )}
            </div>

            <div className="w-full rounded-2xl ts-shadow p-4 mb-4 flex flex-col justify-start items-start">
              <div className="w-full flex justify-between items-center mb-4">
                <Text type="H5" className="ts-accent ">
                  اطلاعات درخواست دهنده
                </Text>
              </div>
              <div className="w-full flex justify-start items-start mb-4">
                <Text type="H6" className="ts-accent w-28 ml-2">
                  نام و نام خانوادگی
                </Text>
                <Text type="P4" className="ts-accent">
                  {requestInfo.createdBy.name || "---"}
                </Text>
              </div>

              <div className="w-full flex justify-start items-start mb-4">
                <Text type="H6" className="ts-accent w-28 ml-2">
                  شماره تماس
                </Text>
                <Text type="P4" className="ts-accent">
                  {requestInfo.createdBy.phoneNumber || "---"}
                </Text>
              </div>

              <div className="w-full flex justify-start items-start">
                <Text type="H6" className="ts-accent w-28 ml-2">
                  تاریخ
                </Text>
                <Text type="P4" className="ts-accent">
                  {requestInfo.createdAt ? tools.convertDateToPersianDate(requestInfo.createdAt, true) : "---"}
                </Text>
              </div>
            </div>

            {status === "provided" && (
              <div className="w-full rounded-2xl ts-shadow p-4 ts-bg-gray-01 mb-6 flex flex-col justify-start items-start">
                <div className="w-full flex justify-between items-center mb-4">
                  <Text type="H5" className="ts-accent">
                    اعلام امکان تامین
                  </Text>
                  <div onClick={() => acceptPartHandler("edit")} className="flex items-center cursor-pointer">
                    <HiPencilAlt size={20} className="ts-primary ml-1 -mt-0.5" />
                    <Text type="H6" className="ts-primary">
                      ویرایش
                    </Text>
                  </div>
                </div>
                <div className="w-full flex justify-start items-start mb-4">
                  <Text type="H6" className="ts-accent w-28 ml-1">
                    تاریخ
                  </Text>
                  <Text type="P4" className="ts-accent">
                    {supplier.createdAt ? tools.convertDateToPersianDate(supplier.createdAt, true) : "---"}
                  </Text>
                </div>
                <div className="w-full flex justify-start items-start mb-4">
                  <Text type="H6" className="ts-accent w-28 ml-1">
                    قیمت پیشنهادی
                  </Text>
                  <Text type="P4" className="ts-accent">
                    {supplier.price && +supplier.price !== 0
                      ? `${tools.thousandSeparator(parseFloat(supplier.price).toFixed(0))} تومان`
                      : "---"}
                  </Text>
                </div>

                <div className="w-full flex justify-start items-start">
                  <Text type="H6" className="ts-accent w-28 ml-1">
                    توضیحات
                  </Text>
                  <Text type="P4" className="ts-accent ts-supplier-description">
                    {supplier.description || "---"}
                  </Text>
                </div>
              </div>
            )}

            <div
              className={`${
                status === "provided" ? "mt-6" : "mt-2"
              } flex justify-start items-start mb-4 py-2.5 px-4 rounded-2xl ts-pending-chips relative`}
            >
              <AiFillExclamationCircle size={22} className="ts-warning -mt-0.5 absolute top-2.5 right-4" />
              <Text type="P4" className="ts-gray-07 mr-1 indent-6">
                ویراژ هیچگونه مسئولیتی در قبال تامین قطعه و صحت قیمت و سایر اطلاعات آن ندارد. در صورت مشاهده یا بروز
                هرگونه مشکل می توانید
                <b onClick={() => setReportModalShow(true)} className="ts-text-p4 z-20 ts-info mr-0.5 cursor-pointer">
                  گزارش کنید.
                </b>
              </Text>
            </div>

            {pageLoading ? null : status === "provided" ? (
              <Button
                onClick={() => setCancelModalOpen(true)}
                bcolor="black"
                classNameContainer="w-full"
                className="w-full h-11 rounded-xl"
              >
                انصراف از تامین قطعه
              </Button>
            ) : (
              <Button
                onClick={() => acceptPartHandler(status === "cancelled" ? "edit" : "add")}
                bcolor="primary"
                classNameContainer="w-full"
                className="w-full h-11 rounded-xl"
              >
                میتوانم قطعه را تامین کنم
              </Button>
            )}
          </>
        )}
      </ContainerScroll>

      <ApproveProvidePartModal
        onDone={getPartInfo}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        id={modalType === "edit" ? supplierId : id || ""}
        type={modalType}
        data={supplier}
      />
      <CancelPartProvideRequestModal
        onDone={getPartInfo}
        isOpen={cancelModalOpen}
        onClose={() => setCancelModalOpen(false)}
        id={supplierId}
      />
      <ImageViewerModal
        isOpen={viewModalOpen}
        onClose={() => setViewModalOpen(false)}
        images={requestInfo.images || []}
        dir="/parts"
        activeIndex={imageIndex}
      />
      <ReportProblem isOpen={reportModalShow} id={id || ""} onClose={() => setReportModalShow(false)} />
    </>
  );
});

export { UserPartRequestDetail as default };

const styles = {};
