/* eslint-disable */
import { useEffect, useState } from "react";
import { FaCopy } from "react-icons/fa";
import { MdPersonAddAlt1 } from "react-icons/md";
import { RiShareFill } from "react-icons/ri";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { TelegramIcon, TelegramShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { Form, Input } from "rsuite";
import { useSnapshot } from "valtio";
import { ApiInstance, tools, useChangeTitle, vState } from "../../../core";
import { useObjectState } from "../../../core/hooks";
import { Button, ContainerScroll, Text, useNotification } from "../../components";
import { AlreadyInvitedModal } from "./components";

const Invite = () => {
  const metaTags = useChangeTitle("ویراژ - دعوت از دیگران");
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [invitationCount, setInvitationCount] = useState(0);
  const [shareLink, setShareLink] = useState(`https://virazh.ir/invite?code=${snap.userInfo.id || ""}`);
  const [disabled, setDisabled] = useState(true);
  const [alreadyInvitedShow, setAlreadyInvitedShow] = useState(false);
  const [values, setValues] = useObjectState({
    name: "",
    mobile: "",
  });
  const { name, mobile } = values;

  const backClickHandler = () => {
    // history.push(ConfigLink.profile);
    navigate(".");
  };

  const getShortLink = async () => {
    await ApiInstance.account
      .generateShortLink()
      .then((res) => {
        console.log(res);
        const link = window.location.hostname.includes("virazh.ir") ? "" : "dev.";
        setShareLink(`https://${link}stlk.ir/${res.data.data.shortid}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getInviteCount = async () => {
    await ApiInstance.account
      .getInvitationCount()
      .then((res) => {
        console.log(res);
        setInvitationCount(res.data.data.count || 0);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  useEffect(() => {
    setDisabled(name.length === 0 || mobile.length !== 11 || !mobile.startsWith("09"));
  }, [values]);

  useEffect(() => {
    getInviteCount();
    getShortLink();
  }, []);

  const doneHandler = () => {
    const inviteeName = name;
    useNotification(`پیامک دعوت به ${inviteeName} ارسال شد.`, "success", "دعوت دیگران");
    setTimeout(() => {
      setValues({
        name: "",
        mobile: "",
      });
    }, 1000);
  };

  const sendAgainHandler = async () => {
    await ApiInstance.account
      .sendInvitation(values)
      .then((res) => {
        console.log(res);
        doneHandler();
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا مجددا تلاش کنید.", "error", "دعوت دیگران");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendInvitationHandler = async () => {
    setLoading(true);
    await ApiInstance.account
      .sendInvitation(values)
      .then((res) => {
        console.log("res", res);
        const data = res.data;
        doneHandler();
        // if (data.length === 0) {
        // sendAgainHandler();
        // } else if (data[0].inviteeId === null) {
        // number registered
        setLoading(false);
        // setAlreadyInvitedShow(true);
        // }
      })
      .catch((err) => {
        console.log("err", err);
        const error = err.response;
        if (error.status === 400 && error.data.message == "User Already Exist") {
          useNotification("این شماره در حال حاضر در سیستم وجود دارد.", "warning", "دعوت دیگران");
        } else {
          useNotification("مشکلی رخ داده است. لطفا مجددا تلاش کنید.", "error", "دعوت دیگران");
        }
        setLoading(false);
      });
  };

  const linkGenerator = (withLink = true, forTelegram = false): string => {
    return `${
      forTelegram ? "\n" : ""
    }با نصب ویراژ از پیشامدشدن هزینه های زیاد برای خودروتون جلوگیری کنید و نیاز به تعمیرات اش را به حداقل برسونید.\n\n ${
      forTelegram ? "برای ورود/ثبت نام با لینک داده شده وارد شوید." : `لینک ورود/ثبت نام:\n`
    }
    ${withLink && !forTelegram ? shareLink : ""}`;
  };

  const copyLinkHandler = () => {
    if (navigator) {
      const link = linkGenerator();
      navigator.clipboard.writeText(link);
      useNotification("لینک کپی شد.", "success", "دعوت دیگران", 2000);
    } else {
      useNotification("مرورگر شما از این قابلیت پشتیبانی نمیکند.", "warning", "دعوت دیگران");
    }
  };

  const shareHandler = () => {
    if (navigator && navigator.share) {
      navigator
        .share({
          title: "ویراژ",
          text: linkGenerator(false),
          url: shareLink,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => {
          console.log("Error sharing", error);
        });
    }
  };

  return (
    <>
      {/* <Header showBackButton backClick={backClickHandler} profileActive /> */}
      {metaTags}
      <ContainerScroll heightOffset={50} className="">
        <Text type="H4" className="ts-accent">
          دعوت از دیگران
        </Text>

        <div className="w-full flex justify-between items-center my-6 ts-shadow rounded-xl p-4 bg-white">
          <div className="flex justify-center items-center">
            <div className="w-8 h-8 rounded-full ml-2 ts-bg-primary-light p-1 flex justify-center items-center">
              <MdPersonAddAlt1 size={20} className="ts-primary" />
            </div>
            <Text type="P4" className="ts-gray-04">
              افراد دعوت شده توسط شما
            </Text>
          </div>
          {pageLoading ? (
            <Skeleton className="w-5 h-5" />
          ) : (
            <Text type="H3" className="ts-accent">
              {invitationCount}
            </Text>
          )}
        </div>

        <Text type="P3" className="ts-gray-07 mb-6">
          با تکمیل فرم زیر یک پیام حاوی لینک دعوت، به شماره ای که وارد می کنید، ارسال می شود.
        </Text>
        <Form fluid onSubmit={sendInvitationHandler} className="w-full mb-12">
          <Text type="H6" className="ts-accent mb-2">
            نام و نام خانوادگی
          </Text>
          <Input
            value={name}
            required
            onChange={(e) => {
              setValues({ name: e });
            }}
            className={`w-full rounded-xl mb-6 outline-none h-12 bg-transparent placeholder:text-gray-300`}
            placeholder="نام و نام خانوادگی"
          />
          <Text type="H6" className="ts-accent mb-2">
            شماره تماس
          </Text>
          <Input
            type={tools.isIOS() ? "text" : "number"}
            pattern="[0-9]*"
            value={mobile}
            required
            onChange={(e) => {
              if (e.length <= 11) {
                setValues({ mobile: e });
              }
            }}
            className={`w-full rounded-xl mb-6 outline-none h-12 bg-transparent placeholder:text-gray-300`}
            placeholder="09000000000"
          />
          <Button
            type="submit"
            loading={loading}
            disabled={disabled}
            bcolor="primary"
            classNameContainer="w-full"
            className="w-full h-12"
          >
            ارسال پیامک دعوت نامه
          </Button>
        </Form>

        <Text type="P3" className="ts-gray-07 mb-8">
          همچنین می توانید ویراژ را از طریق شبکه های اجتماعی به دیگران معرفی کنید.
        </Text>

        <div className="w-full flex justify-around items-center">
          <div className="flex flex-col justify-center items-center">
            <div className="w-12 h-12 cursor-pointer flex justify-center items-center ts-bg-light-shade rounded-full">
              {/* <FaWhatsapp size={26} className="text-white" /> */}
              <WhatsappShareButton url={shareLink} title={linkGenerator(false)}>
                <WhatsappIcon size={45} round bgStyle={{ fill: "#0000" }} />
              </WhatsappShareButton>
            </div>
            <Text type="HM" className="ts-accent mt-2">
              واتس اپ
            </Text>
          </div>

          <div className="flex flex-col justify-center items-center">
            <div className="w-12 h-12 cursor-pointer flex justify-center items-center ts-bg-light-shade rounded-full">
              {/* <FaTelegramPlane size={26} className="text-white" /> */}
              <TelegramShareButton url={shareLink} title={linkGenerator(true, true)}>
                <TelegramIcon size={45} round bgStyle={{ fill: "#0000" }} />
              </TelegramShareButton>
            </div>
            <Text type="HM" className="ts-accent mt-2">
              تلگرام
            </Text>
          </div>

          <div onClick={copyLinkHandler} className="flex flex-col justify-center items-center">
            <div className="w-12 h-12 cursor-pointer flex justify-center items-center ts-bg-light-shade rounded-full">
              <FaCopy size={25} className="text-white" />
            </div>
            <Text type="HM" className="ts-accent mt-2">
              کپی لینک
            </Text>
          </div>

          <div onClick={shareHandler} className="flex flex-col justify-center items-center">
            <div className="w-12 h-12 cursor-pointer flex justify-center items-center ts-bg-light-shade rounded-full">
              <RiShareFill size={26} className="text-white" />
            </div>
            <Text type="HM" className="ts-accent mt-2">
              اشتراک‌گذاری
            </Text>
          </div>
        </div>
      </ContainerScroll>
      <AlreadyInvitedModal
        isOpen={alreadyInvitedShow}
        onClose={() => setAlreadyInvitedShow(false)}
        data={values}
        onDone={doneHandler}
      />
    </>
  );
};

export { Invite as default };
