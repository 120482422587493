import React, { useCallback, useEffect } from "react";
import { useMap } from "react-leaflet";

interface IBboxHandlerProps {
  setBbox: (bbox) => void;
  selectWorkshopHandler: (any) => void;
  setCurrentLocation: (any) => void;
  setZoomLevel: (any) => void;
  zoom: string;
}

const BboxHandler: React.FC<IBboxHandlerProps> = React.memo(
  ({ setBbox, selectWorkshopHandler, setCurrentLocation, setZoomLevel, zoom }) => {
    const map = useMap();

    useEffect(() => {
      const bbox: any = map.getBounds();
      console.log(bbox);
      setBbox([bbox._southWest.lng, bbox._southWest.lat, bbox._northEast.lng, bbox._northEast.lat]);
    }, []);

    useEffect(() => {
      console.log(zoom);
      map.setZoom(+zoom);
    }, [zoom]);

    const mapMoveHandler = useCallback(() => {
      console.log("moveend");
      const bbox: any = map.getBounds();
      const latLng = map.getCenter();
      setCurrentLocation([latLng.lat, latLng.lng]);
      selectWorkshopHandler(null);
      setBbox([bbox._southWest.lng, bbox._southWest.lat, bbox._northEast.lng, bbox._northEast.lat]);
    }, []);

    map.once("moveend", mapMoveHandler);

    map.once("zoomend", () => {
      setZoomLevel(map.getZoom());
    });

    return null;
  },
);

export { BboxHandler };
