/* eslint-disable */
import { isEmpty } from "lodash";
import React from "react";

const TEXT_TYPE = {
  H1: "H1",
  H2: "H2",
  H3: "H3",
  H4: "H4",
  H5: "H5",
  H6: "H6",
  Logo: "Logo",
  HM: "HM",
  P1: "P1",
  P2: "P2",
  P3: "P3",
  P4: "P4",
  P5: "P5",
  Tiny: "Tiny",
} as const;
export type TEXT_TYPE = (typeof TEXT_TYPE)[keyof typeof TEXT_TYPE];

interface IText {
  type: TEXT_TYPE;
  className?: string;
  dicPattern?: string | any[];
  inline?: boolean;
  shrink?: boolean;
  empty?: boolean;
  onClick?: () => void;
  children?: any;
  ref?: any;
  id?: string;
}

/**
 * This component auto wired with 'i18n' and 'k' to support translator.
 * 'k' is a object of keys for i18n
 * @example
 *  <Text type={"H2"} dicKey={"helloWorld"} />
    <Text type={"H2"} dicKey={"testPattern"} dicPattern={[1, "en-US"]} />
 */
const Text: React.FC<IText> = React.memo(
  ({ type, className = "", children, inline, shrink, empty, dicPattern, onClick, ref, id }: IText) => {
    const adaptiveStyle = (type: TEXT_TYPE) => {
      let cn = "";
      switch (type) {
        case TEXT_TYPE.H1:
          cn = "ts-text-h1";
          break;
        case TEXT_TYPE.H2:
          cn = "ts-text-h2";
          break;
        case TEXT_TYPE.H3:
          cn = "ts-text-h3";
          break;
        case TEXT_TYPE.H4:
          cn = "ts-text-h4";
          break;
        case TEXT_TYPE.H5:
          cn = "ts-text-h5";
          break;
        case TEXT_TYPE.H6:
          cn = "ts-text-h6";
          break;
        case TEXT_TYPE.Logo:
          cn = "ts-text-logo";
          break;
        case TEXT_TYPE.HM:
          cn = "ts-text-hm";
          break;
        case TEXT_TYPE.P1:
          cn = "ts-text-p1";
          break;
        case TEXT_TYPE.P2:
          cn = "ts-text-p2";
          break;
        case TEXT_TYPE.P3:
          cn = "ts-text-p3";
          break;
        case TEXT_TYPE.P4:
          cn = "ts-text-p4";
          break;
        case TEXT_TYPE.P5:
          cn = "ts-text-p5";
          break;
        case TEXT_TYPE.Tiny:
          cn = "ts-text-tiny";
          break;
      }
      // cn += " ts-noselect"; //default options
      return cn;
    };

    const onClickHandler = () => {
      if (onClick) onClick();
    };

    return (
      <div
        ref={ref}
        id={id}
        className={`${adaptiveStyle(type)} ${className} ${shrink ? "table" : ""} ${inline ? "inline" : ""}`}
        onClick={onClickHandler}
      >
        {empty || isEmpty(children) ? (isNaN(children) ? ` ` : children) : children}
      </div>
    );
  },
);

export { Text };
