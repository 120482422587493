/* eslint-disable */
import React from "react";
import { Text } from "../../../components";

interface IActivityComparisonProps {
  data: number;
  label: string;
}

const ActivityComparison: React.FC<IActivityComparisonProps> = React.memo(({ data, label }) => {
  return (
    <div className="w-full flex justify-between items-center">
      <Text type="P4" className="ts-gray-08">
        {label}
      </Text>
      <div className="flex justify-center items-center">
        {data ? (
          <Text type="P4" className={`ml-px ${data && data > 0 ? "ts-success" : "ts-error ts-reverse-direction"}`}>
            {`${parseFloat(data.toString()).toFixed(0)}٪ `}
          </Text>
        ) : null}
        {data ? (
          <Text type="P4" className={`ml-1 ${data && data > 0 ? "ts-success" : "ts-error"}`}>
            {`${data > 0 ? "بیشتر" : "کمتر"}`}
          </Text>
        ) : null}
        <Text type="P4" className="ts-gray-05">
          {`${data ? "از میانگین کسب و کارها" : "اطلاعاتی یافت نشد"}`}
        </Text>
      </div>
    </div>
  );
});

export { ActivityComparison };

const styles = {};
