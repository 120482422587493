/* eslint-disable */
import { useRef, EffectCallback, DependencyList, useEffect } from "react";

/**
 * This Special useEffect does not run in the mount stage (Initial Stage)
 * @param effect
 * @param dependencies
 * @example 
    useEffectNoInitial(() => {
        // perform something, returning callback is supported
    }, [a, b]);
 */
const useEffectNoInitial = (effect: EffectCallback, dependencies?: DependencyList) => {
  //Preserving the true by default as initial render cycle
  const initialRender = useRef(true);

  useEffect(() => {
    let effectReturns: void | (() => void) = () => {};

    // Updating the ref to false on the first render, causing
    // subsequent render to execute the effect
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      effectReturns = effect();
    }

    // Preserving and allowing the Destructor returned by the effect
    // to execute on component unmount and perform cleanup if
    // required.
    if (effectReturns && typeof effectReturns === "function") {
      return effectReturns;
    }
    return undefined;
  }, dependencies);
};

export { useEffectNoInitial };
