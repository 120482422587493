/* eslint-disable */
import React from "react";
import NoCar from "../../../../assets/images/no-car-record.svg";
import { Text } from "../../../components";

interface INoRegisteredCarsProps {
  note?: string;
}

const NoRegisteredCars: React.FC<INoRegisteredCarsProps> = React.memo(({ note }) => {
  return (
    <div className="w-full flex flex-col justify-start items-center mb-3">
      <img src={NoCar} alt="no-car" className="mt-8 mb-6" />
      <Text type="P4" inline className="ts-gray-07 mb-6">
        {note ? note : "هیچ سابقه ای برای امروز تا کنون ثبت نشده است."}
      </Text>
    </div>
  );
});

export { NoRegisteredCars };

const styles = {};
