/* eslint-disable */
import { vState } from "core";
import React from "react";
import { useSnapshot } from "valtio";
import { Text } from "./";

interface IDesktopTitleProps {
  title: string;
}

const DesktopTitle: React.FC<IDesktopTitleProps> = React.memo(({ title }) => {
  const snap = useSnapshot(vState);

  return (
    <div className="w-full hidden justify-start items-start mb-6 lg:flex">
      <Text type="H3" className="ts-accent font-black">
        {title}
      </Text>
    </div>
  );
});

export { DesktopTitle };

const styles = {};
