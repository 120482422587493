/* eslint-disable */
import noReminder from "assets/images/no-reminder.svg";
import { vState } from "core";
import React from "react";
import { MdOutlineAddCircle } from "react-icons/md";
import { Loader } from "rsuite";
import { Button, Image, ReminderCard, Text } from "ui/components";
import { useSnapshot } from "valtio";
import { ReminderLoader } from "./ReminderLoader";

interface IRemindersListTabProps {
  loading: boolean;
  onEditReminderClicked: (item) => void;
  onAddNewReminderClicked: () => void;
  onAddNewReminderWithKilometerClicked: () => void;
}

const env = import.meta.env;

const RemindersListTab: React.FC<IRemindersListTabProps> = React.memo(
  ({ loading, onEditReminderClicked, onAddNewReminderClicked, onAddNewReminderWithKilometerClicked }) => {
    const snap = useSnapshot(vState);

    return (
      <>
        <div className={`w-full flex justify-between items-center mb-6 ts-reminders-wrapper`}>
          <Text type="H6" className="ts-accent">
            یادآورهای ثبت شده
          </Text>
        </div>

        {loading ? (
          <ReminderLoader />
        ) : snap.reminders.length === 0 ? (
          <div className="w-full flex flex-col justify-center items-center">
            <Image src={noReminder} width="" height="" alt="no-reminder" className="mt-2" />
            <Text type="P4" className="ts-gray-07 mt-2 mb-6">
              هیچ یادآوری برای آینده ثبت نشده است
            </Text>
          </div>
        ) : (
          snap.reminders.map((item: any) => {
            return <ReminderCard data={item} onClick={(item) => onEditReminderClicked(item)} />;
          })
        )}
        {snap.carStatus.status === "in_progress" ? (
          <Button
            onClick={onAddNewReminderClicked}
            textType="H6"
            icon={<MdOutlineAddCircle size={24} className="text-white ml-3 -mt-1" />}
            bcolor="black"
            classNameContainer="w-full lg:hidden"
            className="ts-float-button ts-ios-btn h-12 rounded-xl mr-0"
          >
            ثبت یادآور جدید
          </Button>
        ) : (
          <Button
            onClick={onAddNewReminderWithKilometerClicked}
            textType="H6"
            bcolor="black"
            classNameContainer="w-full lg:hidden"
            className="ts-float-button ts-ios-btn h-12 rounded-xl mr-0"
          >
            ثبت کارکرد و یادآور جدید
          </Button>
        )}

        {snap.isEndOfPage && !loading && (
          <div className="w-full flex justify-center mt-3">
            <Loader size="sm" />
          </div>
        )}
      </>
    );
  },
);

export { RemindersListTab };
