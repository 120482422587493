/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "rsuite";
import { useSnapshot } from "valtio";
import { ConfigLink, ServiceStorage, useChangeTitle, vState } from "../../../core";
import {
  Button,
  CharacterLimit,
  CheckPickerField,
  ContainerScroll,
  InputField,
  Text,
  useNotification,
} from "../../components";

interface IAddWorkshopStepOneProps {}

const env = import.meta.env;

const AddWorkshopStepOne: React.FC<IAddWorkshopStepOneProps> = React.memo(() => {
  const defaultError = "پر کردن این فیلد الزامی می‌باشد.";
  const metaTags = useChangeTitle("ایجاد محیط کاری - اطلاعات مرکز ارائه خدمت");
  const navigate = useNavigate();
  const snap = useSnapshot(vState) as typeof vState;
  const picker = React.useRef();
  const { name, categoryTitle, categories, /*services,*/ description, cars } = snap.userWorkspaceInfo;
  const returnUrl = ServiceStorage.getTempReturnURL();
  const otherID =
    snap.workshopTypes.length !== 0 ? snap.workshopTypes.filter((cat) => cat.alias === "other")[0]?.id || "" : "";

  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [carsModal, setCarModalVisible] = useState(false);
  const [errors, setErrors] = useState<any>({});

  const allServices: any[] = snap.services.map((item) => item.value);

  useEffect(() => {
    if (snap.userWorkspaceInfo.name.length !== 0) {
      vState.userWorkspaceInfo = {
        name: "",
        categoryTitle: "",
        categories: [],
        agent: null,
        features: [],
        // services: [],
        description: "",
        cars: [],
        phone: "",
        city: "",
        address: "",
        location: null,
        id: "",
      };
    }
    const tempWorkspace = ServiceStorage.getTempWorkshop();
    if (tempWorkspace) {
      vState.userWorkspaceInfo = JSON.parse(tempWorkspace);
    }
  }, []);

  useEffect(() => {
    const ownersWorkshops = snap.userWorkspaces.filter((item) => item.agent.accessLevel === "owner");
    if (!snap.userInfo.premium && ownersWorkshops.length !== 0) {
      navigate(ConfigLink.manageWorkshops, { replace: true });
    }
  }, [snap.userInfo, snap.userWorkspaces]);

  const registerDataHandler = () => {
    let errorsObj = { ...errors };
    if (name.length === 0) {
      errorsObj.name = defaultError;
    }
    if (categories && categories.includes(otherID) && categoryTitle && categoryTitle.length === 0) {
      errorsObj.categoryTitle = defaultError;
    }
    if (categories.length === 0) {
      errorsObj.categories = "انتخاب (حداقل) یک گزینه الزامی می‌باشد.";
    }

    if (Object.keys(errorsObj).length !== 0) {
      useNotification("لطفا فیلدهای مشخص شده را پر کنید.", "warning");
      setErrors(errorsObj);
    } else {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        ServiceStorage.setTempWorkshop(JSON.stringify(snap.userWorkspaceInfo));
        navigate(ConfigLink.createWorkspaceStepTwo);
      }, 500);
    }
  };

  const backClickHandler = () => {
    if (returnUrl) {
      navigate(returnUrl);
    } else {
      navigate(ConfigLink.homePage);
    }
  };

  const handleCheckAll = (value, checked) => {
    // vState.userWorkspaceInfo.services = checked ? (allServices as any) : [];
  };

  const removeField = (field) => {
    let cn = { ...errors };
    delete cn[field];
    setErrors(cn);
  };

  return (
    <>
      <div
        className={`w-full ts-base-bg-white overflow-x-hidden h-full relative flex flex-col justify-start items-center`}
      >
        {/* <Header showBackButton backClick={backClickHandler} /> */}
        {metaTags}
        <ContainerScroll className="pb-4 overflow-x-hidden">
          <Text type="H4" className="ts-accent mb-6">
            اطلاعات مرکز ارائه خدمت
          </Text>
          <Text type="P3" className="ts-gray-06 mb-6">
            جهت اضافه کردن کسب و کار خود، اطلاعات خواسته شده را وارد نمایید:
          </Text>

          <Form fluid className="w-full">
            <CheckPickerField
              label="نوع کسب و کار"
              cleanable={false}
              countable={false}
              searchable={false}
              data={snap.SelectPickerCategories}
              value={categories}
              onChange={(value) => (vState.userWorkspaceInfo.categories = value)}
              onOpen={() => removeField("categories")}
              withCloseButton
              error={"categories" in errors}
              errorText={errors.categories || ""}
              placeholder="نوع کسب و کار خود را انتخاب کنید"
            />

            {categories.length !== 0 && categories.includes(otherID) && (
              <InputField
                label="لطفا نوع کسب و کار خود را بنویسید."
                value={categoryTitle}
                onChange={(e) => (vState.userWorkspaceInfo.categoryTitle = e)}
                onFocus={() => removeField("categoryTitle")}
                placeholder="مثلا: نمایندگی خودرو"
                error={"categoryTitle" in errors}
                errorText={errors.categoryTitle || ""}
              />
            )}

            <InputField
              label="نام کسب و کار"
              value={name}
              onChange={(e) => {
                if (e.length < 26) vState.userWorkspaceInfo.name = e;
              }}
              onFocus={() => removeField("name")}
              placeholder="نام کسب و کار"
              error={"name" in errors}
              errorText={errors.name || ""}
              margin="mb-1"
            />
            <CharacterLimit field={name} />

            <Text type="H6" className="ts-accent mb-1">
              معرفی (اختیاری)
            </Text>
            <Text type="P4" className="ts-gray-06 mb-2">
              توضیحات مختصری برای معرفی خود وارد نمایید. این اطلاعات در زمان جستجوی شما به مشتریان نمایش داده می شود.
            </Text>
            <InputField
              value={description}
              onChange={(e) => (vState.userWorkspaceInfo.description = e)}
              fieldType="textarea"
              rows={4}
              placeholder="توضیحات برای معرفی کسب و کار"
            />

            <Button
              type="submit"
              bcolor="primary"
              textType="H6"
              classNameContainer="w-full"
              className="w-full rounded-xl h-12"
              onClick={registerDataHandler}
              disabled={disabled}
              loading={loading}
            >
              ذخیره و ادامه
            </Button>
          </Form>
        </ContainerScroll>
      </div>
      {/* <CarsModal
        isOpen={carsModal}
        onClose={() => setCarModalVisible(false)}
        selectedCars={cars}
        selectCarHandler={(value) => (vState.userWorkspaceInfo.cars = value)}
      /> */}
    </>
  );
});

export { AddWorkshopStepOne as default };

const styles = {};
