/* eslint-disable */
import React from "react";
import Skeleton from "react-loading-skeleton";
import { Text } from "../../../components";
import { CardsLoader } from "./CardsLoader";

interface IDashboardLoaderProps {}

const DashboardLoader: React.FC<IDashboardLoaderProps> = React.memo(() => {
  return (
    <div className="w-full">
      <div className="w-full flex justify-between items-center">
        <Text type="H4" className="ts-accent">
          فعالیت روزانه شما
        </Text>
      </div>
      <div className="w-full flex flex-col justify-start items-center mt-4">
        <Skeleton containerClassName="w-full" className={`w-full h-10 rounded-xl mb-4`} />
        <div className="w-full justify-between items-center mb-4 grid grid-cols-3 gap-2">
          <Skeleton style={{ height: "56.8px" }} containerClassName="w-full" className={`w-full rounded-xl`} />
          <Skeleton style={{ height: "56.8px" }} containerClassName="w-full" className={`w-full rounded-xl`} />
          <Skeleton style={{ height: "56.8px" }} containerClassName="w-full" className={`w-full rounded-xl`} />
        </div>
        <CardsLoader />
      </div>
    </div>
  );
});

export { DashboardLoader };

const styles = {};
