import { CanceledError } from "axios";
import { ApiInstance, tools } from "core";
import { ChangeEvent, forwardRef, Reducer, useEffect, useReducer, useRef } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { MdOutlineRefresh } from "react-icons/md";
import { Progress } from "rsuite";
import upload from "../../assets/images/upload-button.svg";
import defaultImage from "../../assets/images/upload-default-image.png";
import { configs } from "./../../configs/configs";
import { Text } from "./Text";

export enum UploadStatus {
  NotStarted = "not_started",
  InProgress = "in_progress",
  Succeeded = "succeeded",
  Failed = "failed",
}

export type UploadFile = {
  progress?: {
    status: UploadStatus;
    file: File;
    controller: AbortController;
    url: string;
    id: string;
    value: number;
  };
  key: string;
};

type UploadState = {
  multiple: boolean;
  uploads: Array<UploadFile>;
};

type ReducerAction =
  | {
      type: "add_file";
      key: string;
      controller: AbortController;
      url: string;
      id: string;
      file: File;
    }
  | {
      type: "upload_progress";
      key: string;
      value: number;
    }
  | {
      type: "upload_success";
      key: string;
    }
  | {
      type: "upload_fail";
      key: string;
    }
  | {
      type: "remove_file";
      key: string;
    };

export type UploadProps = {
  id?: string;
  accept?: string;
  multiple?: boolean;
  files?: Array<string>;
  onChange?: (files: Array<UploadFile>) => void;
  optional?: boolean;
};

export const Upload = forwardRef<HTMLInputElement, UploadProps>((props: UploadProps, ref) => {
  const uploadRef = useRef();
  const [state, dispatch] = useReducer<Reducer<UploadState, ReducerAction>, { files: string[]; multiple: boolean }>(
    (state: UploadState, action) => {
      switch (action.type) {
        case "add_file": {
          if (!state.multiple && state.uploads.length === 1) {
            return state;
          }

          return {
            ...state,
            uploads: [
              ...state.uploads,
              {
                key: action.key,
                progress: {
                  controller: action.controller,
                  file: action.file,
                  status: UploadStatus.NotStarted,
                  url: action.url,
                  id: action.id,
                  value: 0,
                },
              },
            ],
          };
        }
        case "remove_file": {
          const upload = state.uploads.find((item) => item.key === action.key);

          if (!upload) {
            return state;
          }

          if (upload.progress) {
            upload.progress.controller.abort();
          }

          return {
            ...state,
            uploads: state.uploads.filter((item) => item.key !== action.key),
          };
        }
        case "upload_progress": {
          const index = state.uploads.findIndex((item) => item.key === action.key);
          if (index < 0) {
            return state;
          }
          const upload = state.uploads[index];

          if (!upload.progress) {
            return state;
          }

          return {
            ...state,
            uploads: [
              ...state.uploads.slice(0, index),
              {
                ...upload,
                progress: {
                  ...upload.progress!,
                  status: UploadStatus.InProgress,
                  value: action.value,
                },
              },
              ...(index < state.uploads.length - 1 ? state.uploads.slice(index + 1) : []),
            ],
          };
        }
        case "upload_success": {
          const index = state.uploads.findIndex((item) => item.key === action.key);
          if (index < 0) {
            return state;
          }

          const upload = state.uploads[index];

          if (!upload.progress || upload.progress.status !== UploadStatus.InProgress) {
            return state;
          }

          return {
            ...state,
            uploads: [
              ...state.uploads.slice(0, index),
              {
                ...upload,
                progress: {
                  ...upload.progress!,
                  status: UploadStatus.Succeeded,
                  value: 1,
                },
              },
              ...(index < state.uploads.length - 1 ? state.uploads.slice(index + 1) : []),
            ],
          };
        }
        case "upload_fail": {
          const index = state.uploads.findIndex((item) => item.key === action.key);
          if (index < 0) {
            return state;
          }

          const upload = state.uploads[index];

          if (!upload.progress || upload.progress.status !== UploadStatus.InProgress) {
            return state;
          }

          return {
            ...state,
            uploads: [
              ...state.uploads.slice(0, index),
              {
                ...upload,
                progress: {
                  ...upload.progress!,
                  status: UploadStatus.Failed,
                },
              },
              ...(index < state.uploads.length - 1 ? state.uploads.slice(index + 1) : []),
            ],
          };
        }
        default:
          return state;
      }
    },
    {
      files: props.files ?? [],
      multiple: props.multiple === true,
    },
    ({ files, multiple }): UploadState => {
      return {
        multiple: multiple,
        uploads: files.map(
          (item): UploadFile => ({
            key: item,
          }),
        ),
      };
    },
  );

  const addFiles = (files: FileList) => {
    for (const file of files) {
      ApiInstance.common.getUploadUrl().then(
        (response) => {
          const controller = new AbortController();

          ApiInstance.common
            .uploadFile(response.data.url, file, {
              controller,
              onUploadProgress: (progressEvent: ProgressEvent) => {
                const progress = progressEvent.loaded / progressEvent.total;

                dispatch({
                  type: "upload_progress",
                  key: response.data.key,
                  value: progress,
                });
              },
            })
            .then(
              () => {
                dispatch({
                  type: "upload_success",
                  key: response.data.key,
                });
              },
              (e) => {
                if (e instanceof CanceledError === false) {
                  dispatch({
                    type: "upload_fail",
                    key: response.data.key,
                  });
                }
              },
            );

          dispatch({
            type: "add_file",
            file,
            controller,
            id: response.data.id,
            key: response.data.key,
            url: response.data.url,
          });
        },
        (e) => {
          console.error("Failed to create upload url", e);
        },
      );
    }
  };

  const reUploadFile = (key, failedFile: File | undefined) => {
    if (failedFile) {
      removeFile(key);
      ApiInstance.common.getUploadUrl().then(
        (response) => {
          const controller = new AbortController();

          ApiInstance.common
            .uploadFile(response.data.url, failedFile, {
              controller,
              onUploadProgress: (progressEvent: ProgressEvent) => {
                const progress = progressEvent.loaded / progressEvent.total;

                dispatch({
                  type: "upload_progress",
                  key: response.data.key,
                  value: progress,
                });
              },
            })
            .then(
              () => {
                dispatch({
                  type: "upload_success",
                  key: response.data.key,
                });
              },
              (e) => {
                if (e instanceof CanceledError === false) {
                  dispatch({
                    type: "upload_fail",
                    key: response.data.key,
                  });
                }
              },
            );

          dispatch({
            type: "add_file",
            file: failedFile,
            controller,
            id: response.data.id,
            key: response.data.key,
            url: response.data.url,
          });
        },
        (e) => {
          console.error("Failed to create upload url", e);
        },
      );
    }
  };

  const removeFile = (key: string) => {
    dispatch({
      type: "remove_file",
      key,
    });
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      addFiles(e.target.files);
    }
    e.target.value = "";
  };

  useEffect(() => {
    if (typeof props.onChange === "function") {
      props.onChange(state.uploads);
    }
  }, [state.uploads]);

  return (
    <div className="w-full mb-3">
      <Text type="H6" className="ts-accent mb-2 text-right">
        بارگذاری عکس قطعه{props.optional && "(اختیاری)"}
      </Text>
      <div
        // @ts-ignore
        onClick={() => uploadRef?.current.click()}
        className="w-full flex flex-col bg-white justify-center items-center py-2 rounded-xl ts-shadow mb-3 cursor-pointer ts-upload-button"
      >
        <img src={upload} alt="upload" className="w-24 h-24" />
        <Text type={"H5"} className={`mt-2 ts-gray-08`}>
          بارگذاری عکس
        </Text>
      </div>
      <input
        // @ts-ignore
        ref={uploadRef}
        type="file"
        id={props.id}
        accept={props.accept}
        multiple={state.multiple}
        onChange={onFileChange}
        onError={(f) => console.log("error", f)}
        hidden
      />
      <div>
        {state.uploads.map((item, index) => {
          const name = item.progress ? item.progress.file.name : `picture-${index}`;

          const image =
            item.progress && item.progress.file.type.includes("image")
              ? URL.createObjectURL(item.progress.file)
              : item.progress
              ? defaultImage
              : `${configs().imageUrl}/${item.key}`;

          const progress = item.progress ? `${Math.round(item.progress!.value * 100)}` : "-";
          const error = item.progress && item.progress.status === UploadStatus.Failed ? "Upload failed" : undefined;
          const success =
            item.progress && item.progress.status === UploadStatus.Succeeded ? "Upload finished" : undefined;

          return (
            <div
              key={item.key}
              className="w-full h-16 rounded-2xl p-3 relative ts-shadow mb-3 flex justify-between items-center"
            >
              <span className="w-full h-full flex justify-start items-start">
                <img src={image} alt="img-upload" className="w-10 h-10 rounded-xl ml-2 object-cover" />
                <div className="w-10/12 h-full flex flex-col justify-center items-start">
                  <Text type="P4" className="ts-accent w-3/4 truncate">
                    {name}
                  </Text>
                  {item.progress && (
                    <Text type="P5" className="t-gray-07 mt-1 ts-reverse-direction">
                      {`${tools.convertFileCapacity(item.progress.file.size, 2)}`}
                    </Text>
                  )}
                </div>
              </span>

              <span className="flex justify-end items-center absolute bg-white pr-1 w-fit left-3 top-0 h-full">
                {progress !== "-" && +progress < 100 && typeof error !== "string" && (
                  <>
                    <Text type="P5" className="ts-primary w-max">
                      درحال بارگذاری
                    </Text>
                    <UploadLoader />
                  </>
                )}
                {typeof success === "string" && (
                  <Text type="P5" className="ts-success w-max">
                    بارگذاری موفق
                  </Text>
                )}
                {typeof error === "string" && (
                  <Text type="P5" className="ts-secondary-red w-max">
                    خطا در بارگذاری
                  </Text>
                )}
                {typeof error === "string" && (
                  <span
                    onClick={() => reUploadFile(item.key, item.progress?.file)}
                    className="w-10 h-10 rounded-xl flex justify-center items-center ml-1 mr-1 ts-bg-gray-01 cursor-pointer"
                  >
                    <MdOutlineRefresh size={22} className="ts-accent" />
                  </span>
                )}
                {(typeof success === "string" || typeof error === "string" || !item.progress) && (
                  <span
                    onClick={() => removeFile(item.key)}
                    className="w-10 h-10 rounded-xl flex justify-center items-center mr-1 ts-bg-gray-01 cursor-pointer"
                  >
                    <IoCloseOutline size={22} className="ts-accent" />
                  </span>
                )}
              </span>

              {progress !== "-" && +progress < 100 && typeof error !== "string" && (
                <Progress.Line
                  percent={+progress}
                  strokeColor="#752fbb"
                  showInfo={false}
                  className="absolute -bottom-2 right-0"
                  strokeWidth={2}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
});

const UploadLoader = () => {
  return <span className="ts-upload-loader"></span>;
};
