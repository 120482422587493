/* eslint-disable */
import { useEffect, useState } from "react";
import { IoIosCheckmarkCircle, IoIosCloseCircle } from "react-icons/io";
import { MdOutlineAccessTimeFilled } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { ApiInstance, tools, vState } from "../../../core";
import { useChangeTitle, useFetchQueryString } from "../../../core/hooks";
import { useAuth } from "../../../hooks";
import { ContainerScroll, Text, useNotification } from "../../components";

const env = import.meta.env;

interface IQueryStringTransfer {
  id: string;
}

const BulkSmsDetail = ({}) => {
  const auth = useAuth();
  const metaTags = useChangeTitle("ویراژ - جزئیات پیامک");
  let { id } = useFetchQueryString<IQueryStringTransfer>();
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  const [detail, setDetail] = useState<any>(null);
  const [statusColor, setStatusColor] = useState("ts-warning");
  const [showAllUsers, setShowAllUsers] = useState(false);
  const [loading, setLoading] = useState(true);

  const getDetail = async () => {
    await ApiInstance.bulkSms
      .getHistoryDetail(id || "")
      .then((res) => {
        console.log(res);
        const item = res.data.data;
        setDetail(item);
        setStatusColor(
          item.status === "sent"
            ? "ts-success"
            : item.status === "rejected" || item.status === "payment_failed"
            ? "ts-secondary-red"
            : "ts-warning",
        );
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "گزارشات");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getDetail();
  }, []);

  const backClickHandler = () => {
    navigate(".");
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={26} className="">
        <Text type="H3" className="ts-accent mb-4 text-right">
          جزئیات پیامک ارسالی
        </Text>

        <div className="w-full flex flex-col justify-start items-start rounded-2xl ts-shadow p-4 mb-4">
          {loading ? (
            <Skeleton className="w-20 mb-6" />
          ) : (
            <Text type="H5" className="ts-accent mb-6 text-right">
              {detail.title || ""}
            </Text>
          )}

          <div className="w-full flex justify-start items-center mb-4">
            <Text type="H6" className="ts-gray-06 w-24">
              تاریخ ثبت
            </Text>
            {loading ? (
              <Skeleton className="w-16" />
            ) : (
              <Text type="P4" className="ts-accent ts-sp-detail-item">
                {detail.createdAt ? tools.convertDateToPersianDate(detail.createdAt, true) : "---"}
              </Text>
            )}
          </div>

          <div className="w-full flex justify-start items-center mb-4">
            <Text type="H6" className="ts-gray-06 w-24">
              وضعیت
            </Text>
            {loading ? (
              <Skeleton className="w-16" />
            ) : (
              <span className="flex justify-center items-center">
                {detail.status === "sent" ? (
                  <IoIosCheckmarkCircle size={16} className="ts-success" />
                ) : detail.status === "rejected" || detail.status === "payment_failed" ? (
                  <IoIosCloseCircle size={16} className="ts-secondary-red" />
                ) : (
                  <MdOutlineAccessTimeFilled size={16} className="ts-warning -mt-0.5" />
                )}
                <Text type="H6" className={`${statusColor} mr-1 pt-px`}>
                  {detail.status === "sent"
                    ? "ارسال شده"
                    : detail.status === "rejected"
                    ? "رد شده"
                    : detail.status === "payment_succeeded" || detail.status === "approved"
                    ? "در صف انتشار"
                    : detail.status === "created"
                    ? "در انتظار پرداخت"
                    : "پرداخت ناموفق"}
                </Text>
              </span>
            )}
          </div>

          {/* <div className="w-full flex justify-start items-center mb-4">
            <Text type="H6" className="ts-gray-06 w-24">
              تاریخ ارسال
            </Text>
            {loading ? (
              <Skeleton className="w-16" />
            ) : (
              <Text type="P4" className="ts-accent ts-sp-detail-item">
                {detail.updatedAt ? tools.convertDateToPersianDate(detail.updatedAt, true) : "---"}
              </Text>
            )}
          </div> */}

          <Text type="H6" className="ts-gray-06 w-24">
            گیرندگان
          </Text>
          {loading ? (
            <Skeleton className="w-16" />
          ) : (
            <div className="w-full flex justify-start items-start flex-wrap mb-5 gap-2">
              {detail.contacts.map((item, index) => {
                if (index < 5) {
                  return (
                    <span className="flex justify-center text-white items-center rounded-full ts-bg-gray-08 px-4 py-1 cursor-pointer">
                      <Text type="HM" className="ml-1 w-max">
                        {item.contactName || "بدون نام"}
                      </Text>
                    </span>
                  );
                }
              })}
              {detail.contacts.length > 5 && !showAllUsers && (
                <Text onClick={() => setShowAllUsers(true)} type="H6" className="ts-gray-06 pt-1.5 cursor-pointer">{`${
                  detail.contacts.length - 5
                }+ نفر دیگر`}</Text>
              )}
              {detail.contacts.length > 5 &&
                showAllUsers &&
                detail.contacts.map((item, index) => {
                  if (index > 4) {
                    return (
                      <span className="flex justify-center text-white items-center rounded-full ts-bg-gray-08 px-4 py-1 cursor-pointer">
                        <Text type="HM" className="ml-1 w-max">
                          {item.contactName || "بدون نام"}
                        </Text>
                      </span>
                    );
                  }
                })}
            </div>
          )}

          <Text type="H6" className="ts-accent mb-2 mt-4">
            متن پیام
          </Text>

          {loading ? (
            <Skeleton className="w-16" />
          ) : (
            <Text type="P4" className="ts-accent">
              {detail.payload || "---"}
            </Text>
          )}
        </div>
      </ContainerScroll>
    </>
  );
};

export { BulkSmsDetail as default };
