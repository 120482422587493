/* eslint-disable */
import { isNull } from "lodash";
import { useEffect, useState } from "react";
import { FaCircleExclamation } from "react-icons/fa6";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { Divider } from "rsuite";
import { useSnapshot } from "valtio";
import { ApiInstance, ConfigLink, IPackage, tools, vState } from "../../../../core";
import { useChangeTitle, useFetchQueryString } from "../../../../core/hooks";
import { Button, ContainerScroll, Text, useNotification } from "../../../components";

interface IQueryStringTransfer {
  packageId: string;
}

const SubCheckout = ({}) => {
  const metaTags = useChangeTitle("ویراژ - تایید و تکمیل خرید");
  const snap = useSnapshot(vState);
  const navigate = useNavigate();
  let { packageId } = useFetchQueryString<IQueryStringTransfer>();

  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<IPackage | null>(null);

  const getPackages = async () => {
    await ApiInstance.subscription
      .getPackages()
      .then((res) => {
        console.log(res);
        const selected = res.data.filter((item) => item.package === packageId);
        if (selected.length !== 0) {
          setSelectedPackage(selected[0]);
        } else {
          useNotification("پکیج انتخابی یافت نشد. لطفا مجددا انتخاب کنید.", "warning");
          navigate(ConfigLink.subSelect);
        }
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا لحظاتی بعد مجددا امتحان کنید.", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPackages();
  }, []);

  const paySubHandler = async () => {
    setButtonLoading(true);
    await ApiInstance.subscription
      .selectPackage(selectedPackage?.package || "1month")
      .then((res) => {
        console.log(res);
        const paymentUrl = res.data.paymentUrl;
        if (paymentUrl) {
          window.location.assign(paymentUrl);
        }
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا لحظاتی بعد مجددا امتحان کنید.", "error");
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  return (
    <>
      {/* <SubCustomHeader backClick={() => navigate.push(ConfigLink.subSelect)} /> */}
      {metaTags}
      <ContainerScroll heightOffset={150} NoSticky className="pt-56">
        <Text type="H3" className="ts-accent text-center mb-8">
          تایید و تکمیل خرید
        </Text>

        <Text type="P3" className="ts-accent text-center mb-4">
          جزییات خرید را بررسی کنید:
        </Text>

        <div className="w-full ts-shadow rounded-xl p-6 flex flex-col justify-start items-start">
          <span className="w-full flex justify-start items-center mb-4">
            <Text type="P4" className="ts-accent w-40 ml-2">
              عنوان:
            </Text>
            {loading ? (
              <Skeleton className="w-20" />
            ) : (
              <Text type="P4" className="ts-accent">
                سرویس ویژه
              </Text>
            )}
          </span>

          <span className="w-full flex justify-start items-center mb-4">
            <Text type="P4" className="ts-accent w-40 ml-2">
              مدت زمان:
            </Text>
            {loading ? (
              <Skeleton className="w-20" />
            ) : (
              <Text type="P4" className="ts-accent">
                {selectedPackage?.title}
              </Text>
            )}
          </span>

          <span className="w-full flex justify-start items-center mb-4">
            <Text type="P4" className="ts-accent w-40 ml-2">
              هزینه:
            </Text>
            {loading ? (
              <Skeleton className="w-20" />
            ) : (
              selectedPackage && (
                <Text type="P4" className="ts-accent">
                  {tools.thousandSeparator(selectedPackage?.realPrice / 10)} تومان
                </Text>
              )
            )}
          </span>

          {selectedPackage?.discountPercent ? (
            <span className="w-full flex justify-start items-center mb-4">
              <Text type="P4" className="ts-primary w-40 ml-2">
                تخفیف:
              </Text>
              {loading ? (
                <Skeleton className="w-20" />
              ) : (
                <Text type="P4" className="ts-primary w-max whitespace-nowrap">
                  {`${tools.thousandSeparator(selectedPackage?.discountPrice / 10)} تومان(${
                    selectedPackage?.discountPercent * 100
                  }%)`}
                </Text>
              )}
            </span>
          ) : null}
          <Divider className="w-full ts-bg-gray-02 m-0 mb-4" />

          <span className="w-full flex justify-start items-center mb-4">
            <Text type="P4" className="ts-accent w-40 ml-2">
              مبلغ کل:
            </Text>
            {loading ? (
              <Skeleton className="w-20" />
            ) : (
              selectedPackage && (
                <Text type="P4" className="ts-accent">
                  {tools.thousandSeparator(selectedPackage?.priceWithDiscount / 10)} تومان
                </Text>
              )
            )}
          </span>

          <span className="w-full flex justify-start items-center mb-4">
            <Text type="P4" className="ts-accent w-40 ml-2 tracking-tighter">
              مالیات بر ارزش افزوده ({selectedPackage?.taxPercent ? selectedPackage?.taxPercent * 100 : 10}درصد):
            </Text>
            {loading ? (
              <Skeleton className="w-20" />
            ) : (
              selectedPackage && (
                <Text type="P4" className="ts-accent">
                  {`${tools.thousandSeparator(selectedPackage.taxPrice / 10)} تومان`}
                </Text>
              )
            )}
          </span>

          <Divider className="w-full ts-bg-gray-02 m-0 mb-4" />

          <span className="w-full flex justify-start items-center">
            <Text type="H6" className="ts-accent w-40 ml-2">
              مبلغ قابل پرداخت:
            </Text>
            {loading ? (
              <Skeleton className="w-20" />
            ) : (
              selectedPackage && (
                <Text type="H6" className="ts-accent">
                  {tools.thousandSeparator(selectedPackage?.paidPrice / 10)} تومان
                </Text>
              )
            )}
          </span>
        </div>

        <div className="flex justify-start items-start relative mt-3 py-2.5 px-4 rounded-2xl ts-bulk-notice">
          <FaCircleExclamation size={18} className="ts-info -mt-0.5 absolute" />
          <Text type="P4" className="ts-gray-07 mr-1 indent-5">
            خواهشمند است در فرایند پرداخت به خرید و مبلغ توجه داشته باشید. درصورت پرداخت وجه امکان انصراف و بازگشت وجه
            وجود ندارد.
          </Text>
        </div>

        <div className="w-full ts-sub-sticky-button flex flex-col justify-center items-center bg-white fixed bottom-0 left-0 px-6 pb-10">
          <Button
            onClick={paySubHandler}
            loading={buttonLoading}
            disabled={isNull(selectedPackage)}
            bcolor="primary"
            textType="H6"
            classNameContainer="w-full max-w-7xl"
            className="w-full rounded-xl h-12 mb-2 mt-2"
          >
            پرداخت
          </Button>
        </div>
      </ContainerScroll>
    </>
  );
};

export { SubCheckout as default };
