/* eslint-disable */
import { axios } from "./api";

const baseUrl = "/premiums";
const subscription = {
  getPackages: async () => {
    const url = `${baseUrl}/packages`;
    const result = await axios.get(url);

    return result;
  },
  selectPackage: async (id: string, callbackUrl?: string) => {
    const url = `${baseUrl}/payment`;
    const result = await axios.post(
      url,
      { package: id, ...(callbackUrl ? { callbackUrl } : undefined) },
      {
        headers: {
          Referer: document.referrer || `https://provider.virazh.ir`,
        },
      },
    );

    return result;
  },
  verifyPayment: async (token: string) => {
    const url = `${baseUrl}/payment/verify?token=${token}`;
    const result = await axios.post(url);

    return result;
  },
};
export { subscription };
