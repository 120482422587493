/* eslint-disable */
import { axios } from "./api";
import { neshanAxios } from "./neshanApi";

const baseUrl = "/workshops";

const workshop = {
  getWorkshop: async () => {
    const url = `${baseUrl}?pageSize=20`;
    const result = await axios.get(url);

    return result;
  },
  addWorkshop: async (data) => {
    const url = `${baseUrl}`;
    const result = await axios.post(url, data);

    return result;
  },
  viewSingleWorkshop: async (id: string) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.get(url);

    return result;
  },
  deleteWorkshop: async (id: string) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.delete(url);

    return result;
  },
  editWorkshop: async (id: string, data: any) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.put(url, data);

    return result;
  },
  logoutWorkshop: async () => {
    const url = `/agents`;
    const result = await axios.delete(url);

    return result;
  },
  getLocationAddress: async (lat, lng) => {
    const url = `/reverse?lat=${lat}&lng=${lng}`;
    const result = await neshanAxios.get(url, { baseURL: "https://api.neshan.org/v5" });

    return result;
  },
  setWorkshopShopPolicy: async (id: string) => {
    const url = `/workshops/${id}/termsOfService`;
    const result = await axios.put(url);

    return result;
  },
  getStatisticsGrowth: async (range: string) => {
    const url = `/statistics/growth?range=${range}`;
    const result = await axios.get(url);

    return result;
  },
  getChartStatics: async (range: string) => {
    const url = `/statistics/chart?range=${range}`;
    const result = await axios.get(url);

    return result;
  },
};

export { workshop };
