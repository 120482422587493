/* eslint-disable */
import { digitsFaToEn } from "@persian-tools/persian-tools";
import { ApiInstance, ConfigLink, ServiceStorage, useChangeTitle, useObjectState, useQueryString, vState } from "core";
import React, { useEffect, useState } from "react";
import { BiChevronDown, BiCurrentLocation } from "react-icons/bi";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "rsuite";
import {
  Button,
  CharacterLimit,
  CheckPickerField,
  ContainerScroll,
  InputField,
  SelectCity,
  Text,
  useNotification,
} from "ui/components";
import { useSnapshot } from "valtio";

interface IInfo {
  name: string;
  categories: string[];
  services: string[];
  description: string;
  // cars: [],
  phone: string;
  city: string;
  address: string;
  location: any;
}

const EditWorkshop = ({ workshopRegistered = false }) => {
  const defaultError = "پر کردن این فیلد الزامی می‌باشد.";
  const metaTags = useChangeTitle("ویراژ - ویرایش کسب و کار");
  let { id } = useParams();
  const snap = useSnapshot(vState) as typeof vState;
  const navigate = useNavigate();
  const picker = React.useRef();
  const returnUrl = ServiceStorage.getTempReturnURL();
  const otherID =
    snap.workshopTypes.length !== 0 ? snap.workshopTypes.filter((cat) => cat.alias === "other")[0]?.id || "" : "";

  const [info, setInfo] = useObjectState<any>({
    name: "",
    categories: [],
    services: [],
    description: "",
    categoryTitle: "",
    // cars: [],
    phone: "",
    city: "",
    address: "",
    location: null,
  });
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [cityModal, setCityModal] = useState(false);
  const [selectedCity, setSelectedCity] = useState<any | null>(null);
  const [errors, setErrors] = useState<any>({});

  const allServices: any[] = snap.services.map((item) => item.value);

  const getWorkshop = async () => {
    await ApiInstance.workshop
      .viewSingleWorkshop(id || "")
      .then((result) => {
        console.log(result);
        const data = result.data.data;

        let selectedServices = [];

        if (data.services) {
          const newServices = data.services.map(({ id: value }) => ({
            value,
          }));
          selectedServices = newServices.map((item) => {
            return item.value;
          });
        }

        let finalWS = {
          ...data,
          services: selectedServices,
          city: data.city ? data.city.id || "" : "",
          categoryTitle: data.categoryInfo || "",
          categories: data.categories.length !== 0 ? data.categories.map((item) => item.id) : [],
          /*, cars: selectedCars */
        };
        console.log(finalWS);

        if (data.city) {
          setSelectedCity({ label: `${data.city.city}, ${data.city.province}`, value: data.city.id });
        }

        const savedInfo = ServiceStorage.getTempWorkshop();
        console.log("savedInfo", savedInfo);

        if (snap.tempLocation) {
          if (savedInfo) {
            var finalWSChanged = { ...JSON.parse(savedInfo), location: snap.tempLocation };
          } else {
            var finalWSChanged = { ...finalWS, location: snap.tempLocation };
          }
          setInfo(finalWSChanged);
        } else {
          if (savedInfo) {
            setInfo(JSON.parse(savedInfo));
          } else {
            setInfo(finalWS);
          }
        }

        vState.userWorkspaceInfo = finalWS;
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا مجددا تلاش کنید", "error", "ویرایش تعمیرگاه");
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  useEffect(() => {
    getWorkshop();
  }, []);

  useEffect(() => {
    const { name, categories, /*services,*/ description /*, cars*/, phone, city, address, location, categoryTitle } =
      snap.userWorkspaceInfo;

    // const noServiceNeedCondition = info.categories.includes("oil_change") || info.categories.includes("repair_shop");
    const isOtherTypes = info.categories.includes(otherID);

    setDisabled(
      name === info.name &&
        JSON.stringify(categories) === JSON.stringify(info.categories) &&
        // JSON.stringify(services) === JSON.stringify(info.services) &&
        phone === info.phone &&
        city === info.city &&
        address === info.address &&
        description === info.description &&
        JSON.stringify(location) === JSON.stringify(info.location) &&
        categoryTitle === info.categoryTitle,
      // ||
      // info.name.length === 0 ||
      // // (noServiceNeedCondition && info.services.length === 0) ||
      // info.categories.length === 0 ||
      // (info.phone && info.phone.length === 0) ||
      // info.city.length === 0 ||
      // info.address.length === 0 ||
      // (isOtherTypes && info.categoryTitle.length === 0),
    );
  }, [info, snap.userWorkspaceInfo]);

  const updateFooter = async () => {
    vState.menuLoading = true;
    await ApiInstance.workshop
      .viewSingleWorkshop(id || "")
      .then((result) => {
        console.log(result);
        const data = result.data.data;
        const features = data.categories.length !== 0 ? data.categories.map((item) => item.features).flat() : [];
        console.log(features);

        vState.activeFeatures = features;
        vState.activeWorkshop = data;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        vState.menuLoading = false;
      });
  };

  const editWorkshopHandler = async () => {
    setLoading(true);

    const workshopData = {
      name: info.name,
      categories: info.categories,
      // services: info.services,
      // cars: info.cars,
      city: info.city,
      address: info.address,
      location: info.location || null,
      description: info.description || null,
      phone: info.phone ? digitsFaToEn(info.phone) : null,
      categoryTitle: info.categoryTitle || null,
    };
    await ApiInstance.workshop
      .editWorkshop(id || "", workshopData)
      .then((res) => {
        console.log(res);
        ServiceStorage.removeTempWorkshop();
        vState.tempLocation = null;
        useNotification("کسب و کار شما ویرایش شد.", "success", "ویرایش کسب و کار");
        if (id === snap.selectedWorkshop) {
          updateFooter();
        }
        backClickHandler();
      })
      .catch((err) => {
        console.log("err", err);
        useNotification("اشتباهی رخ داده. لطفا مجددا تلاش کنید.", "error", "ویرایش کسب و کار");
      })
      .then(() => {
        setLoading(false);
      });
  };

  const submitChangeHandler = async () => {
    let errorsObj = { ...errors };

    if (info.name.length === 0) {
      errorsObj.name = defaultError;
    }
    if (info.categories && info.categories.includes(otherID) && info.categoryTitle.length === 0) {
      errorsObj.categoryTitle = defaultError;
    }
    if (info.categories.length === 0) {
      errorsObj.categories = "انتخاب (حداقل) یک گزینه الزامی می‌باشد.";
    }
    if (
      info.phone &&
      ((info.phone.length !== 0 && info.phone.length < 11) ||
        (info.phone.length !== 0 && digitsFaToEn(info.phone.slice(0, 1)) !== "0"))
    ) {
      errorsObj.phone = "فرمت شماره وارد شده صحیح نیست";
    }
    if (info.city.length === 0) {
      errorsObj.city = defaultError;
    }
    if (info.address.length === 0) {
      errorsObj.address = defaultError;
    }
    console.log(errorsObj);

    if (Object.keys(errorsObj).length !== 0) {
      useNotification("لطفا فیلدهای مشخص شده را پر کنید.", "warning");
      setErrors(errorsObj);
    } else {
      editWorkshopHandler();
    }
  };

  const setLocationHandler = () => {
    const latLng = snap.userWorkspaceInfo.location?.coordinates;
    ServiceStorage.setTempWorkshop(JSON.stringify(info));
    if (latLng) {
      navigate({
        pathname: ConfigLink.selectLocation,
        search: useQueryString({ lat: latLng[0], lng: latLng[1], type: "edit" }),
      });
    } else {
      navigate({
        pathname: ConfigLink.selectLocation,
        search: useQueryString({ type: "edit" }),
      });
    }
  };

  const backClickHandler = () => {
    navigate(-1);
  };

  const handleCheckAll = (value, checked) => {
    setInfo({ services: checked ? (allServices as any) : [] });
  };

  const selectCityHandler = (city) => {
    setSelectedCity(city);
    setInfo({ city: city.value });
  };

  const openCityModalHandler = () => {
    if ("city" in errors) {
      removeField("city");
    }
    setCityModal(true);
  };

  const removeField = (field) => {
    let cn = { ...errors };
    delete cn[field];
    setErrors(cn);
  };

  return (
    <>
      {/* <Header showBackButton noLogo backClick={backClickHandler} /> */}
      {metaTags}
      <ContainerScroll heightOffset={120} className="">
        {pageLoading ? (
          <Skeleton className="w-44 h-7 mb-7" />
        ) : (
          <Text type="H4" className="ts-accent mb-6">
            {snap.userWorkspaceInfo.id.length === 0 ? "اضافه کردن کسب و کار" : "اطلاعات مرکز ارائه خدمت"}
          </Text>
        )}

        <Form fluid className="w-full">
          <Text type="H6" className="ts-accent mb-2">
            نوع کسب و کار
          </Text>
          <>
            {pageLoading ? (
              <Skeleton containerClassName="w-full" className="w-full leading-6 rounded-xl h-12 mb-6" />
            ) : (
              <CheckPickerField
                cleanable={false}
                countable={false}
                searchable={false}
                data={snap.SelectPickerCategories}
                value={info.categories}
                onChange={(value) => setInfo({ categories: value })}
                onOpen={() => removeField("categories")}
                withCloseButton
                error={"categories" in errors}
                errorText={errors.categories || ""}
                placeholder="نوع کسب و کار خود را انتخاب کنید"
              />
            )}
          </>

          {info.categories && info.categories.includes(otherID) && (
            <>
              <Text type="P4" className="ts-gray-06 mb-2">
                لطفا نوع کسب و کار خود را بنویسید.
              </Text>
              <>
                {pageLoading ? (
                  <Skeleton containerClassName="w-full" className="w-full leading-6 rounded-xl h-12 mb-6" />
                ) : (
                  <InputField
                    value={info.categoryTitle}
                    onChange={(e) => setInfo({ categoryTitle: e })}
                    onFocus={() => removeField("categoryTitle")}
                    placeholder="مثلا: نمایندگی خودرو"
                    error={"categoryTitle" in errors}
                    errorText={errors.categoryTitle || ""}
                  />
                )}
              </>
            </>
          )}

          <Text type="H6" className="ts-accent mb-2">
            نام کسب و کار
          </Text>
          <>
            {pageLoading ? (
              <Skeleton containerClassName="w-full" className="w-full leading-6 rounded-xl h-12 mb-6" />
            ) : (
              <>
                <InputField
                  value={info.name}
                  onChange={(e) => {
                    if (e.length < 26) setInfo({ name: e });
                  }}
                  onFocus={() => removeField("name")}
                  placeholder="نام کسب و کار"
                  error={"name" in errors}
                  errorText={errors.name || ""}
                  margin="mb-1"
                />
                <CharacterLimit field={info.name} />
              </>
            )}
          </>

          <Text type="H6" className="ts-accent mb-1">
            معرفی (اختیاری)
          </Text>
          <Text type="P4" className="ts-gray-07 mb-2">
            توضیحات مختصری برای معرفی خود وارد نمایید. این اطلاعات در زمان جستجوی شما به مشتریان نمایش داده می شود.
            {/* در این قسمت می توانید توضیحاتی برای معرفی تعمیرگاه خود به مشتریان وارد نمایید. */}
          </Text>
          {pageLoading ? (
            <Skeleton containerClassName="w-full" className="w-full leading-6 rounded-xl h-24 mb-6" />
          ) : (
            <InputField
              value={info.description}
              onChange={(e) => setInfo({ description: e })}
              fieldType="textarea"
              rows={4}
              placeholder="توضیحات برای معرفی کسب و کار"
            />
          )}

          <Text type="H6" className="ts-accent mb-2">
            شماره تلفن ثابت (اختیاری)
          </Text>
          <>
            {pageLoading ? (
              <Skeleton containerClassName="w-full" className="w-full leading-6 rounded-xl h-12 mb-6" />
            ) : (
              <InputField
                inputType="number"
                value={info.phone}
                onChange={(e) => {
                  if (e.length <= 11) {
                    setInfo({ phone: e });
                  }
                }}
                onFocus={() => removeField("phone")}
                placeholder="02100000000"
                error={"phone" in errors}
                errorText={errors.phone || ""}
              />
            )}
          </>

          <Text type="H6" className="ts-accent mb-2">
            شهر
          </Text>
          <>
            {pageLoading ? (
              <Skeleton containerClassName="w-full" className="w-full leading-6 rounded-xl h-12 mb-6" />
            ) : (
              <div className="w-full mb-6">
                <div
                  onClick={openCityModalHandler}
                  className={`transition-all w-full cursor-pointer rounded-xl p-4 flex justify-between items-center h-12 ${
                    errors.city ? "ts-error-border" : "ts-input-border"
                  }`}
                >
                  <Text type="P4" className="ts-accent">
                    {selectedCity ? selectedCity.label : "انتخاب شهر"}
                  </Text>
                  <BiChevronDown size={21} className="ts-accent -mt-px" />
                </div>
                {errors.city && (
                  <Text type="P5" className={`ts-error mt-1`}>
                    {"پر کردن این فیلد الزامی است"}
                  </Text>
                )}
              </div>
            )}
          </>

          <Text type="H6" className="ts-accent mb-2">
            نشانی
          </Text>
          <>
            {pageLoading ? (
              <Skeleton containerClassName="w-full" className="w-full leading-6 rounded-xl h-12 mb-2" />
            ) : (
              <Button
                icon={<BiCurrentLocation size={19} className="ts-primary -mt-1" />}
                bcolor="primaryTransparent"
                textType="H5"
                classNameContainer="w-full"
                className="w-full rounded-xl h-10 mb-2"
                onClick={setLocationHandler}
              >
                {info.location || snap.userWorkspaceInfo.location || snap.tempLocation
                  ? "ویرایش موقعیت مکانی روی نقشه"
                  : "مشخص کردن روی نقشه"}
              </Button>
            )}
            {pageLoading ? (
              <Skeleton containerClassName="w-full" className="w-full leading-6 rounded-xl h-28 mb-6" />
            ) : (
              <InputField
                value={info.address}
                onChange={(e) => setInfo({ address: e })}
                fieldType="textarea"
                rows={5}
                onFocus={() => removeField("address")}
                placeholder="آدرس پستی"
                error={"address" in errors}
                errorText={errors.address || ""}
              />
            )}
          </>
          {pageLoading ? (
            <Skeleton containerClassName="w-full" className="w-full leading-6 rounded-xl h-12" />
          ) : (
            <Button
              type="submit"
              bcolor="primary"
              textType="H5"
              classNameContainer="w-full"
              className="w-full rounded-xl h-12"
              loading={loading}
              disabled={disabled}
              onClick={submitChangeHandler}
            >
              {snap.userWorkspaceInfo.id.length === 0 ? "ثبت کسب و کار" : "ویرایش اطلاعات"}
            </Button>
          )}
        </Form>
      </ContainerScroll>
      <SelectCity
        isOpen={cityModal}
        selectedId={info.city || selectedCity ? selectedCity.value : null}
        onClose={() => setCityModal(false)}
        onSelect={(city) => selectCityHandler(city)}
      />
    </>
  );
};

export { EditWorkshop as default };
