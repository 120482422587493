/* eslint-disable */
import React, { useState } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import markerImg from "../../../../assets/images/marker.svg";
import L from "leaflet";

interface IMiniMapProps {
  data: any;
  id: string;
}

const MiniMap: React.FC<IMiniMapProps> = React.memo(({ data, id }) => {
  const defaultLocation = [35.69979085412715, 51.33753633512243];

  let mapIcon = L.icon({
    iconUrl: markerImg,
    iconSize: [20, 25],
    iconAnchor: [12, 25],
  });
  return (
    <MapContainer
      boxZoom={false}
      dragging={false}
      scrollWheelZoom={false}
      className="ts-mini-map"
      id={id}
      center={data.location ? data.location.coordinates : defaultLocation}
      zoom={15}
      zoomControl={false}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={data.location ? data.location.coordinates : defaultLocation} icon={mapIcon}></Marker>
    </MapContainer>
  );
});

export { MiniMap };

const styles = {};
