/* eslint-disable */
import { tools, vState } from "core";
import { kilometerAverages } from "hooks";
import React from "react";
import { BsChatSquareTextFill, BsExclamationCircleFill } from "react-icons/bs";
import { HiPencilAlt } from "react-icons/hi";
import { IoMdCall, IoMdRefresh } from "react-icons/io";
import { MdPersonAddAlt1 } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { Button, Text } from "ui/components";
import { useSnapshot } from "valtio";

interface ICarInfoTabProps {
  reminderFeatureActivated: boolean;
  loading: boolean;
  onCarEditClicked: () => void;
  onOwnerEditClicked: () => void;
  onAddToCustomersClicked: () => void;
  openPremiumModal: () => void;
  onUpdateKilometerClicked: () => void;
}

const env = import.meta.env;

const CarInfoTab: React.FC<ICarInfoTabProps> = React.memo(
  ({
    reminderFeatureActivated,
    loading,
    onCarEditClicked,
    onOwnerEditClicked,
    onAddToCustomersClicked,
    openPremiumModal,
    onUpdateKilometerClicked,
  }) => {
    const snap = useSnapshot(vState);

    const callHandler = () => {
      window.open(`tel:${snap.carStatus.plate.mobile}`, "_self");
    };

    const smsHandler = () => {
      window.open(`sms:/${snap.carStatus.plate.mobile}`, "_self");
    };

    const checkPremium = (callBack: () => void) => {
      if (snap.userInfo.premium) {
        callBack();
      } else {
        if (!snap.carStatus.plate.mobile.includes("*")) {
          callBack();
        } else {
          openPremiumModal();
        }
      }
    };

    return (
      <>
        <div
          className={`w-full transition-all bg-white ts-box-shadow rounded-2xl p-4 mb-4 ${
            reminderFeatureActivated ? "ts-reminders-wrapper" : ""
          }`}
        >
          <div className={`w-full flex justify-between items-center mb-6`}>
            <Text type="H5" className="ts-accent ml-3">
              اطلاعات خودرو
            </Text>
            {!loading && (
              <div onClick={onCarEditClicked} className="flex items-center cursor-pointer">
                <HiPencilAlt size={20} className="ts-primary ml-1 -mt-0.5" />
                <Text type="H6" className="ts-primary">
                  ویرایش
                </Text>
              </div>
            )}
          </div>

          <div className="flex justify-start items-center mb-4">
            <Text type="H6" className="ts-accent w-20 lg:w-24  ml-3">
              خودرو
            </Text>
            {loading ? (
              <Skeleton containerClassName="-mb-1" className="w-20 lg:w-24 h-3" />
            ) : (
              <Text type="P4" className="ts-accent">
                {snap.carStatus.plate.car
                  ? snap.carStatus.plate.car.name || "---"
                  : snap.carStatus.plate.carName === "Not_Defined"
                  ? "یافت نشد"
                  : snap.carStatus.plate.carName || "---"}
              </Text>
            )}
          </div>

          <div className="flex justify-start items-center">
            <Text type="H6" className="ts-accent w-20 lg:w-24 ml-3">
              پلاک
            </Text>
            {loading ? (
              <Skeleton containerClassName="-mb-1" className="w-20 lg:w-24 h-3" />
            ) : (
              <Text type="P4" className="ts-accent">
                {`ایران ${snap.carStatus.plate.plateNumber.substring(
                  7,
                  9,
                )} - ${snap.carStatus.plate.plateNumber.substring(3, 6)} ${
                  snap.carStatus.plate.plateNumber.slice(2, 3) === "ا"
                    ? "الف"
                    : snap.carStatus.plate.plateNumber.slice(2, 3)
                } ${snap.carStatus.plate.plateNumber.slice(0, 2)}`}
              </Text>
            )}
          </div>

          {snap.carStatus.plate.monthlyUsage && (
            <div className="flex justify-start items-center mt-4">
              <Text type="H6" className="ts-accent w-28 ml-2">
                متوسط کارکرد (ماه)
              </Text>
              {loading ? (
                <Skeleton containerClassName="-mb-1" className="w-20 lg:w-24 h-3" />
              ) : (
                <Text type="P4" className="ts-accent">
                  {kilometerAverages.filter((item) => item.value === snap.carStatus.plate.monthlyUsage)[0].label}
                </Text>
              )}
            </div>
          )}
        </div>

        <div className={`w-full transition-all bg-white ts-box-shadow rounded-2xl p-4 mb-4`}>
          <div className={`w-full flex justify-between items-center mb-6`}>
            <Text type="H5" className="ts-accent ml-3">
              مالک خودرو
            </Text>
            {snap.carStatus.contact ? (
              <div onClick={onOwnerEditClicked} className="flex items-center cursor-pointer">
                <HiPencilAlt size={20} className="ts-primary ml-1 -mt-0.5" />
                <Text type="H6" className="ts-primary">
                  ویرایش
                </Text>
              </div>
            ) : (
              <Button
                onClick={onAddToCustomersClicked}
                bcolor="transparent"
                className="ts-primary h-5 p-0"
                icon={<MdPersonAddAlt1 size={20} className="ts-primary" />}
              >
                افزودن به لیست مشتریان
              </Button>
            )}
          </div>

          <div className="flex justify-start items-center mb-2">
            <Text type="H6" className="ts-accent w-20 lg:w-24 ml-3">
              مالک
            </Text>
            {loading ? (
              <Skeleton containerClassName="-mb-1" className="w-20 lg:w-24 h-3" />
            ) : (
              <Text type="P4" className="ts-accent">
                {snap.carStatus?.contact?.contactName || "---"}
              </Text>
            )}
          </div>

          <div className="flex justify-start items-center mb-4">
            <Text type="H6" className="ts-accent w-20 lg:w-24 ml-3">
              شماره تماس
            </Text>
            {loading ? (
              <Skeleton containerClassName="-mb-1" className="w-20 lg:w-24 h-3" />
            ) : (
              <Text type="P4" className="ts-accent ts-reverse-direction">
                {snap.carStatus.plate.mobile || "---"}
              </Text>
            )}
          </div>

          <div className="flex justify-between items-center gap-2">
            <Button
              disabled={!Boolean(snap.carStatus.contact)}
              onClick={() => checkPremium(callHandler)}
              bcolor="gray"
              icon={<IoMdCall size={21} className="-rotate-90" />}
              classNameContainer="w-full ts-primary"
              className="w-full h-10 ts-primary"
            >
              تماس
            </Button>
            <Button
              disabled={!Boolean(snap.carStatus.contact)}
              onClick={() => checkPremium(smsHandler)}
              bcolor="gray"
              icon={<BsChatSquareTextFill size={21} />}
              classNameContainer="w-full ts-primary"
              className="w-full h-10 ts-primary"
            >
              پیامک
            </Button>
            {/* <Button
                  bcolor="gray"
                  icon={<BsFillChatDotsFill />}
                  classNameContainer="w-full ts-primary"
                  className="w-full h-10 ts-primary"
                >
                  چت
                </Button> */}
          </div>

          {snap.carStatus.contact ? null : (
            <div className="w-full flex justify-start items-start mt-4">
              <BsExclamationCircleFill size={21} className="ts-gray-07 -mt-0.5" />
              <Text type="P5" className="ts-gray-07 mr-1">
                این مشتری در لیست مشتریان شما وجود ندارد. درصورت تمایل، او را به لیست مشتریان خود اضافه کنید.
              </Text>
            </div>
          )}
        </div>

        <div className={`w-full transition-all bg-white ts-box-shadow rounded-2xl p-4 mb-4`}>
          <div className={`w-full flex justify-between items-center mb-6`}>
            <Text type="H5" className="ts-accent ml-3">
              اطلاعات پذیرش
            </Text>
          </div>

          <div className="w-full flex justify-between items-center mb-4">
            <div className="flex justify-start items-center">
              <Text type="H6" className="ts-accent w-20 lg:w-24 ml-3">
                کارکرد فعلی
              </Text>
              {loading ? (
                <Skeleton containerClassName="-mb-1" className="w-20 lg:w-24 h-3" />
              ) : (
                <Text type="P4" className="ts-accent">
                  {`${tools.thousandSeparator(snap.carStatus.kilometer)} کیلومتر`}
                </Text>
              )}
            </div>
            {snap.carStatus.status === "in_progress" && !loading && (
              <div onClick={onUpdateKilometerClicked} className="flex items-center cursor-pointer">
                <IoMdRefresh size={20} className="ts-primary ml-1 -mt-0.5" />
                <Text type="H6" className="ts-primary">
                  به روزرسانی
                </Text>
              </div>
            )}
          </div>

          <div className="flex justify-start items-center mb-4">
            <Text type="H6" className="ts-accent w-20 lg:w-24 ml-3">
              تاریخ پذیرش
            </Text>
            {loading ? (
              <Skeleton containerClassName="-mb-1" className="w-20 lg:w-24 h-3" />
            ) : (
              <Text type="P4" className="ts-accent">
                {snap.carStatus.createdAt
                  ? `${new Date(snap.carStatus.createdAt).toLocaleDateString("fa-IR")} ساعت ${new Date(
                      snap.carStatus.createdAt,
                    ).toLocaleTimeString("fa-IR", { hour: "2-digit", minute: "2-digit" })}`
                  : "---"}
              </Text>
            )}
          </div>

          <div className="flex justify-start items-center mb-4">
            <Text type="H6" className="ts-accent w-20 lg:w-24 ml-3">
              پذیرش کننده
            </Text>
            {loading ? (
              <Skeleton containerClassName="-mb-1" className="w-20 lg:w-24 h-3" />
            ) : (
              <Text type="P4" className="ts-accent">
                {snap.carStatus.createdBy?.name || "---"}
              </Text>
            )}
          </div>

          <div className="flex justify-start items-center">
            <Text type="H6" className="ts-accent w-20 lg:w-24 ml-3">
              وضعیت فعلی
            </Text>
            {loading ? (
              <Skeleton containerClassName="-mb-1" className="w-20 lg:w-24 h-3" />
            ) : (
              <Text type="P4" className="ts-accent ts-reverse-direction">
                {snap.carStatus.status === "in_progress" ? "ترخیص نشده" : "ترخیص شده"}
              </Text>
            )}
          </div>
        </div>
      </>
    );
  },
);

export { CarInfoTab };
