/* eslint-disable */
import noSegment from "assets/images/no-segment-requested.svg";
import { ApiInstance, useChangeTitle } from "core";
import { useEffect, useState } from "react";
import { FaCircleExclamation } from "react-icons/fa6";
import { HiPencilAlt, HiTrash } from "react-icons/hi";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { Button, ContainerScroll, ImageSlider, ImageViewerModal, Text, useNotification } from "ui/components";
import { AddPartRequestModal, DeletePartRequestModal, SupplierCard, WorkshopInfoModal } from "./components";

const MyPartRequestDetail = () => {
  const metaTags = useChangeTitle("ویراژ - جزئیات درخواست قطعه");
  let { id } = useParams();

  const [pageLoading, setPageLoading] = useState(true);
  const [requestInfo, setRequestInfo] = useState<any>({
    name: "",
    car: {
      id: "",
      name: "",
    },
    description: "",
    suppliers: [],
    images: null,
  });
  const [editRequestModal, setEditRequestModal] = useState(false);
  const [deleteRequestModal, setDeleteRequestModal] = useState(false);
  const [workshopInfoModal, setWorkshopInfoModal] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const getRequests = async () => {
    await ApiInstance.partSupply
      .getRequestInfo(id || "")
      .then((res) => {
        console.log(res);
        setRequestInfo(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error");
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  useEffect(() => {
    getRequests();
  }, []);

  const viewWorkshopHandler = (workshop) => {
    setSelectedSupplier(workshop);
    setWorkshopInfoModal(true);
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={120} className="">
        <div className="w-full flex justify-between items-center mt-3 mb-4">
          <Text type="H4" className="ts-accent">
            اطلاعات قطعه
          </Text>
          <div className="flex justify-center items-center gap-2">
            <Button
              onClick={() => setEditRequestModal(true)}
              bcolor="white"
              classNameContainer="w-10 ts-shadow rounded-xl"
              className="w-full h-10 rounded-xl"
            >
              <HiPencilAlt size={21} className="ts-primary" />
            </Button>

            <Button
              onClick={() => setDeleteRequestModal(true)}
              bcolor="white"
              classNameContainer="w-10 ts-shadow rounded-xl"
              className="w-full h-10 rounded-xl "
            >
              <HiTrash size={21} className="ts-primary" />
            </Button>
          </div>
        </div>
        {pageLoading ? (
          <Skeleton containerClassName="w-full" className="w-full h-80 ts-part-card rounded-2xl mb-7" />
        ) : (
          <div className="w-full rounded-2xl ts-shadow p-4 mb-8 flex flex-col justify-start items-start">
            <div className="w-full flex justify-start items-start mb-4">
              <Text type="H6" className="ts-accent w-69 ml-2">
                نام قطعه
              </Text>
              <Text type="P4" className="ts-accent">
                {requestInfo.name || "---"}
              </Text>
            </div>

            <div className="w-full flex justify-start items-start mb-3">
              <Text type="H6" className="ts-accent w-69 ml-2">
                خودرو
              </Text>
              <Text type="P4" className="ts-accent">
                {requestInfo.car.name || "---"}
              </Text>
            </div>

            {/* <div className="w-full flex justify-start items-start mb-3">
              <Text type="H6" className="ts-accent w-69 ml-2">
                دسته بندی
              </Text>
              <Text type="P4" className="ts-accent">
                تسمه
              </Text>
            </div> */}

            <div className="w-full flex justify-start items-start">
              <Text type="H6" className="ts-accent w-69 ml-2">
                توضیحات
              </Text>
              <Text type="P4" className="ts-accent ts-request-description">
                {requestInfo.description || "---"}
              </Text>
            </div>

            {requestInfo.images && requestInfo.images.length !== 0 && (
              <div className="w-full flex flex-col justify-start items-start mt-3">
                <Text type="H6" className="ts-accent w-69 mb-2">
                  عکس قطعه
                </Text>
                <ImageSlider
                  images={requestInfo.images}
                  // dir="/parts"
                  dir=""
                  onClick={(i) => {
                    setImageIndex(i);
                    setViewModalOpen(true);
                  }}
                />
              </div>
            )}
          </div>
        )}

        <Text type="H4" className="ts-accent mb-4">
          تامین کنندگان
        </Text>
        {requestInfo.suppliers.length === 0 && !pageLoading ? (
          <div className="w-full flex flex-col justify-center items-center mb-8">
            <img src={noSegment} alt="no-part" className="mb-6" />
            <Text type="P4" className="ts-gray-07">
              تاکنون تامین کننده‌ای پیدا نشده است.
            </Text>
          </div>
        ) : null}
        <div className="flex justify-start items-start mb-4 py-2.5 px-4 rounded-2xl ts-pending-chips">
          <FaCircleExclamation size={23} className="ts-warning -mt-0.5" />
          <Text type="P4" className="ts-gray-07 mr-1">
            ویراژ هیچگونه مسیولیتی در قبال تامین قطعه و صحت قیمت و سایر اطلاعات آن ندارد.
          </Text>
        </div>

        {pageLoading
          ? [1, 2, 3].map((item) => {
              return <Skeleton containerClassName="w-full" className="w-full h-44 rounded-2xl mb-3" />;
            })
          : requestInfo.suppliers.map((item: any) => {
              return <SupplierCard data={item} key={item.id} viewClicked={viewWorkshopHandler} />;
            })}
      </ContainerScroll>
      <AddPartRequestModal
        isOpen={editRequestModal}
        id={id}
        type="edit"
        onDone={getRequests}
        selectedItem={requestInfo}
        onClose={() => setEditRequestModal(false)}
      />
      <DeletePartRequestModal id={id || ""} isOpen={deleteRequestModal} onClose={() => setDeleteRequestModal(false)} />
      <WorkshopInfoModal
        isOpen={workshopInfoModal}
        onClose={() => setWorkshopInfoModal(false)}
        workshop={selectedSupplier}
      />
      <ImageViewerModal
        isOpen={viewModalOpen}
        onClose={() => setViewModalOpen(false)}
        images={requestInfo.images || []}
        dir="/parts"
        activeIndex={imageIndex}
      />
    </>
  );
};

export { MyPartRequestDetail as default };
