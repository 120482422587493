/* eslint-disable */
import { Outlet, useLocation } from "react-router-dom";
import { useSnapshot } from "valtio";
import { ConfigLink, vState } from "../../core";
import { Header, StickyMenu } from "./components";

const NoProfileLayout = ({ headerClassName = "" }) => {
  const snap = useSnapshot(vState);
  const location = useLocation();

  return (
    <div className="ts-mobile-dimension w-full flex flex-col justify-start items-start bg-white min-w-320 relative">
      <Header
        className={headerClassName}
        showBackButton
        noProfile
        lowMargin={location.pathname === ConfigLink.support}
      />
      <div className="w-full flex justify-start items-start">
        <StickyMenu />
        <Outlet />
      </div>
    </div>
  );
};

export { NoProfileLayout as default };

const styles = {};
