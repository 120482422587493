/* eslint-disable */
import React from "react";
import Skeleton from "react-loading-skeleton";
import user from "../../../../assets/images/user.svg";

interface IProfileLoaderProps {}

const ProfileLoader: React.FC<IProfileLoaderProps> = React.memo(() => {
  return (
    <>
      <div className="w-full flex justify-start items-center mb-2">
        <img src={user} alt="tst-user" className="w-14 h-14 rounded-full ml-4"></img>
        <div className="flex flex-col justify-center items-center">
          <Skeleton className={`w-32 -mr-1`} />
        </div>
      </div>

      {[1, 2, 3, 4, 5, 6].map((item) => {
        return (
          <Skeleton
            key={item}
            style={{ height: "72px" }}
            containerClassName="w-full"
            className={`w-full mb-2 rounded-xl`}
          />
        );
      })}
    </>
  );
});

export { ProfileLoader };

const styles = {};
