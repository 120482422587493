import { Text } from "ui/components";
import car from "../../../assets/images/car-virazh.png";
import img_gs from "../../../assets/images/logo-Loading.svg";
import "./style.scss";

const SuspenseLoading = () => {
  return (
    <div className="w-full h-screen ts-loading-page-background flex flex-col justify-center items-center relative pt-16 z-50">
      <div className="ts-canvas">
        <div className="ts-road">
          <img className="virazh_car" src={car} alt="virazh-image" />
        </div>
      </div>
      <Text type="P3" className="mt-9 text-center ts-loading-tips">
        ۱۸۰۰+ کسب و کار در سراسر کشور از ویراژ استفاده می کنند.
      </Text>

      <img src={img_gs} alt="logo-loading" className="mt-28" />
    </div>
  );
};

export { SuspenseLoading };
