/* eslint-disable */
import React, { useState } from "react";
import { BottomModal, Button, Text } from "ui/components";

import { DeletePartRequestReasonModal } from "./DeletePartRequestReasonModal";

interface IDeletePartRequestModalProps {
  isOpen: boolean;
  onClose: () => void;
  id: string;
}

const DeletePartRequestModal: React.FC<IDeletePartRequestModalProps> = React.memo(({ isOpen, onClose, id }) => {
  const [deleteRequestModal, setDeleteRequestModal] = useState(false);

  const confirmHandler = () => {
    onClose();
    setDeleteRequestModal(true);
  };

  return (
    <>
      <BottomModal isOpen={isOpen} onClose={onClose}>
        <div className="w-full flex flex-col justify-start items-start">
          <Text type="H4" className="ts-accent">
            حذف درخواست
          </Text>

          <Text type="P3" className="ts-accent my-6">
            آيا میخواهید درخواست تامین قطعه خود را حذف کنید؟
          </Text>

          <div className=" w-full flex justify-between items-center">
            <Button
              onClick={confirmHandler}
              bcolor="primary"
              classNameContainer="ts-half-width"
              className="w-full rounded-xl h-12"
            >
              بله، حذف شود
            </Button>

            <Button
              onClick={() => onClose()}
              bcolor="primaryTransparent"
              classNameContainer="ts-half-width"
              className="w-full rounded-xl h-12"
            >
              لفو
            </Button>
          </div>
        </div>
      </BottomModal>
      <DeletePartRequestReasonModal id={id} isOpen={deleteRequestModal} onClose={() => setDeleteRequestModal(false)} />
    </>
  );
});

export { DeletePartRequestModal };

const styles = {};
