/* eslint-disable */
/* @ts-ignore */
import React, { useEffect } from "react";
import { Checkbox, CheckPicker, CheckPickerProps } from "rsuite";
import { PickerBaseProps } from "rsuite/esm/@types/common";
import { Button, Text } from "./";
import { TEXT_TYPE } from "./Text";

interface ICheckPickerFieldProps extends CheckPickerProps<PickerBaseProps> {
  disabled?: boolean;
  data: any[];
  value: any;
  label?: any;
  withCloseButton?: boolean;
  withSelectAll?: boolean;
  mainData?: any[]; //forCheckAll
  selectedData?: any[]; //forCheckAll
  checkAllHandler?: (v, c) => void; //forCheckAll
  // searchable?: boolean;
  // cleanable?: boolean;
  // name: string;
  labelFont?: TEXT_TYPE;
  labelClassName?: string;
  error?: boolean;
  errorText?: string;
  margin?: string;
  containerClassName?: string;
  onChange?: (e?: any) => void;
  onClean?: () => void;
  onOpen?: () => void;
}

const CheckPickerField: React.FC<ICheckPickerFieldProps> = React.memo(
  ({
    disabled,
    data,
    value,
    label,
    withCloseButton = false,
    withSelectAll = false,
    mainData,
    selectedData,
    checkAllHandler,
    labelFont = "H6",
    labelClassName = "",
    error = false,
    errorText = "",
    margin = "mb-6",
    containerClassName,
    onChange,
    onClean,
    onOpen,
    ...restProps
  }) => {
    const picker = React.useRef();
    useEffect(() => {}, []);

    return (
      <div className={`w-full flex flex-col justify-start items-start ${margin} ${containerClassName}`}>
        {label && (
          <Text type={labelFont} className={`mb-2 ts-accent ${labelClassName}`}>
            {label}
          </Text>
        )}
        <div className={`w-full relative`}>
          <CheckPicker
            size="lg"
            //@ts-ignore
            ref={picker}
            disabled={disabled}
            data={data}
            value={value}
            onClean={onClean}
            onChange={onChange}
            onOpen={onOpen}
            block
            placement="auto"
            menuClassName="z-50"
            {...restProps}
            className={`transition-all w-full h-12 outline-none rounded-xl ${error && "ts-error-border"}`}
            renderExtraFooter={
              withCloseButton
                ? () => (
                    <div className="w-full py-3 border-t-2 flex justify-center">
                      <Button
                        bcolor="primary"
                        onClick={() => {
                          //@ts-ignore
                          picker.current.close();
                        }}
                        classNameContainer="w-full mx-3"
                        className="w-full rounded-xl h-10"
                      >
                        تایید و بستن
                      </Button>
                    </div>
                  )
                : withSelectAll && selectedData && mainData
                ? () => (
                    <div className="w-full py-3 pl-3 border-t-2 flex justify-between">
                      <Checkbox
                        // inline
                        indeterminate={selectedData.length > 0 && selectedData.length < mainData.length}
                        checked={selectedData.length === mainData.length}
                        onChange={checkAllHandler}
                        className="mt-1"
                      >
                        <Text type="H5" className="pr-6">
                          {`${selectedData.length === mainData.length ? "پاک کردن همه" : "انتخاب همه"}`}
                        </Text>
                      </Checkbox>
                      <Button
                        bcolor="primary"
                        onClick={() => {
                          //@ts-ignore
                          picker.current.close();
                        }}
                        className="rounded-xl h-11"
                      >
                        تایید و بستن
                      </Button>
                    </div>
                  )
                : undefined
            }
          />
        </div>
        {error && (
          <Text type="P5" className={`ts-error mt-1`}>
            {errorText || "این فیلد الزامی است"}
          </Text>
        )}
      </div>
    );
  },
);

export { CheckPickerField };

const styles = {};
