/* eslint-disable */
import { ConfigLink, useQueryString } from "core";
import { isNull } from "lodash";
import { HiEye, HiPhone } from "react-icons/hi";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { Button, Text } from "../../../components";

interface IProviderCardProps {
  type: "loader" | "info";
  data?: any;
}

interface IProviderCardLoaderProps {
  type: "loader" | "info";
  data: any;
}

const ProviderCard: React.FC<IProviderCardProps | IProviderCardLoaderProps> = ({ type, data }) => {
  const navigate = useNavigate();

  const viewWorkshopHandler = (id) => {
    navigate({
      pathname: ConfigLink.workshopInfo,
      search: useQueryString({ id }),
    });
  };

  return (
    <div className="w-full flex flex-col justify-start items-start p-4 rounded-2xl ts-shadow">
      {type === "loader" ? (
        <Skeleton className="w-32 mb-4" />
      ) : (
        <Text type="H6" className="ts-accent mb-4">
          {data.workshop.name || "---"}
        </Text>
      )}
      <div className="w-full flex justify-between items-center gap-4">
        {type === "loader" ? (
          <>
            <Skeleton containerClassName="ts-half-width" className="w-full h-10 rounded-xl" />
            <Skeleton containerClassName="ts-half-width" className="w-full h-10 rounded-xl" />
          </>
        ) : (
          <>
            <Button
              bcolor="primary"
              disabled={
                data.createdBy
                  ? isNull(data.createdBy.mobile) || data.createdBy.mobile.includes("*")
                  : data.workshop
                  ? isNull(data.workshop.phone) || (data.workshop.phone && data.workshop.phone.length === 0)
                  : false
              }
              onClick={() => window.open(`tel:${data.createdBy ? data.createdBy.mobile : data.workshop.phone || ""}`)}
              icon={<HiPhone size={21} className="text-white -mt-1 -rotate-90" />}
              classNameContainer={"ts-half-width"}
              className="w-full h-10"
            >
              تماس
            </Button>
            <Button
              bcolor="primaryTransparent"
              onClick={() => viewWorkshopHandler(data.workshop.id)}
              icon={<HiEye size={21} className="ts-primary -mt-1" />}
              classNameContainer={"ts-half-width"}
              className="w-full h-10"
            >
              مشاهده پروفایل
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export { ProviderCard as default };

