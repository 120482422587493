/* eslint-disable */
import React from "react";
import { HiExclamation } from "react-icons/hi";
import { BottomModal, Button, Text } from "../../../components";

type ModalType = "add" | "edit";
type UserType = "active" | "pending";

interface IMapErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const MapErrorModal: React.FC<IMapErrorModalProps> = React.memo(({ isOpen, onClose }) => {
  return (
    <BottomModal isOpen={isOpen} onClose={onClose} centerScreen>
      <HiExclamation size={95} className="ts-warning mx-auto mb-5" />
      <Text type="H4" className="ts-accent mx-auto text-center mb-6">
        خطای ثبت موقعیت
      </Text>

      <Text type="P3" className="ts-accent mb-6 whitespace-pre-wrap">
        {`در ثبت موقعیت مکانی شما ایرادی وجود دارد.

لطفا ابتدا تنظیمات موقعیت گوشی همراه خود را بررسی نمایید.

در صورت برطرف نشدن مشکل، پروفایل خود را بدون ثبت موقعیت مکانی ایجاد کنید. سپس به پشتیبانی پیام دهید.`}
      </Text>

      <Button onClick={onClose} bcolor="primary" classNameContainer="w-full" className="w-full rounded-xl h-12">
        متوجه شدم
      </Button>
    </BottomModal>
  );
});

const privileges = [
  {
    label: "مدیر سیستم (ادمین)",
    value: "admin",
  },
  {
    label: "کاربر عادی",
    value: "agent",
  },
];

export { MapErrorModal };

const styles = {};
