import { devConfigs } from "./dev";
import { prodConfigs } from "./prod";
import { stageConfigs } from "./stage";
import { IConfigurations } from "./types";

let memoized: IConfigurations;

export const configs = () => {
  if (memoized) {
    return { ...memoized };
  }

  if (window.location.hostname.includes("virazh.ir")) {
    memoized = { ...prodConfigs };
  } else if (window.location.hostname.includes("designersonline.ir")) {
    memoized = { ...stageConfigs };
  } else {
    memoized = { ...stageConfigs };
  }

  return { ...memoized };
};
