/* eslint-disable */
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { vState } from "../../../core";
import { useChangeTitle } from "../../../core/hooks";
import { ContainerScroll, Text } from "../../components";

const ContactUs = ({}) => {
  const metaTags = useChangeTitle("ویراژ - تماس با ما");
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  // const accountClickHandler = () => {
  //   history.push(ConfigLink);
  // };

  return (
    <>
      {/* <Header showBackButton noLogo backClick={() => navigate(ConfigLink.profile)} profileActive /> */}
      {metaTags}
      <ContainerScroll heightOffset={50} className="">
        <Text type="H3" className="ts-accent mb-6">
          تماس با ما
        </Text>

        <div className="w-full p-4 rounded-2xl ts-bg-primary-light flex flex-col justify-start items-start">
          <Text type="H5" className="ts-gray-07">
            اطلاعات تماس
          </Text>
          <Text type="P4" className="ts-gray-06 my-4">
            پاسخگویی در روز های کاری از ۸ الی ۱۸ می باشد.
          </Text>
          {/* <div className="flex justify-start items-center mb-4">
            <Text type="P4" className="ts-accent ts-text-space">
              شماره ثابت:
            </Text>
            <Text
              onClick={() => window.open("tel:03135234656", "_self")}
              type="P4"
              className="ts-contact-us-number cursor-pointer"
            >
              ۰۳۱-۳۵۲۳۴۶۵۶
            </Text>
          </div> */}
          <div className="flex justify-start items-center">
            <Text type="P4" className="ts-accent ts-text-space">
              پشتیبانی:
            </Text>
            <Text
              onClick={() => window.open("tel:02191693862", "_self")}
              type="P4"
              className="ts-contact-us-number cursor-pointer"
            >
              021-91693862
            </Text>
            <Text type="P4" className="ts-accent mx-2">
              -
            </Text>
            <Text
              onClick={() => window.open("tel:+989207901785", "_self")}
              type="P4"
              className="ts-contact-us-number cursor-pointer"
            >
              ۰۹۲۰۷۹۰۱۷۸۵
            </Text>
          </div>
        </div>
      </ContainerScroll>
      {/* <Footer activeTab={2} /> */}
    </>
  );
};

export { ContactUs as default };
