import { ConfigLink, useFetchQueryString } from "core";
import { useAuth } from "hooks";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "rsuite";

interface IQueryStringTransfer {
  to: string;
  mobile: string;
}

const RedirectPage = () => {
  const navigate = useNavigate();
  let { to, mobile } = useFetchQueryString<IQueryStringTransfer>();

  const auth = useAuth();
  const mobileNumber = useMemo(() => (auth.profile ? auth.profile.mobile : undefined), [auth.profile]);

  useEffect(() => {
    if (!to || !mobile) {
      navigate(ConfigLink.homePage, { replace: true });
      return;
    }

    if (auth.ready) {
      if (!auth.authenticated) {
        auth.login({
          redirectUri: window.location.href,
          loginHint: mobile,
        });
        return;
      }

      if (mobileNumber === mobile) {
        navigate(decodeURIComponent(to));
      } else {
        auth.logout({
          redirectUri: window.location.href,
        });
      }
    }
  }, [auth.ready, mobileNumber, auth.authenticated, to, mobile]);

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <Loader size="sm" center />
    </div>
  );
};

export { RedirectPage as default };
