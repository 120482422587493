/* eslint-disable */
import React from "react";
import Skeleton from "react-loading-skeleton";

interface ICardLoaderProps {
  height: string;
  moreMargin?: boolean;
  className?: string;
}

const CardLoader: React.FC<ICardLoaderProps> = React.memo(({ height = "75px", moreMargin = false, className = "" }) => {
  return (
    <>
      {[1, 2, 3, 4, 5, 6].map(() => {
        return (
          <Skeleton
            style={{ height: `${height}` }}
            containerClassName="w-full"
            className={`w-full rounded-2xl ${moreMargin ? "mb-6" : "mb-4"} ${className}`}
          />
        );
      })}
    </>
  );
});

export { CardLoader };

const styles = {};
