/* eslint-disable */
import { ApiInstance, ConfigLink, useChangeTitle, useFetchQueryString, vState } from "core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { HiQrcode } from "react-icons/hi";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { Button, ContainerScroll, DesktopTitle, NoWorkspaceEmptyState, Text, useNotification } from "ui/components";
import { useSnapshot } from "valtio";
import { DashboardLoader, RegisteredCars } from "./components";

interface IAdmissionProps {}

interface IQueryStringTransfer {
  day: string;
}

const Dashboard: React.FC<IAdmissionProps> = React.memo(({}) => {
  const metaTags = useChangeTitle("ویراژ - داشبورد");
  const { day } = useFetchQueryString<IQueryStringTransfer>();

  const isLargeScreen = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [statics, setStatics] = useState({
    commited: 0,
    reminders: 0,
    total: 0,
  });
  const [workspaceRegistered, setWorkshopRegistered] = useState(false);

  const getWorkshop = async () => {
    await ApiInstance.workshop
      .getWorkshop()
      .then((result) => {
        console.log(result);
        if (result.data.data.length === 0) {
          console.log("No Workshop");
          setWorkshopRegistered(false);
        } else {
          console.log("Workshop OK");
          setWorkshopRegistered(true);
          vState.userWorkspaces = result.data.data;
          // tools.setActiveWorkshop(result.data.data);
          getCars();
        }
      })
      .catch((err) => {
        useNotification("مشکلی رخ داده است. لظفا دقایقی بعد مجددا امتحان کنید.", "error", "ویراژ");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCars = async () => {
    const minDate = moment()
      .subtract(day ? Math.abs(+day) + 1 : 1, "days")
      .format("YYYY-MM-DDT23:59:59.000[Z]");
    const maxDate =
      day && +day < 0
        ? moment().subtract(Math.abs(+day), "days").format("YYYY-MM-DDT23:59:59.000[Z]")
        : moment().format("YYYY-MM-DDT23:59:59.000[Z]");

    await ApiInstance.sessions
      .getAllSessions(`?minDate=${minDate}&maxDate=${maxDate}&pageSize=50`)
      .then((res) => {
        console.log("cars", res);
        vState.carList = res.data.data;
        vState.carLoading = false;
        setStatics(res.data.meta);
      })
      .catch((err) => console.log("err getCars", err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    vState.carLoading = true;
    getWorkshop();
  }, []);

  useEffect(() => {
    if (snap.activeWorkshop) {
      if (!snap.activeFeatures.includes("admission_discharge")) {
        navigate(ConfigLink.homePage, { replace: true });
      }
    }
  }, [snap.activeWorkshop]);

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={160} className={`overflow-x-hidden h-screen`}>
        <DesktopTitle title="پذیرش و ترخیص" />
        {loading ? (
          <DashboardLoader />
        ) : workspaceRegistered ? (
          <>
            <div className="w-full flex justify-between items-center">
              <Text type="H4" className="ts-accent">
                فعالیت روزانه شما
              </Text>
              {isLargeScreen && (
                <Button
                  onClick={() => navigate(ConfigLink.admission)}
                  iconPosition="beforeText"
                  icon={<HiQrcode size={23} className="text-white -mt-0.5" />}
                  textType="H6"
                  bcolor="primary"
                  classNameContainer="w-64"
                  className="w-full h-12 rounded-xl"
                >
                  پذیرش خودرو
                </Button>
              )}
            </div>
            <RegisteredCars statics={statics} onDone={(data) => setStatics(data)} day={+day || 0} />

            {!isLargeScreen && (
              <Button
                onClick={() => navigate(ConfigLink.admission)}
                iconPosition="beforeText"
                icon={<HiQrcode size={23} className="text-white -mt-0.5" />}
                textType="H6"
                bcolor="primary"
                classNameContainer="w-full"
                className="ts-float-button h-12 rounded-xl"
              >
                پذیرش خودرو
              </Button>
            )}
          </>
        ) : (
          <NoWorkspaceEmptyState returnUrl={ConfigLink.homePage} />
        )}
      </ContainerScroll>
    </>
  );
});

export { Dashboard as default };

const styles = {};
