/* eslint-disable */
import React, { useEffect, useState } from "react";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { useSnapshot } from "valtio";
import { vState } from "../../../../core";
import { BottomModal, Button, Text } from "../../../components";

interface IDeleteReminderModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const DeleteReminderModal: React.FC<IDeleteReminderModalProps> = React.memo(({ isOpen, onClose }) => {
  const snap = useSnapshot(vState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  return (
    <BottomModal isOpen={isOpen} onClose={onClose}>
      <div className="w-full flex flex-col justify-start items-start">
        <div className="flex justify-end items-center mb-6">
          <RiDeleteBin6Fill size={21} className="ts-secondary-red" />
          <Text type="H4" className="ts-secondary-red mr-2">
            حذف یادآور
          </Text>
        </div>
        <Text type="P3" className="ts-accent mb-8">
          آيا نسبت به حذف یادآور برای این خودرو اطمینان دارید؟
        </Text>
        <div className=" w-full flex justify-between items-center">
          <Button
            // onClick={() => onClose()}
            bcolor="black"
            textType="H6"
            classNameContainer="ts-half-width"
            className="w-full rounded-lg h-12"
          >
            بلی
          </Button>

          <Button
            onClick={() => onClose()}
            bcolor="blackTransparent"
            textType="H6"
            classNameContainer="ts-half-width"
            className="w-full rounded-lg h-12"
          >
            خیر
          </Button>
        </div>
      </div>
    </BottomModal>
  );
});

export { DeleteReminderModal };

const styles = {};
