/* eslint-disable */
import { ApiInstance, ConfigLink, ServiceStorage, tools, useChangeTitle, useFetchQueryString, vState } from "core";
import React, { useEffect, useRef, useState } from "react";
import { BiCurrentLocation } from "react-icons/bi";
import { FiCornerUpRight } from "react-icons/fi";
import { MapContainer, TileLayer } from "react-leaflet";
import { useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import { Button, Text, useNotification } from "ui/components";
import { useSnapshot } from "valtio";
import { MapErrorModal, MarkerComponent, UserLocation } from "./components";

interface ISetLocationProps {}

interface IQueryStringTransfer {
  lat?: string;
  lng?: string;
  type: "add" | "edit";
}
const env = import.meta.env;

const SetLocation: React.FC<ISetLocationProps> = React.memo(({}) => {
  const metaTags = useChangeTitle("ویراژ - انتخاب موقعیت مکانی");

  const navigate = useNavigate();
  const snap = useSnapshot(vState);
  let { lat, lng, type } = useFetchQueryString<IQueryStringTransfer>();
  const returnUrl = ServiceStorage.getTempLocationReturnURL() || ConfigLink.manageWorkshops;
  const defaultLocation = [35.69979085412715, 51.33753633512243];

  const [currentLocation, setCurrentLocation] = useState<any>(null);
  const [userLocation, setUserLocation] = useState<any>(null);
  const [address, setAddress] = useState<string>("");
  const [markerAnchor, setMarkerAnchor] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [locationLoading, setLocationLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [errorModal, setErrorModal] = useState(false);
  const ref: any = useRef();

  const updateViewportHeight = () => {
    document.documentElement.style.setProperty("--viewport-height", `${window.innerHeight}px`);
  };

  useEffect(() => {
    if (lat && lng) {
      setCurrentLocation([+lat, +lng]);
    } else {
      const tempWs = ServiceStorage.getTempWorkshop();
      if (tempWs) {
        const tempObj = JSON.parse(tempWs);
        if (tempObj.location) {
          setCurrentLocation(tempObj.location.coordinates);
        } else {
          setDefaultLocation();
        }
      } else {
        setDefaultLocation();
      }
    }

    setTimeout(() => {
      updateViewportHeight();
      window.scrollTo(0, 1);
      setPageLoading(false);
    }, 2000);
  }, []);

  const setDefaultLocation = () => {
    if ("geolocation" in navigator) {
      console.log("Available");
      navigator.geolocation.getCurrentPosition(
        function (pos) {
          //success
          console.log("location found", pos);
          setCurrentLocation([pos.coords.latitude, pos.coords.longitude]);
        },
        function (err) {
          //fail
          setCurrentLocation([35.69979085412715, 51.33753633512243]); // AZADI Square
          console.log("location failed", err.message);
          // useNotification(
          //   "امکان نمایش موقعیت مکانی شما وجود ندارد. لطفا تنظیمات گوشی خود را بررسی نمایید.",
          //   "error",
          //   "خطا",
          // );
          setErrorModal(true);
        },
        { timeout: 10000, enableHighAccuracy: true },
      );
    } else {
      console.log("Not Available");
      setErrorModal(true);
    }
  };

  // get user location button functions --------------------

  //------------------------ get address from NeshanApi
  useEffect(() => {
    console.log("currentLocation", currentLocation);
    if (currentLocation) {
      getLocationAddress();
    }
  }, [currentLocation]);

  const getLocationAddress = async (): Promise<string> => {
    let address = "";
    setLoading(true);
    await ApiInstance.workshop
      .getLocationAddress(currentLocation[0], currentLocation[1])
      .then((res) => {
        console.log(res);
        address = `${res.data.formatted_address || ""}${res.data.neighbourhood ? `, ${res.data.neighbourhood}` : ""}${
          res.data.place ? `, ${res.data.place}` : ""
        }`;
        setAddress(address);
      })
      .catch((err) => {
        console.log("err", err);
        useNotification(
          "امکان دریافت آدرس از موقعیت مکانی در حال حاضر وجود ندارد. در صورت بروز مجدد این مشکل با پشتیبانی تماس بگیرید.",
          "error",
        );
      })
      .finally(() => {
        setLoading(false);
      });
    return address;
  };

  // END----------------------- get address from NeshanApi

  const getLocationSuccessful = (pos) => {
    let crd = pos.coords;
    setLocationLoading(false);
    console.log(`Latitude : ${crd.latitude}`);
    console.log(`Longitude: ${crd.longitude}`);
    console.log(`More or less ${crd.accuracy} meters.`);
    // const map = useMap();
    if (ref && ref.current) {
      setUserLocation([crd.latitude, crd.longitude]);
      ref.current.moveToUserLocation([crd.latitude, crd.longitude]);
    }
  };
  const getLocationFailed = (e) => {
    console.log("error getLocation", e.message);
    // useNotification(
    //   "امکان نمایش موقعیت مکانی شما وجود ندارد. لطفا تنظیمات گوشی خود را بررسی نمایید.",
    //   "error",
    //   "موقعیت مکانی",
    // );
    setErrorModal(true);
    setLocationLoading(false);
  };
  const getUserLocationButton = () => {
    if (navigator.geolocation) {
      navigator.permissions.query({ name: "geolocation" }).then(function (result) {
        if (result.state === "granted") {
          console.log(result.state);
          setLocationLoading(true);
          window.navigator.geolocation.getCurrentPosition(getLocationSuccessful, getLocationFailed, {
            timeout: 10000,
            enableHighAccuracy: true,
          });
        } else if (result.state === "prompt") {
          console.log(result.state);
          if (tools.isIOS()) {
            setLocationLoading(true);
            window.navigator.geolocation.getCurrentPosition(getLocationSuccessful, getLocationFailed, {
              timeout: 10000,
              enableHighAccuracy: true,
            });
          }
        } else if (result.state === "denied") {
          //If denied then you have to show instructions to enable location
          useNotification(
            "شما دسترسی این برنامه به موقعیت مکانی خود را لغو کرده اید. لطفا از تنظیمات اجازه دسترسی به ویراژ را بدهید و از روشن بودن موقعیت مکانی دستگاه خود اطمینان حاصل کنید.",
            "warning",
            "عدم دسترسی",
            6000,
          );
        }
        result.onchange = function () {
          console.log(result.state);
        };
      });
    } else {
      console.log("not");
      useNotification("مرورگر شما از این قابلیت پشتیبانی نمی کند.", "error", "خطا");
    }
  };

  //primary button functions -------------------------------

  const addLocationHandler = async (locationObject) => {
    const tempWs = ServiceStorage.getTempWorkshop();
    if (tempWs) {
      const finalObj = { ...JSON.parse(tempWs), location: locationObject, address: address };
      vState.userWorkspaceInfo = finalObj;
      ServiceStorage.setTempWorkshop(JSON.stringify(finalObj));
    } else {
      navigate(ConfigLink.createWorkspaceStepOne, { replace: true });
    }
  };

  const editOrSetLocationHandler = async (locationObject) => {
    const tempWs = ServiceStorage.getTempWorkshop();
    if (tempWs) {
      const finalObj = { ...JSON.parse(tempWs), address: address };
      ServiceStorage.setTempWorkshop(JSON.stringify(finalObj));
    }
    vState.tempLocation = locationObject;
    // vState.userWorkspaceInfo.address = address;
  };

  const setLocationButtonHandler = () => {
    const locationObj = {
      type: "Point",
      coordinates: currentLocation,
    };

    if (type === "add") {
      addLocationHandler(locationObj);
      navigate(ConfigLink.createWorkspaceStepTwo, { replace: true });
    } else {
      editOrSetLocationHandler(locationObj);
      backClickHandler();
    }
  };

  const backClickHandler = () => {
    navigate(-1);
  };

  return (
    <>
      {metaTags}
      <div className="w-full fixed ts-float-button-back-location right-6 z-30">
        <div
          onClick={backClickHandler}
          className="h-10 px-3 bg-white rounded-xl absolute flex justify-start items-center cursor-pointer"
        >
          <FiCornerUpRight size={18} className="ts-primary" />
          <Text type="H6" className="ts-primary mr-1">
            بازگشت
          </Text>
        </div>
      </div>
      {pageLoading && <Loader size="md" center className="fixed" />}
      {currentLocation && !pageLoading && (
        <MapContainer
          id="provider-map"
          whenReady={() => setPageLoading(false)}
          center={currentLocation || defaultLocation}
          zoom={16}
          zoomControl={false}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MarkerComponent
            ref={ref}
            currentLocation={currentLocation}
            onDone={(location) => setCurrentLocation(location)}
          />
          <UserLocation userLocation={userLocation} />
        </MapContainer>
      )}
      <div
        className={`w-full fixed ts-float-button-avoid-address-bar bottom-9 z-30 flex flex-col justify-center items-start px-6`}
      >
        <Button
          bcolor="white"
          onClick={getUserLocationButton}
          icon={<BiCurrentLocation size={28} className="ts-primary ts-my-location-icon" />}
          loading={locationLoading}
          classNameContainer="w-12 h-12 ts-shadow"
          className="w-full h-full rounded-xl flex justify-center items-center bg-white ts-shadow z-40"
        ></Button>
        <div
          className={`w-full h-11 mt-3 rounded-xl px-4 py-3 ts-bg-gray-01 flex ${
            loading ? "justify-center" : "justify-start"
          } items-center mb-3`}
        >
          {loading ? (
            <Loader size="sm" />
          ) : (
            <Text type="P4" className="ts-gray-06 ts-map-address-box truncate">
              {address}
            </Text>
          )}
        </div>
        <Button
          onClick={setLocationButtonHandler}
          bcolor="primary"
          textType="H5"
          classNameContainer="w-full"
          className="w-full h-12"
        >
          انتخاب موقعیت مکانی کسب و کار
        </Button>
      </div>

      <MapErrorModal isOpen={errorModal} onClose={() => setErrorModal(false)} />
    </>
  );
});

export { SetLocation as default };
