/* eslint-disable */
import { ConfigLink, ServiceStorage } from "core";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/subscription-image.svg";
import { BottomModal, Button, Text } from "./";

interface IPremiumNoticeModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const PremiumNoticeModal: React.FC<IPremiumNoticeModalProps> = React.memo(({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {}, []);

  const viewSubHandler = () => {
    ServiceStorage.setTempSubReturnURL(`${location.pathname}${location.search}`);
    navigate(ConfigLink.subInfo);
  };

  return (
    <BottomModal isOpen={isOpen} onClose={onClose} className={"pb-7"}>
      <img src={logo} className="mx-auto" />
      <Text type="H4" className="ts-accent text-center my-4">
        فعالسازی سرویس ویژه
      </Text>

      <Text type="P4" className="ts-accent mb-6">
        این امکان برای شما وجود ندارد. برای رفع محدودیت لازم است سرویس ویژه ویراژ را تهیه نمایید.
      </Text>
      <Button
        onClick={viewSubHandler}
        bcolor="primary"
        textType="H6"
        classNameContainer="w-full"
        className="w-full rounded-xl h-12 mb-2"
      >
        مشاهده سرویس ویژه
      </Button>

      <Button
        onClick={() => onClose()}
        bcolor="transparent"
        textType="H6"
        classNameContainer="w-full"
        className="w-full rounded-xl h-12 ts-primary"
      >
        تمایلی ندارم
      </Button>
    </BottomModal>
  );
});

export { PremiumNoticeModal };

const styles = {};
