/* eslint-disable */
// @ts-ignore
import React from "react";
import { useNavigate } from "react-router-dom";
import { BottomModal, Button, Text } from "../../../components";

interface IMessageSentSuccessfullyProps {
  isOpen: boolean;
  onClose: () => void;
}

const env = import.meta.env;

const MessageSentSuccessfully: React.FC<IMessageSentSuccessfullyProps> = React.memo(({ onClose, isOpen }) => {
  const history = useNavigate();

  const closeModalHandler = () => {
    onClose();
  };

  return (
    <BottomModal isOpen={isOpen} onClose={onClose} centerScreen className="rounded-xl">
      <Text type="H4" className={`ts-accent text-center mx-auto my-6`}>
        اطلاعیه ارسال پیامک
      </Text>

      <Text type="P4" className={`ts-accent mb-4`}>
        پیامک شما، با موفقیت به مشتریان ارسال شد.
      </Text>

      <Button onClick={closeModalHandler} bcolor="green" classNameContainer="w-full" className="w-full h-12">
        متوجه شدم
      </Button>
    </BottomModal>
  );
});

export { MessageSentSuccessfully };
