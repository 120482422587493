/* eslint-disable */
import { useState } from "react";
import { BsDash } from "react-icons/bs";
import { MdCheck } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Table } from "rsuite";
import { ConfigLink } from "../../../../core";
import { useChangeTitle } from "../../../../core/hooks";
import { Button, ContainerScroll, Text } from "../../../components";

const SubCompare = ({}) => {
  const metaTags = useChangeTitle("ویراژ - مقایسه سرویس ها");
  const navigate = useNavigate();
  const { Column, HeaderCell, Cell } = Table;

  const [columnKeys, setColumnKeys] = useState(defaultColumns.map((column) => column.key));

  const columns = defaultColumns.filter((column) => columnKeys.some((key) => key === column.key));
  const CustomCell = Cell;
  const CustomHeaderCell = HeaderCell;

  const activateSubHandler = () => {
    navigate(ConfigLink.subSelect);
  };

  const options = [
    {
      id: 0,
      features: "پذیرش و ترخیص نامحدود",
      free: true,
      premium: true,
    },
    {
      id: 1,
      features: "تعریف یادآور نامحدود",
      free: true,
      premium: true,
    },
    // {
    //   id: 2,
    //   features: "ارتباط با مشتریان",
    //   free: false,
    //   premium: true,
    // },
    {
      id: 3,
      features: "مدیریت چند کسب و کار",
      free: false,
      premium: true,
    },
    // {
    //   id: 4,
    //   features: "فروشگاه قطعات نامحدود",
    //   free: false,
    //   premium: true,
    // },
  ];

  const renderCell = (value) => {
    return typeof value !== "boolean" ? (
      value
    ) : value ? (
      <MdCheck size={20} color="#38C976" />
    ) : (
      <BsDash size={20} color="#8F9CA9" />
    );
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={130} NoSticky className="pt-56">
        <Text type="H3" className="ts-accent text-center mb-8">
          مقایسه سرویس رایگان و ویژه
        </Text>

        <Table
          hover={false}
          showHeader
          autoHeight
          className="ts-providers-activity-wrapper"
          affixHeader
          data={options}
          bordered={false}
          cellBordered={false}
          virtualized
          headerHeight={36}
          rowHeight={56}
        >
          {columns.map((column) => {
            const { key, label, style, ...rest } = column;
            return (
              <Column {...rest} key={key} align={"right"}>
                <CustomHeaderCell
                  fixed
                  className={`ts-text-h6 ${key === "premium" ? "ts-table-header-text" : "ts-accent"}`}
                >
                  {label}
                </CustomHeaderCell>
                <CustomCell dataKey={key} verticalAlign="middle" className={`${style}`} renderCell={renderCell} />
              </Column>
            );
          })}
        </Table>

        <div className="w-full ts-sub-sticky-button flex flex-col justify-center items-center bg-white fixed bottom-0 left-0 px-6 pb-10">
          <Button
            onClick={activateSubHandler}
            bcolor="primary"
            textType="H6"
            classNameContainer="w-full max-w-7xl"
            className="w-full rounded-xl h-12 mb-2 mt-2"
          >
            فعالسازی سرویس ویژه
          </Button>
        </div>
      </ContainerScroll>
    </>
  );
};

const defaultColumns = [
  {
    key: "premium",
    label: "ویژه",
    flexGrow: 1,
    style: "ts-text-p4 ts-accent",
  },
  {
    key: "free",
    label: "رایگان",
    flexGrow: 1,
    style: "ts-text-p4 ts-accent",
  },
  {
    key: "features",
    label: "امکانات",
    flexGrow: 3,
    style: "ts-text-p4 ts-accent",
  },
];

export { SubCompare as default };
