/* eslint-disable */
import moment from "moment";
import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-multi-date-picker/styles/colors/purple.css";
import { Progress } from "rsuite";
import { BottomModal, Button, Text } from "../../../components";

interface ISingleReminderDetailModalProps {
  isOpen: boolean;
  onClose: () => void;
  onBtnClicked: () => void;
  data: any;
  currentKilometer: number;
  type: "register" | "edit" | "update";
}

const SingleReminderDetailModal: React.FC<ISingleReminderDetailModalProps> = React.memo(
  ({ isOpen, onClose, onBtnClicked, data, currentKilometer, type }) => {
    const weekDays = ["ش", "ی", "د", "س", "چ", "پ", "ج"];
    const defaultValue = {
      serviceType: "",
      nextKM: "",
      selectedService: "",
      // maxDate: "",
      // dateLabel: "",
      detail: "",
    };

    const [loading, setLoading] = useState(false);
    const [deleteReminder, setDeleteReminder] = useState(false);

    const expiresType = data ? data.service.expiresType : "";
    const today = moment();
    const nextChangeTime =
      data && data.updatedAt && data.expiresInDays ? moment(data.updatedAt).add(data.expiresInDays, "days") : 0;
    const differenceTiming = nextChangeTime ? nextChangeTime.diff(today, "days") : 0;

    const daysRemained = nextChangeTime ? nextChangeTime.diff(today, "days") : -1000;
    const isTimePassed = nextChangeTime ? nextChangeTime.diff(today, "days") <= 0 : false;

    const currentKM = +currentKilometer || 0;
    const nextKM = data ? +(data.kilometer + data.expiresInKilometers) : 0;
    const remainKM = nextKM - currentKM;
    const kilometerPercentage =
      currentKM > nextKM || remainKM === 0 ? 100 : 100 - +(remainKM / data.expiresInKilometers).toFixed(2) * 100;

    const timePercentage = !nextChangeTime
      ? 100
      : 100 - +(+nextChangeTime.diff(today, "days") / (data ? data.expiresInDays : 1)).toFixed(2) * 100;

    return (
      <BottomModal isOpen={isOpen} onClose={onClose} className={`${deleteReminder ? "w-11/12 ml-4" : ""}`}>
        {data && isOpen && (
          <div className="w-full flex flex-col justify-start items-start">
            <div className="w-full flex justify-between items-center mb-6">
              {loading ? (
                <Skeleton className="w-14" />
              ) : (
                <Text type="H4" className="ts-gray-08">
                  {data.service.name}
                </Text>
              )}

              {isTimePassed && data.committed && (
                <div className="p-2 -mt-px flex justify-center items-center ts-bg-light-red rounded-xl">
                  <Text type="HM" className="ts-secondary-red">
                    موعد زمانی گذشته
                  </Text>
                </div>
              )}
            </div>
            {loading ? (
              <>
                <Skeleton containerClassName="w-full" className="w-full h-4" />
                <Skeleton className="w-36 h-4" />
              </>
            ) : (
              <Text type="P4" className="ts-gray-07 mb-4">
                آخرین {data.service.name}، در تاریخ{" "}
                <b className="ts-accent font-bold">{new Date(data.updatedAt).toLocaleDateString("fa-IR")}</b> و کیلومتر{" "}
                <b className="ts-accent font-bold">{data.kilometer}</b> انجام شده است.
              </Text>
            )}

            {loading ? (
              <Skeleton
                style={{ height: "68px" }}
                containerClassName="w-full"
                className="w-full mt-4 mb-3 rounded-2xl"
              />
            ) : (
              expiresType !== "days" && (
                <div className="w-full mb-3 ts-bg-gray-01 rounded-2xl p-4 flex flex-col justify-start items-start">
                  <Text type="P4" className="ts-accent mb-2">
                    {`${
                      !data.committed
                        ? data.expiresInKilometers
                        : remainKM <= 0
                        ? "0"
                        : Math.abs(nextKM - (currentKM || 0))
                    } کیلومتر مانده تا تعویض بعدی`}
                  </Text>
                  <Progress.Line
                    strokeColor={kilometerPercentage >= 80 ? "#CA2525" : "#272D37"}
                    strokeWidth={7}
                    percent={kilometerPercentage < 0 || !data.committed ? 0 : kilometerPercentage}
                    showInfo={false}
                    className="w-full p-0"
                  />
                </div>
              )
            )}

            {loading ? (
              <Skeleton style={{ height: "68px" }} containerClassName="w-full" className="w-full mb-3 rounded-2xl" />
            ) : (
              (expiresType === "both" || expiresType === "days") &&
              data.service.expiresInDays && (
                <div className="w-full mb-3 ts-bg-gray-01 rounded-2xl p-4 flex flex-col justify-start items-start">
                  <Text type="P4" className="ts-accent mb-2">
                    {`${!data.committed ? +data.expiresInDays / 30 : Math.abs(Math.round(differenceTiming / 30))} ${
                      differenceTiming / 30 > 1 ? "ماه" : "روز"
                    } ${differenceTiming / 30 < 0 ? "از موعد تعویض گذشته" : "تا تعویض بعدی"}`}
                  </Text>
                  <Progress.Line
                    strokeColor={timePercentage >= 80 ? "#CA2525" : "#752FBB"}
                    strokeWidth={7}
                    percent={timePercentage < 0 || !data.committed ? 0 : timePercentage}
                    showInfo={false}
                    className="w-full p-0"
                  />
                  {daysRemained < 0 && daysRemained !== -1000 && (
                    <Text type="P5" className="ts-gray-07 mt-2">
                      {`تاریخ آخرین تعویض: ${new Date(data.updatedAt).toLocaleDateString("fa-IR")}`}
                    </Text>
                  )}
                </div>
              )
            )}

            {loading ? (
              <Skeleton containerClassName="w-full" className="w-full h-28 mb-4 rounded-2xl" />
            ) : (
              <div className="w-full cursor-pointer ts-bg-gray-01 rounded-2xl p-4 flex flex-col justify-start items-start">
                <Text type="H6" className="ts-accent mb-3">
                  توضیحات یادآور:
                </Text>
                <Text type="P4" className="ts-gray-06">
                  {data.description || "توضیحاتی برای این یادآور اضافه نشده است."}
                </Text>
              </div>
            )}
            {loading ? (
              <Skeleton containerClassName="w-full" className="w-full h-12 rounded-xl" />
            ) : (
              (type === "edit" || type === "update") && (
                <Button
                  onClick={() => onBtnClicked()}
                  bcolor="primaryTransparent"
                  classNameContainer="w-full"
                  className="w-full h-12 rounded-xl mt-4"
                >
                  به روز رسانی یادآور
                </Button>
              )
            )}
          </div>
        )}
      </BottomModal>
    );
  },
);

export { SingleReminderDetailModal };
