/* eslint-disable */
import { ApiInstance, useObjectState, vState } from "core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  BottomModal,
  Button,
  CarBrandSelector,
  CarsModal,
  CharacterLimit,
  InputField,
  Text,
  Upload,
  useNotification,
} from "ui/components";
import { useSnapshot } from "valtio";

type IType = "add" | "edit";

interface IAddPartRequestModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDone: () => void;
  type: IType;
  id?: string;
  selectedItem?: any;
}

const AddPartRequestModal: React.FC<IAddPartRequestModalProps> = React.memo(
  ({ isOpen, onClose, onDone, type, id, selectedItem }) => {
    const defaultError = "پر کردن این فیلد الزامی می‌باشد.";
    const history = useNavigate();
    const snap = useSnapshot(vState);
    const uploader = React.useRef();

    const [imagesFiles, setImageFiles] = useState<any[]>([]);
    const [values, setValues] = useObjectState<any>({
      name: "",
      car: {
        name: "",
        id: "",
      },
      // category: "",
      description: "",
      images: [],
    });
    const { name, car, /*category,*/ description, images } = values;

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
      if (type === "edit" && selectedItem) {
        setValues({
          name: selectedItem?.name,
          car: selectedItem?.car,
          description: selectedItem?.description || "",
          images: selectedItem?.images || [],
        });
      }
    }, [isOpen, selectedItem]);

    const resetValues = () => {
      setValues({
        name: "",
        car: {
          name: "",
          id: "",
        },
        description: "",
        images: [],
      });

      setImageFiles([]);
    };

    const addRequestHandler = async (imagesArray) => {
      await ApiInstance.partSupply
        .addRequests({ ...values, car: car.id, images: imagesArray })
        .then((res) => {
          console.log(res);
          useNotification("درخواست قطعه با موفقیت ثبت شد.", "success");
          onDone();
          onClose();
        })
        .catch((err) => {
          console.log(err);
          useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const editRequestHandler = async (imagesArray) => {
      await ApiInstance.partSupply
        .editRequestInfo(id || "", { ...values, car: car.id, images: imagesArray })
        .then((res) => {
          console.log(res);
          useNotification("درخواست قطعه ویرایش شد.", "success");
          onDone();
          onClose();
        })
        .catch((err) => {
          console.log(err);
          useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const actionFunction = (imagesArray) => {
      setLoading(true);
      type === "add" ? addRequestHandler(imagesArray) : editRequestHandler(imagesArray);
    };

    const requestHandler = async () => {
      let errorsObj = { ...errors };
      if (name.length === 0) {
        errorsObj.name = defaultError;
      }
      if (car.id.length === 0) {
        errorsObj.car = defaultError;
      }

      const inProgressImages = imagesFiles.filter((item) => item.progress && item.progress.status === "in_progress");
      const failedImages = imagesFiles.filter((item) => item.progress && item.progress.status === "failed");

      if (inProgressImages.length !== 0) {
        useNotification("تا بارگذاری کامل تصویر(ها) منتظر بمانید.", "error");
        return;
      }
      if (failedImages.length !== 0) {
        useNotification("ابتدا وضعیت تصاویری که دارای خطای بارگذاری هستند را مشخص کنید.", "warning");
        return;
      }

      if (Object.keys(errorsObj).length !== 0) {
        useNotification("لطفا فیلدهای مشخص شده را پر کنید.", "warning");
        setErrors(errorsObj);
      } else {
        const imagesArray = imagesFiles.map((item) => (item.progress ? item.progress.id : item.key));
        actionFunction(imagesArray);
      }
    };

    const removeField = (field) => {
      let cn = { ...errors };
      delete cn[field];
      setErrors(cn);
    };

    const uploadHandler = (files) => {
      // console.log(files);
      setImageFiles(files);
    };

    return (
      <>
        <BottomModal isOpen={isOpen} onClose={onClose}>
          <div className="w-full flex flex-col justify-start items-start">
            <Text type="H4" className="ts-accent mb-6">
              {type === "add" ? "ثبت درخواست قطعه" : "ویرایش درخواست قطعه"}
            </Text>

            <InputField
              label="نام قطعه"
              value={name}
              onChange={(e) => {
                if (e.length < 26) setValues({ name: e });
              }}
              placeholder="مثلا: آیینه بغل سمت شاگرد"
              onFocus={() => removeField("name")}
              error={"name" in errors}
              errorText={errors.name || ""}
              margin="mb-1"
            />
            <CharacterLimit field={name} />

            <CarBrandSelector
              value={car ? car.name : null}
              error={"car" in errors}
              onClick={() => {
                setModalOpen(true);
                removeField("car");
              }}
            />

            <InputField
              label="توضیحات (اختیاری)"
              value={description}
              fieldType="textarea"
              rows={4}
              onChange={(e) => setValues({ description: e })}
              placeholder="توضیحاتی درمورد قطعه درخواستی و..."
            />

            <Upload
              multiple
              optional
              files={values.images}
              onChange={(uploads) => uploadHandler(uploads)}
              accept="image/*"
            />

            <div className=" w-full flex justify-between items-center gap-3">
              <Button
                onClick={requestHandler}
                loading={loading}
                bcolor="primary"
                classNameContainer="w-full"
                className="w-full rounded-xl h-12"
              >
                {type === "add" ? "ارسال درخواست قطعه" : "ویرایش درخواست قطعه"}
              </Button>
              <Button
                onClick={() => {
                  resetValues();
                  onClose();
                }}
                bcolor="primaryTransparent"
                textType="H6"
                classNameContainer="w-full"
                className="w-full rounded-xl h-12"
              >
                انصراف
              </Button>
            </div>
          </div>
        </BottomModal>
        <CarsModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          isSingleChoice
          selectedCars={car}
          selectCarHandler={(value) => {
            console.log(value);
            setValues({ car: value });
          }}
        />
      </>
    );
  },
);

export { AddPartRequestModal };

const styles = {};
