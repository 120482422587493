/* eslint-disable */
import React, { useState } from "react";
import { BottomModal, Button, Text } from "ui/components";

interface IChangeOwnerConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDone: () => void;
  mobile: string;
}

const env = import.meta.env;

const ChangeOwnerConfirmModal: React.FC<IChangeOwnerConfirmModalProps> = React.memo(
  ({ isOpen, onClose, onDone, mobile }) => {
    const [loading, setLoading] = useState(false);

    const onDoneHandler = async () => {
      setLoading(true);
      await onDone();
      setLoading(false);
      onClose();
    };

    return (
      <>
        <BottomModal isOpen={isOpen} onClose={onClose}>
          <div className="w-full text-right">
            <Text type="H4" className="ts-accent">
              ویرایش اطلاعات مالک خودرو
            </Text>

            <Text type="P4" className="ts-accent my-6">
              با تغییر شماره تماس، مالکیت خودرو به <strong>{mobile}</strong> منتقل خواهد شد. آیا از تغییر آن اطمینان
              دارید؟
            </Text>

            <div className=" w-full flex justify-between items-center gap-2">
              <Button
                onClick={onDoneHandler}
                loading={loading}
                bcolor="primary"
                classNameContainer="w-full"
                className="w-full h-12"
              >
                بله، اطمینان دارم
              </Button>

              <Button
                onClick={() => onClose()}
                bcolor="blackTransparent"
                textType="H6"
                classNameContainer="w-full"
                className="w-full h-12"
              >
                انصراف
              </Button>
            </div>
          </div>
        </BottomModal>
      </>
    );
  },
);

export { ChangeOwnerConfirmModal };
