/* eslint-disable */
import React, { useContext, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { CustomProvider } from "rsuite";
import { Guard } from "ui/components/Guard";
import CarStatus from "ui/views/Car/CarStatus";
import ActivityReports from "ui/views/Home/ActivityReports";
import Admission from "ui/views/Home/Admission";
import Dashboard from "ui/views/Home/Dashboard";
import HomePage from "ui/views/Home/HomePage";
import MyPartRequestDetail from "ui/views/Home/MyPartRequests/MyPartRequestDetail";
import MyPartRequests from "ui/views/Home/MyPartRequests/MyPartRequests";
import MyPartProvidesActivity from "ui/views/Home/PartRequests/MyPartProvidesActivity";
import UserPartRequestDetail from "ui/views/Home/PartRequests/UserPartRequestDetail";
import UsersPartRequests from "ui/views/Home/PartRequests/UsersPartRequests";
import ClientBulkSmsCheckout from "ui/views/Home/WorkshopClients/ClientBulkSmsCheckout";
import ClientDetail from "ui/views/Home/WorkshopClients/ClientDetail";
import ClientServiceHistoryDetail from "ui/views/Home/WorkshopClients/ClientServiceHistoryDetail";
import WorkshopClients from "ui/views/Home/WorkshopClients/WorkshopClients";
import CompleteAccount from "ui/views/Introductions/CompleteAccount";
import CompleteInvite from "ui/views/Introductions/CompleteInvite";
import Introduction from "ui/views/Introductions/Introduction";
import SetLocation from "ui/views/Location/SetLocation";
import AccountSetting from "ui/views/Profile/AccountSetting";
import BulkSmsDetail from "ui/views/Profile/BulkSmsDetail";
import BulkSmsHistory from "ui/views/Profile/BulkSmsHistory";
import ContactUs from "ui/views/Profile/ContactUs";
import Invite from "ui/views/Profile/Invite";
import Payments from "ui/views/Profile/Payments";
import RegisterNewUser from "ui/views/Profile/RegisterNewUser";
import PaymentStatus from "ui/views/Profile/Subscription/PaymentStatus";
import SelectSubscription from "ui/views/Profile/Subscription/SelectSubscription";
import SubCheckout from "ui/views/Profile/Subscription/SubCheckout";
import SubCompare from "ui/views/Profile/Subscription/SubCompare";
import SubInfo from "ui/views/Profile/Subscription/SubInfo";
import UserProfile from "ui/views/Profile/UserProfile";
import UserSetting from "ui/views/Profile/UserSetting";
import Wallet from "ui/views/Profile/Wallet";
import EditWorkshop from "ui/views/Profile/Workshops/EditWorkshop";
import ManageWorkshops from "ui/views/Profile/Workshops/ManageWorkshops";
import ManageWorkshopStaffs from "ui/views/Profile/Workshops/ManageWorkshopStaffs";
import ViewWorkshop from "ui/views/Profile/Workshops/ViewWorkshop";
import WorkshopPanel from "ui/views/Profile/Workshops/WorkshopPanel";
import RedirectPage from "ui/views/Redirect";
import SearchWorkshops from "ui/views/SearchWorkshops/SearchWorkshops";
import WorkshopInfo from "ui/views/SearchWorkshops/WorkshopInfo";
import MyShop from "ui/views/Shop/MyShop";
import MyShopPartDetail from "ui/views/Shop/MyShopPartDetail";
import Support from "ui/views/Support";
import AddWorkshopStepOne from "ui/views/Workspace/AddWorkshopStepOne";
import AddWorkshopStepTwo from "ui/views/Workspace/AddWorkshopStepTwo";
import Welcome from "ui/views/Workspace/Welcome";
import NoProfileLayout from "ui/_layouts/NoProfileLayout";
import { AuthProvider } from "./auth";
import { configs } from "./configs/configs";
import { ConfigLink, ServiceStorage } from "./core";
import { AppContext, AppProvider } from "./core/contexts/context.app";
import { ConfigFetcher } from "./ui/components";
import { LoadingCustom } from "./ui/components/LoadingPage";

//Layouts
const MainPagesLayout = React.lazy(() => import("./ui/_layouts/MainPagesLayout"));
const SubPagesLayout = React.lazy(() => import("./ui/_layouts/SubPagesLayout"));
const SubscriptionLayout = React.lazy(() => import("./ui/_layouts/SubscriptionLayout"));

//================= [Solo Pages] =================
const ErrorPage = React.lazy(() => import("./ui/views/ErrorPages/ErrorPage"));

const App = () => {
  const appContext = useContext(AppContext);

  useEffect(() => {
    const tempActive = ServiceStorage.getTempActiveWorkshop();
    const blackListUrl = [
      ConfigLink.viewWorkshop,
      ConfigLink.editWorkshop,
      ConfigLink.manageWorkshopStaffs,
      ConfigLink.clientDetail,
      ConfigLink.clientServiceHistory,
      ConfigLink.workshopClients,
    ];

    if (tempActive && !blackListUrl.includes(window.location.pathname)) {
      ServiceStorage.removeTempActiveWorkshop();
      console.log("tempActiveDeleted");
    }

    // ENABLE/DISABLE Console Logs
    if (configs().prod === "true") {
      console.log = function () {};
    }
  }, []);

  const router = createBrowserRouter([
    {
      element: <MainPagesLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: ConfigLink.homePage,
          element: (
            <Guard forceAuthenticated>
              <HomePage />
            </Guard>
          ),
        },
        {
          path: ConfigLink.intro,
          element: <Introduction />,
        },
        {
          path: ConfigLink.completeAccount,
          element: (
            <Guard forceAuthenticated>
              <CompleteAccount />
            </Guard>
          ),
        },
      ],
    },
    {
      element: <SubPagesLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: ConfigLink.admission,
          element: (
            <Guard forceAuthenticated>
              <Admission />
            </Guard>
          ),
        },
        {
          path: ConfigLink.dashboard,
          element: (
            <Guard forceAuthenticated>
              <Dashboard />
            </Guard>
          ),
        },
        {
          path: ConfigLink.activityReport,
          element: (
            <Guard forceAuthenticated>
              <ActivityReports />
            </Guard>
          ),
        },
        {
          path: ConfigLink.myPartRequest,
          element: (
            <Guard forceAuthenticated>
              <MyPartRequests />
            </Guard>
          ),
        },
        {
          path: ConfigLink.myPartRequestInfo,
          element: (
            <Guard forceAuthenticated>
              <MyPartRequestDetail />
            </Guard>
          ),
        },
        {
          path: ConfigLink.usersPartRequests,
          element: (
            <Guard forceAuthenticated>
              <UsersPartRequests />
            </Guard>
          ),
        },
        {
          path: ConfigLink.usersPartRequestDetail,
          element: (
            <Guard forceAuthenticated>
              <UserPartRequestDetail />
            </Guard>
          ),
        },
        {
          path: ConfigLink.myPartsActivity,
          element: (
            <Guard forceAuthenticated>
              <MyPartProvidesActivity />
            </Guard>
          ),
        },
        {
          path: ConfigLink.createWorkspaceStepOne,
          element: (
            <Guard forceAuthenticated>
              <AddWorkshopStepOne />
            </Guard>
          ),
        },
        {
          path: ConfigLink.createWorkspaceStepTwo,
          element: (
            <Guard forceAuthenticated>
              <AddWorkshopStepTwo />
            </Guard>
          ),
        },
        {
          path: ConfigLink.welcome,
          element: (
            <Guard forceAuthenticated>
              <Welcome />
            </Guard>
          ),
        },
        {
          path: ConfigLink.workshopInfo,
          element: (
            <Guard forceAuthenticated>
              <WorkshopInfo />
            </Guard>
          ),
        },
        {
          path: ConfigLink.profile,
          element: (
            <Guard forceAuthenticated>
              <UserProfile />
            </Guard>
          ),
        },
        {
          path: ConfigLink.accountSetting,
          element: (
            <Guard forceAuthenticated>
              <AccountSetting />
            </Guard>
          ),
        },
        {
          path: ConfigLink.userSetting,
          element: (
            <Guard forceAuthenticated>
              <UserSetting />
            </Guard>
          ),
        },
        {
          path: ConfigLink.wallet,
          element: (
            <Guard forceAuthenticated>
              <Wallet />
            </Guard>
          ),
        },
        {
          path: ConfigLink.payments,
          element: (
            <Guard forceAuthenticated>
              <Payments />
            </Guard>
          ),
        },
        {
          path: ConfigLink.inviteUsers,
          element: (
            <Guard forceAuthenticated>
              <Invite />
            </Guard>
          ),
        },
        {
          path: ConfigLink.contactUs,
          element: (
            <Guard forceAuthenticated>
              <ContactUs />
            </Guard>
          ),
        },
        {
          path: ConfigLink.manageWorkshops,
          element: (
            <Guard forceAuthenticated>
              <ManageWorkshops />
            </Guard>
          ),
        },
        {
          path: ConfigLink.workshopPanel,
          element: (
            <Guard forceAuthenticated>
              <WorkshopPanel />
            </Guard>
          ),
        },
        {
          path: ConfigLink.viewWorkshop,
          element: (
            <Guard forceAuthenticated>
              <ViewWorkshop />
            </Guard>
          ),
        },
        {
          path: ConfigLink.editWorkshop,
          element: (
            <Guard forceAuthenticated>
              <EditWorkshop />
            </Guard>
          ),
        },
        {
          path: ConfigLink.manageWorkshopStaffs,
          element: (
            <Guard forceAuthenticated>
              <ManageWorkshopStaffs />
            </Guard>
          ),
        },
        {
          path: ConfigLink.workshopBulkSmsHistory,
          element: (
            <Guard forceAuthenticated>
              <BulkSmsHistory />
            </Guard>
          ),
        },
        {
          path: ConfigLink.workshopClients,
          element: (
            <Guard forceAuthenticated>
              <WorkshopClients />
            </Guard>
          ),
        },
        {
          path: ConfigLink.clientDetail,
          element: (
            <Guard forceAuthenticated>
              <ClientDetail />
            </Guard>
          ),
        },
        {
          path: ConfigLink.clientServiceHistory,
          element: (
            <Guard forceAuthenticated>
              <ClientServiceHistoryDetail />
            </Guard>
          ),
        },
        {
          path: ConfigLink.clientsBulkMessageHistory,
          element: (
            <Guard forceAuthenticated>
              <BulkSmsHistory />
            </Guard>
          ),
        },
        {
          path: ConfigLink.bulkSmsDetail,
          element: (
            <Guard forceAuthenticated>
              <BulkSmsDetail />
            </Guard>
          ),
        },
        {
          path: ConfigLink.clientMessageCheckout,
          element: (
            <Guard forceAuthenticated>
              <ClientBulkSmsCheckout />
            </Guard>
          ),
        },
        {
          path: ConfigLink.shop,
          element: (
            <Guard forceAuthenticated>
              <MyShop />
            </Guard>
          ),
        },
        {
          path: ConfigLink.allShop,
          element: (
            <Guard forceAuthenticated>
              <MyShop />
            </Guard>
          ),
        },
        {
          path: ConfigLink.myShopPartDetail,
          element: (
            <Guard forceAuthenticated>
              <MyShopPartDetail />
            </Guard>
          ),
        },
      ],
    },
    {
      element: <NoProfileLayout />,
      children: [
        {
          path: ConfigLink.support,
          element: (
            <Guard forceAuthenticated>
              <Support />
            </Guard>
          ),
        },
        {
          path: ConfigLink.carStatus,
          element: (
            <Guard forceAuthenticated>
              <CarStatus />
            </Guard>
          ),
        },
      ],
    },
    {
      path: ConfigLink.selectLocation,
      element: (
        <Guard forceAuthenticated>
          <SetLocation />
        </Guard>
      ),
    },
    {
      path: ConfigLink.redirect,
      element: <RedirectPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: ConfigLink.searchWorkshops,
      element: (
        <Guard forceAuthenticated>
          <SearchWorkshops />
        </Guard>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: ConfigLink.paymentStatus,
      element: (
        <Guard forceAuthenticated>
          <PaymentStatus />
        </Guard>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: ConfigLink.registerNewUser,
      element: <RegisterNewUser />,
      errorElement: <ErrorPage />,
    },
    {
      path: ConfigLink.completeInvitation,
      element: (
        <Guard forceAuthenticated>
          <CompleteInvite />
        </Guard>
      ),
      errorElement: <ErrorPage />,
    },
    {
      element: (
        <Guard forceAuthenticated>
          <SubscriptionLayout />
        </Guard>
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          path: ConfigLink.subInfo,
          element: (
            <Guard forceAuthenticated>
              <SubInfo />
            </Guard>
          ),
        },
        {
          path: ConfigLink.compareSub,
          element: (
            <Guard forceAuthenticated>
              <SubCompare />
            </Guard>
          ),
        },
        {
          path: ConfigLink.subSelect,
          element: (
            <Guard forceAuthenticated>
              <SelectSubscription />
            </Guard>
          ),
        },
        {
          path: ConfigLink.subCheckout,
          element: (
            <Guard forceAuthenticated>
              <SubCheckout />
            </Guard>
          ),
        },
      ],
    },
  ]);

  return (
    <AuthProvider>
      <AppProvider>
        <CustomProvider>
          <React.Suspense fallback={<LoadingCustom />}>
            <ConfigFetcher>
              <RouterProvider router={router} />
            </ConfigFetcher>
          </React.Suspense>
        </CustomProvider>
      </AppProvider>
    </AuthProvider>
  );
};

export default App;
