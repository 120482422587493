/* eslint-disable */
import { ConfigLink, useQueryString } from "core";
import React from "react";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { StarRating } from "ui/_layouts/components";
import { Text } from "../../../components";
import { MiniMap } from "./MiniMap";

interface IWorkshopCardProps {
  data: any;
}

const WorkshopCard: React.FC<IWorkshopCardProps> = React.memo(({ data }) => {
  const navigate = useNavigate();

  const showMoreHandler = () => {
    navigate({ pathname: ConfigLink.workshopInfo, search: useQueryString({ id: data.id }) });
  };

  return (
    <div className="w-full mb-2 bg-white ts-shadow p-3 relative rounded-xl flex flex-col justify-center items-center">
      <div style={{ height: "109px" }} className="w-full rounded-xl mb-3 object-cover">
        <MiniMap data={data} id={"owner-mini-map"} />
      </div>

      <div className="w-full flex justify-between items-end">
        <div style={{ width: "65%" }} className=" h-full flex flex-col justify-between items-start">
          <Text type="H6" className="ts-accent mb-3 truncate w-99/100">
            {data.name || "---"}
          </Text>
          <Text type="P4" className="ts-gray-06 truncate w-99/100">
            {data?.categories ? data.categories.map((item) => item.name).join(", ") : "---"}
          </Text>
        </div>
        <div
          style={{
            width: "35%",
            marginLeft: data.averageScore !== 0 ? "2px" : "",
            paddingTop: data.averageScore !== 0 ? "4px" : "",
          }}
          className="h-full flex flex-col justify-center items-center"
        >
          {data.averageScore !== 0 && (
            <div className="flex justify-center items-center -mt-1.5 ml-3">
              <Text type="P4" className="ts-gray-06 ml-1 mt-0.5">
                {data.averageScore ? parseFloat(data.averageScore).toFixed(1) : 0}
              </Text>
              <StarRating
                // @ts-ignore
                initialValue={data.averageScore ? parseFloat(data.averageScore).toFixed(1) : 0}
                size={18}
              />
            </div>
          )}
          <div
            onClick={showMoreHandler}
            className={`flex ${
              data.averageScore && data.averageScore !== 0 ? "-ml-4 ts-with-star-card" : "-ml-1"
            } justify-center items-center cursor-pointer`}
          >
            <Text type="H6" className="ts-primary ml-1 pt-0.5 w-max">
              اطلاعات بیشتر
            </Text>
            <BiChevronLeft size={23} className="ts-primary " />
          </div>
        </div>
      </div>
    </div>
  );
});

export { WorkshopCard };

const styles = {};
