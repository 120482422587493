/* eslint-disable */
import { digitsFaToEn } from "@persian-tools/persian-tools";
import { tools } from "core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "rsuite";
import { useSnapshot } from "valtio";
import { ApiInstance, ConfigLink, vState } from "../../../../core";
import { BottomModal, Button, Text } from "../../../components";
import { ConfirmKilometerUpdate } from "./ConfirmKilometerUpdate";

interface ICarKilometerRegisterModalProps {
  isOpen: boolean;
  onClose: () => void;
  justForEdit?: boolean;
  isComplete?: boolean;
  id?: string;
  km?: number;
}

const CarKilometerRegisterModal: React.FC<ICarKilometerRegisterModalProps> = React.memo(
  ({ isOpen, onClose, justForEdit = false, id = "", km = 0, isComplete = false }) => {
    const navigate = useNavigate();
    const snap = useSnapshot(vState);

    const [kilometer, setKilometer] = useState("");
    const [loading, setLoading] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    useEffect(() => {
      if (isOpen) {
        setLoading(false);
      }
    }, [isOpen]);

    const onDone = (id) => {
      navigate(
        {
          pathname: ConfigLink.carStatus.replace(":id", id),
        },
        { replace: true },
      );
      if (isComplete) {
        window.location.reload();
      }
    };

    const setPlateSession = async () => {
      setLoading(true);
      if (snap.userWorkspaceInfo.id.length === 0) {
        await ApiInstance.workshop.getWorkshop().then((res) => (vState.userWorkspaceInfo = res.data.data[0]));
      }

      await ApiInstance.sessions
        .setSession(
          +digitsFaToEn(kilometer),
          isComplete ? snap.carStatus.plate.id : snap.selectedPlate.id,
          snap.userWorkspaceInfo.id,
        )
        .then((res) => {
          console.log(res);
          vState.sessionId = res.data.data.id;
          onDone(res.data.data.id);
          closeConfirmModal();
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
        });
    };

    const updateKilometer = async (activeSessionId?: string | null) => {
      await ApiInstance.car
        .updateCarKilometer(activeSessionId ? activeSessionId : id || snap.sessionId, +digitsFaToEn(kilometer))
        .then((res) => {
          console.log(res);
          if (activeSessionId) {
            onDone(activeSessionId);
          } else {
            vState.carStatus = res.data.data;
            onClose();
          }
          closeConfirmModal();
        })
        .catch((err) => {
          console.log("err", err);
        })
        .then(() => {
          setLoading(false);
        });
    };

    const changeKilometerHandler = async () => {
      const activeSession = snap.selectedPlate.activeSession;

      if (!justForEdit && !activeSession) {
        await setPlateSession();
      } else {
        await updateKilometer(activeSession ? activeSession.id : null);
      }
    };

    const viewCarHandler = async () => {
      if (km && +km !== 0 && (+km > +kilometer || +kilometer - +km >= 10000)) {
        setConfirmModalOpen(true);
      } else {
        setLoading(true);
        changeKilometerHandler();
      }
    };

    const closeConfirmModal = () => {
      setConfirmModalOpen(false);
    };

    return (
      <>
        <BottomModal isOpen={isOpen} onClose={onClose}>
          <div className="w-full flex flex-col justify-start items-start">
            <Text type="H3" className="ts-accent">
              ثبت کارکرد
            </Text>
            <Text type="P3" className="ts-gray-08 my-4">
              {`${justForEdit ? "" : "ابتدا"} کارکرد فعلی خودرو را وارد نمایید.`}
            </Text>

            <Text type="H6" className="ts-accent mb-2">
              کیلومتر فعلی
            </Text>
            <Input
              type={tools.isIOS() ? "text" : "number"}
              pattern="[0-9]*"
              // inputMode={!isIOS() ? "numeric" : "text"}
              value={kilometer}
              required
              onChange={(e) => {
                // if (/^[\u06F0-\u06F9]*$/.test(e) || /^[0-9]*$/g.test(e)) setKilometer(e);
                setKilometer(e);
              }}
              className={`w-full rounded-xl mb-4 outline-none h-12 placeholder:text-gray-300`}
              placeholder={
                km
                  ? `کیلومتر قبلی: ${km.toString()}`
                  : snap.selectedPlate.kilometer && snap.selectedPlate.kilometer !== 0
                  ? `کیلومتر قبلی: ${snap.selectedPlate.kilometer}`
                  : "14,500"
              }
            />
            <Button
              disabled={+kilometer === 0}
              loading={loading}
              onClick={viewCarHandler}
              bcolor="primary"
              classNameContainer="w-full"
              className="w-full h-12"
            >
              {justForEdit ? " ثبت کارکرد" : " ثبت کارکرد و پذیرش"}
            </Button>
          </div>
        </BottomModal>

        <ConfirmKilometerUpdate
          isOpen={confirmModalOpen}
          lastKilometer={km}
          nextKilometer={kilometer}
          onClose={closeConfirmModal}
          onDone={changeKilometerHandler}
        />
      </>
    );
  },
);

export { CarKilometerRegisterModal };

const styles = {};
