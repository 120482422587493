/* eslint-disable */
import React, { useState } from "react";
import { HiExclamation } from "react-icons/hi";
import { tools } from "../../../../core";
import { BottomModal, Button, Text } from "../../../components";

interface IConfirmKilometerUpdateProps {
  isOpen: boolean;
  onClose: () => void;
  onDone: () => void;
  lastKilometer: string | number;
  nextKilometer: string;
}

const ConfirmKilometerUpdate: React.FC<IConfirmKilometerUpdateProps> = React.memo(
  ({ isOpen, onClose, onDone, lastKilometer, nextKilometer }) => {
    const [loading, setLoading] = useState(false);

    const registerKilometerHandler = async () => {
      setLoading(true);
      await onDone();
      setLoading(false);
    };

    return (
      <BottomModal isOpen={isOpen} onClose={onClose}>
        <div className="w-full flex flex-col justify-start items-start">
          <span className="flex justify-center items-center">
            <HiExclamation size={22} className="ts-warning" />
            <Text type="H4" className="ts-accent mr-2">
              بروز رسانی کارکرد
            </Text>
          </span>
          <Text type="P3" className="ts-gray-08 my-4">
            آیا از درستی کیلومتر وارد شده اطمینان دارید؟!
          </Text>

          <Text type="P3" className="ts-gray-08 mb-3">
            کیلومتر قبلی : <b>{tools.thousandSeparator(lastKilometer)}</b>
          </Text>

          <Text type="P3" className="ts-gray-08 mb-4">
            کیلومتر فعلی : <b>{tools.thousandSeparator(nextKilometer)}</b>
          </Text>

          <div className="w-full flex justify-between items-center gap-1.5">
            <Button onClick={onClose} bcolor="blackTransparent" classNameContainer="w-full" className="w-full h-12">
              لغو ویرایش
            </Button>
            <Button
              loading={loading}
              onClick={registerKilometerHandler}
              bcolor="primary"
              classNameContainer="w-full"
              className="w-full h-12"
            >
              بله، ثبت کیلومتر
            </Button>
          </div>
        </div>
      </BottomModal>
    );
  },
);

export { ConfirmKilometerUpdate };

const styles = {};
