/* eslint-disable */
import { ApiInstance, ServiceStorage, tools, useChangeTitle, useObjectState, vState } from "core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, ContainerScroll, DesktopTitle, InputLicensePlate, Text, useNotification } from "ui/components";
import { PlateAddLoader, PlateRegisterModal } from "ui/views/Car/components";
import { useSnapshot } from "valtio";
import { RecentlySearchedCard } from "./components";

interface IAdmissionProps {}

const Admission: React.FC<IAdmissionProps> = React.memo(() => {
  const metaTags = useChangeTitle("ویراژ - پذیرش");
  const navigate = useNavigate();
  const snap = useSnapshot(vState);

  const [plateRegisterModal, setPlateRegisterModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [screenLoading, setScreenLoading] = useState(true);

  const [plateValue, setPlateValue] = useObjectState({
    firstSection: "",
    secondSection: "",
    thirdSection: "",
    letter: "ا",
  });
  const { firstSection, letter, secondSection, thirdSection } = plateValue;

  const [isRegistered, setIsRegistered] = useState(false);

  useEffect(() => {
    const { firstSection, secondSection, thirdSection } = plateValue;
    setDisabled(firstSection.length !== 2 || secondSection.length !== 3 || thirdSection.length !== 2);
  }, [plateValue]);

  useEffect(() => {
    const recently: any = ServiceStorage.getRecentlySearches();
    if (recently) {
      vState.recentlySearches = recently;
    }
    setTimeout(() => {
      setScreenLoading(false);
    }, 1200);
  }, []);

  const checkStateValue = (state: string, operation: () => void) => {
    if (plateValue[state].length === 0) {
      operation();
    }
  };

  const saveToRecentlyList = () => {
    const list = [...snap.recentlySearches];
    const { firstSection, letter, secondSection, thirdSection } = plateValue;

    const plateNumber = tools.getFormattedPlate(firstSection, letter, secondSection, thirdSection);

    const savedBefore = list.findIndex((item) => {
      return item.plateNumber === plateNumber;
    });
    if (savedBefore === -1) {
      list.push({
        id: list.length + 1,
        plateNumber: plateNumber,
      });
    }
    ServiceStorage.setRecentlySearches(list);
    vState.recentlySearches = list;
  };

  const searchBtnClick = async () => {
    setLoading(true);

    const plate = tools.getFormattedPlate(firstSection, letter, secondSection, thirdSection);
    await ApiInstance.plates
      .getPlate(plate)
      .then((res) => {
        console.log(res);
        if (!res.data.meta.found) {
          setIsRegistered(false);
        } else {
          setIsRegistered(true);
          vState.selectedPlate = res.data.data;
        }
        saveToRecentlyList();
        setPlateRegisterModal(true);
      })
      .catch((err) => {
        console.log("err", err);
        useNotification("مشکلی رخ داده است. لطفا مجددا تلاش کنید.", "error", "خطا");
      })
      .then(() => {
        setLoading(false);
      });
  };

  const recentSearchClick = async (plate) => {
    setPlateValue({
      firstSection: plate.slice(0, 2),
      secondSection: plate.substring(3, 6),
      thirdSection: plate.substring(7, 9),
      letter: plate.slice(2, 3),
    });
  };

  const deleteRecentHandler = (plate) => {
    const list = snap.recentlySearches.filter((item) => item.plateNumber !== plate);
    ServiceStorage.setRecentlySearches(list);
    vState.recentlySearches = list;
  };

  const deleteAllRecentHandler = () => {
    vState.recentlySearches = [];
    ServiceStorage.removeRecentlySearches();
  };

  const setPlateValues = (e, place) => {
    setPlateValue({ [place]: e });
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={160} className={`relative overflow-x-hidden`}>
        <DesktopTitle title="پذیرش و ترخیص" />
        {screenLoading ? (
          <PlateAddLoader />
        ) : (
          <>
            <Text type="H4" className="ts-accent mb-6">
              پذیرش{" "}
            </Text>

            <Text type="H6" className="ts-accent mb-2">
              شماره پلاک
            </Text>

            <InputLicensePlate
              plateValue={plateValue}
              setPlateValues={setPlateValues}
              checkStateValue={checkStateValue}
              letterChange={(value) => setPlateValue({ letter: value })}
            />

            <Button
              onClick={searchBtnClick}
              bcolor="primary"
              classNameContainer="w-full lg:w-1/2"
              className="w-full h-12 rounded-xl mb-12"
              disabled={disabled}
              loading={loading}
            >
              جستجو
            </Button>

            {/* ----------- RECENTLY SEARCHES ----------- */}

            <div className="w-full flex flex-col">
              {snap.recentlySearches.length !== 0 && (
                <div className="w-full flex justify-between items-center mb-6">
                  <Text type="P4" className="ts-accent">
                    جستجوهای اخیر
                  </Text>
                  <Text onClick={deleteAllRecentHandler} type="P4" className="ts-secondary-red cursor-pointer">
                    حذف همه
                  </Text>
                </div>
              )}
              {snap.recentlySearches
                .slice(0)
                .reverse()
                .map((item, index) => {
                  return (
                    <>
                      <RecentlySearchedCard
                        key={item.id}
                        plateNumber={item.plateNumber}
                        onClick={() => recentSearchClick(item.plateNumber)}
                        onDelete={() => deleteRecentHandler(item.plateNumber)}
                      />
                      {snap.recentlySearches.length > index + 1 && <hr className="w-full ts-recently-hr" />}
                    </>
                  );
                })}
            </div>
          </>
        )}
      </ContainerScroll>

      <PlateRegisterModal
        plate={tools.getFormattedPlate(firstSection, letter, secondSection, thirdSection)}
        isOpen={plateRegisterModal}
        isRegistered={isRegistered}
        onClose={() => setPlateRegisterModal(false)}
      />
    </>
  );
});

export { Admission as default };

const styles = {};
