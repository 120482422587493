/* eslint-disable */
import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Modal } from "rsuite";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { configs } from "./../../configs/configs";

interface IImageViewerModalProps {
  images: any[];
  dir: string;
  isOpen: boolean;
  onClose: () => void;
  activeIndex: number;
}

const ImageViewerModal: React.FC<IImageViewerModalProps> = React.memo(
  ({ images, dir, isOpen, onClose, activeIndex }) => {
    useEffect(() => {}, []);

    const [loading, setLoading] = useState(true);

    return (
      <Modal
        open={isOpen}
        onClose={onClose}
        backdrop
        backdropStyle={{ background: "rgba(39, 45, 55, 0.8)" }}
        className="m-0 p-0 w-full h-full ts-image-viewer-wrapper"
        dialogClassName={`w-full h-full m-0 p-0 ts-hide-scroll-but-keep-working bg-black bg-opacity-60 flex justify-center items-center relative`}
      >
        <div
          onClick={onClose}
          className="w-8 h-8 flex justify-center items-center rounded-xl ts-shadow cursor-pointer ts-bg-gray-03 z-40 absolute top-4 left-4"
        >
          <MdClose size={23} className="ts-primary cursor-pointer" />
        </div>
        <div className="w-full h-full bg-transparent flex justify-center items-center relative">
          <Swiper
            initialSlide={activeIndex}
            loop
            lazy
            navigation
            modules={[Navigation]}
            className="ts-image-swiper-wrapper"
          >
            {images.map((item, idx) => {
              return (
                <SwiperSlide className="w-full">
                  <img src={`${configs().imageUrl}/${item}`} alt={`${dir}-${idx}`} className="w-full" />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </Modal>
    );
  },
);

export { ImageViewerModal };

const styles = {};
