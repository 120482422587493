/* eslint-disable */
import noSegment from "assets/images/no-segment-requested.svg";
import { ApiInstance, useChangeTitle, vState } from "core";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Loader } from "rsuite";
import { Button, ContainerScroll, Text, useNotification } from "ui/components";
import { useSnapshot } from "valtio";
import { AddPartRequestModal, MyPartRequestCard } from "./components";

const MyPartRequests = () => {
  const metaTags = useChangeTitle("ویراژ - درخواست قطعه");
  const snap = useSnapshot(vState);

  const [pageLoading, setPageLoading] = useState(true);
  const [count, setCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [requests, setRequests] = useState<any[]>([]);
  const [addRequestModal, setAddRequestModal] = useState(false);

  const getRequests = async (pageIncreased: boolean) => {
    await ApiInstance.partSupply
      .getRequests(pageCount + 1, null)
      .then((res) => {
        console.log(res);
        setCount(res.data.meta.total);
        if (pageIncreased) {
          let data = [...requests, ...res.data.data];
          setRequests(data);
          setPageCount((prev) => prev + 1);
        } else {
          setRequests(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error");
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  useEffect(() => {
    getRequests(false);
  }, []);

  useEffect(() => {
    if (snap.isEndOfPage && count > requests.length && !pageLoading) {
      getRequests(true);
    } else {
      vState.isEndOfPage = false;
    }
  }, [snap.isEndOfPage]);

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={90} className="">
        <div className="w-full flex justify-between items-start mb-6">
          <div className="w-full lg:w-auto flex flex-col justify-start items-start">
            <Text type="H4" className="ts-accent mt-3 mb-4">
              درخواست قطعه
            </Text>
            <Text type="P4" className="ts-gray-06">
              در این قسمت می‌توانید قطعات کمیابی که در بازار به سختی یافت می‌شوند را درخواست دهید.
            </Text>
          </div>
          <Button
            onClick={() => setAddRequestModal(true)}
            bcolor="primary"
            textType="H6"
            classNameContainer="w-56 hidden lg:block"
            className="w-full h-12 rounded-xl"
          >
            ثبت درخواست قطعه
          </Button>
        </div>
        {pageLoading ? (
          [1, 2, 3, 4].map((item) => {
            return (
              <Skeleton key={item} containerClassName="w-full" className="w-full h-24 ts-part-card rounded-2xl mb-2" />
            );
          })
        ) : requests.length === 0 ? (
          <div style={{ height: "50vh" }} className="w-full flex flex-col justify-center items-center">
            <img src={noSegment} alt="no-part" className="mb-6" />
            <Text type="P4" className="ts-gray-07">
              درخواست قطعه‌ای تاکنون ثبت نشده است.
            </Text>
          </div>
        ) : (
          requests.map((item: any) => {
            return <MyPartRequestCard key={item.id} data={item} />;
          })
        )}

        {snap.isEndOfPage && !pageLoading && (
          <div className="w-full flex justify-center my-6">
            <Loader size="xs" />
          </div>
        )}
      </ContainerScroll>
      <Button
        onClick={() => setAddRequestModal(true)}
        bcolor="primary"
        textType="H6"
        classNameContainer="ts-float-button bottom-6 lg:hidden"
        className="w-full h-12 rounded-xl"
      >
        ثبت درخواست قطعه
      </Button>
      <AddPartRequestModal
        type={"add"}
        isOpen={addRequestModal}
        onDone={() => getRequests(false)}
        onClose={() => setAddRequestModal(false)}
      />
    </>
  );
};

export { MyPartRequests as default };
