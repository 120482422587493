/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { ApiInstance, ConfigLink, vState } from "../../../../core";
import { BottomModal, Button, Text, useNotification } from "../../../components";

interface ITerminateSessionProps {
  isOpen: boolean;
  onClose: () => void;
  id: string;
}

const TerminateSession: React.FC<ITerminateSessionProps> = React.memo(({ isOpen, onClose, id }) => {
  const navigate = useNavigate();
  const snap = useSnapshot(vState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  const cancelSessionHandler = async () => {
    setLoading(true);
    await ApiInstance.sessions
      .removeSession(id, false)
      .then((res) => {
        console.log(res);
        useNotification("پذیرش خودروی مورد نظر لغو شد.", "success");
        setTimeout(() => {
          navigate(ConfigLink.dashboard, { replace: true });
          onClose();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <BottomModal isOpen={isOpen} onClose={onClose}>
      <div className="w-full flex flex-col justify-start items-start">
        <Text type="H4" className="ts-accent mb-4">
          انصراف از پذیرش خودرو
        </Text>
        <Text type="P3" className="ts-gray-08 mb-4">
          در صورت انصراف از پذیرش خودرو هیچ تغییری برای خودرو اعمال نخواهد شد.
        </Text>
        <div className=" w-full flex flex-col justify-center items-center">
          <Button
            onClick={cancelSessionHandler}
            loading={loading}
            bcolor="redFill"
            textType="H6"
            classNameContainer="w-full"
            className="w-full rounded-xl h-12"
          >
            انصراف و لغو تغییرات
          </Button>

          <Button
            onClick={() => onClose()}
            bcolor="blackTransparent"
            textType="H6"
            classNameContainer="w-full"
            className="w-full rounded-xl h-12 mt-2"
          >
            بازگشت و ادامه
          </Button>
        </div>
      </div>
    </BottomModal>
  );
});

export { TerminateSession };

const styles = {};
