/* eslint-disable */
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { vState } from "../../../../core";
import { Text } from "../../../components";

interface IGradientButtonProps {
  icon: any;
  label: string;
  onClick: () => void;
  isNew?: boolean;
}

const GradientButton: React.FC<IGradientButtonProps> = React.memo(({ icon, label, onClick, isNew = false }) => {
  const snap = useSnapshot(vState);
  const history = useNavigate();

  return (
    <span
      onClick={onClick}
      className="relative flex flex-col justify-center items-center rounded-xl ts-shadow cursor-pointer ts-most-used-btn h-28 p-4"
    >
      <img src={icon} alt="icon" className="w-12 h-12" />
      <Text type="P4" className="ts-accent mt-3">
        {label}
      </Text>

      {isNew && (
        <span className="rounded-full ts-bg-accent flex justify-center items-center py-0.5 px-3 absolute -top-2 left-0">
          <Text type="HM" className="text-white">
            جدید
          </Text>
        </span>
      )}
    </span>
  );
});

export { GradientButton };
