import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { useMap } from "react-leaflet";
import markerImg from "../../../../assets/images/marker.svg";
import L from "leaflet";
import { useNotification } from "ui/components";

interface ISetLocationProps {
  currentLocation: number[] | null;
  onDone: (location: number[]) => void;
  ref: any;
}

const MarkerComponent: React.FC<ISetLocationProps> = forwardRef((props, ref) => {
  const map = useMap();

  // useEffect(() => {
  //   setUserLocation(props.userLocation);
  // }, [props.userLocation]);

  useImperativeHandle(ref, () => ({
    moveToUserLocation(location) {
      console.log("moving...");
      map.flyTo(location, 18);
    },
  }));

  let mapIcon = L.icon({
    iconUrl: markerImg,
    iconSize: [40, 48],
    iconAnchor: [20, 50],
  });
  let marker = L.marker(map.getCenter(), {
    icon: mapIcon,
    autoPan: true,
  }).addTo(map);
  map.on("move", () => {
    marker.setLatLng(map.getCenter());
  });
  map.on(
    "moveend",
    useCallback(() => {
      const pos = map.getCenter();
      props.onDone([pos.lat, pos.lng]);
      console.log("moveend");
    }, []),
  );

  // map
  //   .locate({
  //     setView: true,
  //     watch: false,
  //     timeout: 10000,
  //     enableHighAccuracy: true,
  //   })
  //   .on(
  //     "locationfound",
  //     useCallback((e) => {
  //       console.log(e);
  //       map.flyTo([e.latlng.lat, e.latlng.lng], 18);
  //     }, []),
  //   );
  // .on(
  //   "locationerror",
  //   useCallback(() => {
  //     console.log("leaflet locationerror");

  //     useNotification(
  //       "امکان نمایش موقعیت مکانی شما وجود ندارد. لطفا تنظیمات گوشی خود را بررسی نمایید.",
  //       "error",
  //       "خطا",
  //     );
  //   }, []),
  // );

  return null;
});

export { MarkerComponent };
