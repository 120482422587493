/* eslint-disable */
import admission from "assets/images/menu-icons/sticky-admission.png";
import customers from "assets/images/menu-icons/sticky-customers.png";
import equipment from "assets/images/menu-icons/sticky-equipment.png";
import home from "assets/images/menu-icons/sticky-home.png";
import parts from "assets/images/menu-icons/sticky-parts.png";
import repairShop from "assets/images/menu-icons/sticky-repair-shop.png";
import request from "assets/images/menu-icons/sticky-request.png";
import search from "assets/images/menu-icons/sticky-search.png";
import store from "assets/images/menu-icons/sticky-store.png";
import { useAuth } from "hooks";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Text } from "ui/components";
import { useSnapshot } from "valtio";
import { ConfigLink, vState } from "../../../core";

interface IStickyMenuProps {
  active?: string;
}

const StickyMenu: React.FC<IStickyMenuProps> = React.memo(({ active = "" }) => {
  const snap = useSnapshot(vState);
  const navigate = useNavigate();
  let location = useLocation();
  const auth = useAuth();
  const url = window.location.pathname;
  const operator = snap.userInfo.roles && snap.userInfo.roles.indexOf("operator") !== -1;
  const earlyAdopter = snap.userInfo.roles && snap.userInfo.roles.indexOf("shop-early-adopter") !== -1;

  const [activeUrl, setActiveUrl] = useState("");

  React.useLayoutEffect(() => {
    setActiveUrl(location.pathname);
  }, [location]);

  useEffect(() => {
    setActiveUrl(url);
  }, []);

  const menuItems = [
    {
      id: 0,
      name: "خانه",
      icon: home,
      isVisible: true,
      path: ConfigLink.homePage,
      search: "",
    },
    {
      id: 1,
      name: "پذیرش و ترخیص",
      icon: admission,
      isVisible: snap.activeFeatures.includes("admission_discharge"),
      path: ConfigLink.dashboard,
      search: "",
    },
    {
      id: 2,
      name: "مشتریان من",
      icon: customers,
      isVisible: snap.activeFeatures.includes("contact_list"),
      path: ConfigLink.workshopClients,
      search: "",
    },
    {
      id: 3,
      name: "درخواستهای مشتریان",
      icon: request,
      isVisible: snap.activeFeatures.includes("part_order_list"),
      path: ConfigLink.usersPartRequests,
      search: "",
    },
    {
      id: 4,
      name: "فروشگاه قطعات من",
      icon: store,
      isVisible: snap.activeFeatures.includes("shop"),
      path: ConfigLink.shop,
      search: "",
    },
    {
      id: 5,
      name: "جستجوی کسب‌و‌کار",
      icon: search,
      isVisible: snap.activeFeatures.includes("workshop_search"),
      path: ConfigLink.searchWorkshops,
      search: "",
    },
    {
      id: 6,
      name: "جستجوی قطعات یدکی",
      icon: repairShop,
      isVisible: snap.activeFeatures.includes("shop_search"),
      path: `${ConfigLink.allShop}`,
    },
    {
      id: 7,
      name: "جستجوی قطعات کمیاب",
      icon: parts,
      isVisible: snap.activeFeatures.includes("part_order"),
      path: ConfigLink.myPartRequest,
      search: "",
    },
    {
      id: 8,
      name: "جستجوی تجهیزات",
      icon: equipment,
      isVisible: snap.activeFeatures.includes("equipment_search"),
      path: "/soon",
      search: "",
    },
  ];

  return (
    <div className={`ts-sticky-menu ${auth && auth.authenticated ? "" : "hidden"}`}>
      {menuItems.map((item) => {
        const isActive =
          item.path === activeUrl || active === activeUrl || (activeUrl.includes(item.path) && item.name !== "خانه");

        if (item.isVisible) {
          return (
            <span
              key={item.id}
              onClick={() => {
                navigate(item.path);
              }}
              className={`cursor-pointer w-full h-14 rounded-xl p-3 ${
                isActive ? "ts-bg-gray-01" : ""
              } flex justify-start items-center`}
            >
              <img src={item.icon} alt={item.name} className={`w-6 h-6 ${isActive ? "" : "grayscale"}`} />
              <Text type={isActive ? "H6" : "P4"} className={`${isActive ? "ts-primary" : "ts-accent"} mr-2`}>
                {item.name}
              </Text>
            </span>
          );
        }
      })}
    </div>
  );
});

export { StickyMenu };
