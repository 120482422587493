/* eslint-disable */
import { digitsFaToEn } from "@persian-tools/persian-tools";
import { kilometerAverages } from "hooks";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { SelectPicker } from "rsuite";
import { useSnapshot } from "valtio";
import { ApiInstance, tools, useObjectState, vState } from "../../../../core";
import { BottomModal, Button, InputField, InputLicensePlate, Text, useNotification } from "../../../components";

interface IEditCarPropsProps {
  isOpen: boolean;
  onClose: () => void;
  data: any;
  noPhoneNumber?: boolean;
}

const env = import.meta.env;

const EditCarProps: React.FC<IEditCarPropsProps> = React.memo(({ isOpen, onClose, data, noPhoneNumber = false }) => {
  const defaultError = "پر کردن این فیلد الزامی می‌باشد.";

  const snap = useSnapshot(vState);
  const history = useNavigate();

  const [plateValue, setPlateValue] = useObjectState({
    firstSection: "",
    secondSection: "",
    thirdSection: "",
    letter: "ا",
  });
  const { firstSection, secondSection, thirdSection } = plateValue;

  const [info, setInfo] = useObjectState({
    carName: "",
    carId: "",
    mobile: "",
    nameFamily: "",
    monthlyUsage: "",
  });
  const { carName, mobile } = info;
  const [disabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const [phoneError, setPhoneError] = useState(false);

  const isSmallMobile = useMediaQuery({
    query: "(max-width: 380px)",
  });

  useEffect(() => {
    setPlateValue({
      firstSection: data.plate.plateNumber.slice(0, 2),
      secondSection: data.plate.plateNumber.substring(3, 6),
      thirdSection: data.plate.plateNumber.substring(7, 9),
      letter: data.plate.plateNumber.slice(2, 3),
    });
    setInfo({
      carName: data.plate.carName || "",
      carId: data.plate.id,
      mobile: data.plate.mobile || "",
      nameFamily: data.plate.ownerName || "",
      monthlyUsage: data.plate.monthlyUsage || "",
    });
  }, [isOpen]);

  const editProps = async () => {
    setLoading(true);

    const { firstSection, letter, secondSection, thirdSection } = plateValue;
    const plate = tools.getFormattedPlate(firstSection, letter, secondSection, thirdSection);
    let editObj: any = {
      plateNumber: plate,
      ...(!noPhoneNumber && info.mobile ? { mobile: digitsFaToEn(info.mobile) } : undefined),
      // mobile: info.mobile ? digitsFaToEn(info.mobile) : null,
      carName: info.carName || null,
      ownerName: info.nameFamily || null,
      monthlyUsage: info.monthlyUsage || null,
    };
    await ApiInstance.plates
      .updateCarProperties(info.carId, editObj)
      .then((res) => {
        console.log(res);
        if (res.status === 200 || res.status === 201) {
          const data = {
            carName: res.data.data.carName || "",
            ...(!noPhoneNumber && res.data.data.mobile ? { mobile: digitsFaToEn(res.data.data.mobile) } : undefined),
            ownerName: res.data.data.ownerName || "",
            plateNumber: res.data.data.plateNumber || "",
            monthlyUsage: res.data.data.monthlyUsage || "",
          };
          vState.carStatus.plate = { ...snap.carStatus.plate, ...data };

          useNotification("اطلاعات تغییر یافت.", "success", "ویرایش مشخصات");
          setTimeout(() => {
            onClose();
          }, 1500);
        } else {
          useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "ویرایش مشخصات");
        }
      })
      .catch((err) => {
        console.log("err", err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "ویرایش مشخصات");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const registerClickHandler = async () => {
    let errorsObj = { ...errors };
    if (carName.length === 0) {
      errorsObj.carName = defaultError;
    }
    if (!noPhoneNumber) {
      if (mobile.length === 0) {
        errorsObj.mobile = defaultError;
      } else if (mobile.length !== 11 || digitsFaToEn(mobile.slice(0, 2)) !== "09") {
        errorsObj.mobile = "شماره وارد شده صحیح نیست";
      }
    }
    if (firstSection.length !== 2 || secondSection.length !== 3 || thirdSection.length !== 2) {
      errorsObj.plate = defaultError;
    }
    if (Object.keys(errorsObj).length !== 0) {
      useNotification("لطفا فیلدهای مشخص شده را پر کنید.", "warning");
      setErrors(errorsObj);
    } else {
      editProps();
    }
  };

  const setPlateValues = (e, place) => {
    if (/^[\u06F0-\u06F9]*$/.test(e) || /^[0-9]*$/g.test(e)) {
      if ("plate" in errors) {
        removeField("plate");
      }
      setPlateValue({ [place]: e });
    }
  };

  const checkStateValue = (state: string, operation: () => void) => {
    if (plateValue[state].length === 0) {
      operation();
    }
  };

  const removeField = (field) => {
    let cn = { ...errors };
    delete cn[field];
    setErrors(cn);
  };

  return (
    <BottomModal isOpen={isOpen} onClose={onClose}>
      <div className="w-full text-right">
        <Text type="H4" className="ts-accent mb-6">
          ویرایش مشخصات خودرو
        </Text>

        <Text type="H6" className="ts-accent mb-2">
          شماره پلاک وارد شده
        </Text>
        {isOpen && (
          <InputLicensePlate
            plateValue={plateValue}
            setPlateValues={setPlateValues}
            checkStateValue={checkStateValue}
            letterChange={(value) => setPlateValue({ letter: value })}
            forEdit
            error={"plate" in errors}
          />
        )}

        <InputField
          label="خودرو"
          value={carName}
          onChange={(e) => setInfo({ carName: e })}
          onFocus={() => removeField("carName")}
          placeholder="نام و مدل خودرو"
          error={"carName" in errors}
          errorText={errors.carName || ""}
        />

        <Text type="H6" className="ts-accent mb-2">
          متوسط کارکرد خودرو در ماه (اختیاری)
        </Text>
        <SelectPicker
          size="lg"
          placement="auto"
          data={kilometerAverages}
          value={info.monthlyUsage}
          onChange={(e) => setInfo({ monthlyUsage: e })}
          block
          searchable={false}
          cleanable={false}
          placeholder="متوسط کارکرد خودروی خود را انتخاب کنید"
          className={`w-full h-12 mb-6 outline-none rounded-xl`}
        />

        {!noPhoneNumber && (
          <InputField
            label="شماره تماس"
            inputType="number"
            value={mobile}
            onChange={(e) => {
              if (e.length <= 11) {
                setInfo({ mobile: e });
              }
            }}
            onFocus={() => removeField("mobile")}
            placeholder="09000000000"
            error={"mobile" in errors}
            errorText={errors.mobile || ""}
          />
        )}

        {/* <InputField
          label=" نام و نام خانوادگی (اختیاری)"
          value={info.nameFamily}
          name="ownerName"
          onChange={(e) => setInfo({ nameFamily: e })}
          placeholder="نام و نام خانوادگی"
        /> */}

        <Button
          onClick={registerClickHandler}
          loading={loading}
          disabled={disabled}
          bcolor="primary"
          classNameContainer="w-full"
          className="w-full h-12"
        >
          ذخیره
        </Button>
      </div>
    </BottomModal>
  );
});

export { EditCarProps };
