import { ConfigLink, vState } from "core";
import { useAuth } from "hooks";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UnauthorizedAccess from "ui/views/ErrorPages/UnauthorizedAccess";
import { useSnapshot } from "valtio";
import { LoadingCustom } from "./LoadingPage";

interface GuardProps {
  forceAuthenticated?: boolean;
  forceUnauthenticated?: boolean;
  forceNotLargeScreen?: boolean;
  children: any;
}

export const Guard: FC<GuardProps> = ({ children, forceAuthenticated, forceUnauthenticated, forceNotLargeScreen }) => {
  // show loading if we need to wait for authentication service
  const [ready, setReady] = useState<boolean>(forceAuthenticated === undefined && forceUnauthenticated === undefined);
  const auth = useAuth();
  const navigate = useNavigate();
  const snap = useSnapshot(vState);

  useEffect(() => {
    if (auth.ready) {
      setReady(true);

      if (!auth.authenticated && forceAuthenticated) {
        navigate(ConfigLink.intro);
      } else if (auth.authenticated && forceUnauthenticated) {
        navigate(ConfigLink.homePage);
      }
    }
  }, [auth.ready, auth.authenticated]);

  // show loading if authentication result is not ready yet
  if (!ready) {
    return <LoadingCustom />;
  }

  // if (isLargeScreen && forceNotLargeScreen) {
  //   return <LargeScreenWarn />;
  // }

  if (snap.unauthorizedAccess) {
    return <UnauthorizedAccess />;
  }

  return <>{children}</>;
};
