/* eslint-disable */
import React from "react";
import { Radio } from "rsuite";
import { Text } from "../../../components";

interface IAccessLevelCardProps {
  data: { label: string; value: string | null; description: string };
  onClick: () => void;
  accessLevel: string;
}

const AccessLevelCard: React.FC<IAccessLevelCardProps> = React.memo(({ data, onClick, accessLevel }) => {
  return (
    <div
      onClick={onClick}
      key={data.value}
      className="w-full cursor-pointer ts-shadow rounded-lg mb-2 p-3 flex flex-col justify-start items-start"
    >
      <Radio checked={accessLevel === data.value} className="ts-text-h6">
        {data.label}
      </Radio>
      <Text type="P4" className="mt-2 ts-gray-07">
        {data.description}
      </Text>
    </div>
  );
});
export { AccessLevelCard };

const styles = {};
