/* eslint-disable */
import React, { useEffect } from "react";
import { Badge, Button as RButton, ButtonProps } from "rsuite";
import { Text, TEXT_TYPE } from "../";

const COLOR_TYPE = {
  primary: "primary",
  primaryLight: "primaryLight",
  primaryTransparent: "primaryTransparent",
  black: "black",
  blackTransparent: "blackTransparent",
  white: "white",
  whiteTransparent: "whiteTransparent",
  transparent: "transparent",
  green: "green",
  red: "red",
  redFill: "redFill",
  gray: "gray",
} as const;
type COLOR_TYPE = (typeof COLOR_TYPE)[keyof typeof COLOR_TYPE];

const ICON_POS_TYPE = {
  beforeText: "beforeText",
  afterText: "afterText",
} as const;
type ICON_POS_TYPE = (typeof ICON_POS_TYPE)[keyof typeof ICON_POS_TYPE];

interface IButtonProps extends ButtonProps {
  bcolor?: COLOR_TYPE;
  dicPattern?: string | any[];
  className?: string;
  classNameContainer?: string;
  textType?: TEXT_TYPE;
  icon?: JSX.Element;
  iconPosition?: ICON_POS_TYPE;
  fluid?: boolean;
  showBadgeOnIcon?: boolean;
  disabled?: boolean;
  style?: any;
}

const Button: React.FC<IButtonProps> = ({
  bcolor,
  icon,
  iconPosition = "beforeText",
  dicPattern,
  className,
  textType = "H6",
  classNameContainer,
  style = {},
  children,
  fluid = false,
  showBadgeOnIcon = false,
  disabled = false,
  ...restProps
}) => {
  useEffect(() => {}, []);

  const IconComponent = icon && React.cloneElement(icon, { className: `icon mx-1 ${icon.props.className}` });

  return (
    <div className={`ts-button ${classNameContainer}`}>
      <RButton
        block={fluid}
        size="md"
        disabled={disabled}
        {...restProps}
        className={`${
          bcolor === "primary"
            ? "primary"
            : bcolor === "primaryLight"
            ? "primary-light"
            : bcolor === "primaryTransparent"
            ? "primary-transparent"
            : bcolor === "black"
            ? "black"
            : bcolor === "blackTransparent"
            ? "blackTransparent"
            : bcolor === "white"
            ? "white"
            : bcolor === "whiteTransparent"
            ? "whiteTransparent"
            : bcolor === "transparent"
            ? "trans-transparent"
            : bcolor === "green"
            ? "green"
            : bcolor === "red"
            ? "red"
            : bcolor === "redFill"
            ? "redFill"
            : bcolor === "gray"
            ? "gray"
            : "default"
        } rounded-xl ${className}`}
        style={{ ...style }}
      >
        <div className={`flex flex-row justify-center items-center ${disabled ? "disabled" : ""}`}>
          {iconPosition === "beforeText" && !restProps.loading && IconComponent}
          {/* {children?.valueOf() !== "string" ? (
            children
          ) : ( */}
          {!restProps.loading && (
            <Text type={textType} className={`${disabled ? "disabled" : ""} pt-0.5 ts-letter-space-min`}>
              {children}
            </Text>
          )}
          {/* )} */}
          {iconPosition === "afterText" &&
            !restProps.loading &&
            (showBadgeOnIcon ? <Badge>{IconComponent}</Badge> : IconComponent)}
        </div>
      </RButton>
    </div>
  );
};

export { Button };

const styles = {};
