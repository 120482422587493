/* eslint-disable */
import { tools, vState } from "core";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import image from "../../assets/images/sub-active-confirm.svg";
import { BottomModal, Button, Hooray, Text } from "./";

type deleteType = "delete" | "logout";

interface ISuccessfulSubscriptionProps {
  isOpen: boolean;
  onClose: () => void;
  newUrl: string;
}

const SuccessfulSubscription: React.FC<ISuccessfulSubscriptionProps> = React.memo(({ isOpen, onClose, newUrl }) => {
  const navigate = useNavigate();
  const snap = useSnapshot(vState);

  const closeHandler = () => {
    navigate(newUrl, { replace: true });
    onClose();
  };

  const packages = [
    {
      name: "1month",
      alias: "یک ماه",
    },
    {
      name: "6month",
      alias: "شش ماه",
    },
    {
      name: "1year",
      alias: "یک سال",
    },
  ];

  const packageType = snap.userInfo.premium
    ? packages.filter((item) => item.name === snap.userInfo.premium?.package)
    : [];
  const packageName = packageType.length !== 0 ? packageType[0].alias : "نامشخص";
  return (
    <BottomModal centerScreen staticBackdrop isOpen={isOpen} onClose={onClose} className={"pb-7"}>
      {isOpen && <Hooray />}
      <img src={image} className="mx-auto" />
      <Text type="H4" className="ts-accent text-center mt-6 mb-4">
        سرویس ویژه فعال شد :)
      </Text>

      {snap.userInfo.premium && (
        <Text type="P4" className="ts-accent mb-6">
          سرویس ویژه ویراژ، از امروز تا تاریخ {tools.getSimpleDate(snap.userInfo.premium?.expiresAt) || ""} به مدت{" "}
          {packageName} ، برای شما فعال خواهد بود.
        </Text>
      )}
      <Button
        onClick={closeHandler}
        bcolor="primary"
        textType="H6"
        classNameContainer="w-full"
        className="w-full rounded-xl h-12"
      >
        متوجه شدم
      </Button>
    </BottomModal>
  );
});

export { SuccessfulSubscription };

const styles = {};
