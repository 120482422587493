/* eslint-disable */
import React from "react";
import { Text } from "./";

interface ICharacterLimitProps {
  field: string;
  limit?: number;
}

const CharacterLimit: React.FC<ICharacterLimitProps> = React.memo(({ field, limit = 25 }) => {
  return (
    <div className="w-full flex justify-between items-center mb-6">
      <Text type="P5" className="ts-gray-07">
        حداکثر {limit} کاراکتر
      </Text>
      <Text type="P5" className="ts-gray-07">{`${limit} / ${field.length}`}</Text>
    </div>
  );
});

export { CharacterLimit };
