/* eslint-disable */
import { ApiInstance, tools } from "core";
import React, { useEffect, useState } from "react";
import { MdContentCopy } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { BottomModal, Button, Text, useNotification } from "../../../components";

interface IPaymentDetailModalProps {
  isOpen: boolean;
  onClose: () => void;
  id: string;
}

const PaymentDetailModal: React.FC<IPaymentDetailModalProps> = React.memo(({ isOpen, onClose, id }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    amount: 0,
    cardNumber: null,
    comment: "",
    createdAt: "",
    description: "",
    factorNumber: 0,
    id: "",
    package: "",
    packageInfo: {
      title: "",
      package: "",
      taxPrice: 0,
      paidPrice: 0,
      realPrice: 0,
      taxPercent: 0,
      discountPrice: 0,
      discountPercent: 0,
      packageItemCount: 0,
      realPricePerItem: 0,
      priceWithDiscount: 0,
      pricePerItemWithDiscount: 0,
    },
    paidAmount: "",
    paymentToken: "",
    refnumber: "",
    status: "",
    trackingCode: "",
    transactionId: "",
    wage: 0,
  });

  const getPaymentDetail = async () => {
    await ApiInstance.account
      .getPaymentsDetail(id)
      .then((res) => {
        console.log(res);
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      getPaymentDetail();
    }
  }, [isOpen]);

  const copyHandler = () => {
    navigator.clipboard.writeText(data.transactionId);
    useNotification("کد پیگیری کپی شد", "info");
  };

  return (
    <BottomModal isOpen={isOpen} onClose={onClose}>
      <Text type="H4" className="ts-accent mb-4">
        جزئیات پرداخت
      </Text>

      <div className="w-full ts-shadow rounded-xl p-6 mb-4 mx-px flex flex-col justify-start items-start">
        <span className="w-full flex justify-start items-start mb-4">
          <Text type="P4" className="ts-accent w-32 ml-8">
            وضعیت تراکنش:
          </Text>
          {loading ? (
            <Skeleton className="w-16" />
          ) : (
            <Text type="P4" className="ts-accent whitespace-normal ts-payment-detail-width">
              {data.status === "failed" ? "ناموفق" : data.status === "succeeded" ? "موفق" : "در انتظار پرداخت"}
            </Text>
          )}
        </span>

        {data.transactionId && (
          <span className="w-full flex justify-start items-start mb-4">
            <Text type="P4" className="ts-accent w-32 ml-8">
              کد پیگیری:
            </Text>
            {loading ? (
              <Skeleton className="w-16" />
            ) : (
              <span onClick={copyHandler} className="flex justify-center items-center cursor-pointer">
                <MdContentCopy size={19} className="ts-accent -mt-1" />
                <Text type="P4" className="ts-accent mr-1">
                  {data.transactionId || 0}
                </Text>
              </span>
            )}
          </span>
        )}

        <span className="w-full flex justify-start items-start mb-4">
          <Text type="P4" className="ts-accent w-32 ml-8">
            نوع بسته:
          </Text>
          {loading ? (
            <Skeleton className="w-16" />
          ) : (
            <Text type="P4" className="ts-accent whitespace-normal ts-payment-detail-width">
              {data.description || "---"}
            </Text>
          )}
        </span>

        <span className="w-full flex justify-start items-start mb-4">
          <Text type="P4" className="ts-accent w-32 ml-8">
            مبلغ کل:
          </Text>
          {loading ? (
            <Skeleton className="w-16" />
          ) : (
            <Text type="P4" className="ts-accent whitespace-normal ts-payment-detail-width">
              {Object.keys(data.packageInfo).length !== 0
                ? data.packageInfo?.priceWithDiscount
                  ? `${tools.thousandSeparator(data.packageInfo?.priceWithDiscount / 10)} تومان`
                  : "---"
                : "---"}
            </Text>
          )}
        </span>

        {data.packageInfo && data.packageInfo?.discountPrice ? (
          <span className="w-full flex justify-start items-start mb-4">
            <Text type="P4" className="ts-accent w-32 ml-8">
              تخفیف:
            </Text>
            {loading ? (
              <Skeleton className="w-16" />
            ) : (
              <Text type="P4" className="ts-accent whitespace-normal ts-payment-detail-width">
                {Object.keys(data.packageInfo).length !== 0
                  ? data.packageInfo?.discountPrice
                    ? `${tools.thousandSeparator(data.packageInfo?.discountPrice / 10)} تومان(${
                        data.packageInfo?.discountPercent * 100
                      }%)`
                    : "---"
                  : "---"}
              </Text>
            )}
          </span>
        ) : null}

        {data.packageInfo && data.packageInfo?.taxPercent && data.packageInfo.taxPrice ? (
          <span className="w-full flex justify-start items-start mb-4">
            <Text type="P4" className="ts-accent w-32 ml-8">
              مالیات بر ارزش افزوده
              {Object.keys(data.packageInfo).length !== 0
                ? `(${data.packageInfo?.taxPercent ? data.packageInfo?.taxPercent * 100 : 10}درصد)`
                : ""}
              :
            </Text>
            {loading ? (
              <Skeleton className="w-16" />
            ) : (
              <Text type="P4" className="ts-accent whitespace-normal ts-payment-detail-width">
                {Object.keys(data.packageInfo).length !== 0
                  ? `${tools.thousandSeparator(data.packageInfo.taxPrice / 10)} تومان`
                  : "---"}
              </Text>
            )}
          </span>
        ) : null}

        <span className="w-full flex justify-start items-start mb-4">
          <Text type="P4" className="ts-accent w-32 ml-8">
            مبلغ پرداخت شده:
          </Text>
          {loading ? (
            <Skeleton className="w-16" />
          ) : (
            <Text type="P4" className="ts-accent whitespace-normal ts-payment-detail-width">
              {Object.keys(data.packageInfo).length !== 0
                ? data.packageInfo?.paidPrice
                  ? `${tools.thousandSeparator(data.packageInfo?.paidPrice / 10)} تومان`
                  : "---"
                : "---"}
            </Text>
          )}
        </span>

        {/* <span className="w-full flex justify-start items-start mb-4">
          <Text type="P4" className="ts-accent w-32 ml-8">
            درگاه پرداخت:
          </Text>
          {loading ? (
            <Skeleton className="w-16" />
          ) : (
            <Text type="P4" className="ts-accent whitespace-normal ts-payment-detail-width">
              شاپرک - بانک ملی
            </Text>
          )}
        </span> */}

        <span className="w-full flex justify-start items-start mb-4">
          <Text type="P4" className="ts-accent w-32 ml-8">
            تاریخ :
          </Text>
          {loading ? (
            <Skeleton className="w-16" />
          ) : (
            <Text type="P4" className="ts-accent whitespace-normal ts-payment-detail-width">
              {tools.convertDateToPersianDate(data.createdAt, true)}
            </Text>
          )}
        </span>
      </div>

      <Button
        onClick={onClose}
        bcolor="primaryTransparent"
        classNameContainer="w-full"
        className="w-full h-12 rounded-xl"
      >
        متوجه شدم
      </Button>
    </BottomModal>
  );
});

const packages = [
  {
    name: "1month",
    alias: "یک ماه",
  },
  {
    name: "6month",
    alias: "شش ماه",
  },
  {
    name: "1year",
    alias: "یک سال",
  },
];

export { PaymentDetailModal };

const styles = {};
