/* eslint-disable */
import { isArabic, toPersianChars } from "@persian-tools/persian-tools";
import axios from "axios";
import { vState } from "core/valtio";
import { axios as Axios } from "./api";

const common = {
  healthCheck: async () => {
    const url = `/ping`;
    const result = await Axios.get(url);

    return result;
  },
  getUIConfigs: async () => {
    const url = `/ui`;
    const result = await Axios.get(url)
      .then((res) => {
        console.log("ui", res);
        const data = res.data.data;
        vState.workshopTypes = data.categories;
        vState.services = data.services;
        // vState.cars = data.cars;
        if (data.categories.length !== 0) {
          const newArrayOfObj = data.categories.map(({ id, name }) => ({
            value: id,
            label: name,
          }));
          vState.SelectPickerCategories = newArrayOfObj.reverse();
        }
        if (data.services.length !== 0) {
          const newArrayOfObj = data.services
            .filter((item) => item.disabled === null || item.disabled === false)
            .map(({ id, name }) => ({
              value: id,
              label: name,
            }));
          vState.SelectPickerServices = newArrayOfObj;
        }
      })
      .catch((e) => {
        console.log("error ui", e);
      });

    return result;
  },
  getCars: async () => {
    const url = `/cars`;
    const result = await Axios.get(url);

    return result;
  },
  getAllCities: async (): Promise<any[]> => {
    const url = `ui/cities`;
    const result = await Axios.get(url)
      .then((res) => {
        console.log(res);
        const citiesArrayList = res.data.data.map(
          ({ id: value, city: city, province: province, priority: priority }) => ({
            value,
            label: `${isArabic(city) ? toPersianChars(city) : city}، ${
              isArabic(province) ? toPersianChars(province) : province
            }`,
            priority,
          }),
        );
        vState.cities = citiesArrayList;
        return citiesArrayList;
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
    return result;
  },
  getVersion: async () => {
    const url = `/versions`;
    const result = await Axios.get(url);

    return result;
  },
  getUploadUrl: async () => {
    const url = `/upload`;
    const result = await Axios.post<{
      data: {
        id: string;
        key: string;
        url: string;
      };
    }>(url);

    return result.data;
  },
  uploadFile: async (
    url: string,
    file: File,
    options?: {
      controller?: AbortController;
      onUploadProgress?: (progressEvent: any) => void;
    },
  ) => {
    const result = await axios.put(url, file, {
      signal: options?.controller?.signal,
      onUploadProgress: options?.onUploadProgress,
      headers: {
        "Content-Type": file.type,
        "x-amz-acl": "public-read",
        "Content-Disposition": "inline",
      },
    });

    return result;
  },
  getUserIp: async () => {
    const result = await axios.get("https://api.ipify.org/?format=json");

    return result;
  },
  getUserLocation: async (ip) => {
    const result = await axios.get(`https://freeipapi.com/api/json/${ip}`);

    return result;
  },
};

export { common };
