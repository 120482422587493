/* eslint-disable */
import { ApiInstance, ConfigLink, tools, useChangeTitle, vState } from "core";
import { useEffect, useState } from "react";
import { HiClock } from "react-icons/hi";
import { MdCheck, MdChevronLeft, MdClose } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { ContainerScroll, Text, useNotification } from "ui/components";
import { useSnapshot } from "valtio";
import noPayment from "../../../assets/images/no-payment.svg";
import { PaymentDetailModal } from "./components";

interface IPaymentCardProps {
  type: "loading" | "data";
  data: any;
  onClick?: () => void;
}

const Payments = () => {
  const metaTags = useChangeTitle("ویراژ - پرداخت‌ها");
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [detailModal, setDetailModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [payments, setPayments] = useState([]);

  const getPayments = async () => {
    await ApiInstance.account
      .getPaymentsHistory()
      .then((res) => {
        console.log(res);
        setPayments(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPayments();
  }, []);

  const backClickHandler = () => {
    navigate(ConfigLink.profile);
  };

  return (
    <>
      {/* <Header showBackButton profileActive backClick={backClickHandler} /> */}
      {metaTags}
      <ContainerScroll heightOffset={50} className="">
        <Text type="H4" className="ts-accent mb-6">
          پرداخت‌ها
        </Text>

        {loading ? (
          [1, 2, 3, 4].map((item) => {
            return <PaymentCard key={item} type="loading" data={null} />;
          })
        ) : payments.length === 0 ? (
          <div style={{ height: "75vh" }} className="w-full flex flex-col justify-center items-center">
            <img src={noPayment} alt="no-payment" />
            <Text type="P4" className="ts-accent mt-6 text-center">
              شما تاکنون هیچ پرداختی نداشته اید.
            </Text>
          </div>
        ) : (
          payments.map((item: any) => {
            return (
              <PaymentCard
                key={item.id || ""}
                type="data"
                data={item}
                onClick={() => {
                  setSelectedId(item.id);
                  setDetailModal(true);
                }}
              />
            );
          })
        )}
      </ContainerScroll>
      <PaymentDetailModal isOpen={detailModal} onClose={() => setDetailModal(false)} id={selectedId} />
    </>
  );
};

const PaymentCard: React.FC<IPaymentCardProps> = ({ type, data, onClick }) => {
  return (
    <div
      onClick={() => {
        onClick && onClick();
      }}
      style={{ height: "122px" }}
      className="w-full rounded-2xl ts-shadow bg-white px-4 py-3 mb-2 flex justify-between items-center"
    >
      <span className="flex flex-col justify-start items-start">
        {type === "loading" ? (
          <Skeleton className="w-24 mb-2" />
        ) : (
          <Text type="H6" className="ts-accent mb-2">
            {data.packageInfo
              ? data.packageInfo?.paidPrice
                ? `${tools.thousandSeparator(data.packageInfo?.paidPrice / 10)} تومان`
                : "---"
              : data.paidAmount
              ? `${tools.thousandSeparator(data.paidAmount)} تومان`
              : "هزینه نامشخص"}
          </Text>
        )}
        {type === "loading" ? (
          <Skeleton className="w-28 mb-2" />
        ) : (
          <Text type="P4" className="ts-gray-07 mb-2">
            {data.description || "---"}
          </Text>
        )}
        {type === "loading" ? (
          <Skeleton className="w-32 mb-2" />
        ) : (
          <Text type="P5" className="ts-accent mb-2">
            {tools.convertDateToPersianDate(data.createdAt, true)}
          </Text>
        )}
        {type === "loading" ? (
          <span className="flex justify-center items-center">
            <Skeleton className="w-5 h-4" />
            <Skeleton className="w-16 h-4 mr-0.5" />
          </span>
        ) : (
          <span className="flex justify-center items-center">
            {data.status === "succeeded" ? (
              <>
                <MdCheck size={20} className="ts-success" />
                <Text type="HM" className="ts-success mr-0.5">
                  تراکنش موفق
                </Text>
              </>
            ) : data.status === "failed" ? (
              <>
                <MdClose size={20} className="ts-secondary-red" />
                <Text type="HM" className="ts-secondary-red mr-0.5">
                  تراکنش ناموفق
                </Text>
              </>
            ) : (
              <>
                <HiClock size={19} className="ts-warning -mt-0.5" />
                <Text type="HM" className="ts-warning mr-0.5">
                  در انتظار پرداخت
                </Text>
              </>
            )}
          </span>
        )}
      </span>

      <MdChevronLeft size={29} className="ts-accent" />
    </div>
  );
};

export { Payments as default };
