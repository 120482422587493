/* eslint-disable */
import { useEffect, useState } from "react";
import { HiCheck } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { Form, Input, Loader } from "rsuite";
import { useSnapshot } from "valtio";
import { ApiInstance, ConfigLink, ServiceStorage, useObjectState, vState } from "../../../core";
import { useChangeTitle } from "../../../core/hooks";
import { useAuth } from "../../../hooks";
import { ContainerScroll, Text, useNotification } from "../../components";
import { ChangePassModal } from "./components";

const env = import.meta.env;

const UserSetting = ({}) => {
  const auth = useAuth();
  const metaTags = useChangeTitle("ویراژ - حساب کاربری");
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  const [changePassShow, setChangePassShow] = useState(false);

  const [nameLoading, setNameLoading] = useState(false);
  const [phoneLoading, setPhoneLoading] = useState(false);
  const [info, setInfo] = useObjectState({
    name: "",
    phone: "",
  });
  const [isFocused, setIsFocused] = useObjectState({
    name: false,
    phone: false,
  });

  const getProfile = async () => {
    const result: any = await ApiInstance.account.getUserProfile();
    console.log(result);
    ServiceStorage.setAuth(result);
    vState.userInfo = result;
    setInfo({
      name: result.name || "",
      phone: result.mobile || result.username,
    });
  };

  useEffect(() => {
    const profile: any = ServiceStorage.getAuth();
    console.log(profile);

    if (profile) {
      vState.userInfo = profile;
      setInfo({
        name: profile.name || "",
        phone: profile.mobile || profile.username,
      });
    } else {
      getProfile();
    }
  }, []);

  const saveInfo = () => {
    const copyUser = { ...snap.userInfo, name: info.name, username: info.phone };
    console.log("copyUser", copyUser);
    ServiceStorage.setAuth(copyUser);
  };

  const changeNameHandler = async () => {
    if (snap.userInfo.name !== info.name) {
      setNameLoading(true);

      await ApiInstance.account
        .updateUserProfile(info.name)
        .then((res) => {
          vState.userInfo.name = info.name;
          saveInfo();
          useNotification("نام با موفقیت تغییر کرد.", "success", "تغییر نام");
          navigate(ConfigLink.profile, { replace: true });
          setIsFocused({ name: false });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setNameLoading(false);
        });
    }
  };

  const changePhoneHandler = () => {
    if (snap.userInfo.username !== info.phone) {
      setPhoneLoading(true);
      setTimeout(() => {
        vState.userInfo.username = info.phone;
        saveInfo();
        setPhoneLoading(false);
        useNotification("شماره همراه با موفقیت تغییر کرد.", "success", "تغییر شماره همراه");
        setIsFocused({ phone: false });
      }, 1500);
    }
  };

  return (
    <>
      {/* <Header showBackButton noLogo backClick={() => navigate(ConfigLink.profile)} profileActive /> */}
      {metaTags}
      <ContainerScroll heightOffset={50} className="">
        <Text type="H3" className="ts-accent mb-8">
          حساب کاربری
        </Text>

        <Form fluid className="w-full">
          <Text type="H6" className="ts-accent mb-2">
            نام و نام خانوادگی
          </Text>
          <div className="w-full relative mb-6">
            <Input
              value={info.name}
              required
              onChange={(e) => setInfo({ name: e })}
              className={`w-full rounded-xl outline-none h-12`}
              placeholder="نام و نام خانوادگی"
              onFocus={() => setIsFocused({ name: true })}
              onBlur={() => {
                snap.userInfo.name === info.name && setIsFocused({ name: false });
              }}
            />
            {isFocused.name && info.name.length !== 0 && (
              <div
                onClick={changeNameHandler}
                className={`cursor-pointer w-8 h-8 rounded-xl  ${
                  snap.userInfo.name !== info.name ? "ts-bg-primary" : "ts-bg-primary-light"
                } flex justify-center items-center absolute left-1.5 top-2`}
              >
                {nameLoading ? <Loader size="xs" /> : <HiCheck size={22} className="text-white" />}
              </div>
            )}
          </div>

          <Text type="H6" className="ts-accent mb-2">
            شماره تماس
          </Text>

          <div className="w-full relative mb-3">
            <Input
              disabled
              value={info.phone}
              required
              onChange={(e) => setInfo({ phone: e })}
              className={`w-full rounded-xl outline-none h-12 bg-white ts-gray-07`}
              placeholder="09000000000"
              onFocus={() => setIsFocused({ phone: true })}
              onBlur={() => {
                snap.userInfo.username === info.phone && setIsFocused({ phone: false });
              }}
            />
            {isFocused.phone && info.phone.length === 11 && (
              <div
                onClick={changePhoneHandler}
                className={`cursor-pointer w-8 h-8 rounded-xl  ${
                  snap.userInfo.username !== info.phone ? "ts-bg-primary" : "ts-bg-primary-light"
                } flex justify-center items-center absolute left-1.5 top-2`}
              >
                {phoneLoading ? <Loader size="xs" /> : <HiCheck size={22} className="text-white" />}
              </div>
            )}
          </div>
          <Text type="P5" className="ts-gray-07 ts-letter-space-min">
            {" "}
            در حال حاضر امکان تغییر شماره تماس وجود ندارد. در صورت نیاز با پشتیبانی تماس بگیرید.
          </Text>

          {/* <Text type="H6" className="ts-accent mb-2">
            کلمه عبور
          </Text>
          <Button
            onClick={() => setChangePassShow(true)}
            bcolor="primaryTransparent"
            textType="H5"
            classNameContainer="w-full"
            className="w-full rounded-lg h-10"
          >
            تغییر رمز عبور
          </Button> */}
        </Form>
      </ContainerScroll>
      {/* <Footer activeTab={2} /> */}
      <ChangePassModal isOpen={changePassShow} onClose={() => setChangePassShow(false)} />
    </>
  );
};

export { UserSetting as default };
