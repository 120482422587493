/* eslint-disable */
import React, { useState } from "react";
import { HiCheckCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { ApiInstance, ConfigLink, ServiceStorage, vState } from "../../../../core";
import { BottomModal, Button, Text, useNotification } from "../../../components";

interface IFinalRegisterModalProps {
  isOpen: boolean;
  onClose: () => void;
  carId: string;
}

const FinalRegisterModal: React.FC<IFinalRegisterModalProps> = React.memo(({ isOpen, onClose, carId }) => {
  const navigate = useNavigate();
  const snap = useSnapshot(vState);
  const returnURL = ServiceStorage.getTempReturnURL() || ConfigLink.admission;

  const [saveLoading, setSaveLoading] = useState(false);

  const saveBtnHandler = async () => {
    setSaveLoading(true);

    await ApiInstance.sessions
      .completeSession(carId)
      .then((res) => {
        console.log("completeSession", res);
        vState.carStatus = res.data.data;
        useNotification("خودرو ترخیص شد", "success", "ترخیص خودرو");
        setTimeout(() => {
          navigate(ConfigLink.dashboard, { replace: true });
          onClose();
        }, 1500);
      })
      .catch((err) => {
        console.log("err", err);
        useNotification("مشکلی رخ داده است لطفا دوباره تلاش کنید.", "error", "ترخیص خودرو");
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  return (
    <>
      <BottomModal isOpen={isOpen} onClose={onClose} className={``}>
        <div className={`w-full flex flex-col justify-start items-start`}>
          <Text type="H3" className="ts-accent">
            ترخیص خودرو
          </Text>
          <Text type="P3" className="ts-accent my-6">
            با ترخیص خودرو، پیامکی به مالک ارسال خواهد شد که اتمام خدمات و آماده بودن خودرو را جهت تحویل، اطلاع خواهد
            داد.
          </Text>
          <div className=" w-full flex flex-col justify-center items-center">
            <Button
              onClick={saveBtnHandler}
              icon={<HiCheckCircle className="w-5 h-5 text-white -mt-px ml-2" />}
              loading={saveLoading}
              bcolor="primary"
              textType="H6"
              classNameContainer="w-full"
              className="w-full rounded-xl h-12 mb-4"
            >
              ثبت اطلاعات و ترخیص خودرو
            </Button>

            <Button
              onClick={() => onClose()}
              bcolor="blackTransparent"
              textType="H6"
              classNameContainer="w-full"
              className="w-full rounded-xl h-12"
            >
              انصراف و بازگشت
            </Button>
          </div>
        </div>
      </BottomModal>
    </>
  );
});

export { FinalRegisterModal };

{
  /* <BottomModal isOpen={isOpen} onClose={onClose} className={`${deleteReminder ? "w-11/12 ml-4" : ""}`}>
        <div
          className={`w-full ${deleteReminder ? "h-44 overflow-hidden" : ""} flex flex-col justify-start items-start`}
        >
          <Text type="H3" className="ts-accent">
            ثبت نهایی سرویس ها
          </Text>
          <Text type="P3" className="ts-accent my-6">
            با ثبت نهایی سرویس های انجام شده برای این خودرو، <b>امکان اضافه کردن سرویس دیگری وجود ندارد.</b> آیا مطمئنید
            که سرویس های خودرو به اتمام رسیده اند؟
          </Text>
          <div className=" w-full flex flex-col justify-center items-center">
            <Button
              onClick={saveBtnHandler}
              icon={<HiCheckCircle className="w-5 h-5 text-white -mt-px ml-2" />}
              loading={saveLoading}
              bcolor="green"
              textType="H6"
              classNameContainer="w-full"
              className="w-full rounded-lg h-12 mb-4"
            >
              ثبت نهایی سرویس ها
            </Button>

            <Button
              onClick={() => onClose()}
              bcolor="red"
              textType="H6"
              classNameContainer="w-full"
              className="w-full rounded-lg h-12"
            >
              لغو ثبت نهایی و بازگشت
            </Button>
          </div>
        </div>
      </BottomModal> */
}
