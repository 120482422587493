/* eslint-disable */
import React from "react";
import { HiLocationMarker, HiPhone } from "react-icons/hi";
import { IoNavigateCircle } from "react-icons/io5";
import { BottomModal, Button, Text } from "ui/components";
import { StarRating } from "ui/_layouts/components";
import { MiniMap } from "./MiniMap";

interface IWorkshopInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  workshop: any;
}

const WorkshopInfoModal: React.FC<IWorkshopInfoModalProps> = React.memo(({ isOpen, onClose, workshop }) => {
  const callWorkshopHandler = () => {
    window.open(`tel:${workshop.phone}`, "_self");
  };

  const openLocation = () => {
    window.open(
      `https://maps.google.com?q=${workshop.location.coordinates[0]},${workshop.location.coordinates[1]}`,
      "_blank",
    );
  };

  return (
    <BottomModal isOpen={isOpen} onClose={onClose}>
      {workshop && (
        <>
          <div className="w-full flex justify-between items-center mb-4">
            <Text type="H3" className="ts-accent">
              {workshop.name || "---"}
            </Text>
          </div>
          {workshop.feedbacks && Object.keys(workshop.feedbacks).length !== 0 && (
            <div
              style={{ height: "47px" }}
              className={`w-full flex flex-col justify-start bg-white ts-shadow rounded-2xl py-3 px-4 items-center mb-4`}
            >
              <div className="w-full flex justify-between items-center">
                <div className="flex justify-center items-center">
                  <StarRating
                    // @ts-ignore
                    initialValue={
                      workshop.feedbacks && workshop.feedbacks.averageScore
                        ? parseFloat(workshop.feedbacks.averageScore).toFixed(1)
                        : 0
                    }
                    size={18}
                  />

                  <Text type="P3" className="ts-gray-06 mr-2.5 mt-0.5">
                    {workshop.feedbacks.averageScore ? parseFloat(workshop.feedbacks.averageScore).toFixed(1) : 0}
                  </Text>
                </div>
                <div className="flex justify-center items-center">
                  <Text type="P5" className="ts-accent mt-0.5">
                    {+workshop.feedbacks.total || 0} دیدگاه
                  </Text>
                </div>
              </div>
            </div>
          )}
          <div className="w-full relative mb-6">
            {!workshop.location && (
              <div className="w-full ts-mini-map bg-black opacity-70 z-20 absolute top-0 left-0 flex justify-center items-center">
                <Text type="H5" className="text-white text-center">
                  موقعیت مکانی برای تعمیرگاه اضافه نشده
                </Text>
              </div>
            )}
            <MiniMap data={workshop} id={"workshop-mini-map"} />
          </div>
          <div className="w-full flex justify-between items-center">
            {workshop.location && (
              <Button
                bcolor="primary"
                textType="H6"
                onClick={openLocation}
                icon={<IoNavigateCircle size={23} className="text-white -mt-1" />}
                classNameContainer={`${workshop.phone ? "ts-half-width" : "w-full"}`}
                className="w-full h-12"
              >
                مسیریابی
              </Button>
            )}
            {workshop.phone && (
              <Button
                bcolor="primaryTransparent"
                textType="H6"
                onClick={callWorkshopHandler}
                icon={<HiPhone size={23} className="ts-primary -mt-1 -rotate-90" />}
                classNameContainer={`${workshop.location ? "ts-half-width" : "w-full"}`}
                className="w-full h-12"
              >
                تماس
              </Button>
            )}
          </div>
          <Text type="H4" className="ts-accent text-right mb-4 mt-8">
            اطلاعات و نشانی
          </Text>
          {workshop.description && (
            <Text type="P3" className="ts-gray-05 text-justify mb-4">
              {workshop.description || "---"}
            </Text>
          )}
          <div className="w-full flex justify-start items-center">
            <div className="w-12 h-12 rounded-2xl p-3 ts-bg-primary-light ml-3">
              <HiLocationMarker size={23} className="ts-accent" />
            </div>
            <Text type="H6" className="ts-accent">
              {workshop.address || "---"}
            </Text>
          </div>

          <div className="w-full flex justify-start items-center my-4">
            <div className="w-12 h-12 rounded-2xl p-3 ts-bg-primary-light ml-3">
              <HiPhone size={23} className="ts-accent -rotate-90" />
            </div>
            <Text
              onClick={() => {
                if (workshop.phone) {
                  window.open(`tel:${workshop.phone}`, "_self");
                }
              }}
              type="H6"
              className="ts-accent"
            >
              {workshop.phone || "---"}
            </Text>
          </div>
        </>
      )}
    </BottomModal>
  );
});

export { WorkshopInfoModal };

const styles = {};
