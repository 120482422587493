/* eslint-disable */
import virazhSubscribed from "assets/images/logo-subscribed.svg";
import virazh from "assets/images/virazh-logo.svg";
import React, { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { FiCornerUpRight } from "react-icons/fi";
import { HiUserCircle } from "react-icons/hi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "ui/components";
import { useSnapshot } from "valtio";
import { ConfigLink, ServiceStorage, vState } from "../../../core";
import { SelectWorkshopModal } from "./SelectWorkshopModal";

interface IHeaderProps {
  className?: string;
  showBackButton?: boolean;
  noLogo?: boolean;
  logoCenter?: boolean;
  backClick?: () => void;
  lowMargin?: boolean;
  showWorkshops?: boolean;
  onWorkshopSelected?: () => void;
  noProfile?: boolean;
  profileActive?: boolean;
}

const Header: React.FC<IHeaderProps> = React.memo(
  ({
    className,
    showBackButton = false,
    showWorkshops = false,
    noLogo = false,
    backClick,
    onWorkshopSelected,
    logoCenter = false,
    lowMargin = false,
    noProfile = false,
    profileActive = false,
  }) => {
    const snap = useSnapshot(vState);
    const navigate = useNavigate();
    const location = useLocation();
    const profileCondition = location.pathname.includes(ConfigLink.profile);

    // const [isLoggedIn, setLoggedIn] = useState(false);
    const [workshopsModal, setWorkshopsModal] = useState<boolean>(false);
    const [activeWorkshopName, setActiveWorkshopName] = useState("");

    const backButtonClickHandler = () => {
      if (backClick) {
        backClick();
      } else {
        // navigate("/", { replace: true });
        navigate(-1);
      }
    };

    const setActiveWorkshopHandler = () => {
      const activeWorkshopId = ServiceStorage.getActiveWorkshop();
      if (activeWorkshopId) {
        const index = snap.userWorkspaces.findIndex((item) => item.id === activeWorkshopId);
        if (index !== -1) {
          setActiveWorkshopName(snap.userWorkspaces[index].name);
        } else {
          setActiveWorkshopName(snap.userWorkspaces[0].name);
        }
      }
    };

    useEffect(() => {
      if (snap.userWorkspaces.length !== 0) {
        setActiveWorkshopHandler();
      }
    }, [snap.userWorkspaces]);

    const profileHandler = () => {
      if (!profileActive) {
        const url = window.location;
      }
    };

    return (
      <>
        <div
          className={`w-full ts-limit-width ${lowMargin ? "px-3" : "px-6 max-2xs:px-3"} py-3 h-16 fixed flex ${
            logoCenter ? "justify-center" : "justify-between"
          } items-center ${className} transition-all bg-white ${
            snap.isScrolling ? "ts-bottom-shadow lg:shadow-none" : ""
          } z-40`}
        >
          {showBackButton ? (
            <div
              onClick={backButtonClickHandler}
              className={`transition-all h-11 px-3 rounded-xl ts-bg-gray-01 flex justify-center items-center cursor-pointer z-20`}
            >
              <FiCornerUpRight size={18} className="ts-primary" />
              <p className="ts-primary mr-1 text-sm font-bold">بازگشت</p>
            </div>
          ) : !noLogo ? (
            <Link to={ConfigLink.homePage}>
              <img src={snap.userInfo.premium ? virazhSubscribed : virazh} alt="logo" className="" />
            </Link>
          ) : null}

          <div className="flex justify-center items-center">
            {showWorkshops && snap.userWorkspaces.length > 1 && activeWorkshopName.length > 1 && (
              <div
                onClick={() => setWorkshopsModal(true)}
                style={{ maxWidth: "138px" }}
                className="flex justify-center cursor-pointer items-center h-11 ts-shadow bg-white p-3 rounded-xl"
              >
                <p className="ts-accent ml-1 mt-0.5 truncate text-base font-bold">{activeWorkshopName}</p>
                <div className="w-6 h-6 flex justify-center items-center">
                  <BiChevronDown size={23} className="ts-accent" />
                </div>
              </div>
            )}

            {!noProfile && (
              <div className="flex justify-center items-center">
                {/* <Button
              classNameContainer="ts-shadow rounded-xl ts-header-button"
              className={`w-11 h-11 rounded-xl ${
                messageCondition ? "ts-bg-primary-light" : "bg-white"
              }`}
            >
              <BiSolidMessageAltDetail
                size={24}
                className={`${messageCondition ? "ts-primary" : "ts-accent"}`}
              />
            </Button> */}
                <Link to={ConfigLink.profile}>
                  <Button
                    onClick={profileHandler}
                    classNameContainer="ts-shadow rounded-xl mr-2 ts-header-button"
                    className={`w-11 h-11 rounded-xl ${
                      profileCondition || profileActive ? "ts-bg-primary-light" : "bg-white"
                    }`}
                  >
                    <HiUserCircle
                      size={24}
                      className={`${profileCondition || profileActive ? "ts-primary" : "ts-accent"}`}
                    />
                  </Button>
                </Link>
              </div>
            )}
          </div>
        </div>
        <SelectWorkshopModal
          isOpen={workshopsModal}
          onClose={() => setWorkshopsModal(false)}
          onDone={setActiveWorkshopHandler}
          onWorkshopSelected={onWorkshopSelected}
        />
      </>
    );
  },
);

export { Header };

const styles = {};
