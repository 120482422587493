/* eslint-disable */
import React from "react";
import { BiChevronLeft } from "react-icons/bi";
import { HiOutlineExternalLink } from "react-icons/hi";
import { Text } from "../../../components";

interface IMenuButtonProps {
  onClick: () => void;
  title: string;
  icon: any;
  isExternal?: boolean;
  logout?: boolean;
  extraInfo?: () => JSX.Element;
}

const MenuButton: React.FC<IMenuButtonProps> = React.memo(
  ({ onClick, title, icon, isExternal = false, logout = false, extraInfo }) => {
    return (
      <div
        style={{ height: "72px" }}
        onClick={onClick}
        className="w-full p-6 mb-2 rounded-xl ts-shadow flex justify-between items-center"
      >
        <div className="cursor-pointer flex justify-end items-center">
          {icon}
          <Text type="P3" className={`${logout ? "ts-secondary-red" : "ts-accent"} mr-2`}>
            {title}
          </Text>
        </div>

        <div className="flex justify-center items-center">
          {extraInfo && extraInfo()}
          {isExternal ? (
            <HiOutlineExternalLink size={24} className="ts-accent" />
          ) : (
            <BiChevronLeft size={24} className={` ${logout ? "ts-secondary-red" : "ts-accent"}`} />
          )}
        </div>
      </div>
    );
  },
);

export { MenuButton };

const styles = {};
