/* eslint-disable */
import React, { useEffect, useState } from "react";
import { HiLogin, HiTrash } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { ApiInstance, ServiceStorage, vState } from "../../../../core";
import { useAuth } from "../../../../hooks";
import { BottomModal, Button, Text, useNotification } from "../../../components";
import { addIDAsTempId } from "./../../../../hooks";

type deleteType = "delete" | "logout";

interface IDeleteWorkshopModalProps {
  data: any | null;
  isOpen: boolean;
  onClose: () => void;
  onEnd?: () => void;
  type: deleteType;
}

const DeleteWorkshopModal: React.FC<IDeleteWorkshopModalProps> = React.memo(
  ({ data, isOpen, onClose, onEnd, type }) => {
    const history = useNavigate();
    const auth = useAuth();
    const snap = useSnapshot(vState);
    const [loading, setLoading] = useState(false);

    useEffect(() => {}, []);

    const removeSelectedFeatures = async () => {
      await ApiInstance.account.setUserSetting({ features: [] }).then((res) => {
        console.log("FT-removed", res);
        vState.menuLoading = true;
        vState.activeFeatures = [];
        setTimeout(() => {
          vState.menuLoading = false;
        }, 1500);
      });
    };

    const deleteWorkshop = async () => {
      addIDAsTempId(data.id);

      await ApiInstance.workshop
        .deleteWorkshop(data.id)
        .then((res) => {
          console.log(res);
          useNotification("کسب و کار با موفقیت حذف شد.", "success", "حذف کسب و کار");
          // removeSelectedFeatures();
          const activeWorkshop = ServiceStorage.getActiveWorkshop();
          if (activeWorkshop && activeWorkshop === data.id) {
            ServiceStorage.removeActiveWorkshop();
            vState.activeWorkshop = null;
            vState.selectedWorkshop = "";
            vState.activeFeatures = [];
          }
          onEnd && onEnd();
          onClose();
        })
        .catch((err) => {
          console.log(err);
          //TEMP
          useNotification("مشکلی رخ داده است. لطفا مجددا تلاش کنید.", "error", "حذف کسب و کار");
        })
        .finally(() => {
          ServiceStorage.removeTempActiveWorkshop();
          setLoading(false);
        });
    };

    const logoutWorkshop = async () => {
      addIDAsTempId(data.id);

      await ApiInstance.workshop
        .logoutWorkshop()
        .then((res) => {
          console.log(res);
          //TEMP
          useNotification("شما از این کسب و کار خارج شدید.", "success", "خروج از کسب و کار");
          onEnd && onEnd();
          setTimeout(() => {
            onClose();
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
          //TEMP
          useNotification("مشکلی رخ داده است. لطفا مجددا تلاش کنید.", "error", "خروج از کسب و کار");
        })
        .finally(() => {
          ServiceStorage.removeTempActiveWorkshop();
          setLoading(false);
        });
    };

    const deleteBtnHandler = () => {
      setLoading(true);
      type === "delete" ? deleteWorkshop() : logoutWorkshop();
    };

    if (data) {
      return (
        <BottomModal isOpen={isOpen} onClose={onClose}>
          <div className="w-full flex flex-col justify-start items-start">
            <div className="flex justify-end items-center mb-6">
              {type === "logout" ? (
                <HiLogin size={21} className="ts-secondary-red" />
              ) : (
                <HiTrash size={21} color="#CA2525" className="ts-secondary-red -mt-1" />
              )}
              <Text type="H4" className="ts-secondary-red mr-2">
                {/* //TEMP */}
                {type === "delete" ? "حذف کسب و کار" : "خروج از کسب و کار"}
              </Text>
            </div>
            <Text type="P3" className="ts-accent mb-8">
              {/* //TEMP */}
              {type === "delete"
                ? `آیا مایلید که از کسب و کار "${data.name}" خارج شوید؟ پس از حذف، اطلاعات شما قابل بازیابی نخواهد بود.`
                : `آیا مایلید که از کسب و کار "${data.name}" خارج شوید؟ پس از خروج، برای ورود دوباره به این کسب و کار، به دعوت جدید از طرف ادمین نیاز خواهید داشت.`}
            </Text>
            <div className=" w-full flex justify-between items-center">
              <Button
                onClick={deleteBtnHandler}
                loading={loading}
                bcolor="black"
                textType="H6"
                classNameContainer="ts-half-width"
                className="w-full rounded-xl h-12"
              >
                {type === "delete" ? "بله، حذف شود" : "بله، خارج می‌شوم"}
              </Button>

              <Button
                onClick={() => onClose()}
                bcolor="blackTransparent"
                textType="H6"
                classNameContainer="ts-half-width"
                className="w-full rounded-xl h-12"
              >
                خیر
              </Button>
            </div>
          </div>
        </BottomModal>
      );
    } else {
      return null;
    }
  },
);

export { DeleteWorkshopModal };

const styles = {};
