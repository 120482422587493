/* eslint-disable */
import { isNull } from "lodash";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { ApiInstance, ConfigLink, IPackage, tools, vState } from "../../../../core";
import { useChangeTitle, useQueryString } from "../../../../core/hooks";
import { Button, ContainerScroll, Text, useNotification } from "../../../components";

const SelectSubscription = ({}) => {
  const metaTags = useChangeTitle("ویراژ - انتخاب سرویس");
  const snap = useSnapshot(vState);
  const navigate = useNavigate();
  const cardStyle = { height: "83px" };

  const [loading, setLoading] = useState(true);
  const [packages, setPackages] = useState<IPackage[]>([]);
  const [selectedPackage, setSelectedPackage] = useState<IPackage | null>(null);

  const getPackages = async () => {
    await ApiInstance.subscription
      .getPackages()
      .then((res) => {
        console.log(res);
        setPackages(res.data);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا لحظاتی بعد مجددا امتحان کنید.", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPackages();
  }, []);

  const selectSubHandler = async () => {
    navigate({
      pathname: ConfigLink.subCheckout,
      search: useQueryString({ packageId: selectedPackage?.package }),
    });
  };

  return (
    <>
      {/* <SubCustomHeader backClick={() => navigate.push(ConfigLink.compareSub)} /> */}
      {metaTags}
      <ContainerScroll heightOffset={150} NoSticky className="pt-56">
        <Text type="H3" className="ts-accent text-center mb-8">
          فعالسازی سرویس ویژه
        </Text>

        <Text type="P3" className="ts-accent text-center mb-4">
          مدت زمان فعال بودن را انتخاب کنید:
        </Text>

        {loading
          ? [1, 2, 3].map((item) => {
              return (
                <Skeleton key={item} style={cardStyle} containerClassName="w-full" className="w-full rounded-xl mb-4" />
              );
            })
          : packages.map((item) => {
              return (
                <div
                  style={cardStyle}
                  onClick={() => setSelectedPackage(item)}
                  className={`w-full transition-all rounded-xl ts-shadow ${
                    selectedPackage?.package === item.package ? "ts-bg-primary-light ts-selected-package" : "bg-white"
                  } mb-4 p-4 flex justify-between ${item.package === "1month" ? "items-center" : "items-start"}`}
                >
                  <span className="flex flex-col justify-start items-start">
                    <span className="flex justify-start items-center">
                      <Text type="H6" className="ts-accent">
                        {item.title}
                      </Text>
                      {item.discountPercent ? (
                        <span className="px-2 py-0.5 ts-bg-primary -mt-px rounded-full mr-1 flex justify-center items-center">
                          <Text type="HM" className="text-white pt-px">
                            {item.discountPercent * 100}% تخفیف
                          </Text>
                        </span>
                      ) : null}
                    </span>
                    {item.package !== "1month" ? (
                      <Text type="P4" className="ts-accent mt-2">
                        هزینه کل: {tools.thousandSeparator(item.priceWithDiscount / 10)} تومان
                      </Text>
                    ) : null}
                  </span>

                  <span className="flex justify-center items-center">
                    <Text type="H5" className="ts-premium-text">
                      {tools.thousandSeparator(item.pricePerItemWithDiscount / 10)}
                    </Text>
                    <Text type="P4" className="mr-1 ts-premium-text">
                      تومان
                    </Text>
                    {item.package !== "1month" && (
                      <Text type="P5" className="mr-1 ts-gray-06">
                        / ماهیانه
                      </Text>
                    )}
                  </span>
                </div>
              );
            })}

        <div className="w-full ts-sub-sticky-button flex flex-col justify-center items-center bg-white fixed bottom-0 left-0 px-6 pb-10">
          <Button
            onClick={selectSubHandler}
            disabled={isNull(selectedPackage)}
            bcolor="primary"
            textType="H6"
            classNameContainer="w-full max-w-7xl"
            className="w-full rounded-xl h-12 mb-2 mt-2"
          >
            خرید
          </Button>
        </div>
      </ContainerScroll>
    </>
  );
};

export { SelectSubscription as default };
