import React, { forwardRef, useImperativeHandle } from "react";
import { useMap } from "react-leaflet";

interface IMoveToPointProps {
  ref: any;
}

const MoveToPoint: React.FC<IMoveToPointProps> = forwardRef((props, ref) => {
  const map = useMap();

  useImperativeHandle(ref, () => ({
    moveToUserLocation(location) {
      console.log("moving...");
      map.flyTo(location, 18);
    },
  }));

  return null;
});

export { MoveToPoint };
