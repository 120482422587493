/* eslint-disable */
import React from "react";
import { BiChevronLeft } from "react-icons/bi";
import { BsCheckSquareFill, BsExclamationCircleFill, BsSquare } from "react-icons/bs";
import { Text } from "ui/components";

interface IUserCardProps {
  data: any;
  selectMode: boolean;
  onClick: () => void;
  selectedUser: any[];
}

const env = import.meta.env;

const UserCard: React.FC<IUserCardProps> = React.memo(({ data, selectMode, onClick, selectedUser }) => {
  return (
    <div
      style={{ height: "76px" }}
      onClick={onClick}
      className={`w-full bg-white transition-all duration-300 relative cursor-pointer flex justify-between items-center ts-shadow rounded-2xl p-3 mb-2`}
    >
      <div className="flex justify-center items-center">
        {selectedUser.map((it) => it.id).includes(data.id) ? (
          <BsCheckSquareFill size={23} className={`ts-primary absolute right-4 top-6`} />
        ) : (
          <BsSquare
            size={23}
            className={`ts-gray-04 transition-all absolute right-4 top-6 ${
              selectMode ? "visible delay-300" : "invisible delay-0"
            }`}
          />
        )}
        <div className={`flex transition-all flex-col justify-start items-start ${selectMode ? "mr-10" : "delay-100"}`}>
          <Text type="H6" className="ts-accent truncate">
            {data.contactName || "---"}
          </Text>
          {data.mobile && data.mobile.includes("*") ? (
            <span className="flex justify-center items-center mt-2.5">
              <BsExclamationCircleFill size={14} className="ts-gray-05 -mt-0.5" />
              <Text type="P5" className="ts-gray-05 mr-1">
                این مشتری شماره خود را مخفی کرده است.
              </Text>
            </span>
          ) : (
            <Text type="P4" className="ts-gray-06 truncate mt-2.5 ts-reverse-direction">
              {data.mobile || "---"}
            </Text>
          )}
        </div>
      </div>
      <BiChevronLeft size={24} className={`${selectMode ? "text-white" : "ts-accent"}`} />
    </div>
  );
});

export { UserCard };
