/* eslint-disable */
import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import flag from "../../../../assets/images/ir-flag.svg";
import { Text } from "../../../components";

interface ILicensePlateProps {
  firstSection: number;
  secondSection: number;
  thirdSection: number;
  letter: string;
}

const env = import.meta.env;

const LicensePlate: React.FC<ILicensePlateProps> = React.memo(
  ({ firstSection, secondSection, thirdSection, letter }) => {
    return (
      <div className="w-full h-14 rounded-xl ts-primary-border flex justify-end items-center">
        <div className="w-20 mr-2 pt-1 h-full flex justify-center items-center ts-left-border">
          <Text type="P4" className="ts-accent ts-letter-space ml-1">
            ایران
          </Text>

          <Text type="P4" className="ts-accent">
            {thirdSection}
          </Text>
        </div>
        <div style={{ width: "70%" }} className=" pt-1 flex justify-center items-center">
          <Text type="P4" className="ts-accent">
            {secondSection}
          </Text>
          <Text type="P4" className="ts-accent mx-3">
            {letter}
          </Text>
          <Text type="P4" className="ts-accent">
            {firstSection}
          </Text>
        </div>
        <div className="w-10 h-14 -ml-px ts-bg-primary flex flex-col justify-center items-end pl-2 pt-2 rounded-l-xl">
          <img src={flag} alt="ir-flag" className="mb-1 w-5 h-3 -ml-px" />
          <Text type="Tiny" className="text-white -mb-0.5">
            IR
          </Text>
          <Text type="Tiny" className="text-white -mt-px">
            IRAN
          </Text>
        </div>
      </div>
    );
  },
);

export { LicensePlate };
