/* eslint-disable */
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { tools } from "core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

interface IStaticLineChartProps {
  orgData: any;
  selectedRange: string;
  type?: 0 | 1 | 2;
}

const StaticLineChart: React.FC<IStaticLineChartProps> = React.memo(({ orgData, selectedRange, type = "1" }) => {
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
  ChartJS.defaults.font.size = 12;
  ChartJS.defaults.font.family = "dana";
  const oneYearLabel = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

  const [firstData, setFirstData] = useState<any[]>([]);
  const [secondData, setSecondData] = useState<any[]>([]);
  const [labels, setLabels] = useState<any[]>([]);
  const [minRotation, setMinRotation] = useState<number>(70);
  const [stepSize, setStepSize] = useState<number>(20);

  useEffect(() => {
    switch (selectedRange) {
      case "per_day":
        setLabels(tools.getChartDateRange(selectedRange).showDates);
        setMinRotation(70);
        setStepSize(20);
        break;
      case "per_week":
        setLabels(tools.getChartDateRange(selectedRange).showDates);
        setMinRotation(40);
        setStepSize(100);
        break;
      case "per_month":
        setLabels(tools.getChartDateRange(selectedRange).showDates);
        setMinRotation(40);
        setStepSize(200);
        break;
    }
  }, [selectedRange]);

  const getSortedValuesWeek = (data) => {
    const finalValue = tools.getChartDateRange(selectedRange).dates.map((item) => {
      const givenDates = data.filter((dt) => moment(dt.date).format("YYYY/MM/DD") === item);

      return givenDates.length !== 0 ? +givenDates[0].count : 0;
    });
    console.log(finalValue);

    return finalValue;
  };

  const getSortedValuesBigger = (data) => {
    const finalValue = tools.getChartDateRange(selectedRange).dates.map((item) => {
      const givenDates = data.filter(
        (dt) => moment(dt.date).format("MM") === new Date(item).toLocaleDateString(undefined, { month: "2-digit" }),
      );
      return givenDates.length !== 0 ? givenDates.reduce((a, b) => a + b.count, 0) : 0;
    });
    console.log(finalValue);
    return finalValue;
  };

  useEffect(() => {
    if (orgData && orgData.data0 && orgData.data1) {
      console.log(orgData.data1.map((item) => moment(item.date).format("YYYY/MM/DD")));
      console.log(orgData.data0.map((item) => moment(item.date).format("YYYY/MM/DD")));
      if (selectedRange === "per_day") {
        setFirstData(getSortedValuesWeek(orgData.data0));
        setSecondData(getSortedValuesWeek(orgData.data1));
      } else if (selectedRange === "per_week") {
        setFirstData(getSortedValuesBigger(orgData.data0.slice(0, 2)));
        setSecondData(getSortedValuesBigger(orgData.data1.slice(0, 2)));
      } else {
        setFirstData(getSortedValuesBigger(orgData.data0));
        setSecondData(getSortedValuesBigger(orgData.data1));
      }
    }
  }, [orgData]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    borderWidth: 1,
    scales: {
      x: {
        ticks: {
          color: "#8F9CA9",
          minRotation: minRotation,
        },
        border: {
          dash: [3, 3],
        },
        grid: {
          color: "#EEF2F6",
          lineWidth: 2,
          drawTicks: false,
        },
      },
      y: {
        beginAtZero: true,
        max: Math.ceil(Math.max(Math.max(...firstData), Math.max(...secondData)) / 5) * 5,
        min: 0,
        ticks: {
          grace: 0,
          color: "#8F9CA9",
          // stepSize: stepSize,
        },
        grid: {
          color: "#EEF2F6",
          lineWidth: 2,
        },
      },
    },
    locale: "fa-Ir",
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        rtl: true,
        intersect: true,
      },
    },
  };

  const data = {
    labels,
    datasets:
      type === 2
        ? [
            {
              label: "",
              data: firstData,
              borderColor: "#38c976",
              backgroundColor: "#38c976",
            },
          ]
        : [
            {
              label: "",
              data: firstData,
              borderColor: "#38c976",
              backgroundColor: "#38c976",
            },
            {
              label: "",
              data: secondData,
              borderColor: "#3eaeff",
              backgroundColor: "#3eaeff",
            },
          ],
  };

  return <Line options={options} data={data} />;
});

export { StaticLineChart };

const styles = {};
