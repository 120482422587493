/* eslint-disable */
import React, { useEffect } from "react";
import { MdAddCircle } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import banner from "../../assets/images/no-workshop-banner.svg";
import { ConfigLink, ServiceStorage } from "../../core";
import { Button } from "./Buttons";
import { Text } from "./Text";

interface INoWorkspaceEmptyStateProps {
  returnUrl: string;
  lowMargin?: boolean;
}

const NoWorkspaceEmptyState: React.FC<INoWorkspaceEmptyStateProps> = React.memo(
  ({ returnUrl = ConfigLink.homePage, lowMargin = false }) => {
    const navigate = useNavigate();

    useEffect(() => {}, []);

    const addWorkshopHandler = () => {
      ServiceStorage.removeTempWorkshop();
      localStorage.removeItem("temp_city");
      navigate(ConfigLink.createWorkspaceStepOne);
    };

    return (
      <div className="w-full ts-shadow flex flex-col justify-start items-start lg:items-center rounded-2xl p-4 mb-6 lg:mb-10 lg:py-12 ts-no-workshop-card-bg">
        <span className="w-full flex justify-center items-center mb-4">
          <Text type="H4" className="ts-gray-08 text-center">
            به ویراژ خوش آمدید
          </Text>
        </span>

        <img src={banner} alt="workshop-banner" className="m-auto mb-5" />

        <Text type="P4" className="ts-gray-08 text-center mb-5 lg:text-center lg:w-96">
          برای ثبت پلاک مشتریان و ایجاد یادآور برای آنها، ابتدا پروفایل خود را با اضافه کردن کسب‌و‌کار تکمیل کنید.
        </Text>

        <Button
          bcolor="primary"
          textType="H6"
          icon={<MdAddCircle size={20} className="text-white -mt-1" />}
          classNameContainer="w-full lg:w-96"
          className="w-full h-12 rounded-xl mb-12"
          onClick={addWorkshopHandler}
        >
          اضافه کردن کسب‌و‌کار
        </Button>
      </div>
    );
  },
);

export { NoWorkspaceEmptyState };

const styles = {};
