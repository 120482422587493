/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import {
  ApiInstance,
  ConfigLink,
  ServiceStorage,
  tools,
  useChangeTitle,
  useFetchQueryString,
  vState,
} from "../../../core";
import { useNotification } from "../../components";
import { useAuth } from "./../../../hooks/auth";

interface IIntroductionProps {} //INTRODUCTION PAGES

interface IQueryStringTransfer {
  code?: string;
}

const env = import.meta.env;

const Introduction: React.FC<IIntroductionProps> = React.memo(({}) => {
  const metaTags = useChangeTitle("ویراژ");
  const auth = useAuth();
  const navigate = useNavigate();
  const snap = useSnapshot(vState);
  let { code } = useFetchQueryString<IQueryStringTransfer>();

  const [userCity, setUserCity] = useState("");

  const checkProfileInfo = (hasCity) => {
    if (!hasCity || !("name" in snap.userInfo) || ("name" in snap.userInfo && !Boolean(snap.userInfo.name))) {
      navigate(ConfigLink.completeAccount);
    } else {
      navigate(ConfigLink.homePage);
    }
  };

  const getUserCity = async () => {
    await ApiInstance.account
      .getUserSetting()
      .then((res) => {
        console.log("userCity", res);
        setUserCity(res.data.cityId || "");
        checkProfileInfo(Boolean(res.data.cityId));
      })
      .catch((err) => {
        console.log("userCity", err);
        checkProfileInfo(false);
      });
  };

  const getWorkshop = async () => {
    await ApiInstance.workshop
      .getWorkshop()
      .then((res) => {
        console.log(res);
        const data = res.data.data;
        if (data.length === 0) {
          ServiceStorage.removeActiveWorkshop();
          ServiceStorage.removeTempReturnURL();
          ServiceStorage.removeTempActiveWorkshop();
          vState.activeWorkshop = null;
          vState.selectedWorkshop = "";
        } else {
          tools.setActiveWorkshop(data);
        }
        getUserCity();
      })
      .catch((err) => {
        console.log("err", err);
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا تلاش کنید.", "warning", "ورود / ثبت نام");
      });
  };

  useEffect(() => {
    if (auth.ready) {
      if (auth.authenticated) {
        getWorkshop();
      } else {
        if (code) {
          auth.login({ redirectUri: `${window.location.origin}/complete-invite?code=${code}` });
        } else {
          const url = localStorage.getItem("saved_url");
          localStorage.removeItem("saved_url");
          console.log("url", url);
          auth.login(url ? { redirectUri: url } : undefined);
        }
      }
    }
  }, [auth.ready, auth.authenticated]);

  return (
    <>
      {metaTags}
      <div></div>
    </>
  );
});

export { Introduction as default };

const styles = {};
