/* eslint-disable */
import { ApiInstance, ConfigLink, ServiceStorage, tools, useChangeTitle, useFetchQueryString, vState } from "core";
import { useAuth } from "hooks";
import { useEffect, useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ContainerScroll,
  NoWorkspaceEmptyState,
  PremiumNoticeModal,
  SuccessfulSubscription,
  Text,
} from "ui/components";
import { useSnapshot } from "valtio";
import { WorkshopCard } from "../components";

const env = import.meta.env;

interface IQueryStringTransfer {
  subPayed?: string;
}

const ManageWorkshops = ({}) => {
  const auth = useAuth();
  const metaTags = useChangeTitle("ویراژ - مدیریت کسب و کار");
  const snap = useSnapshot(vState);
  const navigate = useNavigate();
  let { subPayed } = useFetchQueryString<IQueryStringTransfer>();

  const [loading, setLoading] = useState(true);
  const [addLoading, setAddLoading] = useState(false);

  const [workshops, setWorkshops] = useState<any[]>([]);
  const [workshopRegistered, setWorkshopRegistered] = useState(false);
  const [premiumModal, setPremiumModal] = useState(false);
  const [successfulSubModal, setSuccessfulSubModal] = useState(false);

  const getWorkshop = async () => {
    await ApiInstance.workshop
      .getWorkshop()
      .then((result) => {
        console.log("workshops", result);

        if (result.data.data.length === 0) {
          setWorkshopRegistered(false);
          ServiceStorage.removeActiveWorkshop();
          setWorkshops([]);
          vState.selectedWorkshop = "";
          vState.activeWorkshop = null;
          vState.userWorkspaces = [];
        } else {
          setWorkshopRegistered(true);
          setWorkshops(result.data.data);
          tools.setActiveWorkshop(result.data.data);
        }
      })
      .catch((err) => console.log("err", err))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getWorkshop();

    if (subPayed && subPayed === "1") {
      setSuccessfulSubModal(true);
    }
  }, []);

  useEffect(() => {
    const activeWorkshop = ServiceStorage.getActiveWorkshop();

    if (!activeWorkshop && workshops.length !== 0) {
      ServiceStorage.setActiveWorkshop(workshops[0].id);
      // setActiveWorkshop(workshops[0].id);
      vState.selectedWorkshop = workshops[0].id;
      vState.activeWorkshop = workshops[0];
    }
  }, [workshops]);

  const getUser = async () => {
    await ApiInstance.account
      .getUserProfile()
      .then((res: any) => {
        console.log("user", res);
        vState.userInfo = res;
      })
      .finally(() => {
        setAddLoading(false);
      });
  };

  const addWorkshopHandler = async () => {
    setAddLoading(false);
    const ownersWorkshops = workshops.filter((item) => item.agent.accessLevel === "owner");
    console.log(ownersWorkshops);
    await getUser();

    if (snap.userInfo.premium || ownersWorkshops.length == 0) {
      ServiceStorage.removeTempWorkshop();
      localStorage.removeItem("temp_city");
      navigate(ConfigLink.createWorkspaceStepOne);
    } else {
      setPremiumModal(true);
    }
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={50} className="">
        <Text type="H4" className="ts-accent mb-6">
          مدیریت کسب و کار
        </Text>

        {loading ? (
          <>
            {[1, 2, 3].map(() => {
              return (
                <Skeleton style={{ height: "72px" }} containerClassName="w-full" className="w-full rounded-xl mb-4" />
              );
            })}
          </>
        ) : !workshopRegistered ? (
          <NoWorkspaceEmptyState lowMargin returnUrl={ConfigLink.manageWorkshops} />
        ) : (
          workshops.map((item: any) => {
            return (
              <WorkshopCard
                isActive={item.id === snap.selectedWorkshop}
                onClick={() => {
                  navigate({
                    pathname: ConfigLink.workshopPanel.replace(":id", item.id),
                  });
                }}
                data={item}
              />
            );
          })
        )}
        {!loading && workshopRegistered && (
          <Button
            onClick={addWorkshopHandler}
            icon={<IoIosAddCircle size={26} className="text-white -mt-1 ml-2" />}
            loading={addLoading}
            bcolor="primary"
            textType="H6"
            classNameContainer="w-full"
            className={`w-full rounded-xl h-12 mt-4`}
          >
            اضافه کردن کسب و کار
          </Button>
        )}
      </ContainerScroll>

      <PremiumNoticeModal isOpen={premiumModal} onClose={() => setPremiumModal(false)} />
      <SuccessfulSubscription
        isOpen={successfulSubModal}
        onClose={() => setSuccessfulSubModal(false)}
        newUrl={`${ConfigLink.manageWorkshops}`}
      />
    </>
  );
};

export { ManageWorkshops as default };
