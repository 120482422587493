/* eslint-disable */
import { UserProfile } from "core/interfaces";
import { axios } from "./api";

const baseUrl = `/account/profile`;
const settingBaseUrl = `/account/setting`;

interface IInvitationVal {
  name: string;
  mobile: string;
}

const account = {
  getUserProfile: async () => {
    const url = `${baseUrl}`;
    const result = await axios.get(url);
    return result.data as UserProfile;
  },
  updateUserProfile: async (name) => {
    const url = `${baseUrl}`;
    const result = await axios.put(url, { name, avatar: "" });
    return result.data as UserProfile;
  },
  generateShortLink: async () => {
    const url = `/share/app-link`;
    const result = await axios.post(url);
    return result;
  },
  sendInvitation: async (data: IInvitationVal) => {
    const url = `/share/app`;
    const result = await axios.post(url, data);
    return result;
  },
  getInvitationCount: async () => {
    const url = `/share/count`;
    const result = await axios.get(url);
    return result;
  },
  completeInvitation: async (id: string) => {
    const url = `/share/${id}`;
    const result = await axios.put(url);
    return result;
  },
  getUserSetting: async () => {
    const url = `${settingBaseUrl}`;
    const result = await axios.get(url);
    return result;
  },
  setUserSetting: async (data: {
    hideMyNumber?: boolean | undefined;
    cityId?: string | undefined;
    features?: string[] | undefined;
  }) => {
    const url = `${settingBaseUrl}`;
    const result = await axios.put(url, data);
    return result;
  },
  getPaymentsHistory: async () => {
    const url = `/premiums?pageSize=60`;
    const result = await axios.get(url);
    return result;
  },
  getPaymentsDetail: async (id: string) => {
    const url = `/premiums/${id}`;
    const result = await axios.get(url);
    return result;
  },
};

export { account };
