/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { vState } from "../../../../core";
import { BottomModal, Button, Text, useNotification } from "../../../components";

import { Form, Input } from "rsuite";

interface IChangePassModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ChangePassModal: React.FC<IChangePassModalProps> = React.memo(({ isOpen, onClose }) => {
  const history = useNavigate();
  const snap = useSnapshot(vState);

  const [step, setStep] = useState(1);

  const [code, setCode] = useState("");

  const [disabled, setDisabled] = useState(true);
  const [pass, setPass] = useState("");
  const [passRep, setPassRep] = useState("");

  useEffect(() => {
    setDisabled(pass.length === 0 || passRep.length === 0 || pass !== passRep);
  }, [pass, passRep]);

  const changePassHandler = () => {
    useNotification("رمز عبور با موفقیت تغییر کرد.", "success", "تغییر رمز");
    onClose();
  };

  return (
    <BottomModal isOpen={isOpen} onClose={onClose}>
      <div className="w-full flex flex-col justify-start items-start">
        <Text type="H4" className="ts-accent">
          تغییر رمز عبور
        </Text>
        {step === 1 ? (
          <>
            <Text type="P3" className="ts-gray-08 my-6">
              برای تغییر رمز عبور، لطفا کد ارسال شده بر روی دستگاه تلفن همراه خود را وارد کنید:
            </Text>
            <Input
              value={code}
              required
              type="number"
              maxLength={4}
              onChange={(e) => setCode(e)}
              className={`w-full rounded-xl outline-none h-12 mb-3`}
              placeholder="1234"
            />
            <div className="flex justify-center items-center mb-6">
              <Text type="P4" shrink inline className="ts-accent">
                کد را دریافت نکرده اید؟
              </Text>
              <Text inline type="P4" className="ts-link mr-1">
                ارسال مجدد کد
              </Text>
            </div>
            <Button
              onClick={() => setStep(2)}
              disabled={code.length !== 4}
              bcolor="primary"
              textType="H5"
              classNameContainer="w-full"
              className="rounded-lg h-10"
            >
              ادامه
            </Button>
          </>
        ) : (
          <>
            <Text type="P3" className="ts-gray-08 my-6">
              {`کلمه عبور  جدیدخود را برای شماره ${snap.userInfo.username} وارد کنید:`}
            </Text>
            <Form fluid className="w-full">
              <Text type="H6" className="ts-accent mb-2">
                رمز عبور جدید
              </Text>
              {/* <Input
              value={pass}
              required
              type="password"
              onChange={(e) => setPass(e)}
              className={`w-full rounded-xl outline-none h-12 mb-6`}
              placeholder="رمز عبور جدید"
            /> */}
              {/* <InputField
                fluid
                value={pass}
                type="password"
                onChange={(e) => setPass(e)}
                classNameContainer={`w-full rounded-xl mb-6`}
                placeholder="رمز عبور جدید"
              />

              <Text type="H6" className="ts-accent mb-2">
                تکرار رمز عبور جدید
              </Text>
              <InputField
                fluid
                value={passRep}
                type="password"
                error={pass !== passRep}
                valid={pass === passRep}
                onChange={(e) => setPassRep(e)}
                classNameContainer={`w-full rounded-xl mb-6`}
                placeholder="تکرار رمز عبور جدید"
              /> */}
              <Button
                onClick={changePassHandler}
                disabled={disabled}
                bcolor="primary"
                textType="H5"
                classNameContainer="w-full"
                className="rounded-lg h-10"
              >
                تغییر رمز عبور
              </Button>
            </Form>
          </>
        )}
      </div>
    </BottomModal>
  );
});

export { ChangePassModal };

const styles = {};
