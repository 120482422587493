/* eslint-disable */
import noSegment from "assets/images/no-segment-requested.svg";
import { ApiInstance, useChangeTitle, vState } from "core";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Loader, SelectPicker } from "rsuite";
import { ContainerScroll, Text, useNotification } from "ui/components";
import { useSnapshot } from "valtio";
import { MyPartRequestCard } from "../MyPartRequests/components";

type SORT_TYPE = "DATE" | "NAME" | "";

const MyPartProvidesActivity = ({}) => {
  const metaTags = useChangeTitle("ویراژ - فعالیت شما");
  const snap = useSnapshot(vState);

  const [requests, setRequests] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);

  const [sortReminders, setSortReminders] = useState<SORT_TYPE>("");

  const getRequests = async (sort: SORT_TYPE, pageIncreased: boolean) => {
    await ApiInstance.partSupply
      .getRequests(pageCount + 1, sort || null, true)
      .then((res) => {
        console.log(res);
        setCount(res.data.meta.total);
        if (pageIncreased) {
          let data = [...requests, ...res.data.data];
          setRequests(data);
          setPageCount((prev) => prev + 1);
        } else {
          setRequests(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error");
      })
      .finally(() => {
        setLoading(false);
        vState.isEndOfPage = false;
      });
  };

  useEffect(() => {
    getRequests(sortReminders, false);
  }, []);

  useEffect(() => {
    if (snap.isEndOfPage && count > requests.length && !loading) {
      getRequests(sortReminders, true);
    } else {
      vState.isEndOfPage = false;
    }
  }, [snap.isEndOfPage]);

  const sortChangeHandler = (value) => {
    setSortReminders(value);
    setLoading(true);
    getRequests(value, false);
  };

  const sortData = [
    {
      label: "به ترتیب تاریخ",
      value: "DATE",
    },
    {
      label: "به ترتیب الفبا",
      value: "NAME",
    },
  ];

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={115} className={`relative overflow-x-hidden`}>
        <div className="w-full flex justify-between items-center mb-3">
          <Text type="H4" className="ts-accent">
            فعالیت شما
          </Text>

          {loading ? (
            <Skeleton className="w-36 h-12 rounded-xl" />
          ) : (
            <SelectPicker
              size="lg"
              data={sortData}
              value={sortReminders}
              disabled={loading}
              cleanable={false}
              onChange={(value: any) => sortChangeHandler(value)}
              searchable={false}
              placeholder="مرتب سازی"
              className={`w-36 h-12 outline-none rounded-xl`}
            />
          )}
        </div>

        {loading ? (
          [1, 2, 3, 4, 5].map((item) => (
            <Skeleton key={item} containerClassName="w-full" className="w-full h-24 rounded-2xl mb-4" />
          ))
        ) : requests.length === 0 ? (
          <div className="w-full flex flex-col justify-center items-center mt-20">
            <img src={noSegment} alt="no-part" className="mb-6" />
            <Text type="P4" className="ts-gray-07">
              درخواست تامین قطعه‌ای از سمت شما تاکنون ثبت نشده است.
            </Text>
          </div>
        ) : (
          requests.map((item) => {
            return <MyPartRequestCard key={item.id} data={item} isActivity />;
          })
        )}
        {snap.isEndOfPage && !loading && (
          <div className="w-full flex justify-center my-6">
            <Loader size="xs" />
          </div>
        )}
      </ContainerScroll>
    </>
  );
};

export { MyPartProvidesActivity as default };
