/* eslint-disable */
import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import carOne from "../../../../assets/images/welcome-image-1.svg";
import carTwo from "../../../../assets/images/welcome-image-2.svg";
import { ConfigLink, ServiceStorage, vState } from "../../../../core";
import { Button, Image, Text } from "../../../components";

interface INoFeatureWelcomeProps {}

const env = import.meta.env;

const NoFeatureWelcome: React.FC<INoFeatureWelcomeProps> = React.memo(() => {
  const navigate = useNavigate();
  const snap = useSnapshot(vState);
  const returnUrl = ServiceStorage.getTempReturnURL();

  const [step, setStep] = useState(0);

  const buttonClickHandler = () => {
    if (step === 1) {
      navigate(ConfigLink.homePage);
    } else {
      setStep((prev) => prev + 1);
    }
  };

  const changeSlideHandler = (index) => {
    setStep(index);
  };

  const info = [
    {
      id: 0,
      title: "ویراژ چیست؟!",
      tip: "ویراژ یک پلتفرم تعاملی برای مالکین خودرو و کسب و کارهای خودرویی شامل تعمیرگاه ها، تعویض روغنی ها، و... می باشد. ",
      image: carOne,
    },
    {
      id: 1,
      title: "امکانات ویراژ ویژه کسب و کارهای خودرویی",
      tip: [
        `پذیرش و ترخیص خودرو`,
        `ثبت یادآور سرویس های دوره ای`,
        `نمایش کسب و کار روی نقشه`,
        `تماس با مشتریان`,
        `مدیریت چند کسب و کار`,
      ],
      image: carTwo,
    },
  ];

  return (
    <>
      <div className="w-full bg-white rounded-xl p-6 ts-shadow flex flex-col justify-start items-start">
        <Text type="H3" className="ts-accent mb-4 ts-letter-space text-right">
          خوش آمدید
        </Text>

        <div style={{ direction: "ltr" }} className="w-full flex flex-col justify-center items-center">
          <Carousel
            className="relative"
            selectedItem={step}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
            swipeable={true}
            onChange={changeSlideHandler}
          >
            {info.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{ direction: "rtl" }}
                  className="w-full flex flex-col justify-start items-start"
                >
                  <div style={{ height: "211px" }} className="w-full mb-8">
                    <Image src={item.image} alt={`car-image-${step}`} className={`w-full h-full `} />
                  </div>
                  <Text type="H5" className="mb-4 ts-letter-space ts-gray-08 text-right">
                    {item.title}
                  </Text>
                  <ul
                    className={`ts-text-p3 mb-4 ts-gray-08 text-right ${
                      typeof item.tip === "string" ? "list-none" : "list-disc mr-6"
                    }`}
                  >
                    {typeof item.tip === "string"
                      ? item.tip
                      : item.tip.map((tipItem, indexes) => {
                          return (
                            <li key={indexes} className="ts-text-p3 ts-gray-08">
                              {tipItem}
                            </li>
                          );
                        })}
                  </ul>
                </div>
              );
            })}
          </Carousel>
        </div>

        <Button
          bcolor="primary"
          textType="H6"
          classNameContainer="w-full"
          className="w-full h-12 rounded-xl ts-letter-space mt-7"
          onClick={buttonClickHandler}
        >
          {step === 1 ? "متوجه شدم" : "ادامه"}
        </Button>
      </div>
    </>
  );
});

export { NoFeatureWelcome };

const styles = {};
