/* eslint-disable */
import { configs } from "#configs";
import defaultCar from "assets/images/part-request-default.svg";
import { ConfigLink, tools, vState } from "core";
import React, { useEffect, useState } from "react";
import { HiCheckCircle } from "react-icons/hi";
import { IoIosCloseCircle } from "react-icons/io";
import { MdAccessTimeFilled, MdChevronLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Text } from "ui/components";
import { useSnapshot } from "valtio";

interface IMyPartRequestCardProps {
  data: any;
  isActivity?: boolean;
}

const MyPartRequestCard: React.FC<IMyPartRequestCardProps> = React.memo(({ data, isActivity = false }) => {
  const navigate = useNavigate();
  const snap = useSnapshot(vState);

  const [status, setStatus] = useState<"provided" | "cancelled" | null>(null);

  const requestClickHandler = () => {
    navigate({
      pathname: isActivity
        ? ConfigLink.usersPartRequestDetail.replace(":id", data.id)
        : ConfigLink.myPartRequestInfo.replace(":id", data.id),
      // search: useQueryString({ id: data.id }),
    });
  };

  useEffect(() => {
    if (isActivity) {
      if (data.suppliers.length !== 0) {
        const userRequest = data.suppliers.filter((item) => item.userId === snap.userInfo.id);
        if (userRequest.length !== 0) {
          setStatus(userRequest[0].status);
        }
      }
    }
  }, [data]);

  return (
    <div
      onClick={requestClickHandler}
      key={data.id}
      className="w-full flex justify-between items-center p-3 bg-white rounded-2xl ts-shadow mb-2 cursor-pointer"
    >
      <div className="flex h-full flex-col justify-between items-start">
        <div className="flex justify-start items-start mb-3">
          <div className="w-12 h-12">
            <img
              src={data.images && data.images.length !== 0 ? `${configs().imageUrl}/${data.images[0]}` : defaultCar}
              alt="default"
              className={`w-full h-full ${
                data.images && data.images.length !== 0 ? "object-cover aspect-square rounded-xl" : ""
              }`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = defaultCar;
              }}
            />
          </div>
          <div className="flex flex-col justify-start items-start mr-3">
            <Text type="H6" className="ts-accent">
              {data.name || "---"}
            </Text>
            <Text type="P4" className="ts-gray-07 mt-2">
              {data.car.name || "---"}
            </Text>
          </div>
        </div>
        {isActivity ? (
          <div className="flex justify-center items-center">
            {status === "cancelled" ? (
              <IoIosCloseCircle size={18} className="ts-secondary-red -mt-0.5" />
            ) : (
              <HiCheckCircle size={18} className="ts-success -mt-0.5" />
            )}
            <Text type="HM" className={`${status === "cancelled" ? "ts-secondary-red" : "ts-success"} mr-1`}>
              {status === "cancelled"
                ? `انصراف از تامین - ساعت ${tools.convertDateToIranTime(data.createdAt)}`
                : `اعلام امکان تامین  - ساعت ${tools.convertDateToIranTime(data.createdAt)}`}
            </Text>
          </div>
        ) : (
          <div className="flex justify-center items-center">
            {data.suppliers.length === 0 ? (
              <MdAccessTimeFilled size={18} className="ts-warning -mt-0.5" />
            ) : (
              <HiCheckCircle size={18} className="ts-success -mt-0.5" />
            )}
            <Text type="HM" className={`${data.suppliers.length === 0 ? "ts-warning" : "ts-success"} mr-1`}>
              {data.suppliers.length === 0
                ? "تاکنون تامین کننده‌ای پیدا نشده است."
                : `${data.suppliers.length} تامین کننده میتوانند این قطعه را تامین کنند.`}
            </Text>
          </div>
        )}
      </div>
      <MdChevronLeft size={25} className="ts-accent" />
    </div>
  );
});

export { MyPartRequestCard };

const styles = {};
