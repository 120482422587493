/* eslint-disable */
import { tools } from "core";
import { isNull } from "lodash";
import React from "react";
import { HiCheckCircle, HiEye, HiPhone } from "react-icons/hi";
import { Button, Text } from "ui/components";

interface ISupplierCardProps {
  data: any;
  viewClicked: (workshop) => void;
}

const SupplierCard: React.FC<ISupplierCardProps> = React.memo(({ data, viewClicked }) => {
  return (
    <div key={data.id} className="w-full rounded-2xl ts-shadow p-4 mb-3 flex flex-col justify-start items-start">
      <div className="w-full flex justify-between items-center mb-3">
        <Text type="H6" className="ts-accent">
          {data.workshop.name || "---"}
        </Text>
        <div className="flex justify-center items-center">
          <HiCheckCircle size={18} className="ts-success -mt-0.5" />
          <Text type="HM" className="ts-success mr-1">
            {data.createdAt ? new Date(data.createdAt).toLocaleDateString("fa-IR") : "---"}
          </Text>
        </div>
      </div>
      <Text type="P4" className="ts-gray-07 mb-2">
        قیمت پیشنهادی: {tools.thousandSeparator(parseFloat(data.price).toFixed(0).toString())} تومان
      </Text>
      <Text type="P4" className="ts-gray-06 mb-4">
        توضیحات: {data.description || "---"}
      </Text>
      <div className="w-full flex justify-between items-center gap-4">
        <Button
          bcolor="primary"
          disabled={isNull(data.workshop.phone) || (data.workshop.phone && data.workshop.phone.length === 0)}
          onClick={() => window.open(`tel:${data.workshop.phone || ""}`)}
          icon={<HiPhone size={21} className="text-white -mt-1 -rotate-90" />}
          classNameContainer={"ts-half-width"}
          className="w-full h-10"
        >
          تماس
        </Button>
        <Button
          bcolor="primaryTransparent"
          onClick={() => viewClicked(data.workshop)}
          icon={<HiEye size={21} className="ts-primary -mt-1" />}
          classNameContainer={"ts-half-width"}
          className="w-full h-10"
        >
          مشاهده پروفایل
        </Button>
      </div>
    </div>
  );
});

export { SupplierCard };

const styles = {};
