/* eslint-disable */
import historyIcon from "assets/images/sms-history-icon-black.svg";
import { ApiInstance, ConfigLink, useChangeTitle, useObjectState, useQueryString, vState } from "core";
import { addIDAsTempId, useAuth } from "hooks";
import { useEffect, useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { FaStar } from "react-icons/fa";
import { HiEye, HiLogin, HiPencilAlt, HiTrash, HiUsers } from "react-icons/hi";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import { ContainerScroll, DesktopTitle, Text } from "ui/components";
import { useSnapshot } from "valtio";
import { DeleteWorkshopModal } from "../components";

const env = import.meta.env;

const workshopPanel = ({}) => {
  const auth = useAuth();
  const metaTags = useChangeTitle("ویراژ - تنظیمات کسب و کار");
  const { id } = useParams();
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);

  const [info, setInfo] = useObjectState<any>({
    name: "",
    categories: [],
    agent: {
      accessLevel: "owner",
    },
    categoryInfo: {},
    services: [],
    description: "",
    // cars: [],
    phone: "",
    city: "",
    address: "",
    location: null,
  });

  const getWorkshop = async () => {
    await ApiInstance.workshop
      .viewSingleWorkshop(id || "")
      .then((result) => {
        console.log(result);
        setInfo(result.data.data);
      })
      .catch((err) => console.log("err", err))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getWorkshop();
  }, []);

  const setUrl = () => {
    addIDAsTempId(info.id);
  };

  const manageUsersHandler = () => {
    setUrl();
    navigate({
      pathname: ConfigLink.manageWorkshopStaffs,
      search: useQueryString({ id: info.id, name: info.name || "---", access: info.agent.accessLevel || "agent" }),
    });
  };

  const onEditClicked = () => {
    setUrl();
    navigate({ pathname: ConfigLink.editWorkshop.replace(":id", info.id) });
  };

  const bulkSmsHistoryHandler = () => {
    setUrl();
    navigate({ pathname: ConfigLink.workshopBulkSmsHistory, search: useQueryString({ id: info.id }) });
  };

  const viewClickHandler = () => {
    setUrl();
    navigate({ pathname: ConfigLink.viewWorkshop.replace(":id", info.id) });
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={50} className="">
        <DesktopTitle title="مدیریت کسب و کار" />
        {loading ? (
          <div className="w-full flex flex-col justify-start items-start">
            <Skeleton className="w-14 h-5 mb-2" />
            <Skeleton className="w-28 h-5 mb-6" />
            {[1, 2, 3, 4, 5].map(() => {
              return (
                <Skeleton style={{ height: "72px" }} containerClassName="w-full" className="w-full rounded-xl mb-2" />
              );
            })}
          </div>
        ) : (
          <>
            <span className="w-full flex justify-between items-start mb-6">
              <span className="flex flex-col justify-start items-start">
                <span className="flex justify-center items-center mb-2">
                  <Text type={"H4"} className="ts-gray-08 ml-2">
                    {info.name || "بدون نام"}
                  </Text>
                  {info?.feedbacks && (
                    <>
                      <Text type="P3" className="ts-gray-06 ml-1">
                        {parseFloat(info.feedbacks.averageScore || 0).toFixed(1)}
                      </Text>
                      <FaStar size={20} color="#FFA23A" className="-mt-1.5" />
                    </>
                  )}
                </span>
                <Text type={"P4"} className="ts-gray-06">
                  {info.categoryInfo
                    ? info.categoryInfo
                    : info && info.categories && info.categories.map((item) => item.name).join(", ")}
                </Text>
              </span>
              {/* <span
                onClick={viewWorkshopHandler} className="w-9 h-9 rounded-xl bg-white ts-shadow flex justify-center items-center cursor-pointer"
              >
                <IoMdShare size={21} className="ts-primary" />
              </span> */}
            </span>

            <div
              style={{ height: "72px" }}
              onClick={viewClickHandler}
              className="w-full p-6 mb-2 rounded-xl ts-shadow flex justify-between items-center"
            >
              <div className="cursor-pointer flex justify-end items-center">
                <HiEye size={23} className="ts-accent -mt-px" />
                <Text type="P3" className="ts-accent mr-2">
                  مشاهده
                </Text>
              </div>
              <BiChevronLeft size={24} className="ts-accent" />
            </div>

            {info && info.agent && info.agent.accessLevel !== "agent" && (
              <div
                style={{ height: "72px" }}
                onClick={onEditClicked}
                className="w-full p-6 mb-2 rounded-xl ts-shadow flex justify-between items-center"
              >
                <div className="cursor-pointer flex justify-end items-center">
                  <HiPencilAlt size={23} className="ts-accent" />
                  <Text type="P3" className="ts-accent mr-2">
                    ویرایش
                  </Text>
                </div>
                <BiChevronLeft size={24} className="ts-accent" />
              </div>
            )}

            {snap.activeFeatures.includes("bulk_sms") && (
              <div
                style={{ height: "72px" }}
                onClick={bulkSmsHistoryHandler}
                className="w-full p-6 mb-2 rounded-xl ts-shadow flex justify-between items-center"
              >
                <div className="cursor-pointer flex justify-end items-center">
                  <img src={historyIcon} alt="history" />
                  <Text type="P3" className="ts-accent mr-2">
                    تاریخچه پیامک انبوه
                  </Text>
                </div>
                <BiChevronLeft size={24} className="ts-accent" />
              </div>
            )}

            {info.categories.length !== 0 &&
              info.categories
                .map((item) => item.features)
                .flat()
                .includes("user_management") && (
                <div
                  style={{ height: "72px" }}
                  onClick={manageUsersHandler}
                  className="w-full p-6 mb-2 rounded-xl ts-shadow flex justify-between items-center"
                >
                  <div className="cursor-pointer flex justify-end items-center">
                    <HiUsers size={20} className="ts-accent" />
                    <Text type="P3" className="ts-accent mr-2">
                      {info.agent && info.agent.accessLevel !== "agent" ? "مدیریت کارکنان" : "لیست کارکنان"}
                    </Text>
                  </div>
                  <BiChevronLeft size={24} className="ts-accent" />
                </div>
              )}

            <div
              style={{ height: "72px" }}
              onClick={() => setDeleteModal(true)}
              className="w-full p-6 rounded-xl ts-shadow flex justify-between items-center"
            >
              <div className="cursor-pointer flex justify-end items-center">
                {info.agent && info.agent.accessLevel === "owner" ? (
                  <HiTrash size={21} color="#CA2525" className="ts-secondary-red" />
                ) : (
                  <HiLogin size={21} color="#CA2525" className="ts-secondary-red" />
                )}
                <Text type="P3" className="ts-secondary-red mr-2">
                  {info.agent && info.agent.accessLevel !== "owner" ? "خروج از کسب و کار" : "حذف کسب و کار"}
                </Text>
              </div>
            </div>
          </>
        )}
      </ContainerScroll>

      <DeleteWorkshopModal
        data={info}
        type={info && info.agent && info.agent.accessLevel === "owner" ? "delete" : "logout"}
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        onEnd={() => navigate(ConfigLink.manageWorkshops, { replace: true })}
      />
    </>
  );
};

export { workshopPanel as default };
