/* eslint-disable */
import React from "react";
import { MdSupportAgent } from "react-icons/md";
import { RxReload } from "react-icons/rx";
import updateBanner from "../../assets/images/update-banner.svg";
import { BottomModal, Button, Text } from "./";

interface INeedUpdateModalProps {
  isOpen: boolean;
  onClose: () => void;
  version: string;
}

const NeedUpdateModal: React.FC<INeedUpdateModalProps> = React.memo(({ isOpen, onClose, version }) => {
  const updateHandler = () => {
    window.location.reload();
    window.location.reload();
    window.location.reload();
  };

  const callBackupHandler = () => {
    window.open("tel:02191693862", "_self");
  };

  return (
    <BottomModal centerScreen isOpen={isOpen} onClose={onClose} className="p-6 rounded-xl mx-0">
      <div className="w-full flex justify-center items-center">
        <img src={updateBanner} className="" alt="banner-update" />
      </div>
      <Text type="H3" className="ts-accent my-6 text-center">
        بروزرسانی نسخه جدید
      </Text>
      <Text
        type="P4"
        className="ts-accent mb-6"
      >{`نسخه جدید (${version}) در دسترس است. برای استفاده از امکانات جدید اپ را بروزرسانی کنید. در صورت مشاهده دوباره این پیام، با پشتیبانی تماس بگیرید.`}</Text>
      <Button
        bcolor="primary"
        onClick={updateHandler}
        icon={<RxReload size={21} className="-mt-px" />}
        classNameContainer="w-full"
        className="w-full h-12 rounded-xl mb-3"
        textType="H6"
      >
        بروزرسانی
      </Button>
      <Button
        bcolor="primaryTransparent"
        onClick={callBackupHandler}
        icon={<MdSupportAgent size={21} className="-mt-px" />}
        classNameContainer="w-full"
        className="w-full h-12 rounded-xl"
        textType="H6"
      >
        ارتباط با پشتیبانی
      </Button>
    </BottomModal>
  );
});

export { NeedUpdateModal };

const styles = {};
