/* eslint-disable */
import { ApiInstance, vState } from "core";
import React, { useState } from "react";
import { Checkbox } from "rsuite";
import { useSnapshot } from "valtio";
import { BottomModal, Button, Text, useNotification } from "../../../components";

interface IShopPolicyModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDone: () => void;
}

const ShopPolicyModal: React.FC<IShopPolicyModalProps> = React.memo(({ isOpen, onClose, onDone }) => {
  const snap = useSnapshot(vState) as typeof vState;
  const [checked, setChecked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const approveBtnHandler = async () => {
    setLoading(true);
    await ApiInstance.workshop
      .setWorkshopShopPolicy(snap.activeWorkshop?.id || "")
      .then((res) => {
        console.log(res);
        if (snap.activeWorkshop) {
          vState.activeWorkshop = {
            ...snap.activeWorkshop,
            agent: {
              ...snap.activeWorkshop.agent,
              termsAcceptedAt: new Date().toISOString(),
            },
          };
        }
        onClose();
        setTimeout(() => {
          onDone();
        }, 300);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <BottomModal isOpen={isOpen} onClose={onClose}>
        <div className="w-full flex flex-col justify-start items-start">
          <Text type="H4" className="ts-accent mb-6">
            شرایط ساخت فروشگاه در ویراژ
          </Text>

          <Text type="P3" className="ts-gray-08 mb-6 whitespace-pre-wrap">
            {`لازم است برای ساخت فروشگاه  شرایط و قوانین ویراژ را مطالعه کنید:

۱- اطلاعات وارد شده عینا برای مالکین خودرو قابل مشاهده می باشد. خواهشمندیم اطلاعات را به صورت واضح و کامل وارد نمایید.

۲- مسئولیت ناشی از درج اطلاعات نادرست و خلاف مقررات و قانون به عهده تعریف کننده قطعات می باشد و در صورت بروز تخلف پیگرد قضایی برای مالک شماره همراه تعریف کننده کسب و کار خواهد داشت.`}
          </Text>

          <div className="w-full -mr-4 mb-5">
            <Checkbox checked={checked} onChange={() => setChecked((prev) => !prev)}>
              <Text type="P4" className="ts-accent mr-5 -mt-px">
                شرایط بالا را مطالعه کرده ام و میپذیرم.
              </Text>
            </Checkbox>
          </div>

          <div className="w-full flex justify-between">
            <Button
              onClick={approveBtnHandler}
              loading={loading}
              disabled={!checked}
              bcolor="primary"
              classNameContainer="ts-half-width"
              className="w-full rounded-xl h-12"
            >
              ساخت فروشگاه
            </Button>
            <Button
              onClick={onClose}
              bcolor="blackTransparent"
              classNameContainer="ts-half-width"
              className="w-full rounded-xl h-12"
            >
              بستن
            </Button>
          </div>
        </div>
      </BottomModal>
    </>
  );
});

export { ShopPolicyModal };

const styles = {};
