/* eslint-disable */
import React, { useEffect, useState } from "react";
import { HiTrash } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { ApiInstance, vState } from "../../../../core";
import { useAuth } from "../../../../hooks";
import { BottomModal, Button, Text, useNotification } from "../../../components";

type UserType = "active" | "pending";

interface IDeleteUserModalProps {
  data: any;
  isOpen: boolean;
  userType: UserType;
  onClose: () => void;
  onEnd: () => void;
}

const DeleteUserModal: React.FC<IDeleteUserModalProps> = React.memo(({ data, isOpen, onClose, onEnd, userType }) => {
  const history = useNavigate();
  const auth = useAuth();
  const snap = useSnapshot(vState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  const successRequest = async () => {
    useNotification("کاربر با موفقیت حذف شد.", "success", "حذف کاربر");
    onEnd && (await onEnd());
    setTimeout(() => {
      onClose();
    }, 1500);
  };

  const failureRequest = () => {
    useNotification("مشکلی رخ داده است. لطفا مجددا تلاش کنید.", "error", "حذف کاربر");
  };

  const deleteAcceptedAgent = async () => {
    await ApiInstance.agents
      .deleteApprovedAgents(data.id)
      .then((res) => {
        console.log(res);
        successRequest();
      })
      .catch((err) => {
        console.log(err);
        failureRequest();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deletePendingAgent = async () => {
    await ApiInstance.agents
      .deletePendingAgents(data.id)
      .then((res) => {
        console.log(res);
        successRequest();
      })
      .catch((err) => {
        console.log(err);
        failureRequest();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteBtnHandler = async () => {
    setLoading(true);

    userType === "active" ? deleteAcceptedAgent() : deletePendingAgent();
  };

  return (
    <BottomModal isOpen={isOpen} onClose={onClose}>
      <div className="w-full flex flex-col justify-start items-start">
        <div className="flex justify-end items-center mb-6">
          <HiTrash size={21} color="#CA2525" className="ts-secondary-red -mt-1" />
          <Text type="H4" className="ts-secondary-red mr-2">
            حذف کاربر
          </Text>
        </div>
        <Text type="P3" className="ts-accent mb-8">
          آیا می خواهید کاربر "{data && data.name}" را از لیست کاربران خود حذف کنید؟
        </Text>
        <div className=" w-full flex justify-between items-center">
          <Button
            onClick={deleteBtnHandler}
            loading={loading}
            bcolor="black"
            textType="H6"
            classNameContainer="ts-half-width"
            className="w-full rounded-lg h-12"
          >
            بله، حذف شود
          </Button>

          <Button
            onClick={() => onClose()}
            bcolor="blackTransparent"
            textType="H6"
            classNameContainer="ts-half-width"
            className="w-full rounded-lg h-12"
          >
            خیر
          </Button>
        </div>
      </div>
    </BottomModal>
  );
});

export { DeleteUserModal };

const styles = {};
