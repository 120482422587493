/* eslint-disable */
import React, { useEffect } from "react";
import { HiLogin } from "react-icons/hi";
import { ServiceStorage, vState } from "../../../../core";
import { useAuth } from "../../../../hooks";
import { BottomModal, Button, Text } from "../../../components";

interface ILogoutModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const LogoutModal: React.FC<ILogoutModalProps> = React.memo(({ isOpen, onClose }) => {
  const auth = useAuth();

  useEffect(() => {}, []);

  const logoutHandler = () => {
    vState.isAuthenticated = false;
    vState.userInfo = {
      id: "",
      name: "",
      username: "",
      phoneNumber: "",
      roles: [],
      groups: [],
      premium: null,
      mobile: "",
      userProfileId: "",
      avatar: "",
    };
    ServiceStorage.removeAuth();
    ServiceStorage.removeToken();
    ServiceStorage.removeActiveWorkshop();
    auth.logout();
  };

  return (
    <BottomModal isOpen={isOpen} onClose={onClose}>
      <div className="w-full flex flex-col justify-start items-start">
        <div className="flex justify-end items-center mb-6">
          <HiLogin size={21} className="ts-secondary-red" />
          <Text type="H4" className="ts-secondary-red mr-2">
            خروج از حساب کاربری
          </Text>
        </div>
        <Text type="P3" className="ts-accent mb-8">
          آيا نسبت به خروج حساب کاربری خود از روی این دستگاه اطمینان دارید؟
        </Text>
        <div className=" w-full flex justify-between items-center">
          <Button
            onClick={logoutHandler}
            bcolor="black"
            textType="H6"
            classNameContainer="ts-half-width"
            className="w-full rounded-lg h-12"
          >
            بله، خارج می‌شوم
          </Button>

          <Button
            onClick={() => onClose()}
            bcolor="blackTransparent"
            textType="H6"
            classNameContainer="ts-half-width"
            className="w-full rounded-lg h-12"
          >
            خیر
          </Button>
        </div>
      </div>
    </BottomModal>
  );
});

export { LogoutModal };

const styles = {};
